import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { View } from 'react-native';
import { MD3Theme } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { UserProfile, UserService } from '../../services/UserService';
import i18n from '../../translations/i18n';
import { setStorageRegionAndDistrict } from '../../utils/locationHelpers';
import DistrictSelector from '../form/DistrictSelector';
import Loading from '../shared/Loading';
import { showError } from '../shared/Toast';
import ProfileActionButton from './ProfileActionButton';

const ProfileActions: React.FC<{
  theme: MD3Theme;
  profile: NonNullable<UserProfile>;
}> = ({ theme, profile }) => {
  const [loading, setLoading] = useState(false);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  const isAdmin = !!profile.admin;
  const isManager = Array.isArray(profile.artists) && !!profile.artists.length;
  const isOwner = Array.isArray(profile.places) && !!profile.places.length;
  const hasPostPermission = isAdmin || isManager || isOwner;
  return (
    <View>
      {loading && <Loading />}
      <DistrictSelector
        style={{ width: '100%' }}
        value={profile.district!}
        onSelect={async (district, location) => {
          setLoading(true);
          try {
            const userData = await UserService.updateUserData({
              ...location,
              district,
            });
            await setStorageRegionAndDistrict({
              latitude: userData?.latitude!,
              longitude: userData?.longitude!,
              latitudeDelta: userData?.latitudeDelta!,
              longitudeDelta: userData?.longitudeDelta!,
              district,
            });
          } catch (error) {
            showError(error);
          }
          setLoading(false);
        }}
      >
        <ProfileActionButton
          theme={theme}
          icon={'crosshairs'}
          label={`${i18n.t('profile.actions.region.label')}: ${
            profile.district
          }`}
        />
      </DistrictSelector>
      <ProfileActionButton
        theme={theme}
        icon={'bookmark-outline'}
        label={i18n.t('profile.actions.savedEvents')}
        onPress={() => navigation.push('UserSavedEvents')}
      />
      <ProfileActionButton
        theme={theme}
        icon={'star-check-outline'}
        label={i18n.t('profile.actions.attendedEvents')}
        onPress={() => navigation.push('UserAttendedEvents')}
      />
      <ProfileActionButton
        theme={theme}
        icon={'heart-outline'}
        label={i18n.t('profile.actions.likedArtists')}
        onPress={() => navigation.push('UserLikedArtists')}
      />
      <ProfileActionButton
        theme={theme}
        icon={'star-outline'}
        label={i18n.t('profile.actions.likedPlaces')}
        onPress={() => navigation.push('UserFavoritePlaces')}
      />
      <ProfileActionButton
        theme={theme}
        icon={'thumb-up-outline'}
        label={i18n.t('profile.actions.likedStories')}
        onPress={() => navigation.push('UserLikedStories')}
      />
      <ProfileActionButton
        theme={theme}
        icon={'text-box-outline'}
        label={i18n.t('profile.actions.postedStories')}
        onPress={() => navigation.push('UserPostedStories')}
      />
      <ProfileActionButton
        theme={theme}
        icon={'comment-outline'}
        label={i18n.t('profile.actions.postedComments')}
        onPress={() => navigation.push('UserPostedComments')}
      />
      <ProfileActionButton
        theme={theme}
        icon={'account-cancel-outline'}
        label={i18n.t('profile.actions.blockedUsers')}
        onPress={() => navigation.push('UserBlockedUsers')}
      />
      {hasPostPermission && (
        <>
          <ProfileActionButton
            theme={theme}
            icon={'calendar'}
            label={i18n.t('profile.actions.postedEvents')}
            onPress={() => navigation.push('UserPostedEvents')}
          />
          <ProfileActionButton
            theme={theme}
            icon={'account-music-outline'}
            label={i18n.t('profile.actions.postedArtists')}
            onPress={() => navigation.push('UserPostedArtists')}
          />
          <ProfileActionButton
            theme={theme}
            icon={'map-marker-outline'}
            label={i18n.t('profile.actions.postedPlaces')}
            onPress={() => navigation.push('UserPostedPlaces')}
          />
        </>
      )}
      {isManager && (
        <ProfileActionButton
          theme={theme}
          icon={'account-edit-outline'}
          label={i18n.t('profile.actions.managedArtists')}
          onPress={() => navigation.push('UserManagedArtists')}
        />
      )}
      {isOwner && (
        <ProfileActionButton
          theme={theme}
          icon={'map-marker-account-outline'}
          label={i18n.t('profile.actions.managedPlaces')}
          onPress={() => navigation.push('UserManagedPlaces')}
        />
      )}
    </View>
  );
};

export default ProfileActions;
