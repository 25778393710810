import React from 'react';
import { useTheme } from 'react-native-paper';

const SpotifyPlayer: React.FC<{ height: 352 | 152; spotifyId: string }> = ({
  height,
  spotifyId,
}) => {
  const theme = useTheme();
  return (
    <iframe
      style={{
        backgroundColor: theme.colors.background,
        width: '100%',
        height,
        border: 0,
        borderRadius: 15,
      }}
      allowFullScreen
      allow={
        'clipboard-write, encrypted-media, fullscreen, picture-in-picture,'
      }
      src={`https://open.spotify.com/embed/artist/${spotifyId}`}
    />
  );
};

export default SpotifyPlayer;
