import * as Linking from 'expo-linking';
import React from 'react';
import { Button, MD3Theme, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { Artist } from '../../repositories/ArtistRepository';
import i18n from '../../translations/i18n';
import { getContactLink } from '../../utils/regexps';
import { colors } from '../../utils/themes';

const BottomContainer = styled.View<{ theme: MD3Theme }>`
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.elevation.level4};
  background-color: ${({ theme }) => theme.colors.background};
`;

const PaymentContainer = styled.View<{ theme: MD3Theme }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ArtistDetailBottomBar: React.FC<{
  artist: NonNullable<Artist>;
  theme: MD3Theme;
}> = ({ artist, theme }) => {
  const contactLink = getContactLink(artist.contact ?? '');
  return (
    <BottomContainer theme={theme}>
      <PaymentContainer>
        {!!artist.toss_payment_url && (
          <Button
            style={{ flex: 1, marginRight: 10 }}
            buttonColor={colors.tossBackgroundColor}
            onPress={() => Linking.openURL(artist.toss_payment_url!)}
          >
            <Text style={{ fontWeight: 'bold', color: colors.tossColor }}>
              {i18n.t('artist.bottomBar.toss_payment_url')}
            </Text>
          </Button>
        )}
        {!!artist.kakao_payment_url && (
          <Button
            style={{ flex: 1 }}
            buttonColor={colors.kakaoBackgroundColor}
            onPress={() => Linking.openURL(artist.kakao_payment_url!)}
          >
            <Text style={{ fontWeight: 'bold', color: colors.kakaoColor }}>
              {i18n.t('artist.bottomBar.kakao_payment_url')}
            </Text>
          </Button>
        )}
      </PaymentContainer>
      {!!artist.shop_url && (
        <Button
          style={{ width: '100%', marginBottom: 10 }}
          buttonColor={theme.colors.secondaryContainer}
          onPress={() => Linking.openURL(artist.shop_url!)}
        >
          <Text
            style={{
              fontWeight: 'bold',
              color: theme.colors.onSecondaryContainer,
            }}
          >
            {i18n.t('artist.bottomBar.shop_url')}
          </Text>
        </Button>
      )}
      {!!contactLink && (
        <Button
          style={{ width: '100%' }}
          buttonColor={theme.colors.secondaryContainer}
          onPress={() => Linking.openURL(contactLink)}
        >
          <Text
            style={{
              fontWeight: 'bold',
              color: theme.colors.onSecondaryContainer,
            }}
          >
            {i18n.t('artist.bottomBar.contact')}
          </Text>
        </Button>
      )}
    </BottomContainer>
  );
};

export default ArtistDetailBottomBar;
