import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ArtistForm from '../components/artist/ArtistForm';
import Loading from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import ArtistService, { useArtistDetailQuery } from '../services/ArtistService';
import { useUserProfileQuery } from '../services/UserService';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';

const ArtistEditScreen: React.FC = () => {
  const insets = useSafeAreaInsets();
  const route = useRoute<RouteProp<MainStackParamList, 'ArtistEdit'>>();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  const artistId = route.params.artistId;

  useEffect(() => {
    if (navigationReady && !artistId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, artistId]);

  const { data: user } = useUserProfileQuery();
  const {
    data: artist,
    isLoading,
    isFetching,
    isFetched,
  } = useArtistDetailQuery(artistId);

  useEffect(() => {
    if (isFetched) {
      if (!ArtistService.hasEditPermission(artist, user)) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'EventFeed' });
        }
      }
    }
  }, [artist]);

  const loading = isLoading || isFetching || !artist;
  return loading ? (
    <Loading />
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      <ArtistForm artist={artist} />
    </KeyboardAwareScrollView>
  );
};

export default ArtistEditScreen;
