import { MD3Theme } from 'react-native-paper/src/types';
import styled from 'styled-components/native';

export const ChipContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Chip = styled.View<{ theme: MD3Theme }>`
  padding: 3px;
  border-radius: 5px;
  color: ${props => props.theme.colors.inverseSurface};
  background-color: ${props => props.theme.colors.inverseOnSurface};
  max-width: 100%;
`;
