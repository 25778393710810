import axios from 'axios';

import supabase from '../supabase';
import { API_URL } from './constants';

const http = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  timeout: 10000,
});

http.interceptors.request.use(config => {
  config.headers['Authorization'] = `Bearer ${supabase['changedAccessToken']}`;
  return config;
});

export default http;
