import React from 'react';
import { Text } from 'react-native-paper';

import i18n from '../../translations/i18n';
import { Column } from '../shared/Layouts';

export const EmptyCommentCard: React.FC<{
  onPressReset: () => void;
}> = ({ onPressReset }) => (
  <Column style={{ flex: 1, gap: 10, marginTop: 20 }}>
    <Text>{i18n.t('comment.cardList.noComment')}</Text>
  </Column>
);
