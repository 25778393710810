import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { Story } from '../../repositories/StoryRepository';
import i18n from '../../translations/i18n';
import { APP_URL } from '../../utils/constants';
import FavoriteButton from '../shared/FavoriteButton';
import { Row } from '../shared/Layouts';
import { SmallLoading } from '../shared/Loading';
import ShareButton, { share } from '../shared/ShareButton';

const StoryLikeComment: React.FC<{
  story: Story;
  isSaving?: boolean;
  onPressLike: () => Promise<void>;
  onPressComment: () => Promise<void>;
}> = ({ story, isSaving, onPressLike, onPressComment }) => {
  const theme = useTheme();
  return (
    <Row
      style={{
        paddingVertical: 15,
        justifyContent: 'flex-start',
        borderStyle: 'solid',
        borderTopColor: theme.colors.elevation.level2,
        borderTopWidth: 1,
        borderBottomColor: theme.colors.elevation.level2,
        borderBottomWidth: 1,
      }}
    >
      <TouchableOpacity onPress={onPressLike} style={{ flex: 1 }}>
        <Row
          style={{
            alignItems: 'center',
            gap: 3,
          }}
        >
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: 20,
              height: 20,
            }}
          >
            {isSaving ? (
              <SmallLoading size={15} />
            ) : (
              <FavoriteButton
                icon={story.liked?.length ? 'thumb-up' : 'thumb-up-outline'}
                size={20}
                color={theme.colors.outline}
                onPress={onPressLike}
              />
            )}
          </View>
          <Text style={{ color: theme.colors.outline }}>
            {i18n.t('common.like')}
          </Text>
        </Row>
      </TouchableOpacity>
      <TouchableOpacity style={{ flex: 1 }} onPress={onPressComment}>
        <Row
          style={{
            alignItems: 'center',
            gap: 3,
          }}
        >
          <FavoriteButton
            icon={'comment-outline'}
            size={20}
            color={theme.colors.outline}
            onPress={onPressComment}
          />
          <Text style={{ color: theme.colors.outline }}>
            {i18n.t('common.comment')}
          </Text>
        </Row>
      </TouchableOpacity>
      <TouchableOpacity
        style={{ flex: 1 }}
        onPress={() =>
          share({
            title: `${story.title!} - ${i18n.t('common.appName')}`,
            url: `${APP_URL}/stories/${story!.id}`,
          })
        }
      >
        <Row
          style={{
            alignItems: 'center',
            gap: 3,
          }}
        >
          <ShareButton
            title={`${story.title!} - ${i18n.t('common.appName')}`}
            url={`${APP_URL}/stories/${story!.id}`}
            icon={'export-variant'}
            size={20}
            color={theme.colors.outline}
          />
          <Text style={{ color: theme.colors.outline }}>
            {i18n.t('common.share')}
          </Text>
        </Row>
      </TouchableOpacity>
    </Row>
  );
};

export default StoryLikeComment;
