import supabase from '../supabase';
import { Database } from '../types/database';
import http from '../utils/http';
import { Artist } from './ArtistRepository';
import { Event } from './EventRepository';
import { Place } from './PlaceRepository';
import { Profile } from './ProfileRepository';
import UserRepository, { UserBlockedUser } from './UserRepository';

type StoryResponse = Awaited<ReturnType<typeof StoryRepository.getStory>>;
type StoryResponseSuccess = StoryResponse['data'];
type UserLikedStory =
  Database['public']['Tables']['users_liked_stories']['Row'];

export type Story = Omit<
  NonNullable<StoryResponseSuccess>,
  'event' | 'artist' | 'place' | 'liked' | 'created'
> & {
  event?: Event | null;
  artist?: Artist;
  place?: Place;
  created?: Profile & {
    blocked?: UserBlockedUser[];
  };
  liked?: UserLikedStory[];
};

export interface StoriesParams {
  eventId?: string;
  artistId?: string;
  placeId?: string;
  district?: string;
  likedUserId?: string;
  postedUserId?: string;
  searchQuery?: string;
  sort?: 'new' | 'comments' | 'likes';
  offset?: number;
}

export default class StoryRepository {
  private static table = () => supabase.from('stories');
  static PAGE_SIZE = 20;

  static async getStory(storyId: string) {
    const user = await UserRepository.getCurrentUser();
    const query = this.table()
      .select(
        '*, event:events(*), artist:artists(*), place:places(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id)), liked:users_liked_stories(*)',
      )
      .eq('id', storyId);
    if (user?.id) {
      query.eq('users_liked_stories.user_id', user?.id);
    }
    return query.eq('display', true).maybeSingle();
  }

  private static getDefaultQuery() {
    return this.table().select(
      '*, event:events(*), artist:artists(*), place:places(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))',
    );
  }

  private static getUserLikedQuery(userId: string) {
    return this.table()
      .select(
        '*, event:events(*), artist:artists(*), place:places(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id)), liked:users_liked_stories!inner(*)',
      )
      .eq('liked.user_id', userId);
  }

  static async getStories({
    eventId,
    artistId,
    placeId,
    likedUserId,
    postedUserId,
    district,
    searchQuery,
    sort,
    offset = 0,
  }: StoriesParams) {
    let query = this.getDefaultQuery();
    if (eventId) {
      query.eq('event_id', eventId);
    }
    if (artistId) {
      query.eq('artist_id', artistId);
    }
    if (placeId) {
      query.eq('place_id', placeId);
    }
    if (likedUserId) {
      query = this.getUserLikedQuery(likedUserId);
    }
    if (postedUserId) {
      query.eq('created_by', postedUserId);
    }
    if (district) {
      query.eq('district', district);
    }
    if (searchQuery) {
      query
        .or(`title.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%`)
        .order('title');
    }

    switch (sort) {
      case 'likes':
        query.order('like_count', { ascending: false });
        break;
      case 'comments':
        query.order('comment_count', { ascending: false });
        break;
    }

    return query
      .eq('display', true)
      .order('created_at', { ascending: false })
      .order('id')
      .range(offset, offset + StoryRepository.PAGE_SIZE - 1);
  }

  static async insertStory({ liked, ...story }: Story) {
    return this.table().upsert(story).select().maybeSingle();
  }

  static async updateStory({ liked, ...story }: Story) {
    return this.table().update(story).eq('id', story.id).select().maybeSingle();
  }

  static async deleteStory(id: string) {
    return this.table().update({ display: false }).eq('id', id);
  }

  static async updateViewCount(storyId: string) {
    try {
      http.post(`/api/counts/stories/${storyId}/views`);
    } catch {}
  }
}
