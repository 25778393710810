import React from 'react';
import { ScrollView, View } from 'react-native';
import Markdown from 'react-native-markdown-display';
import { useTheme } from 'react-native-paper';

import markdown from '../components/setting/OssNotice.md';

const OssNoticeScreen: React.FC = () => {
  const theme = useTheme();
  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={{ padding: 10 }}>
        {/*@ts-ignore*/}
        <Markdown
          style={{
            body: { backgroundColor: theme.colors.background },
            text: { color: theme.colors.onBackground },
            heading1: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
            },
            heading2: { paddingVertical: 10, fontWeight: 'bold' },
          }}
        >
          {markdown}
        </Markdown>
      </ScrollView>
    </View>
  );
};

export default OssNoticeScreen;
