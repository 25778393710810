import React from 'react';
import { Controller } from 'react-hook-form';
import { Platform } from 'react-native';
import { Divider, MD3Theme } from 'react-native-paper';
import styled from 'styled-components/native';

import PlaceService from '../../services/PlaceService';
import i18n from '../../translations/i18n';
import { toFixed } from '../../utils/locationHelpers';
import { contactType, REGEXP_URL } from '../../utils/regexps';
import { FormLocationSelect, FormTextInput } from '../form/FormInputs';
import { PlaceFormType } from './PlaceForm';

export const PlaceFormDivider = styled(Divider)<{ theme: MD3Theme }>`
  background-color: ${({ theme }) => theme.colors.onSurface};
  transform: scaleY(0.5);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PlaceFormName: React.FC<{
  edit?: boolean;
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  edit,
  theme,
  form: {
    control,
    formState: { errors, dirtyFields },
    watch,
  },
}) => (
  <Controller
    control={control}
    name={'name'}
    rules={{
      required: i18n.t('common.formField.name.rules.required'),
      maxLength: {
        value: 30,
        message: i18n.t('common.formField.name.rules.maxLength'),
      },
      validate: async value => {
        if (edit && !dirtyFields.name) {
          return;
        }
        const exists = await PlaceService.existsNameAndAddress(
          value,
          watch('address'),
        );
        if (exists) {
          return i18n.t('common.formField.existsPlace');
        }
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('common.formField.name.label') + ' *'}
        icon={'account'}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        maxLength={30}
        errorMessage={errors.name?.message as string}
      />
    )}
  />
);

export const PlaceFormAddress: React.FC<{
  edit?: boolean;
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  edit,
  theme,
  form: {
    control,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  },
}) => {
  return (
    <Controller
      control={control}
      name={'address'}
      rules={{
        required: i18n.t('place.formField.address.rules.required'),
        validate: async value => {
          if (edit && !dirtyFields.address) {
            return;
          }
          const exists = await PlaceService.existsNameAndAddress(
            watch('name'),
            value,
          );
          if (exists) {
            return i18n.t('common.formField.existsPlace');
          }
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormLocationSelect
          theme={theme}
          label={i18n.t('place.formField.address.label') + ' *'}
          icon={'map-marker-check-outline'}
          location={watch('location')}
          value={value ?? ''}
          onBlur={onBlur}
          onChange={onChange}
          onConfirm={location => {
            if (!location?.latitude || !location?.longitude) {
              return;
            }
            setValue(
              'location',
              {
                latitude: toFixed(location.latitude),
                longitude: toFixed(location.longitude),
              },
              { shouldDirty: true },
            );
          }}
          errorMessage={errors.address?.message as string}
        />
      )}
    />
  );
};

export const PlaceFormInstagram: React.FC<{
  edit?: boolean;
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { dirtyFields, errors },
  },
}) => (
  <Controller
    control={control}
    name={'instagram_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.instagram_url')}
        icon={'instagram'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.instagram_url?.message as string}
      />
    )}
  />
);

export const PlaceFormYoutubeUrl: React.FC<{
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'youtube_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.youtube_url')}
        icon={'youtube'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.youtube_url?.message as string}
      />
    )}
  />
);

export const PlaceFormTossPaymentUrl: React.FC<{
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'toss_payment_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.toss_payment_url')}
        icon={'alpha-t-circle'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.toss_payment_url?.message as string}
      />
    )}
  />
);

export const PlaceFormKakaoPaymentUrl: React.FC<{
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'kakao_payment_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.kakao_payment_url')}
        icon={'alpha-k-circle'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.kakao_payment_url?.message as string}
      />
    )}
  />
);

export const PlaceFormContact: React.FC<{
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'contact'}
    rules={{
      validate: async value => {
        if (!value?.length) {
          return;
        }
        const type = contactType(value ?? '');
        if (type) {
          return;
        }
        return i18n.t('place.formField.contact.rules.validate');
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('place.formField.contact.label')}
        icon={'email'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'email-address'}
        maxLength={100}
        errorMessage={errors.contact?.message as string}
      />
    )}
  />
);

export const PlaceDescription: React.FC<{
  theme: MD3Theme;
  form: PlaceFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'description'}
    rules={{
      required: i18n.t('place.formField.description.rules.required'),
      maxLength: {
        value: 1000,
        message: i18n.t('place.formField.description.rules.maxLength'),
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('place.formField.description.label') + ' *'}
        icon={'card-text-outline'}
        placeholder={i18n.t('place.formField.description.placeholder')}
        multiline
        numberOfLines={Platform.OS === 'web' ? 3 : undefined}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={1000}
        errorMessage={errors.description?.message as string}
      />
    )}
  />
);
