import { UserMetadata } from '@supabase/gotrue-js/src/lib/types';
import { useMutation, useQuery } from '@tanstack/react-query';

import { showError } from '../components/shared/Toast';
import { Artist } from '../repositories/ArtistRepository';
import { Place } from '../repositories/PlaceRepository';
import ProfileRepository, { Profile } from '../repositories/ProfileRepository';
import UserRepository from '../repositories/UserRepository';
import supabase from '../supabase';
import queryClient, { refetchAll, resetAll } from '../utils/queryClient';

export interface UserData extends UserMetadata {
  email?: string;
  locale?: string;
  district?: string;
  latitude?: number;
  longitude?: number;
  latitudeDelta?: number;
  longitudeDelta?: number;
  instagram?: string;
  youtube?: string;
  gender?: string | null;
  birthday?: string | null;
  accepted_terms_at?: string | null;
  accepted_marketing_terms_at?: string | null;
}

type ManagedArtists = { artist_id: string } | { artist_id: string }[] | null;
type OwnedPlaces = { place_id: string } | { place_id: string }[] | null;

export type UserProfile = UserData &
  Profile & {
    admin?: { id: string } | { id: string }[] | null;
    artists?: ManagedArtists;
    places?: OwnedPlaces;
  };

export class UserService {
  static hasPostPermission(user?: UserProfile | null): boolean {
    if (!user) {
      return false;
    }
    const isAdmin = !!user.admin;
    const isManager = Array.isArray(user.artists) && user.artists.length > 0;
    const isOwner = Array.isArray(user.places) && user.places.length > 0;
    return isAdmin || isManager || isOwner;
  }

  static isManager(user: UserProfile, artists?: Artist[]): boolean {
    const managed: ManagedArtists = user.artists;
    if (!artists || !Array.isArray(managed)) {
      return false;
    }
    if (artists.some(artist => artist.created_by === user.id)) {
      return true;
    }
    return managed.some(({ artist_id }) =>
      artists.some(({ id }) => id === artist_id),
    );
  }

  static isOwner(user: UserProfile, place?: Place): boolean {
    const owned: OwnedPlaces = user.places;
    if (!place || !Array.isArray(owned)) {
      return false;
    }
    if (place.created_by === user.id) {
      return true;
    }
    return owned.some(({ place_id }) => place_id === place.id);
  }

  static async updateUserData(
    userData: UserData,
  ): Promise<UserData | undefined> {
    const {
      data: { session },
      error: sessionError,
    } = await supabase.auth.getSession();
    if (sessionError) {
      showError(sessionError);
      return;
    }
    if (!session) {
      return;
    }
    const { data, error } = await supabase.auth.updateUser({
      data: userData,
    });
    if (error) {
      showError(error);
      return;
    }
    await refetchAll();
    return data?.user?.user_metadata;
  }

  static async getCurrentUserProfile(): Promise<UserProfile | null> {
    const user = await UserRepository.getCurrentUser();
    if (!user) {
      return null;
    }
    const { data: profile, error } = await ProfileRepository.getProfile(
      user.id,
    );
    if (error) {
      showError(error);
      return null;
    }
    if (!profile) {
      return null;
    }
    return {
      ...user.user_metadata,
      ...profile,
    };
  }

  static async upsertUserProfile(
    userProfile: UserProfile,
  ): Promise<UserProfile | undefined> {
    const {
      id,
      photo_url,
      name,
      bio,
      district,
      latitude,
      longitude,
      birthday,
      gender,
      accepted_terms_at,
      accepted_marketing_terms_at,
    } = userProfile;
    const now = new Date().toISOString();
    const { data: profile, error } = await ProfileRepository.upsertProfile({
      id,
      photo_url,
      name,
      bio,
      created_at: now,
      updated_at: now,
      updated_name_at: now,
    });
    if (error) {
      return Promise.reject(error);
    }
    if (!profile) {
      throw new Error('Upsert failure');
    }
    const userData = await this.updateUserData({
      district,
      latitude,
      longitude,
      birthday,
      gender,
      accepted_terms_at,
      accepted_marketing_terms_at,
    });
    return {
      ...userData,
      ...(profile as Profile),
    };
  }

  static async deleteUserProfile(id: string): Promise<void> {
    const { error } = await ProfileRepository.deleteProfile(id);
    if (error) {
      showError(error);
      return;
    }
    await this.updateUserData({
      gender: null,
      birthday: null,
      accepted_terms_at: null,
      accepted_marketing_terms_at: null,
    });
  }

  static async existsName(name: string): Promise<boolean> {
    const { data, error } = await ProfileRepository.getProfileByName(name);
    if (error) {
      showError(error);
      return false;
    }
    return !!data;
  }

  static async blockUser(userId: string): Promise<void> {
    try {
      await UserRepository.insertUserBlockedUser(userId);
      await queryClient.clear();
    } catch (e) {
      showError(e);
    }
  }

  static async unblockUser(
    userId: string,
    blockedUserId: string,
  ): Promise<void> {
    try {
      await UserRepository.deleteUserBlockedUser(userId, blockedUserId);
      await queryClient.clear();
    } catch (e) {
      showError(e);
    }
  }
}

export function useUserProfileQuery() {
  return useQuery<UserProfile | null>(
    ['userProfile'],
    async () => await UserService.getCurrentUserProfile(),
  );
}

export function useUpsertUserProfileMutation() {
  return useMutation(
    async (userProfile: UserProfile) =>
      await UserService.upsertUserProfile(userProfile),
    {
      onSuccess: async () => {
        await supabase.auth.refreshSession();
        await refetchAll();
        await queryClient.clear();
      },
    },
  );
}

export function useDeleteUserProfileMutation(userId?: string) {
  return useMutation(
    () =>
      userId
        ? UserService.deleteUserProfile(userId)
        : Promise.reject(new Error('Invalid UserId')),
    {
      onSuccess: async () => {
        await resetAll();
        await queryClient.clear();
      },
    },
  );
}
