import { MaterialCommunityIcons } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import {
  Button,
  Surface,
  Text,
  IconButton,
  useTheme,
} from 'react-native-paper';

import MapView, { LatLng, Region } from '../components/map';
import PlaceFilterDialog from '../components/place/PlaceFilterDialog';
import PlaceListMap from '../components/place/PlaceListMap';
import { useDialog } from '../components/providers/DialogProvider';
import { Column, Row } from '../components/shared/Layouts';
import Loading from '../components/shared/Loading';
import { showError } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { MainTabParamList } from '../navigation/MainTab';
import PlaceRepository, { Place } from '../repositories/PlaceRepository';
import { usePlaceDetailQuery } from '../services/PlaceService';
import { UserData } from '../services/UserService';
import i18n from '../translations/i18n';
import {
  DEFAULT_DELTA,
  deltaToKm,
  getCurrentLocation,
  getNearby,
  getStorageRegion,
} from '../utils/locationHelpers';

const PlaceListScreen: React.FC = () => {
  const theme = useTheme();
  const user = useUser();
  const { showDialog, hideDialog } = useDialog();
  const [map, setMap] = useState<MapView | null>(null);
  const [region, setRegion] = useState<Region | null>(null);
  const [prevRegion, setPrevRegion] = useState<Region | null>(null);
  const [places, setPlaces] = useState<Place[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [moved, setMoved] = useState<boolean>(false);
  const [showAllPlaces, setShowAllPlaces] = useState<boolean>(false);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute<RouteProp<MainTabParamList, 'Places'>>();
  const placeId = route.params?.placeId;
  const mapRef = useRef<MapView | null>();
  mapRef.current = map;
  const showAllPlacesRef = useRef<boolean | undefined>();
  showAllPlacesRef.current = showAllPlaces;

  const { data: place, isFetched } = usePlaceDetailQuery(placeId!, user?.id!);

  const updateCamera = (location: LatLng) => {
    mapRef?.current?.setCamera({
      center: location,
    });
  };

  const updateHeader = (delta: number) =>
    navigation.setOptions({
      title: i18n.t('place.listScreen.find_event', {
        deltaToKm: deltaToKm(delta),
      }),
      headerRight,
    });

  const headerRight = ({ tintColor }: { tintColor?: string }) => (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <IconButton
        style={{ marginHorizontal: 0 }}
        size={23}
        icon={'crosshairs'}
        iconColor={tintColor}
        onPress={async () => {
          setLoading(true);
          const location = await getCurrentLocation();
          updateCamera(location);
          onPressMap();
          const latitudeDelta = region?.latitudeDelta ?? DEFAULT_DELTA;
          const longitudeDelta = region?.longitudeDelta ?? DEFAULT_DELTA;
          await updateMarkers({ ...location, latitudeDelta, longitudeDelta });
          setMoved(false);
          setLoading(false);
        }}
      />
      <IconButton
        style={{ marginLeft: 0, marginRight: 10 }}
        size={23}
        icon={'tune-variant'}
        iconColor={tintColor}
        onPress={onPressFilter}
      />
    </View>
  );

  const onPressFilter = () => {
    showDialog(
      <PlaceFilterDialog
        showAllPlaces={showAllPlacesRef.current!}
        setShowAllPlaces={setShowAllPlaces}
        hideDialog={hideDialog}
      />,
    );
  };

  const updateCurrentLocation = async () => {
    setLoading(true);
    let nextRegion: Region | undefined;
    const latitudeDelta = region?.latitudeDelta ?? DEFAULT_DELTA;
    const longitudeDelta = region?.longitudeDelta ?? DEFAULT_DELTA;
    if (place) {
      if (place.latitude && place.longitude) {
        nextRegion = {
          latitude: place.latitude,
          longitude: place.longitude,
          latitudeDelta,
          longitudeDelta,
        };
      }
    }
    if (!nextRegion) {
      const userData: UserData | undefined = user?.user_metadata;
      const storageRegion = await getStorageRegion();
      const latitude = userData?.latitude ?? storageRegion.latitude;
      const longitude = userData?.longitude ?? storageRegion.longitude;
      nextRegion = {
        latitude,
        longitude,
        latitudeDelta,
        longitudeDelta,
      };
    }
    setPrevRegion(nextRegion);
    setRegion(nextRegion);
    updateHeader(latitudeDelta);
    setLoading(false);
    return nextRegion;
  };

  const updateMarkers = async (region?: Region) => {
    try {
      if (!region) {
        return;
      }
      let { data } = await PlaceRepository.getPlacesByRegionWithin14Days(
        getNearby(region),
      );
      if (!showAllPlaces) {
        (data as Place[]) = (data as Place[]).filter(
          place => place.id === placeId || place?.events?.length,
        ) as Place[];
      }
      setPlaces(data as Place[]);
      setPrevRegion(region);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (isFetched) {
      (async () => {
        const region = await updateCurrentLocation();
        if (!prevRegion) {
          await updateMarkers(region);
        }
      })();
      if (!place?.id) {
        onPressMap();
      }
    }
  }, [isFetched]);

  const isMoved = () => {
    if (!prevRegion || !region) {
      return false;
    }
    return (
      prevRegion.longitude.toPrecision(5) !== region.longitude.toPrecision(5) ||
      prevRegion.latitude.toPrecision(5) !== region.latitude.toPrecision(5)
    );
  };

  useEffect(() => {
    if (isMoved()) {
      setMoved(true);
    }
    if (region) {
      updateHeader(region.latitudeDelta);
    }
  }, [region]);

  useEffect(() => {
    if (region) {
      updateMarkers(region);
    }
  }, [showAllPlaces]);

  const onPressMap = () => {
    navigation.setParams({
      placeId: undefined,
    });
  };

  const onPressLabel = () => {
    if (place?.id) {
      navigation.push('PlaceDetail', { placeId: place.id });
    }
  };

  const onPressMarker = useCallback(
    (place: Place) => {
      if (region && place.latitude && place.longitude) {
        navigation.setParams({
          placeId: place.id,
        });
      }
    },
    [places],
  );

  return (
    <>
      {loading && <Loading />}
      <PlaceListMap
        region={region}
        places={places}
        selectedPlaceId={placeId}
        onReady={setMap}
        onChange={setRegion}
        onPress={onPressMap}
        onPressMarker={onPressMarker}
      />
      {!!place?.id && (
        <Pressable
          style={{
            zIndex: 1000,
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}
          onPress={onPressLabel}
        >
          <Surface
            style={{
              flexDirection: 'row',
              alignSelf: 'center',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              paddingHorizontal: 15,
              paddingVertical: 12,
              margin: 20,
              width: '90%',
              height: 100,
              borderRadius: 20,
            }}
          >
            <Column
              style={{
                width: '90%',
                height: '100%',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <Text
                  variant={'bodyLarge'}
                  style={{ fontWeight: 'bold' }}
                  numberOfLines={1}
                >
                  {place.name}
                </Text>
                <Text variant={'bodyMedium'} numberOfLines={1}>
                  {place.address}
                </Text>
              </View>
              <Text
                variant={'bodyMedium'}
                style={{
                  color: place.events?.length
                    ? theme.colors.primary
                    : theme.colors.onSurface,
                  fontWeight: place.events?.length ? 'bold' : 'normal',
                }}
                numberOfLines={1}
              >
                {i18n.t('place.listScreen.scheduled_event')}:{' '}
                {place.events?.length ?? i18n.t('place.listScreen.nothing')}
              </Text>
            </Column>
            <Column
              style={{
                width: '10%',
                height: '100%',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <MaterialCommunityIcons
                name={'chevron-right'}
                color={theme.colors.onSurface}
                size={40}
              />
            </Column>
          </Surface>
        </Pressable>
      )}
      {moved && (
        <Row
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 1000,
            marginTop: 10,
          }}
        >
          <Button
            mode={'elevated'}
            onPress={() => {
              updateMarkers(region!);
              setMoved(false);
              onPressMap();
            }}
          >
            <Text style={{ justifyContent: 'center', alignItems: 'center' }}>
              <MaterialCommunityIcons name={'refresh'} size={15} />{' '}
              {i18n.t('common.location.refresh')}
            </Text>
          </Button>
        </Row>
      )}
    </>
  );
};

export default PlaceListScreen;
