export default {
  common: {
    appName: '라이브와이어',
    updating: '최신 버전으로 업데이트 중입니다.',
    updatingError: '업데이트에 실패했습니다. 앱을 재시작 해주세요.',
    english: '영어',
    korean: '한국어',
    genre: '장르',
    like: '좋아요',
    view: '조회',
    interest: '관심',
    comment: '댓글',
    reply: '답글',
    event: '공연',
    events: '공연 일정',
    story: '커뮤니티',
    posts: '커뮤니티',
    share: '공유하기',
    areYouSureYouWantToDeleteIt: '정말 삭제 하시겠습니까?',
    itHasBeenDeleted: '삭제 되었습니다.',
    modify: '수정',
    delete: '삭제',
    cancel: '취소',
    report: '신고 / 요청',
    block: '차단',
    blockConfirm:
      '%{name}님을 차단 하시겠습니까?\n모든 공연과 글이 보이지 않게됩니다.',
    blockSuccess: '차단 되었습니다.',
    unblock: '차단 해제',
    unblockConfirm:
      '%{name}님을 차단 해제 하시겠습니까?\n모든 공연과 글이 다시 보이게 됩니다.',
    unblockSuccess: '차단 해제 되었습니다.',
    apply: '적용',
    save: '저장',
    search: '검색',
    searchEmpty: '검색 결과가 없습니다.',
    post: '등록',
    all: '전체',
    more: '더 보기',
    select: '선택',
    confirm: '확인',
    searchByAddress: '주소로 검색',
    addressCannotBeDetermined: '위치를 확인 할 수 없는 주소 입니다.',
    linkHasBeenCopiedToTheClipboard: '링크가 클립보드로 복사 되었습니다.',
    defaultError: '오류가 발생했습니다.',
    defaultErrorExtra: '계속해서 발생할 경우 앱을 재시작 해주세요.',
    unknownError: '알 수 없는 오류입니다.',
    networkError: '네트워크 오류입니다.',
    networkErrorExtra: '네트워크 상태를 확인해주세요.',
    doNotHavePermission: '권한이 없습니다.',
    pleaseSignIn: '로그인 해주세요.',
    signIn: '로그인',
    signUp: '가입',
    artist: '뮤지션',
    agency: '관계자',
    place: '장소',
    pleaseEnterSearchKeyword: '검색어를 입력해주세요',
    recentSearchWord: '최근 검색어',
    notExistRecentSearchTerms: '최근 검색어가 없습니다.',
    closest: '날짜순',
    nearest: '거리순',
    new: '등록순',
    price: '가격순',
    likes: '추천순',
    comments: '댓글순',
    district: '검색 지역',
    distance: '검색 범위',
    version: '버전',
    location: {
      refresh: '현 위치에서 재검색',
      rules: {
        permission: '위치 정보 사용 권한이 없습니다.',
        permissionExtra: '기본 위치 정보를 사용 합니다.',
      },
    },
    basicRegion: '서울특별시 마포구',
    justNow: '방금 전',
    foundBannedKeyword: "사용할 수 없는 단어입니다. '%{keyword}'",
    form: {
      modified: '수정 되었습니다.',
      registered: '등록 되었습니다.',
      checkInput: '입력하신 내용을 확인해 주세요.',
    },
    formField: {
      name: {
        rules: {
          required: '이름을 입력해주세요.',
          minLength: '이름은 1글자 이상부터 입력 가능 합니다.',
          maxLength: '이름은 30글자 까지 입력 가능 합니다.',
        },
        label: '이름',
      },
      members: {
        rules: {
          maxLength: '멤버/본명은 200글자 까지 입력 가능 합니다.',
        },
        label: '멤버/본명',
      },
      id: {
        rules: {
          pattern: '아이디에는 영문 소문자 숫자 _. 만 사용할 수 있습니다.',
          required: '아이디를 입력해주세요.',
          minLength: '아이디는 3글자 이상부터 입력 가능 합니다.',
          maxLength: '아이디는 30글자 까지 입력 가능 합니다.',
        },
        label: '아이디 (사용자 아이디와 별도)',
      },
      instagramId: {
        rules: {
          pattern:
            '인스타그램 아이디에는 영문 소문자 숫자 _. 만 사용할 수 있습니다.',
          minLength: '인스타그램 아이디는 3글자 이상부터 입력 가능 합니다.',
          maxLength: '인스타그램 아이디는 30글자 까지 입력 가능 합니다.',
        },
        label: '인스타그램 아이디',
      },
      url: {
        rules: {
          pattern: 'URL 형식을 확인해주세요. (https://로 시작)',
        },
      },
      title: {
        rules: {
          required: '제목을 입력해주세요.',
          minLength: '제목은 3글자 이상부터 입력 가능 합니다.',
          maxLength: '제목은 100글자 까지 입력 가능 합니다.',
        },
        label: '제목',
      },
      instagram_url: '인스타그램 주소',
      youtube_url: '유튜브 주소',
      toss_payment_url: '토스 송금 주소',
      kakao_payment_url: '카카오페이 송금 주소',
      pleaseEnterAnBio: '소개 글을 입력해주세요.',
      existsName: '사용 중인 닉네임 입니다.',
      existsPlace: '이미 등록된 장소입니다. (이름, 주소)',
      number_only: '숫자만 입력해주세요.',
      imageSizeOver: '10MB를 초과하는 이미지는 첨부할 수 없습니다.',
    },
    updateTitle: '업데이트',
    updateMessage: '새로운 업데이트가 있습니다. 업데이트를 진행합니다.',
    recommendNotification:
      '새 공연 알림을 받으시려면 설정 > 알림 설정을 켜주세요',
    relatedNews: '추천 기사',
  },
  signIn: {
    IntroText: '내 마음에 꼭 드는 공연과 아티스트를 발견해보세요.',
    google: 'Google로 계속하기',
    facebook: 'Facebook으로 계속하기',
    twitter: 'Twitter로 계속하기',
    apple: 'Apple로 계속하기',
    kakao: '카카오톡으로 계속하기',
    anonymous: '계정 없이 사용하기',
    signingUp: '가입 처리 중입니다.\n잠시만 기다려주세요.',
    signingUpExtra: '네트워크 상황에 따라 처리가 지연 될 수 있습니다.',
    signUpFailure: '가입 처리 시간이 초과되었습니다.\n다시 시도 부탁드립니다.',
    cannotSignInGoogle:
      '구글 로그인을 지원하지 않는 환경일 수 있습니다.\n실패할 경우 다른 브라우저에서 시도해주세요.',
  },
  stack: {
    EventDetail: '',
    EventAdd: '공연 정보 작성하기',
    EventEdit: '공연 수정',
    StoryDetail: '커뮤니티',
    StoryAdd: '글 쓰기',
    StoryEdit: '글 수정',
    CommentDetail: '댓글',
    ArtistList: '뮤지션 목록',
    ArtistDetail: '',
    ArtistAdd: '뮤지션, 관계자 추가하기',
    ArtistEdit: '뮤지션, 관계자 수정',
    ArtistEventFeed: '뮤지션 공연 목록',
    PlaceDetail: '공연 장소 정보',
    PlaceAdd: '공연 장소 추가하기',
    PlaceEdit: '공연 장소 수정',
    ProfileEdit: '프로필 수정',
    UserSavedEvents: '저장한 공연',
    UserAttendedEvents: '다녀온 공연',
    UserAttendedEventDetail: '다녀온 공연',
    UserAttendedEventAdd: '공연 감상',
    UserAttendedEventEdit: '공연 감상 수정',
    UserLikedStories: '좋아요 한 글',
    UserLikedArtists: '좋아요 한 뮤지션',
    UserFavoritePlaces: '좋아요 한 공연장',
    UserPostedStories: '작성한 글',
    UserPostedComments: '작성한 댓글',
    UserBlockedUsers: '차단한 사용자',
    UserPostedEvents: '내가 등록한 공연',
    UserPostedArtists: '내가 등록한 뮤지션',
    UserPostedPlaces: '내가 등록한 공연장',
    UserManagedArtists: '내가 관리하는 뮤지션',
    UserManagedPlaces: '내가 관리하는 공연장',
    Setting: '설정',
    NotificationSetting: '알림 설정',
    OssNotice: '오픈소스 라이센스',
    NoticeFeed: '공지사항',
    NoticeDetail: '공지사항',
    NotificationFeed: '알림',
    Report: '신고 / 요청',
  },
  tab: {
    EventFeed: '공연 일정',
    StoryFeed: '커뮤니티',
    Places: '공연 찾기',
    Search: '검색',
    Profile: '내 정보',
  },
  event: {
    card: {
      advance: '예매: %{advance_ticket_price}원',
      door: '현매: %{door_ticket_price}원',
      price: '가격: 본문 참조',
      past: '지나간 공연',
      rated: '평가함',
    },
    detailBottomBar: {
      seeLink: '링크 참조',
      reservation: '예매하기',
      information: '공연 정보',
      textReference: '가격: 본문 참조',
    },
    detailContent: {
      seeLink: '링크 참조',
      date: '시간',
      place: '장소',
      lineUp: '출연',
      presents: '기획',
      story: '커뮤니티',
      eventStory: '공연 이야기',
      postEventStory: '공연 이야기 쓰기',
      postAttendedEvent: '다녀온 공연이에요',
      editAttendedEvent: '내가 다녀온 공연',
      submitAttendedEvent: '공연 감상, 별점, 티켓 사진을 추가하시겠어요?',
      addAttendedEventDetail: '추가하기',
      stats: '매출 추정',
      audience: `추정 관객 수: %{audience}명`,
      revenue: `추정 매출: %{revenue}원`,
      statsWarning:
        '관객 수와 매출은 추정치이며, 실제와는 차이가 있을 수 있습니다.',
      statsNotFound: '매출 정보를 제공하지 않는 공연장입니다.',
      warning:
        '라이브와이어는 통신판매 중개자이며, 통신판매의 당사자가 아닙니다. 따라서 상품 거래정보 및 거래에 대하여 책임을 지지 않습니다.',
    },
    formField: {
      artist: {
        validate: {
          notExist: '출연 뮤지션을 입력해주세요.',
          limit:
            '출연 뮤지션은 최대 %{ARTIST_LIMIT}팀 까지 입력할 수 있습니다.',
        },
        label: '출연 뮤지션',
      },
      agency: {
        label: '기획/주최',
        validate: {
          limit: '기획/주최는 최대 %{AGENCY_LIMIT}팀 까지 입력할 수 있습니다.',
        },
      },
      place: {
        rules: {
          required: '공연 장소를 입력해주세요.',
          notValid: '등록되지 않은 장소입니다.',
        },
        label: '공연 장소',
      },
      add: '추가하기',
      event_date: {
        rules: {
          required: '공연 일자를 입력해주세요.',
        },
        label: '공연 일자',
      },
      event_time: {
        label: '공연 시간',
      },
      advance_ticket_price: '예매 가격',
      door_ticket_price: '현매 가격',
      scale: '규모',
      seats: '석',
      payment_type: {
        free: '무료공연',
        freeDonation: '자율기부',
        soldOut: '매진',
      },
      ticket_url: '예매 링크',
      streaming_url: '스트리밍 링크 (유튜브, 인스타그램)',
      description: {
        rules: {
          required: '공연 정보를 입력해주세요.',
          maxLength: '5000글자 까지 입력 가능 합니다.',
        },
        label: '공연 상세 정보',
        placeholder:
          '공연 상세 정보를 입력해주세요.\n(링크, 인스타그램 아이디 포함 가능.)',
      },
    },
    feed: {
      artistsOrAgencies: '뮤지션, 관계자',
      places: '공연 장소',
      updateLocation: '검색 지역을 현재 위치로 변경 하시겠습니까?',
      district: '검색 지역',
    },
    emptyEventCard: {
      not_matched: '조건에 맞는 공연이 없습니다.',
      check_another: '검색 조건을 변경해 보세요.',
      change_filter: '검색 조건 변경',
    },
  },
  story: {
    cardList: {
      noStory: '작성된 글이 없습니다.',
      write: '작성하기',
      total: '전체보기',
    },
    detailContent: {
      invalidStory: '존재하지 않는 게시물입니다.',
      youtube_urls: '유튜브 영상',
      instagram_url: '인스타그램 링크',
    },
    formField: {
      insertLimit: '글을 연속해서 작성하실 수 없습니다.',
      description: {
        rules: {
          required: '상세 내용을 입력해주세요.',
          maxLength: '1000글자 까지 입력 가능 합니다.',
        },
        placeholder: '무슨 생각을 하고 계신가요?',
        label: '내용',
      },
      event: {
        validate: {
          not_found_id: '등록되지 않은 공연입니다.',
        },
        label: '@공연',
      },
      artist: {
        validate: {
          not_found_id: '등록되지 않은 뮤지션입니다.',
        },
        label: '@뮤지션',
      },
      place: {
        validate: {
          not_found_id: '등록되지 않은 장소입니다.',
        },
        label: '@공연 장소',
      },
      addMore: '추가하기',
      district: '@지역',
      youtube_urls: '유튜브 링크',
      instagram_url: '인스타그램 링크',
    },
    feed: {
      musicStory: '커뮤니티',
      eventStory: '공연 이야기',
      story: '%{name} 커뮤니티',
    },
  },
  userAttendedEvent: {
    cardList: {
      noUserAttendedEvent: '작성된 글이 없습니다.',
      write: '작성하기',
      total: '전체보기',
    },
    detailContent: {
      invalidUserAttendedEvent: '삭제 되었습니다.',
    },
    formField: {
      description: {
        rules: {
          required: '내용을 입력해주세요.',
          maxLength: '1000글자 까지 입력 가능 합니다.',
        },
        placeholder: '공연은 어땠나요?',
        label: '내용',
      },
      private: '작성한 모든 내용은 작성자만 볼 수 있습니다.',
      image_urls: {
        label: '티켓, 입장권, 팔찌, 도장, 공연 사진',
        options: {
          ticket: '티켓 스캔',
          picture: '사진',
        },
      },
      cameraPermissionErrorTitle: '권한 없음',
      cameraPermissionError:
        '티켓 스캔을 위해서 카메라 사용 권한이 필요합니다.',
    },
  },
  comment: {
    originalPost: '원글 보기',
    cardList: {
      noComment: '댓글이 없습니다.',
      write: '작성하기',
      commentInput: '댓글을 입력해주세요.',
    },
    invalidComment: '존재하지 않는 댓글입니다.',
    insertLimit: '댓글을 연속해서 작성하실 수 없습니다.',
  },
  notification: {
    story: {
      newComment: "'%{title}' 게시물에 댓글이 달렸습니다.",
      likes: "회원님의 글을 %{likeCount}명이 좋아합니다. '%{title}'",
    },
    comment: {
      newReply: "'%{title}' 댓글에 대댓글이 달렸습니다.",
    },
    removeNotice: '알림은 30일 동안만 보관 됩니다.',
    artist: {
      newEvent:
        "%{name}의 새로운 공연이 등록 되었습니다. '%{title}' (%{district})",
      newStory: "%{name} 커뮤니티에 새 게시물이 등록 되었습니다. '%{title}'",
      newArtistStory: "%{name}의 새 게시물이 등록 되었습니다. '%{title}'",
    },
    agency: {
      newEvent:
        "%{name}의 새로운 기획 공연이 등록 되었습니다. '%{title}' (%{district})",
    },
    place: {
      newEvent: "%{name}에 새로운 공연이 등록 되었습니다. '%{title}'",
      newStory: "%{name} 커뮤니티에 새 게시물이 등록 되었습니다. '%{title}'",
    },
  },
  place: {
    cardList: {
      place: {
        rules: {
          empty: '조건에 맞는 장소가 없습니다.',
        },
      },
    },
    detailBottomBar: {
      toss_payment_url: 'Toss로 송금하기',
      kakao_payment_url: '카카오페이로 송금하기',
      contact: '대관문의 하기',
    },
    detailContent: {
      address: '주소: %{address}',
      event: '%{name} 공연 목록',
      story: '%{name} 커뮤니티',
      postStory: '%{name} 글 쓰기',
      naver: '네이버 지도에서 보기',
      kakao: '카카오 지도에서 보기',
      nextEvent: '다가오는 공연',
      favorite: '즐겨찾는 장소에 추가되었습니다',
      unfavorite: '즐겨찾는 장소에서 제외되었습니다',
    },
    formField: {
      address: {
        label: '위치',
        rules: { required: '위치를 입력해주세요.' },
      },
      contact: {
        rules: {
          validate: '이메일 또는 전화번호만 입력 가능 합니다.',
        },
        label: '대관 문의처 (전화 이메일)',
      },
      description: {
        rules: {
          required: '소개 글을 입력해주세요.',
          maxLength: '1000글자 까지 입력 가능 합니다.',
        },
        placeholder:
          '소개 글을 입력해주세요.\n(링크, 인스타그램 아이디 포함 가능.)',
        label: '소개 글',
      },
    },
    listScreen: {
      find_event: '공연 찾기 (~%{deltaToKm}Km)',
      scheduled_event: '다음 주 까지의 공연',
      nothing: '없음',
      showAllPlaces: '장소 전체 보기',
      showAllPlacesDescription:
        '공연이 예정되어 있지 않은 장소들도 볼 수 있어요.',
    },
    service: {
      address: {
        notValid: '주소 정보를 확인할 수 없습니다.',
      },
      location: {
        notValid: '위치 정보를 확인할 수 없습니다.',
        setDefault: '기본 위치 정보로 설정 합니다.',
      },
    },
  },
  artist: {
    cardList: {
      artist: {
        rules: {
          empty: '조건에 맞는 뮤지션 또는 관계자가 없습니다.',
        },
      },
    },
    bottomBar: {
      toss_payment_url: 'Toss로 후원하기',
      kakao_payment_url: '카카오페이로 후원하기',
      shop_url: '음반, 굿즈 구매',
      contact: '섭외문의 하기',
    },
    detailContent: {
      check_out_profile: '%{name}의 프로필을 확인해보세요 - %{appName}',
      region: '지역',
      event: '%{name} 공연 목록',
      agencyEvent: '%{name} 기획 공연',
      story: '%{name} 커뮤니티',
      postStory: '%{name} 글 쓰기',
      spotify: 'Spotify',
      youtube: 'Youtube',
      liked: '좋아하는 뮤지션에 추가되었습니다',
      unliked: '좋아하는 뮤지션에서 제외되었습니다',
    },
    formField: {
      genre: {
        rules: {
          maxItem: '장르는 5개 까지만 선택 가능합니다.',
        },
      },
      activity_area: '활동 지역',
      debut: '데뷔',
      spotify_url: '스포티파이 주소',
      soundcloud_url: '사운드클라우드 주소',
      shop_url: '음반 굿즈 구매 주소',
      contact: {
        rules: {
          validate: '이메일 또는 전화번호만 입력 가능 합니다.',
        },
        label: '섭외 연락처 (전화 이메일)',
      },
      description: {
        placeholder:
          '소개 글을 입력해주세요.\n(링크, 인스타그램 아이디 포함 가능.)',
        label: '소개 글',
        rules: {
          maxLength: '1000글자 까지 입력 가능 합니다.',
          required: '소개 글을 입력해주세요.',
        },
      },
    },
    recommended: {
      upcoming: '%{district} 근처에 오는 뮤지션 미리 들어보기',
      eventInfo: '공연 정보 보기',
    },
  },
  genres: {
    alternative: '얼터너티브',
    blues: '블루스',
    classical: '클래식',
    country: '컨트리',
    dance: '댄스',
    disco: '디스코',
    electronic: '일렉트로닉',
    folk: '포크',
    funk: '펑크',
    hipHop: '힙합',
    house: '하우스',
    jazz: '재즈',
    latin: '라틴',
    metal: '메탈',
    pop: '팝',
    psychedelic: '사이키텔릭',
    punk: '펑크',
    rAndB: 'R&B',
    reggae: '레게',
    rock: '락',
    shoegaze: '슈게이징',
    ska: '스카',
    soul: '소울',
    synthPop: '신스팝',
  },
  profile: {
    join: '회원 가입',
    formField: {
      email: {
        rules: {
          not_modified: '이메일은 수정 할 수 없습니다.',
        },
        label: '이메일',
      },
      district: {
        rules: {
          required: '관심 지역을 지정해주세요.',
        },
        label: '관심 지역',
      },
      //enterYourId: '아이디를 입력해주세요.',
      // id: '아이디',
      name: {
        rules: {
          required: '닉네임을 입력해주세요.',
          minLength: '닉네임은 3글자 이상부터 입력 가능 합니다.',
          maxLength: '닉네임은 30글자 까지 입력 가능 합니다.',
          whitespace: '닉네임에는 공백이 포함될 수 없습니다.',
        },
        label: '닉네임',
      },
      birthday: '생년월일',
      male: '남성',
      female: '여성',
      others: '그 외',
      gender: '성별',
      bio: {
        rules: { maxLength: '소개 글은 100글자 까지 입력 가능 합니다.' },
        label: '소개',
      },
    },
    header: {
      editProfile: '프로필 수정',
      idCopied: '사용자 아이디가 클립보드로 복사되었습니다.',
    },
    edit: {
      updated: '프로필이 업데이트 되었습니다.',
      content: '정말 탈퇴 하시겠습니까?',
      confirmText: '탈퇴',
      withdrawal: '회원 탈퇴',
    },
    actions: {
      region: {
        label: '관심 지역',
        content: '관심 지역을 현재 위치로 업데이트 하시겠습니까?',
      },
      likedArtists: '좋아요 한 뮤지션',
      likedPlaces: '좋아요 한 공연 장소',
      savedEvents: '저장한 공연',
      attendedEvents: '다녀온 공연',
      likedStories: '좋아요 한 글',
      postedStories: '내가 작성한 글',
      postedComments: '내가 작성한 댓글',
      postedEvents: '내가 등록한 공연',
      postedArtists: '내가 등록한 뮤지션',
      postedPlaces: '내가 등록한 공연 장소',
      managedArtists: '내가 관리하는 뮤지션',
      managedPlaces: '내가 관리하는 공연 장소',
      blockedUsers: '차단한 사용자',
    },
    empty: {
      emptyUser: '로그인 된 사용자가 없습니다.',
      signIn: '로그인',
    },
    cardList: {
      profile: {
        empty: '조건에 맞는 사용자가 없습니다.',
      },
    },
  },
  setting: {
    language: '언어',
    light_mode: '라이트 모드',
    dark_mode: '다크 모드',
    marketing_on: '마케팅 정보 수신에 동의 하셨습니다.',
    marketing_off: '마케팅 정보 수신을 거부 하셨습니다.',
    notification: '알림 설정',
    notification_require_permission: '장치 알림 권한이 없습니다',
    notification_require_permission_text: '설정에서 알림 권한을 허용 해주세요.',
    notification_not_supported: '알림 미지원 환경',
    notification_not_supported_text: '이 환경은 알림 기능을 지원하지 않습니다.',
    location: '위치정보 사용',
    location_require_permission: '위치정보 사용 권한이 없습니다',
    location_require_permission_text:
      '설정에서 위치정보 사용 권한을 허용 해주세요.',
    tracking: '추적 허용',
    tracking_require_permission: '추적 권한 설정',
    tracking_require_permission_text:
      '시스템 설정에서 추적 권한을 변경 해주세요.',
    settings: '설정',
    notice: '공지사항',
    terms_and_conditions: '이용약관',
    privacy_policy: '개인정보 처리방침',
    marketing_agree: '마케팅 정보 수신 동의',
    customer_service: '고객 센터',
    about: '서비스 소개',
    open_source_license: '오픈 소스 라이센스',
    sign_in: '로그인',
    sign_out: '로그아웃',
  },
  term: {
    checkboxes: {
      pre_sentence: '가입을 진행할 경우, ',
      post_sentence: '에 대해 동의한 것으로 간주됩니다.',
      accepted_terms_at: {
        rules: {
          required: '약관에 동의 해주세요.',
        },
      },
      terms_and_conditions: '이용 약관',
      and: ' 및 ',
      privacy_policy: '개인정보 처리방침',
      AgreeWithYou: '에 동의 합니다.',
      receiveMarketingInformation: '마케팅 정보 수신',
      AgreeSelect: '에 동의 합니다. (선택)',
    },
    dates: {
      accepted_terms_at: '이용 약관 동의 일자',
      accepted_marketing_terms_at: '마케팅 정보 수신 동의 일자',
    },
    dialog: {
      agree_title: '약관 동의',
      agree_content: '서비스 사용을 위한 약관에 동의해주세요.',
      terms_and_conditions: '이용 약관',
      and: '및',
      privacy_policy: '개인정보 처리방침',
      marketing_agree: '마케팅 정보 수신 동의',
      select: '(선택)',
      allAgree: '모두 동의',
      terms_and_conditions_required: '서비스 이용 약관에 동의 해주세요.',
      consent: '동의',
    },
  },
  report: {
    submit: '접수',
    reported: '접수 되었습니다',
    formField: {
      checkInput: '입력하신 내용을 확인해 주세요.',
      description: '신고 / 요청 내용',
      pleaseEnterDescription: '신고 사유를 입력해주세요.',
      youCanEnterUpTo1000Characters: '내용은 1000글자 까지 입력 가능 합니다.',
    },
  },
};
