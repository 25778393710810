import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Loading from '../components/shared/Loading';
import StoryForm from '../components/story/StoryForm';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { useStoryDetailQuery } from '../services/StoryService';
import { useUserProfileQuery } from '../services/UserService';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';

const StoryEditScreen: React.FC = () => {
  const insets = useSafeAreaInsets();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute<RouteProp<MainStackParamList, 'StoryEdit'>>();
  useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  const storyId = route.params.storyId;
  const { data: user, isFetched: isUserFetched } = useUserProfileQuery();

  useEffect(() => {
    if (!isUserFetched) {
      return;
    }
    if (navigationReady && !user) {
      navigation.replace('SignIn');
    }
    if (navigationReady && !storyId) {
      navigation.replace('MainTab', { screen: 'StoryFeed' });
    }
  }, [navigationReady, user, storyId]);

  const {
    data: story,
    isLoading,
    isFetching,
    isFetched,
  } = useStoryDetailQuery(storyId);

  useEffect(() => {
    if (isFetched && isUserFetched) {
      if (user?.admin) {
        return;
      }
      if (!story || story.created_by !== user?.id) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'StoryFeed' });
        }
      }
    }
  }, [isFetched, isUserFetched]);

  const loading = isLoading || isFetching || !story;
  return loading ? (
    <Loading />
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      <StoryForm story={story} />
    </KeyboardAwareScrollView>
  );
};

export default StoryEditScreen;
