import supabase from '../supabase';
import { Database } from '../types/database';

type PushToken = Database['public']['Tables']['push_tokens']['Insert'];

export default class PushTokenRepository {
  private static table = () => supabase.from('push_tokens');

  static async insertPushToken(pushToken: PushToken) {
    return this.table().upsert(pushToken as PushToken);
  }

  static async deletePushToken(token: string) {
    return this.table().delete().eq('token', token);
  }
}
