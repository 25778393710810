import { NavigationContext } from '@react-navigation/native';
import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import React, { useContext, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { Text } from 'react-native-paper';
import { RefreshControl } from 'react-native-web-refresh-control';

import { Place, PlacesParams } from '../../repositories/PlaceRepository';
import { usePlacesQuery } from '../../services/PlaceService';
import i18n from '../../translations/i18n';
import PlaceCard from '../place/PlaceCard';
import { Column } from '../shared/Layouts';
import { SmallLoading } from '../shared/Loading';

const PlaceCardList: React.FC<
  PlacesParams & { onPressItem: (place: Place) => void }
> = params => {
  const {
    data: places,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = usePlacesQuery({ ...params, sort: 'distance' });
  const [refreshing, setRefreshing] = useState(false);
  const ref = useRef<FlatList>(null);

  if (useContext(NavigationContext)) {
    useScrollToTop(ref);
  }

  const onRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    setRefreshing(false);
  };

  const loading = isLoading || isFetching;
  const empty = !!places?.pages?.[0]?.end;

  return empty ? (
    <Column style={{ flex: 1 }}>
      <Text>{i18n.t('place.cardList.place.rules.empty')}</Text>
    </Column>
  ) : (
    <FlatList
      ref={ref}
      showsVerticalScrollIndicator={false}
      data={places?.pages?.map(({ places }) => places).flat()}
      renderItem={({ item }) => (
        <PlaceCard key={item?.id} place={item!} onPress={params?.onPressItem} />
      )}
      keyExtractor={(item, index) => `${item?.id}${index}`}
      onEndReached={() => !loading && hasNextPage && fetchNextPage()}
      onEndReachedThreshold={0.5}
      ListFooterComponent={!refreshing && loading ? <SmallLoading /> : null}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    />
  );
};

export default PlaceCardList;
