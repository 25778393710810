import React, { useRef } from 'react';

import { DEFAULT_DELTA, toFixed } from '../../utils/locationHelpers';
import MapView, { LatLng, MapMarker, PROVIDER_GOOGLE } from '../map';

const PlacePickerMap: React.FC<{
  location?: LatLng;
  markerLocation?: LatLng;
  onReady?: (mapRef: MapView | null) => void;
  onDragMarker?: (location: LatLng) => void;
}> = ({ location, markerLocation, onReady, onDragMarker }) => {
  const mapRef = useRef<MapView>(null);
  return location && markerLocation ? (
    <MapView
      style={{ flex: 1 }}
      ref={mapRef}
      onMapReady={() => onReady?.(mapRef?.current)}
      minZoomLevel={10}
      maxZoomLevel={18}
      initialRegion={{
        ...location,
        latitudeDelta: DEFAULT_DELTA,
        longitudeDelta: DEFAULT_DELTA,
      }}
      onPress={e => {
        const lat =
          e?.nativeEvent?.coordinate?.latitude ?? (e as any)?.latLng?.lat();
        const lng =
          e?.nativeEvent?.coordinate?.longitude ?? (e as any)?.latLng?.lng();
        onDragMarker?.({
          latitude: toFixed(lat),
          longitude: toFixed(lng),
        });
      }}
      provider={PROVIDER_GOOGLE}
      zoomControlEnabled={true}
      rotateEnabled={false}
    >
      <MapMarker coordinate={markerLocation} />
    </MapView>
  ) : null;
};

export default PlacePickerMap;
