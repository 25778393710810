import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Avatar, useTheme } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/src/components/Icon';

const ImageIconAvatar: React.FC<{
  uri?: string | null;
  icon: IconSource;
  size?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
}> = ({ uri, icon, size, color, style }) => {
  const theme = useTheme();
  return uri ? (
    <Avatar.Image size={size} style={style} source={{ uri }} />
  ) : (
    <Avatar.Icon
      icon={icon}
      size={size}
      color={color}
      style={[{ backgroundColor: theme.colors.elevation.level4 }, style]}
    />
  );
};

export default ImageIconAvatar;
