import { useActionSheet } from '@expo/react-native-action-sheet';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { View } from 'react-native';
import { MD3Theme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import i18n from '../../translations/i18n';
import { pickImage } from '../../utils/upload';
import ImageIconAvatar from '../shared/ImageIconAvatar';

const AvatarContainer = styled.TouchableOpacity`
  position: relative;
  margin-bottom: 10px;
`;

const CameraIconWrapper = styled.View`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

interface FormAvatarType {
  photo_url?: string | null;
}

const FormAvatar = <T extends FormAvatarType>({
  theme,
  form,
}: {
  theme: MD3Theme;
  form: UseFormReturn<T>;
}) => {
  const { control, setValue } = form;
  const { showActionSheetWithOptions } = useActionSheet();
  const insets = useSafeAreaInsets();
  const onPress = async () => {
    const options = [
      i18n.t('common.modify'),
      i18n.t('common.delete'),
      i18n.t('common.cancel'),
    ];
    const destructiveButtonIndex = [options.indexOf(i18n.t('common.delete'))];
    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        containerStyle: {
          paddingBottom: insets.bottom,
        },
      },
      async (selectedIndex?: number) => {
        switch (selectedIndex) {
          case options.indexOf(i18n.t('common.modify')): {
            await onPressAvatar();
            break;
          }
          case options.indexOf(i18n.t('common.delete')): {
            await onPressRemove();
            break;
          }
          default:
        }
      },
    );
  };
  const onPressAvatar = async () => {
    const url = await pickImage({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: false,
      aspect: [1, 1],
      quality: 1,
    });
    if (url?.[0]) {
      // @ts-ignore @see https://github.com/orgs/react-hook-form/discussions/7246
      setValue('photo_url', url[0], { shouldDirty: true });
    }
  };
  const onPressRemove = async () => {
    // @ts-ignore @see https://github.com/orgs/react-hook-form/discussions/7246
    setValue('photo_url', null, { shouldDirty: true });
  };
  return (
    <View style={{ alignItems: 'center' }}>
      <Controller
        control={control}
        // @ts-ignore @see https://github.com/orgs/react-hook-form/discussions/7246
        name={'photo_url'}
        render={({ field: { value } }) => (
          <AvatarContainer onPress={() => onPress()}>
            <ImageIconAvatar
              size={128}
              style={{
                backgroundColor: theme.colors.onSurface,
                position: 'relative',
              }}
              uri={value}
              icon={'account'}
            />
            <CameraIconWrapper>
              <MaterialCommunityIcons
                name={'camera'}
                style={{
                  opacity: 0.9,
                  color: theme.colors.surface,
                  backgroundColor: theme.colors.onSurface,
                  padding: 4,
                  borderRadius: 14,
                  marginRight: 5,
                  marginBottom: 5,
                }}
                size={24}
              />
            </CameraIconWrapper>
          </AvatarContainer>
        )}
      />
    </View>
  );
};

export default FormAvatar;
