import supabase from '../supabase';

type ProfileResponse = Awaited<ReturnType<typeof ProfileRepository.getProfile>>;
type ProfileResponseSuccess = ProfileResponse['data'];
export type Profile = NonNullable<ProfileResponseSuccess>;

export interface ProfilesParams {
  blockUserId?: string;
  offset?: number;
}

export default class ProfileRepository {
  private static table = () => supabase.from('profiles');
  static PAGE_SIZE = 20;

  static async getProfiles({ blockUserId, offset = 0 }: ProfilesParams) {
    let query = this.table().select(`id, name, photo_url`);
    if (blockUserId) {
      query = this.table().select(
        `id, name, photo_url, blocked:users_blocked_users!blocked_user_id!inner(blocked_user_id, created_at)`,
      );
    }
    return query.range(offset, offset + ProfileRepository.PAGE_SIZE - 1);
  }

  static async getProfile(userId: string) {
    return this.table()
      .select(
        '*, admin:admins(id), artists:artists_managers(artist_id), places:places_owners(place_id)',
      )
      .eq('id', userId)
      .maybeSingle();
  }

  static async getProfileByName(name: string) {
    return this.table().select('name').ilike('name', name).maybeSingle();
  }

  static async upsertProfile(profile: Partial<NonNullable<Profile>>) {
    return this.table()
      .upsert(profile as Profile)
      .select()
      .maybeSingle();
  }

  static async deleteProfile(id: string) {
    return this.table().delete().eq('id', id);
  }
}
