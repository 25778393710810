import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import BackButton from '../components/shared/BackButton';
import DetailHeaderRight from '../components/shared/DetailHeaderRight';
import { useNotificationRoute } from '../notification';
import ArtistAddScreen from '../screens/ArtistAddScreen';
import ArtistDetailScreen from '../screens/ArtistDetailScreen';
import ArtistEditScreen from '../screens/ArtistEditScreen';
import ArtistEventFeedScreen from '../screens/ArtistEventFeedScreen';
import ArtistListScreen from '../screens/ArtistListScreen';
import CommentDetailScreen from '../screens/CommentDetailScreen';
import EventAddScreen from '../screens/EventAddScreen';
import EventDetailScreen from '../screens/EventDetailScreen';
import EventEditScreen from '../screens/EventEditScreen';
import NoticeDetailScreen from '../screens/NoticeDetailScreen';
import NoticeFeedScreen from '../screens/NoticeFeedScreen';
import NotificationFeedScreen from '../screens/NotificationFeedScreen';
import OssNoticeScreen from '../screens/OssNoticeScreen';
import PlaceAddScreen from '../screens/PlaceAddScreen';
import PlaceDetailScreen from '../screens/PlaceDetailScreen';
import PlaceEditScreen from '../screens/PlaceEditScreen';
import PlaceEventFeedScreen from '../screens/PlaceEventFeedScreen';
import ProfileEditScreen from '../screens/ProfileEditScreen';
import ReportScreen from '../screens/ReportScreen';
import SettingScreen from '../screens/SettingScreen';
import SignInScreen from '../screens/SignInScreen';
import StoryAddScreen from '../screens/StoryAddscreen';
import StoryDetailScreen from '../screens/StoryDetailScreen';
import StoryEditScreen from '../screens/StoryEditScreen';
import UserAttendedEventAddScreen from '../screens/UserAttendedEventAddscreen';
import UserAttendedEventDetailScreen from '../screens/UserAttendedEventDetailScreen';
import UserAttendedEventEditScreen from '../screens/UserAttendedEventEditScreen';
import UserAttendedEventsScreen from '../screens/UserAttendedEventsScreen';
import UserBlockedUsersScreen from '../screens/UserBlockedUsersScreen';
import UserFavoritePlacesScreen from '../screens/UserFavoritePlacesScreen';
import UserLikedArtistsScreen from '../screens/UserLikedArtistsScreen';
import UserLikedStoriesScreen from '../screens/UserLikedStoriesScreen';
import UserManagedArtistsScreen from '../screens/UserManagedArtistsScreen';
import UserManagedPlacesScreen from '../screens/UserManagedPlacesScreen';
import UserPostedArtistsScreen from '../screens/UserPostedArtistsScreen';
import UserPostedCommentsScreen from '../screens/UserPostedCommentsScreen';
import UserPostedEventsScreen from '../screens/UserPostedEventsScreen';
import UserPostedPlacesScreen from '../screens/UserPostedPlacesScreen';
import UserPostedStoriesScreen from '../screens/UserPostedStoriesScreen';
import UserSavedEventsScreen from '../screens/UserSavedEventsScreen';
import i18n from '../translations/i18n';
import MainTab, { MainTabScreens } from './MainTab';

export type MainStackParamList = {
  MainTab: {
    screen: string;
    params?: any;
  };
  SignIn: undefined;
  EventDetail: { eventId?: string };
  EventAdd: undefined;
  EventEdit: { eventId?: string };
  StoryDetail: { storyId?: string };
  StoryAdd: {
    eventId?: string;
    artistId?: string;
    placeId?: string;
    district?: string;
  };
  StoryEdit: { storyId?: string };
  CommentDetail: { commentId?: string };
  ArtistList: undefined;
  ArtistDetail: { artistId?: string; section?: 'spotify' | 'youtube' };
  ArtistAdd: undefined;
  ArtistEdit: { artistId?: string };
  ArtistEventFeed: { artistId?: string };
  AgencyEventFeed: { agencyId?: string };
  PlaceDetail: { placeId?: string };
  PlaceAdd: undefined;
  PlaceEdit: { placeId?: string };
  ProfileEdit: undefined;
  PlaceEventFeed: { placeId?: string };
  UserSavedEvents: undefined;
  UserAttendedEvents: undefined;
  UserAttendedEventDetail: { eventId?: string };
  UserAttendedEventAdd: { eventId?: string };
  UserAttendedEventEdit: { eventId?: string };
  UserLikedArtists: undefined;
  UserFavoritePlaces: undefined;
  UserPostedEvents: undefined;
  UserPostedArtists: undefined;
  UserPostedPlaces: undefined;
  UserManagedArtists: undefined;
  UserManagedPlaces: undefined;
  UserLikedStories: undefined;
  UserPostedStories: undefined;
  UserPostedComments: undefined;
  UserBlockedUsers: undefined;
  Setting: undefined;
  OssNotice: undefined;
  NoticeFeed: undefined;
  NotificationFeed: undefined;
  NoticeDetail: { noticeId?: number };
  Report: { title?: string; type?: string; target?: string };
};

export const MainStackScreens = {
  MainTab: MainTabScreens,
  SignIn: 'signin',
  EventDetail: 'events/:eventId',
  EventAdd: 'events/new',
  EventEdit: 'events/:eventId/edit',
  StoryDetail: 'stories/:storyId',
  StoryAdd: 'stories/new',
  StoryEdit: 'stories/:storyId/edit',
  CommentDetail: 'comments/:commentId',
  ArtistList: 'artists',
  ArtistDetail: 'artists/:artistId',
  ArtistAdd: 'artists/new',
  ArtistEdit: 'artists/:artistId/edit',
  ArtistEventFeed: 'artists/:artistId/events',
  AgencyEventFeed: 'agencies/:agencyId/events',
  PlaceDetail: 'places/:placeId',
  PlaceAdd: 'places/new',
  PlaceEdit: 'places/:placeId/edit',
  PlaceEventFeed: 'places/:placeId/events',
  ProfileEdit: 'profile/edit',
  UserSavedEvents: 'profile/events/saved',
  UserAttendedEvents: 'profile/events/attended',
  UserAttendedEventDetail: 'profile/events/attended/:eventId',
  UserAttendedEventAdd: 'profile/events/attended/new',
  UserAttendedEventEdit: 'profile/events/attended/:eventId/edit',
  UserLikedArtists: 'profile/artists/liked',
  UserFavoritePlaces: 'profile/places/favorite',
  UserLikedStories: 'profile/stories/liked',
  UserPostedStories: 'profile/stories/posted',
  UserPostedComments: 'profile/comments/posted',
  UserPostedEvents: 'profile/events/posted',
  UserPostedArtists: 'profile/artists/posted',
  UserPostedPlaces: 'profile/places/posted',
  UserManagedArtists: 'profile/artists/managed',
  UserManagedPlaces: 'profile/places/managed',
  UserBlockedUsers: 'profile/users/blocked',
  Setting: 'setting',
  OssNotice: 'oss-notice',
  NoticeFeed: 'notices',
  NotificationFeed: 'notifications',
  NoticeDetail: 'notices/:noticeId',
  Report: 'report',
};

const Stack = createNativeStackNavigator<MainStackParamList>();

const MainStack: React.FC<{
  initialRouteName?: keyof MainStackParamList;
  initialRouteParams?: any;
}> = ({ initialRouteName, initialRouteParams }) => {
  const theme = useTheme();
  const user = useUser();
  const insets = useSafeAreaInsets();
  useNotificationRoute();
  return initialRouteName ? (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        animation: Platform.select({
          android: 'slide_from_right',
          default: 'default',
        }),
        headerShadowVisible: true,
        headerStyle: {
          height: 55 + insets.top,
          borderBottomColor: theme.colors.elevation.level3,
          backgroundColor: theme.colors.background,
        } as unknown as any,
        headerTitleStyle: {
          color: theme.colors.onBackground,
          fontWeight: 'bold',
        },
        headerTintColor: theme.colors.onBackground,
        contentStyle: {
          backgroundColor: theme.colors.background,
        },
      }}
    >
      <Stack.Screen
        name={'SignIn'}
        component={SignInScreen}
        options={{
          headerShown: false,
          title: i18n.t('common.signIn'),
        }}
      />
      <Stack.Screen
        name={'MainTab'}
        initialParams={initialRouteParams}
        component={MainTab}
        options={{
          headerShown: false,
          animation: 'none',
        }}
      />
      <Stack.Screen
        name={'EventDetail'}
        initialParams={initialRouteParams}
        component={EventDetailScreen}
        options={{
          title: i18n.t('stack.EventDetail'),
          headerTransparent: true,
          headerShadowVisible: false,
          headerStyle: {
            backgroundColor: 'transparent',
          },
          headerTitleStyle: {
            fontSize: 0,
            color: 'transparent',
          },
          headerTintColor: '#fff',
          headerLeft: ({ tintColor }) => (
            <BackButton
              shadow={true}
              tintColor={tintColor}
              defaultScreen={'MainTab'}
            />
          ),
          headerRight: ({ tintColor }) => (
            <DetailHeaderRight share={true} more={!!user} color={tintColor} />
          ),
        }}
      />
      <Stack.Screen
        name={'EventAdd'}
        component={EventAddScreen}
        options={{
          title: i18n.t('stack.EventAdd'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'EventEdit'}
        component={EventEditScreen}
        options={{
          title: i18n.t('stack.EventEdit'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name="StoryDetail"
        initialParams={initialRouteParams}
        component={StoryDetailScreen}
        options={{
          title: i18n.t('stack.StoryDetail'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              shadow={true}
              tintColor={tintColor}
              defaultScreen="MainTab"
              params={{
                screen: 'StoryFeed',
              }}
            />
          ),
        }}
      />
      <Stack.Screen
        name="StoryAdd"
        component={StoryAddScreen}
        options={{
          title: i18n.t('stack.StoryAdd'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen="MainTab"
              params={{
                screen: 'StoryFeed',
              }}
            />
          ),
        }}
      />
      <Stack.Screen
        name="StoryEdit"
        component={StoryEditScreen}
        options={{
          title: i18n.t('stack.StoryEdit'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen="MainTab"
              params={{
                screen: 'StoryFeed',
              }}
            />
          ),
        }}
      />
      <Stack.Screen
        name="CommentDetail"
        initialParams={initialRouteParams}
        component={CommentDetailScreen}
        options={{
          title: i18n.t('stack.CommentDetail'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              shadow={true}
              tintColor={tintColor}
              defaultScreen="MainTab"
              params={{
                screen: 'StoryFeed',
              }}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ArtistList"
        initialParams={initialRouteParams}
        component={ArtistListScreen}
        options={{
          title: i18n.t('stack.ArtistList'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'ArtistDetail'}
        initialParams={initialRouteParams}
        component={ArtistDetailScreen}
        options={{
          title: i18n.t('stack.ArtistDetail'),
          headerTransparent: true,
          headerShadowVisible: false,
          headerStyle: {
            backgroundColor: 'transparent',
          },
          headerTitleStyle: {
            fontSize: 0,
            color: 'transparent',
          },
          headerTintColor: '#fff',
          headerLeft: ({ tintColor }) => (
            <BackButton
              shadow={true}
              tintColor={tintColor}
              defaultScreen={'MainTab'}
            />
          ),
          headerRight: ({ tintColor }) => (
            <DetailHeaderRight share={true} more={!!user} color={tintColor} />
          ),
        }}
      />
      <Stack.Screen
        name={'ArtistAdd'}
        component={ArtistAddScreen}
        options={{
          title: i18n.t('stack.ArtistAdd'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'ArtistEdit'}
        component={ArtistEditScreen}
        options={{
          title: i18n.t('stack.ArtistEdit'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'ArtistEventFeed'}
        initialParams={initialRouteParams}
        component={ArtistEventFeedScreen}
        options={{
          title: i18n.t('stack.ArtistEventFeed'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'AgencyEventFeed'}
        initialParams={initialRouteParams}
        component={ArtistEventFeedScreen}
        options={{
          title: i18n.t('stack.ArtistEventFeed'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'PlaceDetail'}
        initialParams={initialRouteParams}
        component={PlaceDetailScreen}
        options={{
          title: i18n.t('stack.PlaceDetail'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{
                screen: 'Places',
              }}
            />
          ),
          headerRight: ({ tintColor }) => (
            <DetailHeaderRight share={true} more={!!user} color={tintColor} />
          ),
        }}
      />
      <Stack.Screen
        name={'PlaceEventFeed'}
        initialParams={initialRouteParams}
        component={PlaceEventFeedScreen}
        options={{
          title: i18n.t('stack.PlaceEventFeed'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'PlaceAdd'}
        component={PlaceAddScreen}
        options={{
          title: i18n.t('stack.PlaceAdd'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'PlaceEdit'}
        component={PlaceEditScreen}
        options={{
          title: i18n.t('stack.PlaceEdit'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
      <Stack.Screen
        name={'ProfileEdit'}
        component={ProfileEditScreen}
        options={{
          title: i18n.t('stack.ProfileEdit'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserSavedEvents'}
        component={UserSavedEventsScreen}
        options={{
          title: i18n.t('stack.UserSavedEvents'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserAttendedEvents'}
        component={UserAttendedEventsScreen}
        options={{
          title: i18n.t('stack.UserAttendedEvents'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              forceDefault
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name="UserAttendedEventDetail"
        initialParams={initialRouteParams}
        component={UserAttendedEventDetailScreen}
        options={{
          title: i18n.t('stack.UserAttendedEventDetail'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              shadow={true}
              tintColor={tintColor}
              defaultScreen="UserAttendedEvents"
            />
          ),
        }}
      />
      <Stack.Screen
        name="UserAttendedEventAdd"
        component={UserAttendedEventAddScreen}
        options={{
          title: i18n.t('stack.UserAttendedEventAdd'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen="UserAttendedEvents"
            />
          ),
        }}
      />
      <Stack.Screen
        name="UserAttendedEventEdit"
        component={UserAttendedEventEditScreen}
        options={{
          title: i18n.t('stack.UserAttendedEventEdit'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen="UserAttendedEvents"
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserLikedStories'}
        component={UserLikedStoriesScreen}
        options={{
          title: i18n.t('stack.UserLikedStories'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserLikedArtists'}
        component={UserLikedArtistsScreen}
        options={{
          title: i18n.t('stack.UserLikedArtists'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserFavoritePlaces'}
        component={UserFavoritePlacesScreen}
        options={{
          title: i18n.t('stack.UserFavoritePlaces'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserPostedStories'}
        component={UserPostedStoriesScreen}
        options={{
          title: i18n.t('stack.UserPostedStories'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserPostedComments'}
        component={UserPostedCommentsScreen}
        options={{
          title: i18n.t('stack.UserPostedComments'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserBlockedUsers'}
        component={UserBlockedUsersScreen}
        options={{
          title: i18n.t('stack.UserBlockedUsers'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserPostedEvents'}
        component={UserPostedEventsScreen}
        options={{
          title: i18n.t('stack.UserPostedEvents'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserPostedArtists'}
        component={UserPostedArtistsScreen}
        options={{
          title: i18n.t('stack.UserPostedArtists'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserPostedPlaces'}
        component={UserPostedPlacesScreen}
        options={{
          title: i18n.t('stack.UserPostedPlaces'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserManagedArtists'}
        component={UserManagedArtistsScreen}
        options={{
          title: i18n.t('stack.UserManagedArtists'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'UserManagedPlaces'}
        component={UserManagedPlacesScreen}
        options={{
          title: i18n.t('stack.UserManagedPlaces'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'Setting'}
        component={SettingScreen}
        options={{
          title: i18n.t('stack.Setting'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              forceDefault
              defaultScreen={'MainTab'}
              params={{ screen: 'Profile' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'OssNotice'}
        component={OssNoticeScreen}
        options={{
          title: i18n.t('stack.OssNotice'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'Setting'} />
          ),
        }}
      />
      <Stack.Screen
        name={'NoticeFeed'}
        initialParams={initialRouteParams}
        component={NoticeFeedScreen}
        options={{
          title: i18n.t('stack.NoticeFeed'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              forceDefault
              defaultScreen={'Setting'}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'NotificationFeed'}
        initialParams={initialRouteParams}
        component={NotificationFeedScreen}
        options={{
          title: i18n.t('stack.NotificationFeed'),
          headerLeft: ({ tintColor }) => (
            <BackButton
              tintColor={tintColor}
              forceDefault
              defaultScreen={'MainTab'}
            />
          ),
        }}
      />
      <Stack.Screen
        name={'NoticeDetail'}
        initialParams={initialRouteParams}
        component={NoticeDetailScreen}
        options={{
          title: i18n.t('stack.NoticeDetail'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'NoticeFeed'} />
          ),
        }}
      />
      <Stack.Screen
        name={'Report'}
        component={ReportScreen}
        options={{
          title: i18n.t('stack.Report'),
          headerLeft: ({ tintColor }) => (
            <BackButton tintColor={tintColor} defaultScreen={'MainTab'} />
          ),
        }}
      />
    </Stack.Navigator>
  ) : null;
};

export default MainStack;
