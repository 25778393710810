import { startOfDay } from 'date-fns';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Button, MD3Theme } from 'react-native-paper';

import CommonService from '../../services/CommonService';
import { UserService } from '../../services/UserService';
import i18n from '../../translations/i18n';
import { formatDate } from '../../utils/datetimes';
import { trim } from '../../utils/helpers';
import DefaultDialog from '../dialogs/DefaultDialog';
import {
  FormAutoCompleteSelect,
  FormDateInput,
  FormTextInput,
} from '../form/FormInputs';
import DistrictList from '../place/DistrictList';
import { useDialog } from '../providers/DialogProvider';
import { Column } from '../shared/Layouts';
import { showInfo } from '../shared/Toast';
import { ProfileFormType } from './ProfileForm';

export const ProfileFormEmail: React.FC<{
  theme: MD3Theme;
  email: string;
}> = ({ theme, email }) => (
  <TouchableOpacity
    style={{ width: '100%' }}
    onPress={() =>
      showInfo(i18n.t('profile.formField.email.rules.not_modified'))
    }
  >
    <FormTextInput
      disabled={true}
      theme={theme}
      label={i18n.t('profile.formField.email.label')}
      icon={'email'}
      value={email}
    />
  </TouchableOpacity>
);

export const ProfileFormDistrict: React.FC<{
  theme: MD3Theme;
  form: ProfileFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    setValue,
  },
}) => (
  <Controller
    control={control}
    name={'district'}
    rules={{
      required: i18n.t('profile.formField.district.rules.required'),
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormAutoCompleteSelect
        displaySuggestion
        theme={theme}
        label={i18n.t('profile.formField.district.label') + ' *'}
        icon={'map-marker-check-outline'}
        onSelectItem={item =>
          setValue('district', item.name, { shouldDirty: true })
        }
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        errorMessage={errors.district?.message as string}
        ListComponent={DistrictList}
      />
    )}
  />
);

export const ProfileFormName: React.FC<{
  edit?: boolean;
  theme: MD3Theme;
  form: ProfileFormType;
}> = ({
  edit,
  theme,
  form: {
    control,
    formState: { dirtyFields, errors, defaultValues },
  },
}) => (
  <Controller
    control={control}
    name={'name'}
    rules={{
      required: i18n.t('profile.formField.name.rules.required'),
      minLength: {
        value: 3,
        message: i18n.t('profile.formField.name.rules.minLength'),
      },
      maxLength: {
        value: 30,
        message: i18n.t('profile.formField.name.rules.maxLength'),
      },
      validate: async value => {
        if (edit && !dirtyFields.name) {
          return;
        }
        if (defaultValues?.['name']?.toLowerCase() === value.toLowerCase()) {
          return;
        }
        if (/\s/.test(value)) {
          return i18n.t('profile.formField.name.rules.whitespace');
        }
        const exists = await UserService.existsName(trim(value));
        if (exists) {
          return i18n.t('common.formField.existsName');
        }
        const bannedWords = await CommonService.findBannedWords(value);
        if (!bannedWords) {
          return;
        }
        return i18n.t('common.foundBannedKeyword', { keyword: bannedWords });
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('profile.formField.name.label') + ' *'}
        icon={'account'}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        maxLength={30}
        errorMessage={errors.name?.message as string}
      />
    )}
  />
);

export const ProfileFormBirthday: React.FC<{
  theme: MD3Theme;
  form: ProfileFormType;
}> = ({ theme, form: { control, setValue } }) => (
  <Controller
    control={control}
    name={'birthday'}
    render={({ field: { value } }) => (
      <View
        style={{
          width: '100%',
        }}
      >
        <FormDateInput
          theme={theme}
          label={i18n.t('profile.formField.birthday')}
          icon={'calendar'}
          value={value ? formatDate(startOfDay(new Date(value)))! : ''}
          onConfirm={value =>
            setValue('birthday', value?.toISOString() ?? '', {
              shouldDirty: true,
            })
          }
        />
      </View>
    )}
  />
);

export const ProfileFormGender: React.FC<{
  theme: MD3Theme;
  form: ProfileFormType;
}> = ({ theme, form: { control, setValue } }) => {
  const { showDialog, hideDialog } = useDialog();
  return (
    <Controller
      control={control}
      name={'gender'}
      render={({ field: { value } }) => (
        <TouchableOpacity
          style={{ width: '100%' }}
          onPress={() =>
            showDialog(
              <DefaultDialog
                title={i18n.t('profile.formField.gender')}
                content={
                  <Column style={{ gap: 10 }}>
                    <Button
                      mode={'outlined'}
                      style={{ width: '80%' }}
                      onPress={() => {
                        setValue('gender', i18n.t('profile.formField.male'), {
                          shouldDirty: true,
                        });
                        hideDialog();
                      }}
                    >
                      {i18n.t('profile.formField.male')}
                    </Button>
                    <Button
                      mode={'outlined'}
                      style={{ width: '80%' }}
                      onPress={() => {
                        setValue('gender', i18n.t('profile.formField.female'), {
                          shouldDirty: true,
                        });
                        hideDialog();
                      }}
                    >
                      {i18n.t('profile.formField.female')}
                    </Button>
                    <Button
                      mode={'outlined'}
                      style={{ width: '80%' }}
                      onPress={() => {
                        setValue('gender', i18n.t('profile.formField.others'), {
                          shouldDirty: true,
                        });
                        hideDialog();
                      }}
                    >
                      {i18n.t('profile.formField.others')}
                    </Button>
                  </Column>
                }
                onPressDismiss={() => {
                  setValue('gender', '', { shouldDirty: true });
                  hideDialog();
                }}
              />,
            )
          }
        >
          <View pointerEvents={'none'}>
            <FormTextInput
              theme={theme}
              disabled
              label={i18n.t('profile.formField.gender')}
              icon={'gender-male-female'}
              value={value ?? ''}
            />
          </View>
        </TouchableOpacity>
      )}
    />
  );
};

export const ProfileFormBio: React.FC<{
  theme: MD3Theme;
  form: ProfileFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'bio'}
    rules={{
      maxLength: {
        value: 100,
        message: i18n.t('profile.formField.bioCanBeUpTo100Characters'),
      },
      validate: async value => {
        if (!value) {
          return;
        }
        const bannedWords = await CommonService.findBannedWords(value);
        if (!bannedWords) {
          return;
        }
        return i18n.t('common.foundBannedKeyword', { keyword: bannedWords });
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('profile.formField.bio.label')}
        icon={'card-text-outline'}
        multiline
        numberOfLines={Platform.OS === 'web' ? 3 : undefined}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={100}
        errorMessage={errors.bio?.message as string}
      />
    )}
  />
);
