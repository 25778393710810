import React from 'react';
import { View } from 'react-native';
import Markdown from 'react-native-markdown-display';
import { MD3Theme, Text } from 'react-native-paper';

import { Notice } from '../../repositories/NoticeRepository';
import { toDateTimeString } from '../../utils/datetimes';
import { Row } from '../shared/Layouts';

const LINE_HEIGHT_RATIO = 1.5;

const NoticeDetailContent: React.FC<{
  notice: Notice;
  theme: MD3Theme;
}> = ({ notice, theme }) => {
  return (
    <View style={{ margin: 20, gap: 5 }}>
      <Text style={{ fontWeight: 'bold' }} variant={'titleLarge'}>
        {notice.title?.trim()}
      </Text>
      <Row
        style={{
          gap: 3,
          justifyContent: 'flex-start',
        }}
      >
        {notice.created_at && (
          <Text
            variant={'bodySmall'}
            style={{ color: theme.colors.elevation.level5 }}
          >
            {toDateTimeString(notice.created_at)}
          </Text>
        )}
      </Row>
      {/*@ts-ignore*/}
      <Markdown
        mergeStyle={true}
        style={{
          body: {
            color: theme.colors.onSurface,
            fontSize: 16,
            lineHeight: 16 * LINE_HEIGHT_RATIO,
          },
          heading1: {
            flexDirection: 'row',
            fontSize: 32,
            lineHeight: 32 * LINE_HEIGHT_RATIO,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          },
          heading2: {
            flexDirection: 'row',
            fontSize: 24,
            lineHeight: 24 * LINE_HEIGHT_RATIO,
            paddingVertical: 10,
            fontWeight: 'bold',
          },
          heading3: {
            flexDirection: 'row',
            fontSize: 18,
            lineHeight: 18 * LINE_HEIGHT_RATIO,
          },
          heading4: {
            flexDirection: 'row',
            fontSize: 16,
            lineHeight: 16 * LINE_HEIGHT_RATIO,
          },
          heading5: {
            flexDirection: 'row',
            fontSize: 13,
            lineHeight: 13 * LINE_HEIGHT_RATIO,
          },
          heading6: {
            flexDirection: 'row',
            fontSize: 11,
            lineHeight: 11 * LINE_HEIGHT_RATIO,
          },
        }}
      >
        {notice.content}
      </Markdown>
    </View>
  );
};

export default NoticeDetailContent;
