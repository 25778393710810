import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Button, useTheme } from 'react-native-paper';
import styled from 'styled-components/native';

import { Profile } from '../../repositories/ProfileRepository';
import {
  UserProfile,
  useUpsertUserProfileMutation,
} from '../../services/UserService';
import i18n from '../../translations/i18n';
import { ResourceType } from '../../utils/enums';
import { setStorageRegionAndDistrict } from '../../utils/locationHelpers';
import { uploadImage } from '../../utils/upload';
import FormAvatar from '../form/FormAvatar';
import Loading from '../shared/Loading';
import { showError } from '../shared/Toast';
import {
  ProfileFormBio,
  ProfileFormBirthday,
  ProfileFormDistrict,
  ProfileFormEmail,
  ProfileFormGender,
  ProfileFormName,
} from './ProfileFormFields';
import TermCheckboxes from './TermCheckboxes';
import TermDates from './TermDates';

const DEFAULT_MARGIN = 10;

const PROFILE_WIDTH = 256;

const ProfileFormContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
`;

export type ProfileFormType = UseFormReturn<UserProfile>;

const ProfileForm: React.FC<{
  edit?: boolean;
  profile: Partial<UserProfile>;
  onSuccess?: (values?: UserProfile) => void;
}> = ({ edit, profile, onSuccess }) => {
  const theme = useTheme();
  const [uploading, setUploading] = useState<boolean>(false);
  const { mutateAsync: upsertUserProfile, isLoading } =
    useUpsertUserProfileMutation();
  const navigation = useNavigation();

  const form = useForm<UserProfile>({
    defaultValues: profile,
  });

  const {
    handleSubmit,
    setFocus,
    reset,
    formState: { isDirty, dirtyFields },
  } = form;

  useEffect(() => {
    reset(profile);
    if (profile.id) {
      setUploading(false);
    }
  }, [profile]);

  const onSubmit = async (userProfile: UserProfile) => {
    if (edit && !isDirty) {
      onSuccess?.(undefined);
      return;
    }
    setUploading(true);
    if (userProfile.photo_url && dirtyFields.photo_url) {
      userProfile.photo_url = await uploadImage(
        userProfile.photo_url,
        ResourceType.PROFILE,
        userProfile.id,
        PROFILE_WIDTH,
      );
    }
    try {
      const result = await upsertUserProfile(userProfile);
      if (result) {
        await setStorageRegionAndDistrict({
          latitude: result?.latitude!,
          longitude: result?.longitude!,
          latitudeDelta: result?.latitudeDelta!,
          longitudeDelta: result?.longitudeDelta!,
          district: result.district,
        });
        onSuccess?.(result);
      }
    } catch (error) {
      showError(error);
    }
    setUploading(false);
  };

  const onError = (errors: FieldErrors) => {
    const errorNames: (keyof FieldErrors)[] = Object.keys(errors);
    const hasError = !!errorNames.length;
    if (hasError) {
      showError(i18n.t('common.form.checkInput'));
      setFocus(errorNames[0] as keyof Partial<Profile>);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: edit
        ? () => (
            <Button
              disabled={edit && !isDirty}
              labelStyle={{ fontWeight: 'bold', fontSize: 16 }}
              onPress={handleSubmit(onSubmit, onError)}
            >
              {i18n.t('common.save')}
            </Button>
          )
        : null,
    });
  }, [isDirty]);

  const showLoading = uploading || isLoading || !profile;
  return (
    <ProfileFormContainer>
      {showLoading && <Loading />}
      <FormAvatar theme={theme} form={form} />
      <ProfileFormEmail theme={theme} email={profile?.email ?? ''} />
      <ProfileFormDistrict theme={theme} form={form} />
      <ProfileFormName theme={theme} form={form} edit={edit} />
      <ProfileFormBirthday theme={theme} form={form} />
      <ProfileFormGender theme={theme} form={form} />
      <ProfileFormBio theme={theme} form={form} />
      {edit ? (
        <TermDates
          accepted_terms_at={profile.accepted_terms_at}
          accepted_marketing_terms_at={profile.accepted_marketing_terms_at}
        />
      ) : (
        <TermCheckboxes form={form} />
      )}
      {!edit && (
        <Button
          disabled={edit && !isDirty}
          mode={'contained'}
          labelStyle={{ fontSize: 16, fontWeight: 'bold' }}
          style={{ width: '100%', marginBottom: DEFAULT_MARGIN }}
          onPress={handleSubmit(onSubmit, onError)}
        >
          {i18n.t('profile.join')}
        </Button>
      )}
    </ProfileFormContainer>
  );
};

export default ProfileForm;
