import React from 'react';
import { StyleProp } from 'react-native';
import { Text } from 'react-native-paper';
import { TextStyle } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

const Dot: React.FC<{ style?: StyleProp<TextStyle> }> = ({ style }) => (
  <Text style={[style]}>·</Text>
);

export default Dot;
