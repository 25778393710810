import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { useTabNavigation } from 'react-native-paper-tabs';
import { RefreshControl } from 'react-native-web-refresh-control';

import { MainStackParamList } from '../../navigation/MainStack';
import { useArtistsQuery } from '../../services/ArtistService';
import { useEventsQuery } from '../../services/EventService';
import { usePlacesQuery } from '../../services/PlaceService';
import { useStoriesQuery } from '../../services/StoryService';
import i18n from '../../translations/i18n';
import ArtistCard from '../artist/ArtistCard';
import EventCard from '../event/EventCard';
import PlaceCard from '../place/PlaceCard';
import { SmallLoading } from '../shared/Loading';
import StoryCard from '../story/StoryCard';

const DISPLAY_SIZE = 5;

interface Loadable {
  isLoading: boolean;
  isFetching: boolean;
}

const isLoading = (loadable: Loadable) =>
  loadable.isLoading || loadable.isFetching;

const AllSearchList: React.FC<{
  searchQuery: string;
}> = ({ searchQuery }) => {
  const [refreshing, setRefreshing] = useState(false);
  const eventQuery = useEventsQuery({ searchQuery });
  const storiesQuery = useStoriesQuery({ searchQuery });
  const artistsQuery = useArtistsQuery({ searchQuery });
  const placesQuery = usePlacesQuery({ searchQuery });
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const goTo = useTabNavigation();
  const events = eventQuery.data?.pages?.map(({ events }) => events).flat();
  const stories = storiesQuery.data?.pages
    ?.map(({ stories }) => stories)
    .flat();
  const artists = artistsQuery.data?.pages
    ?.map(({ artists }) => artists)
    .flat();
  const places = placesQuery.data?.pages?.map(({ places }) => places).flat();
  const onRefresh = async () => {
    setRefreshing(true);
    await Promise.all([
      eventQuery.refetch(),
      storiesQuery.refetch(),
      artistsQuery.refetch(),
      placesQuery.refetch(),
    ]);
    setRefreshing(false);
  };

  const showLoading = [
    eventQuery,
    storiesQuery,
    artistsQuery,
    placesQuery,
  ].some(isLoading);

  const empty =
    eventQuery.isFetched &&
    storiesQuery.isFetched &&
    artistsQuery.isFetched &&
    placesQuery.isFetched &&
    !events?.length &&
    !stories?.length &&
    !artists?.length &&
    !places?.length;
  return (
    <ScrollView
      style={{ flex: 1, paddingBottom: 20 }}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    >
      {!refreshing && showLoading && <SmallLoading />}
      {empty && (
        <Text
          style={{
            flex: 1,
            padding: 10,
            alignSelf: 'center',
          }}
        >
          {i18n.t('common.searchEmpty')}
        </Text>
      )}
      {!!events?.length && (
        <>
          <Text
            variant={'titleMedium'}
            style={{
              fontWeight: 'bold',
              paddingHorizontal: 20,
              paddingTop: 10,
            }}
          >
            {i18n.t('common.event')}
          </Text>
          {events.slice(0, DISPLAY_SIZE).map((event, index) => (
            <EventCard
              key={index}
              event={event}
              onPress={event =>
                navigation.push('EventDetail', { eventId: event.id })
              }
            />
          ))}
          {events.length > DISPLAY_SIZE && (
            <Button
              style={{ marginVertical: 10, marginHorizontal: 20 }}
              mode={'contained-tonal'}
              onPress={() => goTo(1)}
            >
              <Text variant={'bodyMedium'}>{i18n.t('common.more')} </Text>
            </Button>
          )}
        </>
      )}
      {!!stories?.length && (
        <>
          <Text
            variant={'titleMedium'}
            style={{
              fontWeight: 'bold',
              paddingHorizontal: 20,
              paddingTop: 10,
            }}
          >
            {i18n.t('common.story')}
          </Text>
          {stories.slice(0, DISPLAY_SIZE).map((story, index) => (
            <StoryCard
              key={index}
              story={story}
              onPress={story =>
                navigation.push('StoryDetail', { storyId: story.id })
              }
            />
          ))}
          {stories.length > DISPLAY_SIZE && (
            <Button
              style={{ marginVertical: 10, marginHorizontal: 20 }}
              mode={'contained-tonal'}
              onPress={() => goTo(2)}
            >
              <Text variant={'bodyMedium'}>{i18n.t('common.more')} </Text>
            </Button>
          )}
        </>
      )}
      {!!artists?.length && (
        <>
          <Text
            variant={'titleMedium'}
            style={{
              fontWeight: 'bold',
              paddingHorizontal: 20,
              paddingVertical: 10,
            }}
          >
            {i18n.t('common.artist')} / {i18n.t('common.agency')}
          </Text>
          {artists.slice(0, DISPLAY_SIZE).map((artist, index) => (
            <ArtistCard
              key={index}
              artist={artist}
              onPress={artist =>
                navigation.push('ArtistDetail', { artistId: artist.id })
              }
            />
          ))}
          {artists.length > DISPLAY_SIZE && (
            <Button
              style={{ marginVertical: 10, marginHorizontal: 20 }}
              mode={'contained-tonal'}
              onPress={() => goTo(3)}
            >
              <Text variant={'bodyMedium'}>{i18n.t('common.more')} </Text>
            </Button>
          )}
        </>
      )}
      {!!places?.length && (
        <>
          <Text
            variant={'titleMedium'}
            style={{
              fontWeight: 'bold',
              paddingHorizontal: 20,
              paddingTop: 10,
            }}
          >
            {i18n.t('common.place')}
          </Text>
          {places.slice(0, DISPLAY_SIZE).map((place, index) => (
            <PlaceCard
              key={index}
              place={place}
              onPress={place =>
                navigation.push('PlaceDetail', { placeId: place.id })
              }
            />
          ))}
          {places.length > DISPLAY_SIZE && (
            <Button
              style={{ marginVertical: 10, marginHorizontal: 20 }}
              mode={'contained-tonal'}
              onPress={() => goTo(4)}
            >
              <Text variant={'bodyMedium'}>{i18n.t('common.more')} </Text>
            </Button>
          )}
        </>
      )}
    </ScrollView>
  );
};

export default AllSearchList;
