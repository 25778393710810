import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import UserAttendedEventForm from '../components/event/UserAttendedEventForm';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { useEventDetailQuery } from '../services/EventService';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';

const UserAttendedEventAddScreen: React.FC = () => {
  const insets = useSafeAreaInsets();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route =
    useRoute<RouteProp<MainStackParamList, 'UserAttendedEventAdd'>>();
  const eventId = route.params.eventId;
  const user = useUser();

  useEffect(() => {
    if (navigationReady && !user) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, user]);

  const { data: event } = useEventDetailQuery(eventId);

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      {event && (
        <UserAttendedEventForm
          event={event}
          userAttendedEvent={{ user_id: user?.id, attended_event_id: event.id }}
        />
      )}
    </KeyboardAwareScrollView>
  );
};

export default UserAttendedEventAddScreen;
