import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { sendError, showError } from '../components/shared/Toast';
import StoryRepository, {
  StoriesParams,
  Story,
} from '../repositories/StoryRepository';
import UserRepository from '../repositories/UserRepository';
import { refetchAll, resetAll } from '../utils/queryClient';

export class StoryService {
  static async getStory(storyId: string): Promise<Story | undefined> {
    const { data, error } = await StoryRepository.getStory(storyId);
    if (error) {
      showError(error);
      return;
    }
    return data as Story;
  }

  static async getStories(params: StoriesParams) {
    const { data } = await StoryRepository.getStories(params);
    const stories = ((data ?? []) as Story[])?.filter(
      story => !story.created?.blocked?.length,
    );
    return {
      stories,
      offset: params.offset,
      end: !stories?.length,
    };
  }

  static async upsertStory({
    created,
    event,
    artist,
    place,
    ...story
  }: NonNullable<Story>) {
    if (event?.title && event?.id) {
      story.event_id = event.id;
    } else {
      story.event_id = null;
    }
    if (artist?.name && artist?.id) {
      story.artist_id = artist.id;
    } else {
      story.artist_id = null;
    }
    if (place?.name && place?.id) {
      story.place_id = place.id;
    } else {
      story.place_id = null;
    }
    const { data: upserted, error } = story.created_at
      ? await StoryRepository.updateStory(story as Story)
      : await StoryRepository.insertStory(story as Story);
    if (error) {
      return Promise.reject(error);
    }
    if (!upserted) {
      throw new Error('Upsert failure');
    }
    return upserted;
  }

  static async deleteStory(id: string): Promise<void> {
    const { error } = await StoryRepository.deleteStory(id);
    if (error) {
      showError(error);
    }
  }

  static async likeStory(storyId: string): Promise<void> {
    try {
      await UserRepository.insertUserLikedStory(storyId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }

  static async unlikeStory(storyId: string): Promise<void> {
    try {
      await UserRepository.deleteUserLikedStory(storyId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }
}

export function useUpsertStoryMutation() {
  return useMutation((story: Story) => StoryService.upsertStory(story), {
    onSuccess: async story => await resetAll(),
  });
}

export function useStoriesQuery(params: StoriesParams) {
  return useInfiniteQuery(
    ['stories', params],
    async ({ pageParam }) =>
      await StoryService.getStories({ ...params, offset: pageParam }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + StoryRepository.PAGE_SIZE,
    },
  );
}

export function useDeleteStoryMutation(storyId?: string) {
  return useMutation(
    () =>
      storyId
        ? StoryService.deleteStory(storyId)
        : Promise.reject(new Error('Invalid StoryId')),
    {
      onSuccess: async () => await resetAll(),
    },
  );
}

export function useStoryDetailQuery(id?: string) {
  return useQuery<Story | null>(['storyDetail', id], async () => {
    if (!id) {
      return null;
    }
    const story = await StoryService.getStory(id);
    if (story?.created?.blocked?.length) {
      return null;
    }
    return story ?? null;
  });
}
