import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { getDate, getMonth, getYear, set, startOfDay } from 'date-fns';
import * as ImagePicker from 'expo-image-picker';
import _ from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Platform, TouchableOpacity } from 'react-native';
import {
  Button,
  Checkbox,
  Divider,
  MD3Theme,
  Text,
  TextInput,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { MainStackParamList } from '../../navigation/MainStack';
import { Artist } from '../../repositories/ArtistRepository';
import {
  EventPaymentType as PaymentType,
  EventStatus as Status,
} from '../../repositories/EventRepository';
import { Place } from '../../repositories/PlaceRepository';
import i18n from '../../translations/i18n';
import { formatDate, formatTime } from '../../utils/datetimes';
import { REGEXP_NUMBER, REGEXP_URL } from '../../utils/regexps';
import { pickImage } from '../../utils/upload';
import ArtistCardList from '../artist/ArtistCardList';
import {
  FormAutoCompleteSelect,
  FormDateInput,
  FormTextInput,
  FormTimeInput,
} from '../form/FormInputs';
import ImagesSelector from '../form/ImageSelector';
import PlaceCardList from '../place/PlaceCardList';
import { Row } from '../shared/Layouts';
import { EventFormType } from './EventForm';

export const EventFormDivider = styled(Divider)<{ theme: MD3Theme }>`
  background-color: ${({ theme }) => theme.colors.onSurface};
  transform: scaleY(0.5);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const EventImages: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({ form: { control, setValue } }) => {
  return (
    <Controller
      control={control}
      name={'image_urls'}
      render={({ field: { value } }) => (
        <ImagesSelector
          images={value ?? []}
          size={5}
          onAddImage={async () => {
            const urls = await pickImage({
              mediaTypes: ImagePicker.MediaTypeOptions.Images,
              base64: false,
              quality: 1,
              allowsMultipleSelection: true,
              selectionLimit: 5 - (value?.length ?? 0),
            });
            if (urls?.length) {
              setValue(
                'image_urls',
                [...(value ?? []), ...urls].filter(url => url).slice(0, 5),
                {
                  shouldDirty: true,
                },
              );
            }
          }}
          onRemoveImage={index => {
            if (value?.length) {
              value.splice(index, 1);
              setValue('image_urls', [...value], {
                shouldDirty: true,
              });
            }
          }}
        />
      )}
    />
  );
};

export const EventTitle: React.FC<{ theme: MD3Theme; form: EventFormType }> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'title'}
    rules={{
      required: i18n.t('common.formField.title.rules.required'),
      maxLength: {
        value: 100,
        message: i18n.t('common.formField.title.rules.maxLength'),
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('common.formField.title.label') + ' *'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={100}
        errorMessage={errors.title?.message as string}
      />
    )}
  />
);

const ARTIST_LIMIT = 60;

export const EventArtists: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
  },
}) => {
  const insets = useSafeAreaInsets();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const artists = (watch('artists') as NonNullable<Artist>[]) ?? [];
  return (
    <Controller
      control={control}
      name={'artist'}
      rules={{
        validate: () => {
          if (!artists?.length) {
            return i18n.t('event.formField.artist.validate.notExist');
          }
          if (artists?.length > ARTIST_LIMIT) {
            return i18n.t('event.formField.artist.validate.limit', {
              ARTIST_LIMIT,
            });
          }
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormAutoCompleteSelect
          multiselect
          theme={theme}
          style={{ zIndex: 4 }}
          label={i18n.t('event.formField.artist.label') + ' *'}
          onBlur={onBlur}
          onChange={onChange}
          selectedItems={artists}
          onSelectItem={artist => {
            const merged = _.uniqBy([...artists, artist], 'id');
            if (merged.length > ARTIST_LIMIT) {
              setError('artist', {
                type: 'custom',
                message: i18n.t('event.formField.artist.validate.limit', {
                  ARTIST_LIMIT,
                }),
              });
              return;
            }
            setValue('artists', merged, {
              shouldDirty: true,
            });
            setValue('artist', '', {
              shouldDirty: true,
            });
          }}
          onCloseSelectedItems={(_, index) => {
            artists.splice(index!, 1);
            setValue('artists', [...artists], {
              shouldDirty: true,
            });
          }}
          value={value ?? ''}
          maxLength={30}
          errorMessage={errors.artist?.message as string}
          AddButton={({ hideItems }) => (
            <Button
              style={{
                position: 'absolute',
                alignSelf: 'center',
                width: 150,
                bottom: 30 + insets.bottom,
              }}
              mode={'contained'}
              labelStyle={{ fontWeight: 'bold' }}
              onPress={() => {
                hideItems();
                navigation.push('ArtistAdd');
              }}
            >
              {i18n.t('event.formField.add')}
            </Button>
          )}
          ListComponent={ArtistCardList}
        />
      )}
    />
  );
};

const AGENCY_LIMIT = 2;

export const EventAgencies: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
  },
}) => {
  const insets = useSafeAreaInsets();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const agencies = (watch('agencies') as NonNullable<Artist>[]) ?? [];
  return (
    <Controller
      control={control}
      name={'agency'}
      rules={{
        validate: () => {
          if (agencies?.length > AGENCY_LIMIT) {
            return i18n.t('event.formField.agency.validate.limit', {
              AGENCY_LIMIT,
            });
          }
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormAutoCompleteSelect
          multiselect
          theme={theme}
          style={{ zIndex: 3 }}
          label={i18n.t('event.formField.agency.label')}
          onBlur={onBlur}
          onChange={onChange}
          selectedItems={agencies}
          onSelectItem={agency => {
            const merged = _.uniqBy([...agencies, agency], 'id');
            if (merged.length > AGENCY_LIMIT) {
              setError('agency', {
                type: 'custom',
                message: i18n.t('event.formField.agency.validate.limit', {
                  AGENCY_LIMIT,
                }),
              });
              return;
            }
            setValue('agencies', merged, {
              shouldDirty: true,
            });
            setValue('agency', '', {
              shouldDirty: true,
            });
          }}
          onCloseSelectedItems={(_, index) => {
            agencies.splice(index!, 1);
            setValue('agencies', [...agencies], {
              shouldDirty: true,
            });
          }}
          value={value ?? ''}
          maxLength={30}
          errorMessage={errors.agency?.message as string}
          AddButton={({ hideItems }) => (
            <Button
              style={{
                position: 'absolute',
                alignSelf: 'center',
                width: 150,
                bottom: 30 + insets.bottom,
              }}
              mode={'contained'}
              labelStyle={{ fontWeight: 'bold' }}
              onPress={() => {
                hideItems();
                navigation.push('ArtistAdd');
              }}
            >
              {i18n.t('event.formField.add')}
            </Button>
          )}
          ListComponent={ArtistCardList}
        />
      )}
    />
  );
};

export const EventPlace: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    getValues,
    setValue,
  },
}) => {
  const insets = useSafeAreaInsets();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  return (
    <Controller
      control={control}
      name={'place.name'}
      rules={{
        required: i18n.t('event.formField.place.rules.required'),
        validate: () => {
          const id = getValues('place.id');
          if (!id) {
            return i18n.t('event.formField.place.rules.notValid');
          }
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormAutoCompleteSelect
          theme={theme}
          style={{ zIndex: 2 }}
          label={i18n.t('event.formField.place.label') + ' *'}
          onSelectItem={(item: Place) => setValue('place', item)}
          onBlur={onBlur}
          onChange={onChange}
          value={value ?? ''}
          maxLength={30}
          errorMessage={errors.place?.name?.message as string}
          AddButton={({ hideItems }) => (
            <Button
              style={{
                position: 'absolute',
                alignSelf: 'center',
                width: 150,
                bottom: 30 + insets.bottom,
              }}
              mode={'contained'}
              labelStyle={{ fontWeight: 'bold' }}
              onPress={() => {
                hideItems();
                navigation.push('PlaceAdd');
              }}
            >
              {i18n.t('event.formField.add')}
            </Button>
          )}
          ListComponent={PlaceCardList}
        />
      )}
    />
  );
};

export const EventDate: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    setValue,
  },
}) => (
  <Controller
    control={control}
    name={'event_date'}
    rules={{
      required: i18n.t('event.formField.event_date.rules.required'),
    }}
    render={({ field: { value } }) => (
      <FormDateInput
        theme={theme}
        style={{
          flex: 1,
          marginRight: 5,
        }}
        label={i18n.t('event.formField.event_date.label') + ' *'}
        value={value ? formatDate(new Date(value))! : ''}
        onConfirm={selected => {
          if (selected) {
            const eventDate = value ? new Date(value) : new Date();
            let date = set(eventDate, {
              year: getYear(selected),
              month: getMonth(selected),
              date: getDate(selected),
            });
            if (!value) {
              date = startOfDay(date);
            }
            setValue('event_date', date.toISOString() ?? '', {
              shouldDirty: true,
            });
          }
        }}
        errorMessage={errors.event_date?.message as string}
      />
    )}
  />
);

export const EventTime: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({ theme, form: { control, setValue } }) => (
  <Controller
    control={control}
    name={'event_date'}
    render={({ field: { value } }) => (
      <FormTimeInput
        theme={theme}
        style={{
          flex: 1,
          marginLeft: 5,
        }}
        label={i18n.t('event.formField.event_time.label')}
        value={value ? formatTime(new Date(value)) ?? '' : ''}
        onConfirm={selected => {
          if (selected) {
            const eventDate = value ? new Date(value) : new Date();
            const date = set(eventDate, { ...selected, seconds: 0 });
            setValue('event_date', date.toISOString() ?? '', {
              shouldDirty: true,
            });
          }
        }}
      />
    )}
  />
);

export const EventAdvanceTicketPrice: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    watch,
  },
}) => {
  const payment_type = watch('payment_type');
  const isFree =
    payment_type === PaymentType.FREE ||
    payment_type === PaymentType.FREE_DONATE;
  return (
    <Controller
      control={control}
      name={'advance_ticket_price'}
      rules={{
        pattern: {
          value: REGEXP_NUMBER,
          message: i18n.t('common.formField.number_only'),
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormTextInput
          theme={theme}
          style={{
            flex: 1,
            marginRight: 5,
          }}
          disabled={isFree}
          label={i18n.t('event.formField.advance_ticket_price')}
          left={
            <TextInput.Affix
              text={'₩'}
              textStyle={{ color: theme.colors.onBackground, marginRight: 5 }}
            />
          }
          onBlur={onBlur}
          onChange={onChange}
          value={isFree ? '' : value?.toString() ?? ''}
          keyboardType={'numeric'}
          maxLength={10}
          errorMessage={errors.advance_ticket_price?.message as string}
        />
      )}
    />
  );
};

export const EventDoorTicketPrice: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    watch,
  },
}) => {
  const payment_type = watch('payment_type');
  const isFree =
    payment_type === PaymentType.FREE ||
    payment_type === PaymentType.FREE_DONATE;
  return (
    <Controller
      control={control}
      name={'door_ticket_price'}
      rules={{
        pattern: {
          value: REGEXP_NUMBER,
          message: i18n.t('common.formField.number_only'),
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormTextInput
          theme={theme}
          style={{
            flex: 1,
            marginLeft: 5,
          }}
          disabled={isFree}
          label={i18n.t('event.formField.door_ticket_price')}
          left={
            <TextInput.Affix
              text={'₩'}
              textStyle={{ color: theme.colors.onBackground, marginRight: 5 }}
            />
          }
          onBlur={onBlur}
          onChange={onChange}
          value={isFree ? '' : value?.toString() ?? ''}
          keyboardType={'numeric'}
          maxLength={10}
          errorMessage={errors.door_ticket_price?.message as string}
        />
      )}
    />
  );
};

export const EventTicketCount: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'ticket_count'}
    rules={{
      pattern: {
        value: REGEXP_NUMBER,
        message: i18n.t('common.formField.number_only'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        style={{
          flex: 4,
          marginRight: 10,
        }}
        label={i18n.t('event.formField.scale')}
        right={
          <TextInput.Affix
            text={i18n.t('event.formField.seats')}
            textStyle={{ color: theme.colors.onBackground }}
          />
        }
        onBlur={onBlur}
        onChange={onChange}
        value={value?.toString() ?? ''}
        keyboardType={'numeric'}
        maxLength={6}
        errorMessage={errors.ticket_count?.message as string}
      />
    )}
  />
);

export const EventPaymentType: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({ form: { control, setValue } }) => (
  <Controller
    control={control}
    name={'payment_type'}
    render={({ field: { value } }) => (
      <Row>
        <TouchableOpacity
          style={{ width: '50%' }}
          onPress={() => {
            if (value === PaymentType.FREE) {
              setValue('payment_type', '', { shouldDirty: true });
            } else {
              setValue('payment_type', PaymentType.FREE, { shouldDirty: true });
              setValue('advance_ticket_price', null, { shouldDirty: true });
              setValue('door_ticket_price', null, { shouldDirty: true });
            }
          }}
        >
          <Row style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              status={value === PaymentType.FREE ? 'checked' : 'unchecked'}
            />
            <Text>{i18n.t('event.formField.payment_type.free')}</Text>
          </Row>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ width: '50%' }}
          onPress={() => {
            if (value === PaymentType.FREE_DONATE) {
              setValue('payment_type', '', { shouldDirty: true });
            } else {
              setValue('payment_type', PaymentType.FREE_DONATE, {
                shouldDirty: true,
              });
              setValue('advance_ticket_price', null, { shouldDirty: true });
              setValue('door_ticket_price', null, { shouldDirty: true });
            }
          }}
        >
          <Row style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              status={
                value === PaymentType.FREE_DONATE ? 'checked' : 'unchecked'
              }
            />
            <Text>{i18n.t('event.formField.payment_type.freeDonation')}</Text>
          </Row>
        </TouchableOpacity>
      </Row>
    )}
  />
);

export const EventStatus: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({ form: { control, setValue } }) => (
  <Controller
    control={control}
    name={'status'}
    render={({ field: { value } }) => (
      <Row>
        <TouchableOpacity
          style={{ width: '50%' }}
          onPress={() => {
            if (value === Status.SOLD_OUT) {
              setValue('status', '', { shouldDirty: true });
            } else {
              setValue('status', Status.SOLD_OUT, {
                shouldDirty: true,
              });
            }
          }}
        >
          <Row style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              status={value === Status.SOLD_OUT ? 'checked' : 'unchecked'}
            />
            <Text>{i18n.t('event.formField.payment_type.soldOut')}</Text>
          </Row>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ width: '50%' }}
          onPress={() => {
            if (value === Status.CANCELED) {
              setValue('status', '', { shouldDirty: true });
            } else {
              setValue('status', Status.CANCELED, {
                shouldDirty: true,
              });
            }
          }}
        >
          <Row style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              status={value === Status.CANCELED ? 'checked' : 'unchecked'}
            />
            <Text>{i18n.t('common.cancel')}</Text>
          </Row>
        </TouchableOpacity>
      </Row>
    )}
  />
);

export const EventTicketUrl: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'ticket_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('event.formField.ticket_url')}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.ticket_url?.message as string}
      />
    )}
  />
);

export const EventYoutubeUrl: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'streaming_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('event.formField.streaming_url')}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.streaming_url?.message as string}
      />
    )}
  />
);

export const EventDescription: React.FC<{
  theme: MD3Theme;
  form: EventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'description'}
    rules={{
      required: i18n.t('event.formField.description.rules.required'),
      maxLength: {
        value: 5000,
        message: i18n.t('event.formField.description.rules.maxLength'),
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('event.formField.description.label') + ' *'}
        placeholder={i18n.t('event.formField.description.placeholder')}
        multiline
        numberOfLines={Platform.OS === 'web' ? 3 : undefined}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={5000}
        errorMessage={errors.description?.message as string}
      />
    )}
  />
);
