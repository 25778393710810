import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { User } from '@supabase/supabase-js';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Button, MD3Theme, Text } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { Event } from '../../repositories/EventRepository';
import EventService from '../../services/EventService';
import i18n from '../../translations/i18n';
import DetailItem, { ItemChip } from '../shared/DetailItem';
import { showInfo } from '../shared/Toast';

const EventAttendedEventItem: React.FC<{
  theme: MD3Theme;
  event: Event;
  user: User | null;
}> = ({ theme, event, user }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const [attendPressed, setAttendPressed] = useState(false);
  const attended = !!event.attended?.length;
  const handlePressAttend = async () => {
    if (attended) {
      await EventService.unAttendEvent(event?.id, user?.id!);
      setAttendPressed(false);
    } else {
      await EventService.attendEvent(event?.id, user?.id!);
      setAttendPressed(true);
    }
  };
  const handlePressSubmitAttendedEventDetail = () =>
    user
      ? navigation.push('UserAttendedEventAdd', {
          eventId: event.id,
        })
      : showInfo(i18n.t('common.pleaseSignIn'));
  return (
    <View>
      <DetailItem
        theme={theme}
        icon={attended ? 'star-check' : 'star-check-outline'}
        iconColor={attended ? theme.colors.primary : theme.colors.onPrimary}
        borderColor={attended ? 'transparent' : theme.colors.primary}
        iconBackgroundColor={
          attended ? theme.colors.onPrimary : theme.colors.primary
        }
        textColor={attended ? theme.colors.onPrimary : theme.colors.primary}
        backgroundColor={attended ? theme.colors.primary : undefined}
        iconSize={22}
        label={
          attended
            ? i18n.t('event.detailContent.editAttendedEvent')
            : i18n.t('event.detailContent.postAttendedEvent')
        }
        onPress={handlePressAttend}
      />
      {attendPressed && (
        <ItemChip
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
          }}
          theme={theme}
        >
          <Text variant="bodyMedium">
            {i18n.t('event.detailContent.submitAttendedEvent')}
          </Text>
          <Button
            compact
            mode={'contained'}
            contentStyle={{
              height: 30,
            }}
            labelStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 20,
              fontSize: 12,
              fontWeight: 'bold',
            }}
            disabled={!event.ticket_url}
            onPress={handlePressSubmitAttendedEventDetail}
          >
            {i18n.t('event.detailContent.addAttendedEventDetail')}
          </Button>
        </ItemChip>
      )}
    </View>
  );
};

export default EventAttendedEventItem;
