import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { MD3Theme } from 'react-native-paper/src/types';
import styled from 'styled-components/native';

import { Artist } from '../../repositories/ArtistRepository';
import ImageIconAvatar from '../shared/ImageIconAvatar';

const Card = styled.View<{ theme: MD3Theme }>`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  padding: 5px 0;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level2};
  border-bottom-width: 1px;
`;

const Content = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
  flex-wrap: wrap;
  flex: 1;
`;

const ArtistCard: React.FC<{
  artist: Artist;
  onPress: (artist: Artist) => void;
}> = ({ artist, onPress }) => {
  if (!artist) {
    return null;
  }
  const theme = useTheme();
  return (
    <TouchableOpacity onPress={() => onPress(artist)}>
      <Card theme={theme}>
        <ImageIconAvatar
          size={50}
          uri={artist?.photo_url}
          icon={'account-music'}
        />
        <Content>
          <Text variant={'titleMedium'}>{artist.name?.trim()}</Text>
          {!!artist.instagram && (
            <Text
              variant={'bodySmall'}
              style={{ color: theme.colors.elevation.level5 }}
            >
              @{artist.instagram?.trim()}
            </Text>
          )}
        </Content>
      </Card>
    </TouchableOpacity>
  );
};

export default ArtistCard;
