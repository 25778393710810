import supabase from '../supabase';
import { Database } from '../types/database';
import http from '../utils/http';
import { Profile } from './ProfileRepository';
import UserRepository, { UserBlockedUser } from './UserRepository';

type ArtistResponse = Awaited<ReturnType<typeof ArtistRepository.getArtist>>;
type ArtistResponseSuccess = ArtistResponse['data'];
type UserLikedArtist =
  Database['public']['Tables']['users_liked_artists']['Row'];

export interface Artist
  extends Omit<NonNullable<ArtistResponseSuccess>, 'liked' | 'created'> {
  created?: Profile & {
    blocked?: UserBlockedUser[];
  };
  liked?: UserLikedArtist[];
}

export interface ArtistsParams {
  managerId?: string;
  postedUserId?: string;
  likedUserId?: string;
  searchQuery?: string;
  offset?: number;
}

export default class ArtistRepository {
  private static table = () => supabase.from('artists');
  private static managerTable = () => supabase.from('artists_managers');

  static PAGE_SIZE = 20;

  static async getManagedArtists(managerId: string, offset = 0) {
    return this.managerTable()
      .select('*, artists(*)')
      .eq('manager_id', managerId)
      .range(offset, offset + ArtistRepository.PAGE_SIZE - 1);
  }

  static async getArtists({
    searchQuery,
    postedUserId,
    likedUserId,
    offset = 0,
  }: ArtistsParams) {
    let query = this.table().select(
      `*, events!events_artists(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
    );
    if (searchQuery) {
      query.or(
        `name.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%,members.ilike.%${searchQuery}%`,
      );
    }
    if (postedUserId) {
      query = this.table()
        .select(
          `*, events!events_artists(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
        )
        .eq('created_by', postedUserId);
    }
    if (likedUserId) {
      query = this.table()
        .select(
          `*, events!events_artists(*), liked:users_liked_artists!inner(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
        )
        .eq('liked.user_id', likedUserId);
    }
    return query
      .order('name')
      .range(offset, offset + ArtistRepository.PAGE_SIZE - 1);
  }

  static async getArtist(artistId: string) {
    const user = await UserRepository.getCurrentUser();
    const query = this.table()
      .select(
        '*, liked:users_liked_artists(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))',
      )
      .eq('id', artistId);
    if (user?.id) {
      query.eq('users_liked_artists.user_id', user?.id);
    }
    return query.maybeSingle();
  }

  static async getArtistByHandle(handle: string) {
    return this.table().select('handle').ilike('handle', handle).maybeSingle();
  }

  static async upsertArtist(artist: Artist) {
    return this.table().upsert(artist).select().maybeSingle();
  }

  static async deleteArtist(artistId: string) {
    return this.table().delete().eq('id', artistId);
  }

  static async updateViewCount(artistId: string) {
    try {
      http.post(`/api/counts/artists/${artistId}/views`);
    } catch {}
  }
}
