import supabase from '../supabase';
import { Database } from '../types/database';

export type Report = Database['public']['Tables']['reports']['Insert'];

export default class ReportRepository {
  private static table = () => supabase.from('reports');

  static async insertReport(report: Report) {
    return this.table().insert(report);
  }
}
