import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import React, { useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';

import NoticeCard from '../components/notice/NoticeCard';
import { SmallLoading } from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { Notice } from '../repositories/NoticeRepository';
import { useNoticesQuery } from '../services/NoticeService';

const NoticeFeedScreen: React.FC<any> = () => {
  const {
    data: notices,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = useNoticesQuery();
  const [refreshing, setRefreshing] = useState(false);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  const ref = useRef<FlatList>(null);
  useScrollToTop(ref);

  const onRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    setRefreshing(false);
  };

  const onPressItem = (notice: Notice) =>
    navigation.push('NoticeDetail', { noticeId: notice.id });

  const loading = isLoading || isFetching;

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={notices?.pages?.map(({ notices }) => notices).flat()}
      renderItem={({ item }) => (
        <NoticeCard key={item.id} notice={item!} onPress={onPressItem} />
      )}
      keyExtractor={(item, index) => `${item?.id}`}
      onEndReached={() => !loading && hasNextPage && fetchNextPage()}
      onEndReachedThreshold={0.5}
      ListFooterComponent={!refreshing && loading ? <SmallLoading /> : null}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    />
  );
};

export default NoticeFeedScreen;
