import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import { FAB, IconButton, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { EmptyEventCard } from '../components/event/EmptyEventCard';
import EventCardList from '../components/event/EventCardList';
import EventFilterDialog from '../components/event/EventFilterDialog';
import { LatLng, Region } from '../components/map';
import NoticeCards from '../components/notice/NoticeCards';
import { useDialog } from '../components/providers/DialogProvider';
import { SmallLoading } from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { MainTabParamList } from '../navigation/MainTab';
import { initNotification } from '../notification';
import { useNotificationCountQuery } from '../services/NotificationService';
import PlaceService from '../services/PlaceService';
import { UserService, useUserProfileQuery } from '../services/UserService';
import i18n from '../translations/i18n';
import LocalStorage from '../utils/localStorage';
import {
  DEFAULT_DELTA,
  DEFAULT_DISTANCE_KM,
  deltaToKm,
  getStorageRegion,
} from '../utils/locationHelpers';
import { refetchQueries } from '../utils/queryClient';
import { colors } from '../utils/themes';

const EventFeedScreen: React.FC = () => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const { data: user, isFetched } = useUserProfileQuery();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { data: notification } = useNotificationCountQuery(user);
  const route = useRoute<RouteProp<MainTabParamList, 'EventFeed'>>();
  const params = route.params;

  const { showDialog, hideDialog } = useDialog();
  const [region, setRegion] = useState<Region | undefined>(undefined);
  const [distance, setDistance] = useState<number>(DEFAULT_DISTANCE_KM);
  const [district, setDistrict] = useState<string | undefined>(undefined);
  const [fabOpen, setFabOpen] = useState(false);

  const regionRef = useRef<Region | undefined>();
  const distanceRef = useRef<number>(distance);
  const districtRef = useRef<string | undefined>();
  regionRef.current = region;
  distanceRef.current = distance;
  districtRef.current = district;

  const headerRight = ({ tintColor }: { tintColor?: string }) => (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {user && (
        <View>
          <View
            style={{
              display: notification ? 'flex' : 'none',
              zIndex: 1,
              position: 'absolute',
              top: 14,
              right: 7,
              width: 8,
              height: 8,
              borderRadius: 4,
              backgroundColor: colors.notification,
            }}
          />
          <IconButton
            style={{ marginHorizontal: 0 }}
            size={23}
            icon={'heart-outline'}
            iconColor={tintColor}
            onPress={() => navigation.push('NotificationFeed')}
          />
        </View>
      )}
      <IconButton
        style={{ marginLeft: 0, marginRight: 10 }}
        size={23}
        icon={'tune-variant'}
        iconColor={tintColor}
        onPress={onPressRight}
      />
    </View>
  );

  const onPressRight = () => {
    showDialog(
      <EventFilterDialog
        navigation={navigation}
        sort={params?.sort}
        region={regionRef.current!}
        district={districtRef.current!}
        distance={distanceRef.current!}
        setRegion={setRegion}
        setDistrict={setDistrict}
        setDistance={setDistance}
        hideDialog={hideDialog}
      />,
    );
  };

  const updateCurrentRegion = async () => {
    let region: Region;
    if (user?.latitude && user?.longitude) {
      region = {
        latitude: user.latitude,
        longitude: user.longitude,
        latitudeDelta: user.latitudeDelta ?? DEFAULT_DELTA,
        longitudeDelta: user.longitudeDelta ?? DEFAULT_DELTA,
      };
    } else {
      region = await getStorageRegion();
    }
    setRegion(region);
    LocalStorage.setRegion(region);
    setDistance(deltaToKm(region.latitudeDelta));
    let district: string | undefined;
    if (user?.district) {
      district = user.district;
    } else {
      district = await LocalStorage.getDistrict();
      if (!district) {
        const region = await getStorageRegion();
        const location: LatLng = {
          latitude: region.latitude,
          longitude: region.longitude,
        };
        district = await PlaceService.getDistrictByLocation(location);
        await LocalStorage.setDistrict(district);
      }
    }
    setDistrict(district);
    navigation.setOptions({ headerRight });
  };

  useEffect(() => {
    navigation.setOptions({ headerRight });
  }, [params, notification]);

  useEffect(() => {
    if (isFetched) {
      initNotification(user);
      updateCurrentRegion();
    }
  }, [user]);

  return (
    <>
      {region ? (
        <EventCardList
          {...params}
          header={<NoticeCards eventNotice={true} />}
          district={district}
          region={region}
          emptyCard={<EmptyEventCard onPress={onPressRight} />}
          onPressItem={event => {
            navigation.push('EventDetail', { eventId: event.id });
          }}
          onRefresh={async () => {
            await refetchQueries('notification');
          }}
        />
      ) : (
        <SmallLoading />
      )}

      {user && (
        <FAB.Group
          visible={UserService.hasPostPermission(user)}
          open={fabOpen}
          icon={fabOpen ? 'close' : 'plus'}
          actions={[
            {
              containerStyle: {
                paddingVertical: 0,
                marginVertical: 0,
                marginHorizontal: 0,
              },
              style: {
                backgroundColor: theme.colors.primaryContainer,
              },
              icon: 'calendar',
              label: i18n.t('common.events'),
              onPress: () => navigation.push('EventAdd'),
            },
            {
              containerStyle: {
                paddingVertical: 0,
                marginVertical: 0,
                marginHorizontal: 0,
              },
              style: {
                backgroundColor: theme.colors.primaryContainer,
              },
              icon: 'account-music',
              label: i18n.t('event.feed.artistsOrAgencies'),
              onPress: () => navigation.push('ArtistAdd'),
            },
            {
              containerStyle: {
                paddingVertical: 0,
                marginVertical: 0,
                marginHorizontal: 0,
              },
              style: {
                backgroundColor: theme.colors.primaryContainer,
              },
              icon: 'map-marker',
              label: i18n.t('event.feed.places'),
              onPress: () => navigation.push('PlaceAdd'),
            },
          ]}
          onStateChange={({ open }) => setFabOpen(open)}
          fabStyle={{
            borderRadius: 30,
          }}
          style={{
            padding: 0,
            margin: 0,
            bottom: Platform.select({
              ios: -insets.bottom,
              default: 0,
            }),
          }}
        />
      )}
    </>
  );
};

export default EventFeedScreen;
