import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import i18n from '../../translations/i18n';
import { Column } from '../shared/Layouts';

const StoryFilterDialog: React.FC<{
  navigation: NativeStackNavigationProp<MainStackParamList>;
  currentSort?: string;
  hideDialog: () => void;
}> = ({ navigation, currentSort, hideDialog }) => {
  const theme = useTheme();
  return (
    <Column style={{ gap: 20, paddingTop: 24 }}>
      <TouchableOpacity
        onPress={() => {
          navigation.setParams({
            sort: undefined,
          });
          hideDialog();
        }}
      >
        <Text
          style={{
            color:
              currentSort === undefined
                ? theme.colors.primary
                : theme.colors.onSurface,
            fontWeight: 'bold',
            fontSize: 20,
          }}
        >
          {i18n.t('common.new')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          navigation.setParams({
            sort: 'likes',
          });
          hideDialog();
        }}
      >
        <Text
          style={{
            color:
              currentSort === 'likes'
                ? theme.colors.primary
                : theme.colors.onSurface,
            fontWeight: 'bold',
            fontSize: 20,
          }}
        >
          {i18n.t('common.likes')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          navigation.setParams({
            sort: 'comments',
          });
          hideDialog();
        }}
      >
        <Text
          style={{
            color:
              currentSort === 'comments'
                ? theme.colors.primary
                : theme.colors.onSurface,
            fontWeight: 'bold',
            fontSize: 20,
          }}
        >
          {i18n.t('common.comments')}
        </Text>
      </TouchableOpacity>
    </Column>
  );
};

export default StoryFilterDialog;
