import { useActionSheet } from '@expo/react-native-action-sheet';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import DefaultDialog from '../components/dialogs/DefaultDialog';
import EventCard from '../components/event/EventCard';
import PlaceDetailBottomBar from '../components/place/PlaceDetailBottomBar';
import PlaceDetailContent from '../components/place/PlaceDetailContent';
import { useDialog } from '../components/providers/DialogProvider';
import DetailHeaderRight from '../components/shared/DetailHeaderRight';
import DetailItem from '../components/shared/DetailItem';
import Loading from '../components/shared/Loading';
import { showError, showInfo, showSuccess } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import PlaceRepository, { Place } from '../repositories/PlaceRepository';
import { useClosestPlaceEventsQuery } from '../services/EventService';
import PlaceService, {
  useDeletePlaceMutation,
  usePlaceDetailQuery,
  usePlaceRelatedNewsQuery,
} from '../services/PlaceService';
import {
  UserProfile,
  UserService,
  useUserProfileQuery,
} from '../services/UserService';
import i18n from '../translations/i18n';
import { APP_URL } from '../utils/constants';
import LocalStorage from '../utils/localStorage';

export function hasEditPermission(
  place?: Place | null,
  user?: UserProfile | null,
): boolean {
  if (!place || !user) {
    return false;
  }
  if (place.created_by === user.id || user.admin) {
    return true;
  }
  return UserService.isOwner(user, place);
}

const PlaceDetailScreen: React.FC = () => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const route = useRoute<RouteProp<MainStackParamList, 'PlaceDetail'>>();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const navigationReady = useNavigationReady();
  const { showActionSheetWithOptions } = useActionSheet();
  const [isSaving, setIsSaving] = useState(false);
  const { data: user } = useUserProfileQuery();
  const placeId = route.params.placeId;

  useEffect(() => {
    if (navigationReady && !placeId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, placeId]);

  const { data: place, isFetched } = usePlaceDetailQuery(placeId!, user?.id!);
  const { data: news } = usePlaceRelatedNewsQuery(placeId);
  const { data: events } = useClosestPlaceEventsQuery(placeId!);

  const { mutateAsync: deleteEvent } = useDeletePlaceMutation(placeId);

  const { showDialog, hideDialog } = useDialog();

  const onPressFavorite = async () => {
    if (!user) {
      showInfo(i18n.t('common.pleaseSignIn'));
      return;
    }
    if (!place) {
      return;
    }
    const hasSaved = !!place.favorite?.length;
    setIsSaving(true);
    if (hasSaved) {
      await PlaceService.unfavoritePlace(place.id);
      showSuccess(i18n.t('place.detailContent.unfavorite'));
    } else {
      await PlaceService.favoritePlace(place.id);
      const notification = await LocalStorage.getNotification();
      const recommendNotification =
        !notification && Platform.OS !== 'web'
          ? i18n.t('common.recommendNotification')
          : undefined;
      showSuccess(
        i18n.t('place.detailContent.favorite'),
        recommendNotification,
      );
    }
    setIsSaving(false);
  };

  const onPressEdit = () => {
    let options = [i18n.t('common.cancel')];
    let destructiveButtonIndex: number[] = [];
    if (hasEditPermission(place, user)) {
      options = [i18n.t('common.modify'), i18n.t('common.delete'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.delete'))];
    } else {
      options = [i18n.t('common.report'), i18n.t('common.block'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.report'))];
    }
    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        containerStyle: {
          paddingBottom: insets.bottom,
        },
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case options.indexOf(i18n.t('common.modify')): {
            navigation.push('PlaceEdit', { placeId });
            break;
          }
          case options.indexOf(i18n.t('common.delete')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.delete')}
                content={i18n.t('common.areYouSureYouWantToDeleteIt')}
                confirmText={i18n.t('common.delete')}
                onPressDismiss={hideDialog}
                onPressConfirm={async () => {
                  await deleteEvent();
                  showSuccess(i18n.t('common.itHasBeenDeleted'));
                  hideDialog();
                  if (navigation.canGoBack()) {
                    navigation.goBack();
                  } else {
                    navigation.replace('MainTab', { screen: 'EventFeed' });
                  }
                }}
              />,
            );
            break;
          }
          case options.indexOf(i18n.t('common.report')): {
            navigation.push('Report', {
              title: `${i18n.t('common.report')}: ${place?.name}`,
              type: 'places',
              target: placeId,
            });
            break;
          }
          case options.indexOf(i18n.t('common.block')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.block')}
                content={i18n.t('common.blockConfirm', {
                  name: place?.created?.name!,
                })}
                confirmText={i18n.t('common.block')}
                onPressConfirm={async () => {
                  try {
                    await UserService.blockUser(place?.created?.id!);
                    hideDialog();
                    showInfo(i18n.t('common.blockSuccess'));
                  } catch (e) {
                    hideDialog();
                    showError(e);
                  }
                }}
                onPressDismiss={hideDialog}
              />,
            );
            break;
          }
          default:
        }
      },
    );
  };

  useEffect(() => {
    if (isFetched) {
      if (place) {
        navigation.setOptions({
          title: `${place.name}`,
          headerRight: ({ tintColor }: { tintColor?: string }) => (
            <DetailHeaderRight
              color={tintColor}
              title={`${place.name} - ${i18n.t('common.appName')}`}
              url={`${APP_URL}/places/${place!.id}`}
              more={!!user}
              share={true}
              onPressMore={() => onPressEdit()}
            />
          ),
        });
      }
      if (!place) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'EventFeed' });
        }
      }
    }
  }, [place, user]);

  useEffect(
    () => () => {
      if (user) {
        PlaceRepository.updateViewCount(placeId!);
      }
    },
    [],
  );

  return place?.id ? (
    <View style={{ flex: 1, paddingBottom: insets.bottom }}>
      <ScrollView>
        <PlaceDetailContent
          place={place}
          theme={theme}
          isSaving={isSaving}
          onPressFavorite={onPressFavorite}
        />
        {!!news?.title && !!news?.link && (
          <View style={{ marginHorizontal: 20 }}>
            <Text style={{ fontWeight: 'bold' }} variant={'titleMedium'}>
              {i18n.t('common.relatedNews')}
            </Text>
            <DetailItem
              theme={theme}
              icon={'newspaper-variant-outline'}
              iconSize={30}
              variant={'bodyLarge'}
              label={news.title}
              numberOfLines={2}
              chevron={false}
              onPress={() => Linking.openURL(news.link)}
            />
          </View>
        )}
        {!!events?.length && (
          <>
            <Text
              style={{ fontWeight: 'bold', marginHorizontal: 20 }}
              variant={'titleMedium'}
            >
              {i18n.t('place.detailContent.nextEvent')}
            </Text>
            {events?.map((event, index) => (
              <EventCard
                key={index}
                event={event}
                onPress={() =>
                  navigation.push('EventDetail', { eventId: event.id })
                }
              />
            ))}
          </>
        )}
      </ScrollView>
      <PlaceDetailBottomBar place={place} theme={theme} />
    </View>
  ) : (
    <Loading />
  );
};

export default PlaceDetailScreen;
