import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ImageZoom } from '@likashefqet/react-native-image-zoom';
import { ImageBackground } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Portal } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Loading, { SmallLoading } from '../shared/Loading';

const EventDetailCover: React.FC<{
  width: number;
  height: number;
  image: string;
}> = ({ width, height, image }) => {
  const insets = useSafeAreaInsets();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const handleImagePress = () => {
    setModalVisible(true);
  };
  return (
    <>
      <TouchableOpacity onPress={handleImagePress}>
        {loading && (
          <View
            style={{
              position: 'absolute',
              width,
              height,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SmallLoading />
          </View>
        )}
        <ImageBackground
          style={{ width, height }}
          source={{ uri: image }}
          contentFit={'cover'}
          onLoadEnd={() => setLoading(false)}
        >
          <LinearGradient
            style={{
              width: '100%',
              height: '100%',
            }}
            colors={['rgba(0,0,0, 0.5)', 'transparent', 'rgba(0,0,0, 0.5)']}
          />
        </ImageBackground>
      </TouchableOpacity>
      {modalVisible && (
        <Portal>
          <GestureHandlerRootView style={{ flex: 1, backgroundColor: '#000' }}>
            <TouchableOpacity
              style={{
                position: 'absolute',
                top: 10 + insets.top,
                right: 10,
                zIndex: 1000,
              }}
              onPress={() => setModalVisible(false)}
            >
              <MaterialCommunityIcons color={'#fff'} size={30} name={'close'} />
            </TouchableOpacity>
            <ImageZoom uri={image} renderLoader={() => <Loading />} />
          </GestureHandlerRootView>
        </Portal>
      )}
    </>
  );
};

export default EventDetailCover;
