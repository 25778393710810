import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput as RNTextInput,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  Button,
  Chip,
  HelperText,
  MD3Theme,
  Searchbar,
  Text,
  TextInput,
  TextInputProps,
  useTheme,
} from 'react-native-paper';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import PlaceService from '../../services/PlaceService';
import i18n from '../../translations/i18n';
import { WEB_MAX_WIDTH } from '../../utils/constants';
import { getCurrentLocation } from '../../utils/locationHelpers';
import MapView, { LatLng } from '../map';
import PlacePickerMap from '../place/PlacePickerMap';
import { useAppearanceContext } from '../providers/AppearanceProvider';
import { ChipContainer } from '../shared/Chip';
import { Column, Row } from '../shared/Layouts';
import Loading from '../shared/Loading';
import Toast, { showError } from '../shared/Toast';

const DEFAULT_MARGIN = 10;

type FormInputType = Omit<TextInputProps, 'onChange'> & {
  textInputStyle?: StyleProp<TextStyle>;
  onChange?: (...event: any[]) => void;
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  errorMessage?: string;
  minHeight?: number;
  maxHeight?: number;
};

export const FormTextInput = React.forwardRef<RNTextInput, FormInputType>(
  (props, ref) => {
    const [initialized, setInitialized] = useState(false);
    const {
      disabled,
      icon,
      multiline,
      minHeight = 87,
      maxHeight,
      value,
      errorMessage,
      onChange,
      ...rest
    } = props;
    const adjustTextInputSize = (event: NativeSyntheticEvent<any>) => {
      const el = (event?.currentTarget ||
        (event?.nativeEvent as any)?.target) as unknown as HTMLElement;
      if (el?.tagName?.toUpperCase() === 'TEXTAREA') {
        el.style.height = `${minHeight}px`;
        const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight;
        if (maxHeight && newHeight >= maxHeight) {
          el.style.height = `${maxHeight}px`;
          return;
        }
        if (newHeight > minHeight) {
          el.style.height = `${newHeight}px`;
        }
      }
    };
    const handleChange: TextInputProps['onChange'] = event => {
      onChange?.(event.nativeEvent.text);
      if (Platform.OS === 'web' && multiline) {
        adjustTextInputSize(event);
      }
    };
    const handleLayout: TextInputProps['onLayout'] = event => {
      if (!initialized) {
        if (Platform.OS === 'web' && multiline) {
          adjustTextInputSize(event);
        }
        setInitialized(true);
      }
    };
    return (
      <Column style={[{ alignItems: 'flex-start' }, props.style]}>
        <TextInput
          ref={ref}
          {...rest}
          style={[
            {
              backgroundColor: (props.theme as MD3Theme).colors.elevation
                .level0,
              width: '100%',
              marginBottom: DEFAULT_MARGIN,
            },
            props.textInputStyle,
          ]}
          multiline={multiline}
          editable={!disabled}
          left={(icon && <TextInput.Icon icon={icon} />) || props.left}
          onChange={handleChange}
          onLayout={handleLayout}
          value={value ?? ''}
          contentStyle={
            multiline && Platform.OS === 'android'
              ? {
                  marginVertical: 3,
                }
              : undefined
          }
          error={!!errorMessage}
        />
        {errorMessage && <HelperText type={'error'}>{errorMessage}</HelperText>}
      </Column>
    );
  },
);

export const FormDateInput: React.FC<
  FormInputType & {
    onConfirm: (date?: Date) => void;
  }
> = props => {
  const { icon, errorMessage, onConfirm } = props;
  const { locale } = useAppearanceContext();
  const [visible, setVisible] = useState(false);
  const handlePress = () => setVisible(true);
  return (
    <TouchableOpacity onPress={handlePress} style={props.style}>
      <TextInput
        {...props}
        pointerEvents={'none'}
        style={{
          backgroundColor: (props.theme as MD3Theme).colors.elevation.level0,
          width: '100%',
          marginBottom: DEFAULT_MARGIN,
        }}
        dense
        editable={false}
        left={icon && <TextInput.Icon onPress={handlePress} icon={icon} />}
        error={!!errorMessage}
      />
      <DatePickerModal
        allowEditing={false}
        mode={'single'}
        visible={visible}
        locale={locale}
        inputEnabled={false}
        onConfirm={({ date }) => {
          onConfirm(date);
          setVisible(false);
        }}
        onDismiss={() => {
          onConfirm(undefined);
          setVisible(false);
        }}
      />
      {errorMessage && <HelperText type={'error'}>{errorMessage}</HelperText>}
    </TouchableOpacity>
  );
};

interface Time {
  hours: number;
  minutes: number;
}

export const FormTimeInput: React.FC<
  FormInputType & {
    time?: Time;
    onConfirm: (time?: Time) => void;
  }
> = props => {
  const { icon, errorMessage, time, onConfirm } = props;
  const { locale } = useAppearanceContext();
  const [visible, setVisible] = useState(false);
  const handlePress = () => setVisible(true);
  return (
    <TouchableOpacity onPress={handlePress} style={props.style}>
      <TextInput
        {...props}
        pointerEvents={'none'}
        style={{
          backgroundColor: (props.theme as MD3Theme).colors.elevation.level0,
          width: '100%',
          marginBottom: DEFAULT_MARGIN,
        }}
        dense
        editable={false}
        left={icon && <TextInput.Icon onPress={handlePress} icon={icon} />}
        error={!!errorMessage}
      />
      <TimePickerModal
        label={`${props.label}`}
        cancelLabel={i18n.t('common.cancel')}
        confirmLabel={i18n.t('common.confirm')}
        visible={visible}
        locale={locale}
        hours={time?.hours}
        minutes={time?.minutes}
        onConfirm={time => {
          onConfirm(time);
          setVisible(false);
        }}
        onDismiss={() => {
          onConfirm(undefined);
          setVisible(false);
        }}
      />
      {errorMessage && <HelperText type={'error'}>{errorMessage}</HelperText>}
    </TouchableOpacity>
  );
};

export interface FormAutoCompleteSelectItem {
  id: string;
  name?: string;
  title?: string;
}

type FormAutoCompleteSelectType<T extends FormAutoCompleteSelectItem> =
  FormInputType & {
    children?: React.ReactNode;
    displaySuggestion?: boolean;
    multiselect?: boolean;
    defaultItems?: T[];
    selectedItems?: T[];
    onSelectItem?: (item: T) => void;
    onCloseSelectedItems?: (item: T, index?: number) => void;
    AddButton?: React.ElementType<{ hideItems: () => void }>;
    ListComponent: React.ElementType<{
      searchQuery?: string;
      onPressItem: (item: T) => void;
    }>;
  };

export const FormAutoCompleteSelect = <T extends FormAutoCompleteSelectItem>(
  props: FormAutoCompleteSelectType<T>,
) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const {
    icon,
    style,
    children,
    multiselect,
    displaySuggestion,
    value,
    selectedItems,
    errorMessage,
    onChange,
    onBlur,
    onSelectItem,
    onCloseSelectedItems,
    AddButton,
    ListComponent,
  } = props;

  const [visible, setVisible] = useState(false);

  const handlePress = () => setVisible(true);

  return (
    <>
      <TouchableOpacity
        onPress={handlePress}
        style={[{ width: '100%' }, style]}
      >
        {children ? (
          children
        ) : (
          <TextInput
            {...props}
            pointerEvents={'none'}
            style={{
              backgroundColor: theme.colors.elevation.level0,
              width: '100%',
              marginBottom: DEFAULT_MARGIN,
            }}
            dense
            editable={false}
            left={icon && <TextInput.Icon onPress={handlePress} icon={icon} />}
            error={!!errorMessage}
          />
        )}
        <Modal visible={visible} onRequestClose={() => setVisible(false)}>
          <View
            style={{
              flex: 1,
              paddingTop: insets.top,
              paddingBottom: insets.bottom,
              backgroundColor: theme.colors.background,
            }}
          >
            <View
              style={{
                flex: 1,
                alignSelf: 'center',
                maxWidth: Platform.select({
                  web: WEB_MAX_WIDTH,
                }),
                width: '100%',
              }}
            >
              <Row>
                <Searchbar
                  autoFocus={true}
                  style={{
                    flex: 1,
                    backgroundColor: theme.colors.elevation.level2,
                    margin: 10,
                  }}
                  inputStyle={{
                    minHeight: 50,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                  placeholder={i18n.t('common.search')}
                  elevation={0}
                  value={value ?? ''}
                  onClearIconPress={() => onChange?.('')}
                  onChange={e => onChange?.(e.nativeEvent.text)}
                  onBlur={onBlur}
                  keyboardAppearance={'default'}
                />
                <Button
                  compact
                  style={{ marginRight: 15 }}
                  onPress={() => {
                    setVisible(false);
                    onChange?.('');
                  }}
                >
                  <Text style={{ color: theme.colors.primary, fontSize: 16 }}>
                    {i18n.t('common.cancel')}
                  </Text>
                </Button>
              </Row>
              {!!value || displaySuggestion ? (
                <ListComponent
                  searchQuery={value}
                  onPressItem={item => {
                    onSelectItem?.(item);
                    setVisible(false);
                  }}
                />
              ) : (
                <View
                  style={{
                    flex: 1,
                    paddingTop: 10,
                    alignItems: 'center',
                  }}
                >
                  <Text variant={'bodyLarge'}>
                    {i18n.t('common.pleaseEnterSearchKeyword')}
                  </Text>
                </View>
              )}
              {AddButton && <AddButton hideItems={() => setVisible(false)} />}
            </View>
          </View>
          <Toast />
        </Modal>
      </TouchableOpacity>
      {multiselect && selectedItems && (
        <ChipContainer>
          {selectedItems.map((selectedItem, key) => (
            <Chip
              key={key}
              style={{ marginRight: 5, marginBottom: 5 }}
              ellipsizeMode={'tail'}
              theme={theme}
              closeIcon={'close'}
              onClose={() => onCloseSelectedItems?.(selectedItem, key)}
            >
              {selectedItem.name ?? selectedItem.title ?? selectedItem.id}
            </Chip>
          ))}
        </ChipContainer>
      )}
      {errorMessage && <HelperText type={'error'}>{errorMessage}</HelperText>}
    </>
  );
};

export const FormLocationSelect: React.FC<
  FormInputType & {
    location?: LatLng;
    onConfirm: (location?: LatLng) => void;
  }
> = props => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const { style, icon, errorMessage, location, onConfirm } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [map, setMap] = useState<MapView | null>(null);
  const [mapLocation, setMapLocation] = useState<LatLng | undefined>(location);
  const [markerLocation, setMarkerLocation] = useState<LatLng | undefined>(
    location,
  );
  const handlePress = () => setVisible(true);

  const handleSearch = async () => {
    if (!address) {
      return;
    }
    setLoading(true);
    const location = await PlaceService.getLocationByAddress(address);
    if (location?.latitude && location?.longitude) {
      setMapLocation(location);
      setMarkerLocation(location);
      map?.setCamera({
        center: location,
      });
    } else {
      showError(i18n.t('common.addressCannotBeDetermined'));
      setAddress('');
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (!location) {
        setLoading(true);
        const initialLocation = await getCurrentLocation();
        setMapLocation(initialLocation);
        setMarkerLocation(initialLocation);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <TouchableOpacity
        onPress={handlePress}
        style={[{ width: '100%' }, style]}
      >
        <TextInput
          {...props}
          pointerEvents={'none'}
          style={{
            backgroundColor: theme.colors.elevation.level0,
            width: '100%',
            marginBottom: DEFAULT_MARGIN,
          }}
          dense
          editable={false}
          left={icon && <TextInput.Icon onPress={handlePress} icon={icon} />}
          error={!!errorMessage}
        />
        <Modal visible={visible}>
          <View
            style={{
              paddingTop: insets.top,
              paddingBottom: insets.bottom,
              flex: 1,
              alignSelf: 'center',
              maxWidth: Platform.select({
                web: WEB_MAX_WIDTH,
              }),
              width: '100%',
              backgroundColor: theme.colors.background,
            }}
          >
            <Searchbar
              style={{
                backgroundColor: theme.colors.elevation.level2,
                margin: 10,
              }}
              inputStyle={{ minHeight: 50, paddingTop: 10, paddingBottom: 10 }}
              placeholder={i18n.t('common.searchByAddress')}
              elevation={0}
              value={address}
              traileringIcon={'chevron-down'}
              onChangeText={setAddress}
              onSubmitEditing={handleSearch}
              onClearIconPress={() => setAddress('')}
              onTraileringIconPress={() => setVisible(false)}
            />
            <PlacePickerMap
              location={mapLocation}
              markerLocation={markerLocation}
              onReady={setMap}
              onDragMarker={location => {
                setMapLocation(location);
                setMarkerLocation(location);
              }}
            />
            <Button
              style={{
                position: 'absolute',
                alignSelf: 'center',
                width: 150,
                bottom: 30 + insets.bottom,
              }}
              mode={'contained'}
              labelStyle={{ fontWeight: 'bold' }}
              onPress={() => {
                setVisible(false);
                onConfirm(markerLocation);
              }}
            >
              {i18n.t('common.select')}
            </Button>
          </View>
          <Toast />
        </Modal>
        {errorMessage && <HelperText type={'error'}>{errorMessage}</HelperText>}
      </TouchableOpacity>
    </>
  );
};
