import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

const TextCard: React.FC<{
  id: string;
  name: string;
  onPress?: (item: { id: string; name: string }) => void;
}> = ({ id, name, onPress }) => {
  const theme = useTheme();
  return (
    <TouchableOpacity onPress={() => onPress?.({ id, name })}>
      <Text
        style={{
          padding: 10,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.elevation.level4,
        }}
        variant={'bodyLarge'}
        key={id}
      >
        {name}
      </Text>
    </TouchableOpacity>
  );
};

export default TextCard;
