import { useActionSheet } from '@expo/react-native-action-sheet';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Platform, View } from 'react-native';
import { MD3Theme, Text } from 'react-native-paper';

import CommonService from '../../services/CommonService';
import i18n from '../../translations/i18n';
import imageScanHandler from '../../utils/imageScanHandler';
import { FormTextInput } from '../form/FormInputs';
import ImagesSelector from '../form/ImageSelector';
import StarRating from '../shared/StarRating';
import { UserAttendedEventFormType } from './UserAttendedEventForm';

export const UserAttendedEventTitle: React.FC<{
  theme: MD3Theme;
  title: string;
}> = ({ theme, title }) => (
  <Text
    theme={theme}
    style={{ fontWeight: 'bold', padding: 0 }}
    variant={'titleLarge'}
  >
    {title?.trim()}
  </Text>
);

export const UserAttendedEventDescription: React.FC<{
  theme: MD3Theme;
  form: UserAttendedEventFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name="description"
    rules={{
      maxLength: {
        value: 1000,
        message: i18n.t(
          'userAttendedEvent.formField.description.rules.maxLength',
        ),
      },
      validate: async value => {
        if (!value) {
          return;
        }
        const bannedWords = await CommonService.findBannedWords(value);
        if (!bannedWords) {
          return;
        }
        return i18n.t('common.foundBannedKeyword', { keyword: bannedWords });
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('userAttendedEvent.formField.description.label')}
        placeholder={i18n.t(
          'userAttendedEvent.formField.description.placeholder',
        )}
        placeholderTextColor={'grey'}
        multiline
        numberOfLines={Platform.OS === 'web' ? 3 : undefined}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={1000}
        errorMessage={errors.description?.message as string}
      />
    )}
  />
);

export const UserAttendedEventRating: React.FC<{
  form: UserAttendedEventFormType;
}> = ({
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'rating'}
    render={({ field: { onChange, value } }) => (
      <StarRating onRatingChanged={onChange} defaultRating={value ?? 0} />
    )}
  />
);

export const UserAttendedEventImages: React.FC<{
  theme: MD3Theme;
  form: UserAttendedEventFormType;
}> = ({ theme, form: { control, setValue } }) => {
  const { showActionSheetWithOptions } = useActionSheet();

  return (
    <View>
      <Text theme={theme} style={{ fontWeight: 'bold' }} variant={'titleSmall'}>
        {i18n.t('userAttendedEvent.formField.image_urls.label')}
      </Text>
      <Controller
        control={control}
        name={'image_urls'}
        render={({ field: { value } }) => (
          <ImagesSelector
            images={value ?? []}
            size={5}
            onAddImage={imageScanHandler(
              value,
              setValue,
              showActionSheetWithOptions,
            )}
            onRemoveImage={index => {
              if (value?.length) {
                value.splice(index, 1);
                setValue('image_urls', [...value], {
                  shouldDirty: true,
                });
              }
            }}
          />
        )}
      />
    </View>
  );
};
