import _ from 'lodash';
import React from 'react';
import { FlatList } from 'react-native';

import i18n from '../../translations/i18n';
import { includesTrimmed } from '../../utils/helpers';
import TextCard from '../form/TextCard';

const genreItems = _.memoize((locale: string) => {
  const genre = i18n.t('genres');
  return Object.keys(genre).map(id => ({
    id,
    name: genre[id as keyof typeof genre] as string,
  }));
});

const GenreList: React.FC<{
  searchQuery?: string;
  onPressItem: (genre: { id: string; name: string }) => void;
}> = params => {
  let genres = genreItems(i18n.locale);
  if (params?.searchQuery?.length) {
    genres = genres.filter(genre =>
      includesTrimmed(genre.name, params?.searchQuery),
    );
  }
  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={genres}
      renderItem={({ item }) => (
        <TextCard
          id={item?.id}
          name={item!.name}
          onPress={({ id, name }) => params?.onPressItem({ id: id!, name })}
        />
      )}
      keyExtractor={(item, index) => `${item?.id}${index}`}
    />
  );
};

export default GenreList;
