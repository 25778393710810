import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Image } from 'expo-image';
import React from 'react';
import { Platform } from 'react-native';
import { MarkerPressEvent } from 'react-native-maps/lib/sharedTypes';

import { LatLng, MapMarker } from '../map';

const markerOnIcon = Platform.select({
  web: require('../../../assets/images/marker-on-web.png'),
  native: require('../../../assets/images/marker-on.png'),
});
const markerOffIcon = Platform.select({
  web: require('../../../assets/images/marker-off-web.png'),
  native: require('../../../assets/images/marker-off.png'),
});

const PlaceMarker: React.FC<{
  location: LatLng;
  selected: boolean;
  on: boolean;
  onPress?: (event: MarkerPressEvent) => void;
}> = ({ location, selected, on, onPress }) => {
  const image = selected ? undefined : on ? markerOnIcon : markerOffIcon;
  const zIndex = selected || on ? 1000 : 0;
  return (
    <MapMarker
      style={{ zIndex }}
      tracksViewChanges={Platform.OS === 'ios'}
      zIndex={zIndex}
      coordinate={location}
      image={Platform.OS === 'ios' ? undefined : image}
      onPress={onPress}
    >
      {Platform.OS === 'ios' ? (
        image ? (
          <Image style={{ zIndex, width: 30, height: 30 }} source={image} />
        ) : (
          <MaterialCommunityIcons
            size={40}
            color={'#ff3a1f'}
            name={'map-marker'}
          />
        )
      ) : null}
    </MapMarker>
  );
};

export default PlaceMarker;
