import { useActionSheet } from '@expo/react-native-action-sheet';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { IconButton, Text, useTheme } from 'react-native-paper';
import { MD3Theme } from 'react-native-paper/src/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { MainStackParamList } from '../../navigation/MainStack';
import { Comment } from '../../repositories/CommentRepository';
import { UserService, useUserProfileQuery } from '../../services/UserService';
import i18n from '../../translations/i18n';
import { toDateTimeString } from '../../utils/datetimes';
import linkify from '../../utils/linkify';
import DefaultDialog from '../dialogs/DefaultDialog';
import { useDialog } from '../providers/DialogProvider';
import ImageIconAvatar from '../shared/ImageIconAvatar';
import { Column, Row } from '../shared/Layouts';
import RenderHtml from '../shared/RenderHtml';
import { showError, showInfo, showSuccess } from '../shared/Toast';

const Card = styled.View<{ theme: MD3Theme; reply?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 15px;
  gap: 10px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level3};
  border-bottom-width: 1px;
  ${({ theme, reply }) =>
    reply
      ? `
    background-color: ${theme.colors.elevation.level2};
    padding-left: 47px;
    `
      : ''}
`;

const CommentCard: React.FC<{
  comment: Comment;
  postedUser?: boolean;
  reply?: boolean;
  allowReply?: boolean;
  deleteComment: (comment: Comment) => Promise<boolean>;
  onPress?: (commentId?: string, storyId?: string) => void;
}> = ({ comment, postedUser, reply, allowReply, deleteComment, onPress }) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { data: user } = useUserProfileQuery();
  const { showActionSheetWithOptions } = useActionSheet();
  const { showDialog, hideDialog } = useDialog();
  const [contentWidth, setContentWidth] = useState(0);
  const onPressMore = () => {
    let options = [i18n.t('common.cancel')];
    let destructiveButtonIndex: number[];
    const self = user?.id === comment?.created_by || !!user?.admin;
    if (onPress) {
      options = [i18n.t('comment.originalPost'), ...options];
    }
    if (self) {
      options = [i18n.t('common.delete'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.delete'))];
    } else {
      options = [i18n.t('common.report'), i18n.t('common.block'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.report'))];
    }
    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        containerStyle: {
          paddingBottom: insets.bottom,
        },
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case options.indexOf(i18n.t('common.delete')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.delete')}
                content={i18n.t('common.areYouSureYouWantToDeleteIt')}
                confirmText={i18n.t('common.delete')}
                onPressDismiss={hideDialog}
                onPressConfirm={async () => {
                  const result = await deleteComment(comment);
                  if (result) {
                    showSuccess(i18n.t('common.itHasBeenDeleted'));
                  }
                  hideDialog();
                }}
              />,
            );
            break;
          }
          case options.indexOf(i18n.t('common.block')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.block')}
                content={i18n.t('common.blockConfirm', {
                  name: comment?.created?.name!,
                })}
                confirmText={i18n.t('common.block')}
                onPressConfirm={async () => {
                  try {
                    await UserService.blockUser(comment?.created?.id!);
                    hideDialog();
                    showInfo(i18n.t('common.blockSuccess'));
                  } catch (e) {
                    hideDialog();
                    showError(e);
                  }
                }}
                onPressDismiss={hideDialog}
              />,
            );
            break;
          }
          case options.indexOf(i18n.t('common.report')): {
            navigation.push('Report', {
              title: `${i18n.t('common.report')}: ${comment?.description!}`,
              type: 'comments',
              target: comment.id,
            });
            break;
          }
          case options.indexOf(i18n.t('comment.originalPost')): {
            onPress?.(comment.id, comment.story_id);
            break;
          }
          default:
        }
      },
    );
  };
  const Html = React.memo(() => (
    <RenderHtml
      source={{ html: linkify(comment.description) }}
      style={{
        padding: 0,
        width: '100%',
        alignItems: 'flex-start',
      }}
      fontSize={14}
      contentWidth={contentWidth - 30}
    />
  ));
  return (
    comment && (
      <Card theme={theme} reply={reply}>
        {/* @ts-ignore */}
        <Column style={{ width: null, justifyContent: 'flex-start' }}>
          <ImageIconAvatar
            size={27}
            icon={'account'}
            uri={comment?.created?.photo_url}
          />
        </Column>
        <Column
          /* @ts-ignore */
          style={{ width: null, flex: 1 }}
          onLayout={({
            nativeEvent: {
              layout: { width },
            },
          }) => setContentWidth(width)}
        >
          <Row style={{ gap: 5, justifyContent: 'space-between' }}>
            {/* @ts-ignore */}
            <Column style={{ width: null, flex: 1, alignItems: 'flex-start' }}>
              <Text variant="bodySmall" style={{ fontWeight: 'bold' }}>
                {comment.created?.name}
                {postedUser && (
                  <Text
                    variant="bodyLarge"
                    style={{ color: theme.colors.primary }}
                  >
                    *
                  </Text>
                )}
              </Text>
              <Text variant="bodySmall" style={{ color: theme.colors.outline }}>
                {toDateTimeString(comment.created_at)}
              </Text>
            </Column>
            {user && (
              <IconButton
                onPress={() => onPressMore()}
                size={17}
                iconColor={theme.colors.onBackground}
                icon={'dots-vertical'}
              />
            )}
          </Row>
          <Html />
          {!reply && allowReply && (
            <TouchableOpacity
              style={{ width: '100%', paddingTop: 5 }}
              onPress={() =>
                navigation.push('CommentDetail', { commentId: comment.id })
              }
            >
              <Text
                style={{ alignSelf: 'flex-start', color: theme.colors.outline }}
                variant="bodySmall"
              >
                <MaterialCommunityIcons name={'comment-outline'} />{' '}
                {i18n.t('common.reply')}
              </Text>
            </TouchableOpacity>
          )}
        </Column>
      </Card>
    )
  );
};

export default CommentCard;
