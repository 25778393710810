import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import PlaceForm from '../components/place/PlaceForm';
import Loading from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { usePlaceDetailQuery } from '../services/PlaceService';
import { useUserProfileQuery } from '../services/UserService';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';
import { hasEditPermission } from './PlaceDetailScreen';

const PlaceEditScreen: React.FC = () => {
  const insets = useSafeAreaInsets();
  const route = useRoute<RouteProp<MainStackParamList, 'PlaceEdit'>>();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  const placeId = route.params.placeId;

  useEffect(() => {
    if (navigationReady && !placeId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, placeId]);

  const { data: user } = useUserProfileQuery();
  const {
    data: place,
    isLoading,
    isFetching,
    isFetched,
  } = usePlaceDetailQuery(placeId!, user?.id!);

  useEffect(() => {
    if (isFetched) {
      if (!hasEditPermission(place, user)) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'EventFeed' });
        }
      }
    }
  }, [place]);

  const loading = isLoading || isFetching || !place;
  return loading ? (
    <Loading />
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      <PlaceForm place={place} />
    </KeyboardAwareScrollView>
  );
};

export default PlaceEditScreen;
