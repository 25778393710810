import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { MD3Theme } from 'react-native-paper/src/types';
import styled from 'styled-components/native';

import { Notice } from '../../repositories/NoticeRepository';
import { toDateTimeString } from '../../utils/datetimes';
import { ChipContainer } from '../shared/Chip';

const Card = styled.View<{ theme: MD3Theme }>`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 8px 20px;
  background-color: ${({ theme }) => theme.colors.elevation.level1};
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level3};
  border-bottom-width: 1px;
`;

const Content = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  flex-wrap: wrap;
  flex: 1;
  gap: 5px;
`;

const NoticeCard: React.FC<{
  notice: Notice;
  onPress: (notice: Notice) => void;
}> = ({ notice, onPress }) => {
  const theme = useTheme();
  return (
    notice && (
      <TouchableOpacity onPress={() => onPress(notice)}>
        <Card theme={theme}>
          <Content>
            <Text
              variant="bodyLarge"
              style={{ fontWeight: 'bold' }}
              numberOfLines={1}
            >
              {notice.title?.trim()}
            </Text>
            <ChipContainer>
              <Text
                style={{
                  color: theme.colors.onSurfaceVariant,
                }}
                variant="bodyMedium"
              >
                {toDateTimeString(notice.created_at)}
              </Text>
            </ChipContainer>
          </Content>
        </Card>
      </TouchableOpacity>
    )
  );
};

export default NoticeCard;
