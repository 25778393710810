import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';

import { MainStackParamList } from '../../navigation/MainStack';
import { Notice, NoticesParams } from '../../repositories/NoticeRepository';
import { useNoticesQuery } from '../../services/NoticeService';
import NoticeCard from './NoticeCard';

const NoticeCards: React.FC<NoticesParams> = params => {
  const { data: notices } = useNoticesQuery(params);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const onPressItem = (notice: Notice) =>
    navigation.push('NoticeDetail', { noticeId: notice.id });
  const items: Notice[] =
    notices?.pages?.map(({ notices }) => notices).flat() ?? [];
  return (
    <View>
      {items.map((item: Notice) => (
        <NoticeCard key={item.id} notice={item!} onPress={onPressItem} />
      ))}
    </View>
  );
};

export default NoticeCards;
