import { Image } from 'expo-image';
import React from 'react';
import { ColorSchemeName } from 'react-native';
import { ActivityIndicator, Portal, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { useAppearanceContext } from '../providers/AppearanceProvider';

export const SmallLoading: React.FC<{ size?: number }> = ({ size }) => (
  <ActivityIndicator style={{ marginVertical: 10 }} size={size ?? 'small'} />
);

const LoadingContainer = styled.View<{ colorMode: ColorSchemeName }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  ${({ colorMode }) => {
    switch (colorMode) {
      case 'light':
        return 'background-color: rgba(255,255,255, 0.6)';
      case 'dark':
        return 'background-color: rgba(0, 0, 0, 0.6)';
      default:
        return '';
    }
  }};
`;

const Loading: React.FC<{
  indicator?: boolean;
  text?: string;
  extra?: string;
}> = ({ indicator = true, text, extra }) => {
  const { colorMode } = useAppearanceContext();
  return (
    <Portal>
      <LoadingContainer colorMode={colorMode}>
        {/*<ActivityIndicator size={'large'} />*/}
        {indicator && (
          <Image
            style={{ width: 40, height: 40 }}
            source={require('../../../assets/images/loading.gif')}
          />
        )}
        {!!text && (
          <Text
            variant={'bodyLarge'}
            style={{ paddingTop: 15, fontWeight: 'bold', textAlign: 'center' }}
          >
            {text}
          </Text>
        )}
        {!!extra && (
          <Text
            variant={'bodySmall'}
            style={{ paddingTop: 5, fontWeight: 'bold', textAlign: 'center' }}
          >
            {extra}
          </Text>
        )}
      </LoadingContainer>
    </Portal>
  );
};

export default Loading;
