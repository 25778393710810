import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Text, Button, useTheme } from 'react-native-paper';
import styled from 'styled-components/native';

import { Event } from '../../repositories/EventRepository';
import { UserAttendedEvent } from '../../repositories/UserRepository';
import { useUpsertUserAttendedEventMutation } from '../../services/UserAttendedEventService';
import i18n from '../../translations/i18n';
import { ResourceType } from '../../utils/enums';
import { uploadImage } from '../../utils/upload';
import Loading from '../shared/Loading';
import { showError, showSuccess } from '../shared/Toast';
import {
  UserAttendedEventDescription,
  UserAttendedEventImages,
  UserAttendedEventRating,
  UserAttendedEventTitle,
} from './UserAttendedEventFormFields';

const UserAttendedEventFormContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  gap: 15px;
`;

export type UserAttendedEventFormInputs = UserAttendedEvent;
export type UserAttendedEventFormType =
  UseFormReturn<UserAttendedEventFormInputs>;

const UserAttendedEventForm: React.FC<{
  event: Event;
  userAttendedEvent: Partial<UserAttendedEvent>;
}> = ({ event, userAttendedEvent }) => {
  const theme = useTheme();
  const edit = !!userAttendedEvent.created_at;
  const [uploading, setUploading] = useState<boolean>(false);
  const { mutateAsync: upsertUserAttendedEvent, isLoading } =
    useUpsertUserAttendedEventMutation();
  const navigation = useNavigation<any>();

  const form = useForm<UserAttendedEventFormInputs>({
    defaultValues: userAttendedEvent,
  });

  const {
    handleSubmit,
    setFocus,
    reset,
    formState: { isDirty, dirtyFields },
  } = form;

  useEffect(() => {
    reset(userAttendedEvent);
  }, [userAttendedEvent]);

  const onSubmit = async ({
    ...userAttendedEvent
  }: UserAttendedEventFormInputs) => {
    if (edit && !isDirty) {
      return;
    }
    try {
      setUploading(true);
      if (userAttendedEvent.image_urls?.length && dirtyFields.image_urls) {
        const uploads = userAttendedEvent.image_urls.map((url: string) =>
          url.startsWith('http')
            ? Promise.resolve(url)
            : uploadImage(
                url,
                ResourceType.ATTENDED_EVENT,
                userAttendedEvent.attended_event_id,
              ),
        );
        userAttendedEvent.image_urls = await Promise.all(uploads);
      }
      const result = await upsertUserAttendedEvent(userAttendedEvent);
      setUploading(false);
      showSuccess(
        edit
          ? i18n.t('common.form.modified')
          : i18n.t('common.form.registered'),
      );
      if (result?.attended_event_id) {
        if (edit) {
          navigation.navigate('UserAttendedEventDetail', {
            eventId: result.attended_event_id,
          });
        } else {
          navigation.replace('UserAttendedEventDetail', {
            eventId: result.attended_event_id,
          });
        }
      }
    } catch (error) {
      showError(error);
    }
  };

  const onError = (errors: FieldErrors) => {
    const errorNames: (keyof FieldErrors)[] = Object.keys(errors);
    const hasError = !!errorNames.length;
    if (hasError) {
      showError(i18n.t('common.form.checkInput'));
      setFocus(errorNames[0] as keyof UserAttendedEventFormInputs);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          disabled={edit && !isDirty}
          labelStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={handleSubmit(onSubmit, onError)}
        >
          {edit ? i18n.t('common.save') : i18n.t('common.post')}
        </Button>
      ),
    });
  }, [isDirty]);

  const showLoading = uploading || isLoading;
  return (
    <UserAttendedEventFormContainer>
      {showLoading && <Loading />}
      <UserAttendedEventTitle theme={theme} title={event!.title} />
      <UserAttendedEventRating form={form} />
      <UserAttendedEventDescription theme={theme} form={form} />
      <Text>{i18n.t('userAttendedEvent.formField.private')}</Text>
      <UserAttendedEventImages theme={theme} form={form} />
    </UserAttendedEventFormContainer>
  );
};

export default UserAttendedEventForm;
