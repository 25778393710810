import { getMessaging, getToken } from 'firebase/messaging';
import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';

import { firebaseApp } from './firebase';
import PushTokenRepository from './repositories/PushTokenRepository';
import { UserProfile, UserService } from './services/UserService';
import LocalStorage from './utils/localStorage';

export type Route = {
  screen: string;
  params?: any;
};

export function initRoute(setInitialRoute: (route: Route) => void) {
  const initRouteCallback = useCallback(async () => {
    const region = await LocalStorage.getRegion();
    const screen = region ? 'MainTab' : 'SignIn';
    setInitialRoute({ screen });
  }, []);
  useEffect(() => {
    initRouteCallback();
  }, [initRouteCallback]);
}

export async function initNotification(user?: UserProfile | null) {
  const storageNotification = await LocalStorage.getNotification();
  if (typeof storageNotification === 'undefined') {
    const { enabled: notification, pushToken } =
      await getNotificationPermission();
    if (notification && pushToken) {
      subscribeCommonTopic();
      if (user) {
        PushTokenRepository.insertPushToken({
          token: pushToken,
          user_id: user?.id!,
          platform: Platform.OS,
        });
        UserService.updateUserData({
          accepted_marketing_terms_at: new Date().toISOString(),
        });
      }
    }
  }
}

export function useNotificationRoute() {}

export async function getNotificationPermission(): Promise<{
  enabled: boolean;
  pushToken: string | null;
}> {
  let enabled = false;
  if (typeof Notification !== 'undefined' && Notification.permission) {
    enabled = Notification.permission === 'granted';
    if (!enabled) {
      const permission = await Notification.requestPermission();
      enabled = permission === 'granted';
    }
  }
  let pushToken = null;
  if (enabled) {
    const messaging = getMessaging(firebaseApp);
    pushToken = await getToken(messaging);
  }
  await LocalStorage.setNotification(enabled);
  return { enabled, pushToken };
}

export async function subscribeCommonTopic() {}

export async function unsubscribeCommonTopic() {}
