import { useNavigation } from '@react-navigation/native';
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from '@react-navigation/native-stack';
import React from 'react';
import { ScrollView } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';

import ProfileActions from '../components/profile/ProfileActions';
import { ProfileHeader } from '../components/profile/ProfileHeader';
import { Column } from '../components/shared/Layouts';
import { MainStackParamList } from '../navigation/MainStack';
import { MainTabParamList } from '../navigation/MainTab';
import { useUserProfileQuery } from '../services/UserService';
import { signOut } from '../supabase';
import i18n from '../translations/i18n';

type ProfileScreenProps = NativeStackScreenProps<MainTabParamList, 'Profile'>;

const ProfileScreen: React.FC<ProfileScreenProps> = () => {
  const theme = useTheme();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { data: profile } = useUserProfileQuery();
  return profile ? (
    <ScrollView style={{ flex: 1 }}>
      <ProfileHeader theme={theme} profile={profile} />
      <ProfileActions theme={theme} profile={profile} />
    </ScrollView>
  ) : (
    <Column style={{ flex: 1, gap: 10 }}>
      <Text>{i18n.t('profile.empty.emptyUser')}</Text>
      <Button
        style={{ width: 100 }}
        compact={true}
        mode={'contained'}
        onPress={async () => {
          await signOut();
          navigation.push('SignIn');
        }}
      >
        {i18n.t('profile.empty.signIn')}
      </Button>
    </Column>
  );
};

export default ProfileScreen;
