import _ from 'lodash';
import { Dimensions, Platform } from 'react-native';

import { WEB_MAX_WIDTH } from './constants';

export const getDimensions = _.memoize(() => {
  const window = Dimensions.get('window');
  const width =
    Platform.OS === 'web'
      ? Math.min(window.width, WEB_MAX_WIDTH)
      : window.width;
  const height = window.height;
  return { width, height };
});

export const FORM_BOTTOM_PADDING = Platform.OS === 'android' ? 50 : 25;
