import { NavigationContext } from '@react-navigation/native';
import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import React, { useContext, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { Text } from 'react-native-paper';
import { RefreshControl } from 'react-native-web-refresh-control';

import { Artist, ArtistsParams } from '../../repositories/ArtistRepository';
import { useArtistsQuery } from '../../services/ArtistService';
import i18n from '../../translations/i18n';
import ArtistCard from '../artist/ArtistCard';
import { Column } from '../shared/Layouts';
import { SmallLoading } from '../shared/Loading';

const ArtistCardList: React.FC<
  ArtistsParams & { onPressItem: (artist: Artist) => void }
> = params => {
  const {
    data: artists,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = useArtistsQuery(params);
  const [refreshing, setRefreshing] = useState(false);
  const ref = useRef<FlatList>(null);

  if (useContext(NavigationContext)) {
    useScrollToTop(ref);
  }

  const onRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    setRefreshing(false);
  };

  const loading = isLoading || isFetching;
  const empty = !!artists?.pages?.[0]?.end;

  return empty ? (
    <Column style={{ flex: 1 }}>
      <Text>{i18n.t('artist.cardList.artist.rules.empty')}</Text>
    </Column>
  ) : (
    <FlatList
      ref={ref}
      showsVerticalScrollIndicator={false}
      data={artists?.pages?.map(({ artists }) => artists).flat()}
      renderItem={({ item }) => (
        <ArtistCard
          key={item?.id}
          artist={item!}
          onPress={params?.onPressItem}
        />
      )}
      keyExtractor={(item, index) => `${item?.id}${index}`}
      onEndReached={() => !loading && hasNextPage && fetchNextPage()}
      onEndReachedThreshold={0.5}
      ListFooterComponent={!refreshing && loading ? <SmallLoading /> : null}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    />
  );
};

export default ArtistCardList;
