import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ArtistForm from '../components/artist/ArtistForm';
import { MainStackParamList } from '../navigation/MainStack';
import { UserService, useUserProfileQuery } from '../services/UserService';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';

const ArtistAddScreen: React.FC = () => {
  const insets = useSafeAreaInsets();

  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { data: user, isFetched } = useUserProfileQuery();
  useEffect(() => {
    if (isFetched) {
      if (!UserService.hasPostPermission(user)) {
        navigation.replace('MainTab', { screen: 'EventFeed' });
      }
    }
  }, [isFetched]);

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      <ArtistForm artist={{}} />
    </KeyboardAwareScrollView>
  );
};

export default ArtistAddScreen;
