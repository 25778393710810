import 'react-native-url-polyfill/auto';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { StatusBar } from 'expo-status-bar';
import React, { Component, useMemo } from 'react';
import { Platform } from 'react-native';
import { DownloadProgress } from 'react-native-code-push';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { PaperProvider } from 'react-native-paper';
import { en, ko, registerTranslation } from 'react-native-paper-dates';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { useAnalytics } from './src/analytics';
import AppearanceProvider, {
  useAppearanceContext,
} from './src/components/providers/AppearanceProvider';
import DialogProvider from './src/components/providers/DialogProvider';
import Toast from './src/components/shared/Toast';
import UpdateModal from './src/components/update/UpdateModal';
import Navigation from './src/navigation';
import supabase from './src/supabase';
import { SyncStatus, restartApp } from './src/utils/codePush';
import queryClient from './src/utils/queryClient';
import { dark, light } from './src/utils/themes';

registerTranslation('en', en);
registerTranslation('ko', ko);

interface AppState {
  updating: boolean;
  updateProgress: number;
  updateError: boolean;
}

export default class App extends Component<any, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      updating: false,
      updateProgress: 0,
      updateError: false,
    };
  }

  codePushStatusDidChange(status: typeof SyncStatus) {
    switch (status) {
      case SyncStatus.DOWNLOADING_PACKAGE:
        this.setState({ updating: true, updateProgress: 0 });
        break;
      case SyncStatus.INSTALLING_UPDATE:
        this.setState({ updateProgress: 100 });
        break;
      case SyncStatus.UPDATE_INSTALLED:
        this.setState({ updating: false, updateProgress: 0 });
        restartApp();
        break;
      case SyncStatus.UNKNOWN_ERROR:
        this.setState({ updating: false, updateError: true });
        break;
    }
  }

  codePushDownloadDidProgress = (progress: DownloadProgress) => {
    const updateProgress = (progress.receivedBytes / progress.totalBytes) * 100;
    this.setState({ updateProgress });
  };

  render() {
    return (
      <GestureHandlerRootView style={{ flex: 1, backgroundColor: '#000' }}>
        <AppearanceProvider>
          <Providers {...this.state} />
        </AppearanceProvider>
      </GestureHandlerRootView>
    );
  }
}

const Providers: React.FC<AppState> = ({
  updating,
  updateProgress,
  updateError,
}) => {
  useAnalytics();
  const { colorMode } = useAppearanceContext();

  const paperTheme = useMemo(
    () => (colorMode === 'light' ? light : dark),
    [colorMode],
  );

  const backgroundColor = colorMode === 'light' ? '#fff' : '#000';
  if (Platform.OS === 'web') {
    document.documentElement.style.backgroundColor = backgroundColor;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SessionContextProvider supabaseClient={supabase}>
        <SafeAreaProvider>
          <PaperProvider theme={paperTheme}>
            <UpdateModal
              isVisible={updating}
              progress={updateProgress}
              error={updateError}
            />
            <ActionSheetProvider>
              <DialogProvider>
                <Navigation />
                <StatusBar style={colorMode === 'light' ? 'dark' : 'light'} />
              </DialogProvider>
            </ActionSheetProvider>
            <Toast />
          </PaperProvider>
        </SafeAreaProvider>
      </SessionContextProvider>
    </QueryClientProvider>
  );
};
