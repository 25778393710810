import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';

import PlaceCardList from '../components/place/PlaceCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';

const UserManagedPlacesScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (navigationReady && !userId) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, userId]);

  return (
    <PlaceCardList
      ownerId={userId}
      onPressItem={place =>
        navigation.push('PlaceDetail', { placeId: place.id })
      }
    />
  );
};

export default UserManagedPlacesScreen;
