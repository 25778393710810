import { useMutation, useQuery } from '@tanstack/react-query';

import { showError } from '../components/shared/Toast';
import UserRepository, {
  UserAttendedEvent,
} from '../repositories/UserRepository';
import { resetAll } from '../utils/queryClient';

export class UserAttendedEventService {
  static async getUserAttendedEvent(
    userId: string,
    eventId: string,
  ): Promise<UserAttendedEvent | undefined> {
    const { data, error } = await UserRepository.getUserAttendedEvent(
      userId,
      eventId,
    );
    if (error) {
      showError(error);
      return;
    }
    return data as UserAttendedEvent;
  }

  static async upsertUserAttendedEvent({
    event,
    ...userAttendedEvent
  }: NonNullable<UserAttendedEvent>) {
    const { data: upserted, error } =
      await UserRepository.upsertUserAttendedEvent(
        userAttendedEvent as UserAttendedEvent,
      );
    if (error) {
      return Promise.reject(error);
    }
    if (!upserted) {
      throw new Error('Upsert failure');
    }
    return upserted;
  }

  static async deleteUserAttendedEvent(
    userId: string,
    eventId: string,
  ): Promise<void> {
    const { error } = await UserRepository.deleteUserAttendedEvent(
      userId,
      eventId,
    );
    if (error) {
      showError(error);
    }
  }
}

export function useUpsertUserAttendedEventMutation() {
  return useMutation(
    (userAttendedEvent: UserAttendedEvent) =>
      UserAttendedEventService.upsertUserAttendedEvent(userAttendedEvent),
    {
      onSuccess: async userAttendedEvent => await resetAll(),
    },
  );
}

export function useDeleteUserAttendedEventMutation() {
  return useMutation(
    ({ userId, eventId }: { userId?: string; eventId?: string }) =>
      userId && eventId
        ? UserAttendedEventService.deleteUserAttendedEvent(userId, eventId)
        : Promise.reject(new Error('Invalid UserId or EventId')),
    {
      onSuccess: async () => await resetAll(),
    },
  );
}

export function useUserAttendedEventDetailQuery(
  userId?: string,
  eventId?: string,
) {
  return useQuery<UserAttendedEvent | null>(
    ['userAttendedEventDetail', eventId],
    async () => {
      if (!userId || !eventId) {
        return null;
      }
      const userAttendedEvent =
        await UserAttendedEventService.getUserAttendedEvent(userId, eventId);
      return userAttendedEvent ?? null;
    },
  );
}
