import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { MD3Theme, Text } from 'react-native-paper';
import styled from 'styled-components/native';

const SettingActionContainer = styled.TouchableOpacity<{ theme: MD3Theme }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-horizontal: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const SettingActionButton: React.FC<{
  theme: MD3Theme;
  style?: StyleProp<ViewStyle>;
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  iconColor?: string;
  label: string;
  onPress?: () => void;
}> = ({ theme, style, label, icon, iconColor, onPress }) => (
  <SettingActionContainer theme={theme} style={style} onPress={onPress}>
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <MaterialCommunityIcons
        name={icon}
        color={iconColor ? iconColor : theme.colors.onBackground}
        size={23}
        style={{ marginRight: 10 }}
      />
      <Text style={{ fontSize: 18 }}>{label}</Text>
    </View>
    <MaterialCommunityIcons
      name={'chevron-right'}
      color={theme.colors.onBackground}
      size={30}
    />
  </SettingActionContainer>
);

const SettingActionToggleContainer = styled.View<{ theme: MD3Theme }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-horizontal: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const SettingActionToggleButton: React.FC<{
  theme: MD3Theme;
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  label: string;
  onPress?: () => void;
  button?: React.ReactNode;
}> = ({ theme, label, icon, button }) => (
  <SettingActionToggleContainer theme={theme}>
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <MaterialCommunityIcons
        name={icon}
        color={theme.colors.onBackground}
        size={23}
        style={{ marginRight: 10 }}
      />
      <Text style={{ fontSize: 18 }}>{label}</Text>
    </View>
    {button}
  </SettingActionToggleContainer>
);

export default SettingActionButton;
