import { initializeAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import { FIREBASE_WEB_CONFIG } from './utils/constants';

export const firebaseApp = initializeApp(FIREBASE_WEB_CONFIG as any);
const firebase = initializeAnalytics(firebaseApp, {
  config: { send_page_view: false },
});

export default firebase;

export const logScreenView = async (screen: {
  name?: string;
  params?: any;
}) => {
  logEvent(firebase, 'screen_view', {
    firebase_screen: screen.name,
    firebase_screen_class: screen.name,
    ...screen.params,
  });
};
