import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import * as Linking from 'expo-linking';
import React, { RefObject, useEffect, useRef } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { MD3Theme, Text } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { Artist } from '../../repositories/ArtistRepository';
import { RelatedNews } from '../../repositories/NewsRepository';
import i18n from '../../translations/i18n';
import { getDimensions } from '../../utils/dimensions';
import linkify from '../../utils/linkify';
import { extractSpotifyId, extractYoutubeId } from '../../utils/regexps';
import { colors } from '../../utils/themes';
import DetailItem, { ItemContainer } from '../shared/DetailItem';
import FavoriteButton from '../shared/FavoriteButton';
import { SmallLoading } from '../shared/Loading';
import RenderHtml from '../shared/RenderHtml';
import SocialMediaIcons from '../shared/SocialMediaIcons';
import TextInputButton from '../shared/TextInputButton';
import { showInfo } from '../shared/Toast';
import SpotifyPlayer from './SpotifyPlayer';
import YoutubePlayer from './YoutubePlayer';

const GAP = 10;

const ArtistDetailContent: React.FC<{
  section?: 'spotify' | 'youtube';
  setSectionOffset?: (sectionOffset?: number) => void;
  artist: NonNullable<Artist>;
  news?: RelatedNews | null;
  theme: MD3Theme;
  isSaving?: boolean;
  onPressLike: () => Promise<void>;
}> = ({
  section,
  setSectionOffset,
  artist,
  news,
  theme,
  isSaving,
  onPressLike,
}) => {
  const user = useUser();
  const margin = 15;
  const { width } = getDimensions();
  const height = ((width - margin) / 16) * 9;
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const spotifyId = extractSpotifyId(artist.spotify_url ?? '');
  const youtubeId = extractYoutubeId(artist.youtube_url ?? '');
  const spotifyRef = useRef<View>(null);
  const youtubeRef = useRef<View>(null);
  useEffect(() => {
    let ref: RefObject<View> | null = null;
    if (section === 'spotify' && spotifyRef?.current) {
      ref = spotifyRef;
    }
    if (section === 'youtube' && youtubeRef?.current) {
      ref = youtubeRef;
    }
    if (ref) {
      setTimeout(() => {
        ref?.current?.measure((x, y, width, height, pageX, pageY) => {
          setSectionOffset?.(pageY);
        });
      }, 100);
    }
  }, [spotifyRef, youtubeRef]);
  return (
    <View style={{ margin }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text
          style={{ fontWeight: 'bold', marginBottom: GAP }}
          variant={'titleLarge'}
        >
          {artist.name?.trim()}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: 25,
            height: 25,
          }}
        >
          {isSaving ? (
            <SmallLoading />
          ) : (
            <FavoriteButton
              icon={artist.liked?.length ? 'heart' : 'heart-outline'}
              onPress={onPressLike}
            />
          )}
        </View>
      </View>
      <View style={{ marginBottom: GAP }}>
        <SocialMediaIcons artist={artist} />
        <Text>
          <RenderHtml
            source={{ html: linkify(artist.description) }}
            style={{ paddingHorizontal: 10 }}
          />
        </Text>
      </View>
      <ItemContainer>
        <DetailItem
          theme={theme}
          iconSize={30}
          icon={'calendar'}
          label={i18n.t('artist.detailContent.event', { name: artist.name })}
          variant={'bodyLarge'}
          onPress={() =>
            navigation.push('ArtistEventFeed', { artistId: artist.id })
          }
        />
        <DetailItem
          theme={theme}
          iconSize={30}
          icon={'calendar-text'}
          label={i18n.t('artist.detailContent.agencyEvent', {
            name: artist.name,
          })}
          variant={'bodyLarge'}
          onPress={() =>
            navigation.push('AgencyEventFeed', { agencyId: artist.id })
          }
        />
        <DetailItem
          theme={theme}
          iconSize={30}
          icon={'forum-outline'}
          label={i18n.t('artist.detailContent.story', { name: artist.name })}
          variant={'bodyLarge'}
          onPress={() =>
            navigation.push('MainTab', {
              screen: 'StoryFeed',
              params: {
                artistId: artist.id,
              },
            })
          }
        />
        {news?.title && news?.link && (
          <>
            <DetailItem
              theme={theme}
              icon={'newspaper-variant-outline'}
              iconSize={30}
              numberOfLines={2}
              label={news.title}
              variant={'bodyLarge'}
              onPress={() => Linking.openURL(news.link)}
            />
          </>
        )}
        <TextInputButton
          theme={theme}
          onPress={() =>
            user
              ? navigation.push('StoryAdd', {
                  artistId: artist.id,
                })
              : showInfo(i18n.t('common.pleaseSignIn'))
          }
          placeholder={i18n.t('artist.detailContent.postStory', {
            name: artist.name,
          })}
        />
      </ItemContainer>
      {!!spotifyId && (
        <View ref={spotifyRef} style={{ marginBottom: GAP }}>
          <TouchableOpacity
            onPress={() => Linking.openURL(artist?.spotify_url!)}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                marginVertical: 10,
                justifyContent: 'center',
              }}
            >
              <MaterialCommunityIcons
                name={'spotify'}
                color={colors.spotifyColor}
                size={18}
              />{' '}
              {i18n
                .t('artist.detailContent.spotify')
                .replace('${artist.name}', artist.name)}
            </Text>
          </TouchableOpacity>
          <SpotifyPlayer height={352} spotifyId={spotifyId} />
        </View>
      )}
      {!!youtubeId && (
        <View ref={youtubeRef}>
          <TouchableOpacity
            onPress={() => Linking.openURL(artist?.youtube_url!)}
          >
            <Text
              style={{ fontWeight: 'bold', fontSize: 18, marginVertical: 10 }}
            >
              <MaterialCommunityIcons
                name={'youtube'}
                size={18}
                color={colors.youtubeColor}
              />{' '}
              {i18n.t('artist.detailContent.youtube')}
            </Text>
          </TouchableOpacity>
          <YoutubePlayer youtubeId={youtubeId} height={height} />
        </View>
      )}
    </View>
  );
};

export default ArtistDetailContent;
