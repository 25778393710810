import { getLocales } from 'expo-localization';

import { DEFAULT_LOCALE } from './i18n';

export function getLocale(): string {
  const lang = getLang();
  if (lang) {
    return lang;
  }
  return getLocales?.()?.[0]?.languageCode || DEFAULT_LOCALE;
}

function getLang() {
  const queryParams = new URLSearchParams(new URL(window.location.href).search);
  const lang = queryParams.get('lang');
  if (
    lang?.toLocaleLowerCase() === 'ko' ||
    lang?.toLocaleLowerCase() === 'en'
  ) {
    return lang;
  }
}
