import { format, formatDistanceToNow, isAfter, parseISO } from 'date-fns';
import { enUS, ko } from 'date-fns/locale';

import i18n from '../translations/i18n';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const TIME_FORMAT = 'HH:mm';

export const parse = (
  dateISOString?: string | null,
): Date | null | undefined => {
  if (!dateISOString) {
    return;
  }
  return parseISO(dateISOString);
};

export const formatDate = (date?: Date | null): string | null | undefined => {
  if (!date) {
    return;
  }
  return format(date, DATE_FORMAT);
};

export const formatDateTime = (
  date?: Date | null,
): string | null | undefined => {
  if (!date) {
    return;
  }
  return format(date, DATE_TIME_FORMAT);
};

export const formatTime = (date?: Date | null): string | null | undefined => {
  if (!date) {
    return;
  }
  return format(date, TIME_FORMAT);
};

export const toDateString = (
  dateISOString?: string | null,
): string | null | undefined => formatDate(parse(dateISOString));

export const toDateTimeString = (
  dateISOString?: string | null,
): string | null | undefined => {
  const locale = i18n.locale;
  const date = parse(dateISOString);
  if (!date) {
    return '';
  }
  const now = Date.now();
  const diff = Math.abs((now - date.getTime()) / 1000);
  if (diff < 60) {
    return i18n.t('common.justNow');
  }
  const localeFormat = locale === 'ko' ? ko : enUS;
  if (diff < 60 * 60 * 24 * 7) {
    return formatDistanceToNow(date, { addSuffix: true, locale: localeFormat });
  }
  return format(date, 'P E p', { locale: localeFormat });
};

export const isPast = (dateString?: string): boolean =>
  isAfter(Date.now(), parse(dateString)!);
