import { ActionSheetOptions } from '@expo/react-native-action-sheet/lib/typescript/types';
import * as ImagePicker from 'expo-image-picker';
import { UseFormSetValue } from 'react-hook-form';

import { pickImage } from './upload';

const imageScanHandler =
  (
    value: string[] | null,
    setValue: UseFormSetValue<any>,
    _?: (
      options: ActionSheetOptions,
      callback: (i?: number) => void | Promise<void>,
    ) => void,
  ) =>
  async () => {
    const urls = await pickImage({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: false,
      quality: 1,
      allowsMultipleSelection: true,
      selectionLimit: 5 - (value?.length ?? 0),
    });
    if (urls?.length) {
      setValue(
        'image_urls',
        [...(value ?? []), ...urls].filter(url => url).slice(0, 5),
        {
          shouldDirty: true,
        },
      );
    }
  };

export default imageScanHandler;
