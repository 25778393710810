import { endOfDay, startOfDay } from 'date-fns';

import { LatLng, Region } from '../components/map';
import supabase from '../supabase';
import { Database } from '../types/database';
import http from '../utils/http';
import { Event } from './EventRepository';
import { Profile } from './ProfileRepository';
import { UserBlockedUser } from './UserRepository';

type PlaceResponse = Awaited<ReturnType<typeof PlaceRepository.getPlace>>;
type PlaceResponseSuccess = PlaceResponse['data'];
type UserFavoritePlace =
  Database['public']['Tables']['users_favorite_places']['Row'];

export interface Place
  extends Omit<
    NonNullable<PlaceResponseSuccess>,
    'location' | 'events' | 'favorite'
  > {
  location?: LatLng;
  events?: Event[];
  favorite?: UserFavoritePlace[];
  created?: Profile & {
    blocked?: UserBlockedUser[];
  };
}

export interface PlacesParams {
  ownerId?: string;
  postedUserId?: string;
  favoriteUserId?: string;
  region?: Region;
  searchQuery?: string;
  sort?: 'distance';
  offset?: number;
}

export default class PlaceRepository {
  private static table = () => supabase.from('places');
  private static ownerTable = () => supabase.from('places_owners');

  static RANGE_DAYS = 14;
  static PAGE_SIZE = 20;

  static async getOwnedPlaces(ownerId: string, offset = 0) {
    return this.ownerTable()
      .select('*, places(*)')
      .eq('owner_id', ownerId)
      .range(offset, offset + PlaceRepository.PAGE_SIZE - 1);
  }

  static async getPlaces({
    searchQuery,
    postedUserId,
    favoriteUserId,
    offset = 0,
  }: PlacesParams) {
    let query = this.table().select(`*, events(*)`);
    if (searchQuery) {
      query.or(
        `name.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%,address.ilike.%${searchQuery}%`,
      );
    }
    if (postedUserId) {
      query = this.table()
        .select(`*, events(*)`)
        .eq('created_by', postedUserId);
    }
    if (favoriteUserId) {
      query = this.table()
        .select(`*, events(*), favorite:users_favorite_places!inner(*)`)
        .eq('favorite.user_id', favoriteUserId);
    }
    return query
      .eq('events.display', true)
      .order('name')
      .range(offset, offset + PlaceRepository.PAGE_SIZE - 1);
  }

  static async getPlace(placeId: string, userId: string) {
    const rangeFrom = new Date();
    const rangeTo = new Date(
      rangeFrom.getFullYear(),
      rangeFrom.getMonth(),
      rangeFrom.getDate() + PlaceRepository.RANGE_DAYS,
    );
    const query = this.table()
      .select('*, events(*), favorite:users_favorite_places(*)')
      .gte('events.event_date', startOfDay(rangeFrom).toISOString())
      .lte('events.event_date', endOfDay(rangeTo).toISOString())
      .eq('events.display', true)
      .eq('id', placeId);
    if (userId) {
      query.eq('users_favorite_places.user_id', userId);
    }
    return query.maybeSingle();
  }

  static async getPlaceByNameAndAddress(name: string, address: string) {
    return this.table()
      .select('name, address')
      .ilike('name', name)
      .ilike('address', address)
      .maybeSingle();
  }

  static async getPlacesByRegionWithin14Days(nears: {
    lesser: LatLng;
    greater: LatLng;
  }) {
    const rangeFrom = new Date();
    const rangeTo = new Date(
      rangeFrom.getFullYear(),
      rangeFrom.getMonth(),
      rangeFrom.getDate() + PlaceRepository.RANGE_DAYS,
    );
    return this.table()
      .select(`*, events(*)`)
      .gte('events.event_date', startOfDay(rangeFrom).toISOString())
      .lte('events.event_date', endOfDay(rangeTo).toISOString())
      .eq('events.display', true)
      .gte('latitude', nears.lesser.latitude)
      .gte('longitude', nears.lesser.longitude)
      .lte('latitude', nears.greater.latitude)
      .lte('longitude', nears.greater.longitude);
  }

  static async getPlacesWithDistance({ region, offset = 0 }: PlacesParams) {
    try {
      const query = supabase.rpc('places_distance', {
        lat: region!.latitude,
        lng: region!.longitude,
        page_offset: offset,
        page_limit: offset + PlaceRepository.PAGE_SIZE - 1,
      });
      const { data } = await query;
      const places = data!.map(({ place }) => place);
      return { data: places };
    } catch (e) {
      throw e;
    }
  }

  static async upsertPlace({
    location,
    events,
    favorite,
    favorite_count,
    ...place
  }: Place) {
    place.latitude = location?.latitude!;
    place.longitude = location?.longitude!;
    // @ts-ignore
    place.location = `POINT(${place.longitude} ${place.latitude})`;
    return this.table().upsert(place).select().maybeSingle();
  }

  static async deletePlace(placeId: string) {
    return this.table().delete().eq('id', placeId);
  }

  static async updateViewCount(placeId: string) {
    try {
      http.post(`/api/counts/places/${placeId}/views`);
    } catch {}
  }
}
