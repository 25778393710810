import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import AvatarIcon from 'react-native-paper/src/components/Avatar/AvatarIcon';
import { MD3Theme } from 'react-native-paper/src/types';
import styled from 'styled-components/native';

import { Place } from '../../repositories/PlaceRepository';

const Card = styled.View<{ theme: MD3Theme }>`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  padding: 5px 0;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level2};
  border-bottom-width: 1px;
`;

const Content = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
  flex-wrap: wrap;
  flex: 1;
`;

const PlaceCard: React.FC<{
  place: Place;
  onPress: (place: Place) => void;
}> = ({ place, onPress }) => {
  if (!place) {
    return null;
  }
  const theme = useTheme();
  return (
    <TouchableOpacity onPress={() => onPress(place)}>
      <Card theme={theme} style={{ alignItems: 'center' }}>
        <AvatarIcon
          size={40}
          style={{
            backgroundColor: 'transparent',
            borderColor: theme.colors.elevation.level2,
            borderWidth: 1,
          }}
          color={theme.colors.onSurface}
          icon={'map-marker-outline'}
        />
        <Content>
          <Text variant={'titleMedium'}>{place.name}</Text>
          <Text
            variant={'titleMedium'}
            style={{ color: theme.colors.elevation.level5 }}
          >
            {place.address?.trim()}
          </Text>
        </Content>
      </Card>
    </TouchableOpacity>
  );
};

export default PlaceCard;
