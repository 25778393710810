import React, { createContext, useContext, useState } from 'react';
import { Dialog } from 'react-native-paper';

const GlobalDialogContext = createContext<{
  showDialog: (dialog: React.ReactNode) => void;
  hideDialog: () => void;
}>({
  showDialog: _ => {},
  hideDialog: () => {},
});

export const useDialog = () => useContext(GlobalDialogContext);

const DialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const [dialog, setDialog] = useState<React.ReactNode>();
  const eventDialog = (dialog: React.ReactNode) => {
    setDialog(dialog);
    setVisible(true);
  };
  const hideDialog = () => {
    setVisible(false);
  };
  return (
    <GlobalDialogContext.Provider
      value={{ showDialog: eventDialog, hideDialog }}
    >
      {children}
      <Dialog
        visible={visible}
        onDismiss={hideDialog}
        style={{
          alignSelf: 'center',
          width: '75%',
        }}
      >
        {dialog}
      </Dialog>
    </GlobalDialogContext.Provider>
  );
};

export default DialogProvider;
