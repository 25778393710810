import React, { useRef } from 'react';

import { Place } from '../../repositories/PlaceRepository';
import MapView, { PROVIDER_GOOGLE, Region } from '../map';
import PlaceMarker from './PlaceMarker';

const PlaceListMap: React.FC<{
  region: Region | null;
  places: Place[] | null;
  selectedPlaceId?: string;
  onReady?: (mapRef: MapView | null) => void;
  onChange: (region: Region) => void;
  onPress?: () => void;
  onPressMarker?: (place: Place) => void;
}> = ({
  region,
  places,
  selectedPlaceId,
  onReady,
  onChange,
  onPress,
  onPressMarker,
}) => {
  const mapRef = useRef<MapView>(null);
  const hasRegion = region?.latitude && region?.longitude;
  return hasRegion ? (
    <MapView
      ref={mapRef}
      style={{ flex: 1, zIndex: 0 }}
      minZoomLevel={7}
      maxZoomLevel={18}
      initialRegion={region}
      provider={PROVIDER_GOOGLE}
      onMapReady={() => onReady?.(mapRef?.current)}
      onPress={onPress}
      onRegionChangeComplete={onChange}
      showsUserLocation={true}
      followsUserLocation={true}
      showsMyLocationButton={false}
      zoomControlEnabled={false}
      rotateEnabled={false}
    >
      {places?.map(
        place =>
          place && (
            <PlaceMarker
              key={place.id}
              location={{
                latitude: place.latitude!,
                longitude: place.longitude!,
              }}
              selected={place.id === selectedPlaceId}
              on={!!place.events?.length}
              onPress={e => {
                e.stopPropagation?.();
                onPressMarker?.(place);
              }}
            />
          ),
      )}
    </MapView>
  ) : null;
};

export default PlaceListMap;
