import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';

import { MainStackParamList } from '../../navigation/MainStack';
import { CommentsParams } from '../../repositories/CommentRepository';
import {
  useCommentsQuery,
  useDeleteCommentMutation,
} from '../../services/CommentService';
import { SmallLoading } from '../shared/Loading';
import CommentCard from './CommentCard';
import { EmptyCommentCard } from './EmptyCommentCard';

const CommentCardList: React.FC<
  CommentsParams & {
    onPressCard?: (commentId?: string, storyId?: string) => void;
  }
> = ({ onPressCard, ...params }) => {
  const {
    data: comments,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = useCommentsQuery(params);
  const [refreshing, setRefreshing] = useState(false);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute();
  const ref = useRef<FlatList>(null);
  useScrollToTop(ref);

  const { mutateAsync: deleteComment, isLoading: isMutationLoading } =
    useDeleteCommentMutation();

  const onRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    setRefreshing(false);
  };

  const onPressEmpty = () => {
    navigation.setParams(_.mapValues(route.params, () => undefined));
  };

  const loading = isLoading || isFetching || isMutationLoading;
  const empty = !!comments?.pages?.[0]?.end;
  return empty ? (
    <EmptyCommentCard onPressReset={onPressEmpty} />
  ) : (
    <FlatList
      showsVerticalScrollIndicator={false}
      ref={ref}
      data={comments?.pages?.map(({ comments }) => comments).flat()}
      renderItem={({ item }) => (
        <CommentCard
          key={item.id}
          comment={item!}
          deleteComment={deleteComment}
          onPress={onPressCard}
        />
      )}
      keyExtractor={(item, index) => `${item?.id + index}`}
      onEndReached={() => !loading && hasNextPage && fetchNextPage()}
      onEndReachedThreshold={0.5}
      ListFooterComponent={!refreshing && loading ? <SmallLoading /> : null}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    />
  );
};

export default CommentCardList;
