import { User } from '@supabase/gotrue-js';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { sendError, showError } from '../components/shared/Toast';
import NotificationRepository, {
  Notification,
  NotificationsParams,
} from '../repositories/NotificationRepository';
import { UserProfile } from './UserService';

export class NotificationService {
  static async getNotification(
    notificationId: number,
  ): Promise<Notification | undefined> {
    const { data, error } = await NotificationRepository.getNotification(
      notificationId,
    );
    if (error) {
      showError(error);
      return;
    }
    return data as Notification;
  }

  static async getNotifications(params: NotificationsParams) {
    const { data } = await NotificationRepository.getNotifications(params);
    const notifications = (data ?? []) as Notification[];
    return {
      notifications,
      offset: params.offset,
      end: !notifications?.length,
    };
  }

  static async getNewNotificationsCount(): Promise<number> {
    const { count, error } =
      await NotificationRepository.getNewNotificationsCount();
    if (error) {
      sendError(error);
      return 0;
    }
    return count ?? 0;
  }

  static async getNewEventNotificationsCount(): Promise<number> {
    const { count, error } =
      await NotificationRepository.getNewEventNotificationsCount();
    if (error) {
      sendError(error);
      return 0;
    }
    return count ?? 0;
  }

  static async getNewStoryNotificationsCount(): Promise<number> {
    const { count, error } =
      await NotificationRepository.getNewStoryNotificationsCount();
    if (error) {
      sendError(error);
      return 0;
    }
    return count ?? 0;
  }
}

export function useNotificationCountQuery(
  user: User | UserProfile | null | undefined,
) {
  return useQuery<number>(['notificationCount', user?.id], async param =>
    user ? await NotificationService.getNewNotificationsCount() : 0,
  );
}

export function useEventNotificationCountQuery(
  user: User | UserProfile | null | undefined,
) {
  return useQuery<number>(['eventNotificationCount', user?.id], async param =>
    user ? await NotificationService.getNewEventNotificationsCount() : 0,
  );
}

export function useStoryNotificationCountQuery(
  user: User | UserProfile | null | undefined,
) {
  return useQuery<number>(['storyNotificationCount', user?.id], async () =>
    user ? await NotificationService.getNewStoryNotificationsCount() : 0,
  );
}

export function useReadNotificationMutation() {
  return useMutation(
    async () => await NotificationRepository.readNotifications(),
  );
}

export function useNotificationsQuery(params: NotificationsParams) {
  return useInfiniteQuery(
    ['notifications', params],
    async ({ pageParam }) =>
      await NotificationService.getNotifications({
        ...params,
        offset: pageParam,
      }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + NotificationRepository.PAGE_SIZE,
    },
  );
}
