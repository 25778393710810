import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import EventForm from '../components/event/EventForm';
import Loading from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import EventService, { useEventDetailQuery } from '../services/EventService';
import { useUserProfileQuery } from '../services/UserService';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';

const EventEditScreen: React.FC = () => {
  const insets = useSafeAreaInsets();
  const route = useRoute<RouteProp<MainStackParamList, 'EventEdit'>>();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { data: user, isFetched: isUserFetched } = useUserProfileQuery();
  const eventId = route.params.eventId;

  useEffect(() => {
    if (!isUserFetched) {
      return;
    }
    if (navigationReady && !user) {
      navigation.replace('SignIn');
    }
    if (navigationReady && !eventId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, user, eventId]);

  const {
    data: event,
    isLoading,
    isFetching,
    isFetched,
  } = useEventDetailQuery(eventId);

  useEffect(() => {
    if (isFetched) {
      if (!EventService.hasEditPermission(event, user)) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'EventFeed' });
        }
      }
    }
  }, [event]);
  const loading = isLoading || isFetching || !event;
  return loading ? (
    <Loading />
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      <EventForm event={event} />
    </KeyboardAwareScrollView>
  );
};

export default EventEditScreen;
