import AsyncStorage from '@react-native-async-storage/async-storage';
import { ColorSchemeName } from 'react-native';

import { Region } from '../components/map';
import { ENV } from './constants';

export interface RegionAndDistrict extends Region {
  district?: string;
}

interface Storage {
  colorMode?: ColorSchemeName;
  district?: string;
  region?: Region;
  notification?: boolean;
  location?: boolean;
  locale?: string;
  recentSearchQueries?: string[];
}

export class LocalStorage {
  private static storageKey = `livewire-${ENV}`;
  private static storage: Storage | undefined = undefined;

  public static async initialize() {
    const storedData = await AsyncStorage.getItem(LocalStorage.storageKey);
    if (storedData) {
      LocalStorage.storage = JSON.parse(storedData);
    } else {
      LocalStorage.storage = {};
    }
  }

  public static async setColorMode(colorMode: ColorSchemeName): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    LocalStorage.storage!.colorMode = colorMode;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getColorMode(): Promise<ColorSchemeName> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return LocalStorage.storage!.colorMode;
  }

  public static async setDistrict(district: string): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    LocalStorage.storage!.district = district;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getDistrict(): Promise<string | undefined> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return LocalStorage.storage!.district;
  }

  public static async setRegion(region: Region): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    LocalStorage.storage!.region = region;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getRegion(): Promise<Region | undefined> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return LocalStorage.storage!.region;
  }

  public static async setRegionAndDistrict(
    regionAndDistrict: RegionAndDistrict,
  ): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    const { district, ...region } = regionAndDistrict;
    LocalStorage.storage!.district = district;
    LocalStorage.storage!.region = region;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getRegionAndDistrict(): Promise<
    RegionAndDistrict | undefined
  > {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return {
      ...LocalStorage.storage!.region!,
      district: LocalStorage.storage!.district,
    };
  }

  public static async setNotification(notification: boolean): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    LocalStorage.storage!.notification = notification;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getNotification(): Promise<boolean | undefined> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return LocalStorage.storage!.notification;
  }

  public static async setLocation(location: boolean): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    LocalStorage.storage!.location = location;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getLocation(): Promise<boolean | undefined> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return LocalStorage.storage!.location;
  }

  public static async setLocale(locale: string): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    LocalStorage.storage!.locale = locale;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getLocale(): Promise<string | undefined> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return LocalStorage.storage!.locale;
  }

  public static async setRecentSearchQueries(
    recentSearchQueries: string[],
  ): Promise<void> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    LocalStorage.storage!.recentSearchQueries = recentSearchQueries;
    await AsyncStorage.setItem(
      LocalStorage.storageKey,
      JSON.stringify(LocalStorage.storage),
    );
  }

  public static async getRecentSearchQueries(): Promise<string[]> {
    if (!LocalStorage.storage) {
      await this.initialize();
    }
    return LocalStorage.storage!.recentSearchQueries ?? [];
  }

  public static async clear(): Promise<void> {
    LocalStorage.storage = undefined;
    await AsyncStorage.removeItem(LocalStorage.storageKey);
  }
}

export default LocalStorage;
