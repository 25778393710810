import React from 'react';
import { ViewProps } from 'react-native';
import styled from 'styled-components/native';

export const Column: React.FC<ViewProps> = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Row: React.FC<ViewProps> = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
