import React from 'react';

import ImageZoom from './ImageZoom';
import { Column } from './Layouts';

const DetailImages: React.FC<{
  width: number;
  images: string[];
  gap?: number;
}> = ({ width, images, gap = 0 }) => {
  return images.length ? (
    <Column style={{ gap }}>
      {images
        .filter(image => image)
        .map((image, index) => (
          <ImageZoom
            key={index}
            width={width}
            resizeMode={'contain'}
            uri={image}
          />
        ))}
    </Column>
  ) : null;
};

export default DetailImages;
