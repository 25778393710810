import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { useTheme } from 'react-native-paper';

import NoticeDetailContent from '../components/notice/NoticeDetailContent';
import Loading from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { useNoticeDetailQuery } from '../services/NoticeService';

const NoticeDetailScreen: React.FC = () => {
  const theme = useTheme();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute<RouteProp<MainStackParamList, 'NoticeDetail'>>();

  const noticeId = route.params.noticeId;

  useEffect(() => {
    if (navigationReady && !noticeId) {
      navigation.replace('MainTab', { screen: 'NoticeFeed' });
    }
  }, [navigationReady, noticeId]);

  const {
    data: notice,
    isLoading,
    isFetching,
    isFetched,
  } = useNoticeDetailQuery(noticeId);

  useEffect(() => {
    if (isFetched) {
      if (!notice) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'NoticeFeed' });
        }
      }
    }
  }, [notice]);

  const loading = isLoading || isFetching || !notice;
  return loading ? (
    <Loading />
  ) : (
    <View style={{ flex: 1 }}>
      <ScrollView overScrollMode={'never'}>
        <NoticeDetailContent notice={notice} theme={theme} />
      </ScrollView>
    </View>
  );
};

export default NoticeDetailScreen;
