import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { Text } from 'react-native-paper';
import { RefreshControl } from 'react-native-web-refresh-control';

import NotificationCard from '../components/notification/NotificationCard';
import { SmallLoading } from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { Notification } from '../repositories/NotificationRepository';
import {
  useNotificationsQuery,
  useReadNotificationMutation,
} from '../services/NotificationService';
import i18n from '../translations/i18n';
import { refetchQueries } from '../utils/queryClient';

const RemoveNotice: React.FC = () => (
  <Text style={{ paddingTop: 10, alignSelf: 'center' }}>
    {i18n.t('notification.removeNotice')}
  </Text>
);

const NotificationFeedScreen: React.FC<any> = () => {
  const user = useUser();
  const [refreshing, setRefreshing] = useState(false);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { mutateAsync } = useReadNotificationMutation();
  const {
    data: notifications,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = useNotificationsQuery({ notifier: user?.id });

  useEffect(() => {
    mutateAsync();
    return () => {
      refetchQueries('notification');
    };
  }, []);

  const onRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    mutateAsync();
    setRefreshing(false);
  };

  const onPressItem = (notification: Notification) => {
    switch (notification.resource_type) {
      case 'stories':
      case 'likes':
      case 'artist_stories':
      case 'place_stories':
        navigation.push('StoryDetail', { storyId: notification.resource_id });
        break;
      case 'comments':
        navigation.push('CommentDetail', {
          commentId: notification.resource_id,
        });
        break;
      case 'events':
        navigation.push('EventDetail', {
          eventId: notification.resource_id,
        });
        break;
    }
  };

  const loading = isLoading || isFetching;

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={notifications?.pages
        ?.map(({ notifications }) => notifications)
        .flat()}
      renderItem={({ item }) => (
        <NotificationCard
          key={item.id + Date.now()}
          notification={item!}
          onPress={onPressItem}
        />
      )}
      keyExtractor={(item, index) => `${item?.id}`}
      onEndReached={() => !loading && hasNextPage && fetchNextPage()}
      onEndReachedThreshold={0.5}
      ListFooterComponent={
        !refreshing && loading ? (
          <>
            <SmallLoading />
            <RemoveNotice />
          </>
        ) : (
          <RemoveNotice />
        )
      }
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    />
  );
};

export default NotificationFeedScreen;
