import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

export const shadowStyle = StyleSheet.create({
  shadow: {
    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 1,
  },
});

const IconButton: React.FC<{
  containerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<TextStyle>;
  shadow?: boolean;
  size?: number;
  tintColor?: string;
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  onPress?: () => void;
}> = ({
  containerStyle,
  style,
  shadow,
  size = 0,
  tintColor,
  icon,
  onPress,
}) => {
  return (
    <TouchableOpacity
      style={[
        {
          width: size,
          height: size,
          alignItems: 'center',
          justifyContent: 'center',
        },
        containerStyle,
      ]}
      onPress={onPress}
    >
      <MaterialCommunityIcons
        style={[shadow ? shadowStyle.shadow : undefined, style]}
        color={tintColor}
        name={icon}
        size={size}
      />
    </TouchableOpacity>
  );
};

export default IconButton;
