import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';
import { MD3Theme } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { UserAttendedEvent } from '../../repositories/UserRepository';
import { getDimensions } from '../../utils/dimensions';
import linkify from '../../utils/linkify';
import DetailImages from '../shared/DetailImages';
import RenderHtml from '../shared/RenderHtml';
import StarRating from '../shared/StarRating';
import EventCard from './EventCard';

const MARGIN = 20;

const UserAttendedEventDetailContent: React.FC<{
  theme: MD3Theme;
  userAttendedEvent: UserAttendedEvent;
  header?: React.ReactElement;
}> = ({ theme, userAttendedEvent, header }) => {
  const { width } = getDimensions();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const Html = React.memo(() => (
    <RenderHtml
      source={{ html: linkify(userAttendedEvent.description) }}
      style={{ paddingHorizontal: 0 }}
    />
  ));
  return (
    <View style={{ margin: MARGIN, gap: 10 }}>
      <EventCard
        event={userAttendedEvent.event}
        onPress={() =>
          navigation.push('EventDetail', {
            eventId: userAttendedEvent.attended_event_id,
          })
        }
      />
      <StarRating
        defaultRating={userAttendedEvent.rating ?? 0}
        editable={false}
      />
      <Html />
      <DetailImages
        width={width - MARGIN * 2}
        images={userAttendedEvent.image_urls ?? []}
        gap={10}
      />
    </View>
  );
};

export default UserAttendedEventDetailContent;
