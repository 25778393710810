import * as TrackingTransparency from 'expo-tracking-transparency';
import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';

import { getBuild } from './utils/codePush';

export function useAnalytics() {
  const hasPermission = async () => {
    if (Platform.OS !== 'ios') {
      return true;
    }
    return await getTrackingPermission();
  };
  const initAnalytics = useCallback(async () => {
    const permission = await hasPermission();
    if (permission) {
      require('./firebase');
      require('./sentry').init(permission, await getBuild());
    }
  }, []);

  useEffect(() => {
    initAnalytics();
  }, [initAnalytics]);
}

export async function getTrackingPermission(): Promise<boolean> {
  const { status: existingStatus } =
    await TrackingTransparency.getTrackingPermissionsAsync();
  let enabled = existingStatus === 'granted';
  if (!enabled) {
    enabled = await new Promise(resolve =>
      setTimeout(async () => {
        const { status } =
          await TrackingTransparency.requestTrackingPermissionsAsync();
        const enabled = status === 'granted';
        resolve(enabled);
      }, 500),
    );
  }
  return enabled;
}
