import { Image, ImageProps, ImageSource } from 'expo-image';
import React, { useEffect, useState } from 'react';
import { Image as NativeImage, View } from 'react-native';

import { SmallLoading } from './Loading';

const AutoHeightImage: React.FC<ImageProps & { width: number }> = props => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const uri =
    typeof props.source === 'object' && 'uri' in (props.source as object)
      ? (props.source as ImageSource).uri
      : undefined;
  if (uri) {
    NativeImage.getSize(uri, (width, height) =>
      setHeight(height * (props.width / width)),
    );
  } else {
    useEffect(() => setHeight(props.width));
  }
  return height ? (
    <Image
      {...props}
      style={[
        props.style,
        {
          width: props.width,
          height,
        },
      ]}
    />
  ) : (
    <View
      style={{
        width: props.width,
        aspectRatio: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SmallLoading />
    </View>
  );
};

export default AutoHeightImage;
