import { NavigationContainerRef } from '@react-navigation/core';
import { NavigationContainer } from '@react-navigation/native';
import { LinkingOptions } from '@react-navigation/native/lib/typescript/src/types';
import { useSessionContext } from '@supabase/auth-helpers-react';
import * as Linking from 'expo-linking';
import React, { useEffect, useRef, useState } from 'react';

import { showError } from '../components/shared/Toast';
import { logScreenView } from '../firebase';
import { Route, initRoute } from '../notification';
import { registerNavigationContainer } from '../sentry';
import { signOut } from '../supabase';
import i18n from '../translations/i18n';
import MainStack, { MainStackParamList, MainStackScreens } from './MainStack';
import NavigationReadyContext from './NavigationReadyContext';

const prefix = Linking.createURL('/');

const linking: LinkingOptions<MainStackParamList> = {
  prefixes: [
    'https://livewire.so',
    'https://*.livewire.so',
    'livewire-so://',
    prefix,
  ],
  config: {
    screens: MainStackScreens,
  },
};

export default function Navigation() {
  const { error } = useSessionContext();
  const [ready, setReady] = useState(false);
  const routeNameRef = useRef<string | undefined>();
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  const [initialRoute, setInitialRoute] = useState<Route>();
  initRoute(setInitialRoute);
  useEffect(() => {
    if (error) {
      showError(error);
      signOut();
    }
  }, [error]);
  return (
    <NavigationReadyContext.Provider value={ready}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        documentTitle={{
          formatter: (options, route) =>
            `${options?.title ?? route?.name} - ${i18n.t('common.appName')}`,
        }}
        onReady={() => {
          setReady(true);
          registerNavigationContainer(navigationRef);
          routeNameRef.current =
            navigationRef?.current?.getCurrentRoute?.()?.name;
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRoute = navigationRef?.current?.getCurrentRoute?.();
          const currentRouteName = currentRoute?.name;
          const currentRouteParams = currentRoute?.params;
          if (previousRouteName !== currentRouteName) {
            await logScreenView({
              name: currentRouteName,
              params: currentRouteParams,
            });
          }
          routeNameRef.current = currentRouteName;
        }}
      >
        {initialRoute && (
          <MainStack
            initialRouteName={initialRoute.screen as keyof MainStackParamList}
            initialRouteParams={initialRoute.params}
          />
        )}
      </NavigationContainer>
    </NavigationReadyContext.Provider>
  );
}
