import React from 'react';
import { Button, Text } from 'react-native-paper';

import i18n from '../../translations/i18n';
import { Column } from '../shared/Layouts';

export const EmptyEventCard: React.FC<{
  onPress: () => void;
}> = ({ onPress }) => (
  <Column style={{ flex: 1, gap: 10 }}>
    <Text>{i18n.t('event.emptyEventCard.not_matched')}</Text>
    <Text>{i18n.t('event.emptyEventCard.check_another')}</Text>
    <Button compact={true} mode={'contained'} onPress={onPress}>
      {i18n.t('event.emptyEventCard.change_filter')}
    </Button>
  </Column>
);

export const NoResultCard: React.FC = () => (
  <Column style={{ flex: 1 }}>
    <Text>{i18n.t('event.emptyEventCard.not_matched')}</Text>
  </Column>
);
