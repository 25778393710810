import React from 'react';
import { Platform, View } from 'react-native';

import i18n from '../../translations/i18n';
import { APP_URL } from '../../utils/constants';
import MoreButton from './MoreButton';
import ShareButton from './ShareButton';

const DetailHeaderRight: React.FC<{
  color?: string;
  title?: string;
  url?: string;
  share?: boolean;
  more?: boolean;
  onPressMore?: () => void;
}> = ({ color, title, url, share, more, onPressMore }) => (
  <View
    style={{
      paddingRight: Platform.select({
        default: 0,
        web: 10,
      }),
      gap: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {share && (
      <ShareButton
        title={title ?? i18n.t('common.appName')!}
        url={url ?? APP_URL}
        color={color}
        shadow={true}
      />
    )}
    {more && <MoreButton tintColor={color} onPress={onPressMore} />}
  </View>
);
export default DetailHeaderRight;
