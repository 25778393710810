import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { MD3Theme, Text, useTheme } from 'react-native-paper';
import styled from 'styled-components';

const Html = styled.div<{ theme: MD3Theme }>`
  color: ${({ theme }) => theme.colors.text};

  & a:link {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  & a:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const RenderHtml: React.FC<{
  source: { html: string };
  style?: StyleProp<ViewStyle>;
  fontSize?: number;
  contentWidth?: number;
}> = ({ source: { html }, style, fontSize = 16, contentWidth }) => {
  const theme = useTheme();
  return (
    <View style={[{ padding: 20 }, style]}>
      <Text style={{ fontSize, width: contentWidth }}>
        <Html theme={theme} dangerouslySetInnerHTML={{ __html: html }}></Html>
      </Text>
    </View>
  );
};

export default RenderHtml;
