import React from 'react';
import { Button, Dialog, Text } from 'react-native-paper';

import i18n from '../../translations/i18n';

const DefaultDialog: React.FC<{
  title?: string;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  confirmText?: string;
  dismissText?: string;
  onPressConfirm?: () => void;
  onPressDismiss?: () => void;
}> = ({
  title,
  content,
  actions,
  dismissText,
  confirmText,
  onPressConfirm,
  onPressDismiss,
}) => (
  <>
    {!!title && (
      <Dialog.Title>
        <Text style={{ fontWeight: 'bold' }} variant={'bodyLarge'}>
          {title}
        </Text>
      </Dialog.Title>
    )}
    {typeof content === 'string' ? (
      <Dialog.Content>
        <Text variant={'bodyLarge'}>{content}</Text>
      </Dialog.Content>
    ) : (
      content
    )}
    <Dialog.Actions>
      {actions ? (
        actions
      ) : (
        <>
          <Button onPress={onPressDismiss}>
            {dismissText ?? i18n.t('common.cancel')}
          </Button>
          {onPressConfirm && (
            <Button
              labelStyle={{
                fontWeight: 'bold',
              }}
              onPress={onPressConfirm}
            >
              {confirmText ?? i18n.t('common.confirm')}
            </Button>
          )}
        </>
      )}
    </Dialog.Actions>
  </>
);

export default DefaultDialog;
