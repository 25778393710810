import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { MD3Theme } from 'react-native-paper/src/types';
import styled from 'styled-components/native';

import { MainStackParamList } from '../../navigation/MainStack';
import { Profile } from '../../repositories/ProfileRepository';
import { Story } from '../../repositories/StoryRepository';
import { toDateTimeString } from '../../utils/datetimes';
import Dot from '../shared/Dot';
import ImageIconAvatar from '../shared/ImageIconAvatar';
import { Row } from '../shared/Layouts';

const Card = styled.View<{ theme: MD3Theme }>`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level2};
  border-bottom-width: 1px;
`;

const Content = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
  flex-wrap: wrap;
  flex: 1;
`;

const StoryProfileCard: React.FC<{
  story: Story;
  onPress?: (created: Profile) => void;
}> = ({ story, onPress }) => {
  if (!story?.created) {
    return null;
  }
  const created = story.created!;
  const theme = useTheme();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  return (
    <Card theme={theme}>
      <ImageIconAvatar size={50} uri={created.photo_url} icon={'account'} />
      <Content>
        <Text variant={'titleMedium'}>{created.name?.trim()}</Text>
        <Row
          style={{
            gap: 3,
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {!!story.district && (
            <>
              <TouchableOpacity
                onPress={() =>
                  navigation.push('MainTab', {
                    screen: 'StoryFeed',
                    params: { district: story.district },
                  })
                }
              >
                <Text
                  variant={'bodySmall'}
                  style={{ color: theme.colors.primary }}
                >
                  {story.district}
                </Text>
              </TouchableOpacity>
              <Dot style={{ color: theme.colors.elevation.level5 }} />
            </>
          )}
          {!!story.created_at && (
            <Text
              variant={'bodySmall'}
              style={{ color: theme.colors.elevation.level5 }}
            >
              {toDateTimeString(story.created_at)}
            </Text>
          )}
        </Row>
      </Content>
    </Card>
  );
};

export default StoryProfileCard;
