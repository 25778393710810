import { useActionSheet } from '@expo/react-native-action-sheet';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ImageBackground } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ArtistDetailBottomBar from '../components/artist/ArtistDetailBottomBar';
import ArtistDetailContent from '../components/artist/ArtistDetailContent';
import DefaultDialog from '../components/dialogs/DefaultDialog';
import { useDialog } from '../components/providers/DialogProvider';
import DetailHeaderRight from '../components/shared/DetailHeaderRight';
import Loading from '../components/shared/Loading';
import { showError, showInfo, showSuccess } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import ArtistRepository from '../repositories/ArtistRepository';
import ArtistService, {
  useArtistDetailQuery,
  useArtistRelatedNewsQuery,
  useDeleteArtistMutation,
} from '../services/ArtistService';
import { UserService, useUserProfileQuery } from '../services/UserService';
import i18n from '../translations/i18n';
import { APP_URL } from '../utils/constants';
import { getDimensions } from '../utils/dimensions';
import LocalStorage from '../utils/localStorage';

const ArtistDetailScreen: React.FC = () => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const route = useRoute<RouteProp<MainStackParamList, 'ArtistDetail'>>();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { showActionSheetWithOptions } = useActionSheet();
  const scrollViewRef = useRef<ScrollView>(null);
  const [sectionOffset, setSectionOffset] = useState<number>();
  const [isSaving, setIsSaving] = useState(false);

  const artistId = route.params.artistId;

  useEffect(() => {
    if (navigationReady && !artistId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, artistId]);

  const { data: user } = useUserProfileQuery();
  const { data: artist, isFetched } = useArtistDetailQuery(artistId);
  const { data: news } = useArtistRelatedNewsQuery(artistId);
  const { mutateAsync: deleteArtist } = useDeleteArtistMutation(artistId);
  const { showDialog, hideDialog } = useDialog();

  const onPressLike = async () => {
    if (!user) {
      showInfo(i18n.t('common.pleaseSignIn'));
      return;
    }
    if (!artist) {
      return;
    }
    const hasSaved = !!artist.liked?.length;
    setIsSaving(true);
    if (hasSaved) {
      await ArtistService.unlikeArtist(artist.id);
      showSuccess(i18n.t('artist.detailContent.unliked'));
    } else {
      await ArtistService.likeArtist(artist.id);
      const notification = await LocalStorage.getNotification();
      const recommendNotification =
        !notification && Platform.OS !== 'web'
          ? i18n.t('common.recommendNotification')
          : undefined;
      showSuccess(i18n.t('artist.detailContent.liked'), recommendNotification);
    }
    setIsSaving(false);
  };

  const onPressEdit = () => {
    let options = [i18n.t('common.cancel')];
    let destructiveButtonIndex: number[] = [];
    if (ArtistService.hasEditPermission(artist, user)) {
      options = [i18n.t('common.modify'), i18n.t('common.delete'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.delete'))];
    } else {
      options = [i18n.t('common.report'), i18n.t('common.block'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.report'))];
    }
    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        containerStyle: {
          paddingBottom: insets.bottom,
        },
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case options.indexOf(i18n.t('common.modify')): {
            navigation.push('ArtistEdit', { artistId: artist?.id });
            break;
          }
          case options.indexOf(i18n.t('common.delete')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.delete')}
                content={i18n.t('common.areYouSureYouWantToDeleteIt')}
                confirmText={i18n.t('common.delete')}
                onPressDismiss={hideDialog}
                onPressConfirm={async () => {
                  await deleteArtist();
                  showSuccess(i18n.t('common.itHasBeenDeleted'));
                  hideDialog();
                  if (navigation.canGoBack()) {
                    navigation.goBack();
                  } else {
                    navigation.replace('MainTab', { screen: 'EventFeed' });
                  }
                }}
              />,
            );
            break;
          }
          case options.indexOf(i18n.t('common.report')): {
            navigation.push('Report', {
              title: `${i18n.t('common.report')}: ${artist?.name!}`,
              type: 'artists',
              target: artistId,
            });
            break;
          }
          case options.indexOf(i18n.t('common.block')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.block')}
                content={i18n.t('common.blockConfirm', {
                  name: artist?.created?.name!,
                })}
                confirmText={i18n.t('common.block')}
                onPressConfirm={async () => {
                  try {
                    await UserService.blockUser(artist?.created?.id!);
                    hideDialog();
                    showInfo(i18n.t('common.blockSuccess'));
                  } catch (e) {
                    hideDialog();
                    showError(e);
                  }
                }}
                onPressDismiss={hideDialog}
              />,
            );
            break;
          }
          default:
        }
      },
    );
  };

  useEffect(() => {
    if (isFetched) {
      if (artist) {
        navigation.setOptions({
          title: `${artist.name}`,
          headerRight: ({ tintColor }: { tintColor?: string }) => (
            <DetailHeaderRight
              color={tintColor}
              title={i18n.t('artist.detailContent.check_out_profile', {
                name: artist.name,
                appName: i18n.t('common.appName') ?? 'Livewire',
              })}
              url={`${APP_URL}/artists/${artist!.id}`}
              share={true}
              more={!!user}
              onPressMore={() => onPressEdit()}
            />
          ),
        });
      }
      if (!artist) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'EventFeed' });
        }
      }
    }
  }, [artist, user]);

  useEffect(
    () => () => {
      if (user) {
        ArtistRepository.updateViewCount(artistId!);
      }
    },
    [],
  );
  useEffect(() => {
    if (sectionOffset) {
      scrollViewRef?.current?.scrollTo({ y: sectionOffset, animated: true });
    }
  }, [sectionOffset]);

  const { width, height } = getDimensions();
  const imageHeight = height / 3;
  return artist?.id ? (
    <View style={{ flex: 1, paddingBottom: insets.bottom }}>
      <ScrollView overScrollMode={'never'} ref={scrollViewRef}>
        <ImageBackground
          source={
            artist.photo_url
              ? { uri: artist.photo_url }
              : require('../../assets/images/event-placeholder.png')
          }
          style={{ width, height: imageHeight }}
        >
          <LinearGradient
            style={{
              width: '100%',
              height: '100%',
            }}
            colors={['rgba(0,0,0, 0.5)', 'transparent', 'rgba(0,0,0, 0.5)']}
          />
        </ImageBackground>
        <ArtistDetailContent
          section={route.params.section}
          setSectionOffset={setSectionOffset}
          artist={artist}
          news={news}
          theme={theme}
          isSaving={isSaving}
          onPressLike={onPressLike}
        />
      </ScrollView>
      <ArtistDetailBottomBar artist={artist} theme={theme} />
    </View>
  ) : (
    <Loading />
  );
};

export default ArtistDetailScreen;
