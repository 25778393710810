import React from 'react';

interface UpdateModalProps {
  isVisible: boolean;
  progress: number;
  error: boolean;
}

const UpdateModal: React.FC<UpdateModalProps> = () => {
  return <></>;
};

export default UpdateModal;
