import { useActionSheet } from '@expo/react-native-action-sheet';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Image } from 'expo-image';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import DefaultDialog from '../components/dialogs/DefaultDialog';
import EventDetailBottomBar from '../components/event/EventDetailBottomBar';
import EventDetailContent from '../components/event/EventDetailContent';
import EventDetailCover from '../components/event/EventDetailCover';
import { useDialog } from '../components/providers/DialogProvider';
import DetailHeaderRight from '../components/shared/DetailHeaderRight';
import Loading from '../components/shared/Loading';
import { showError, showInfo, showSuccess } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import EventRepository from '../repositories/EventRepository';
import EventService, {
  useDeleteEventMutation,
  useEventDetailQuery,
  useEventRelatedNewsQuery,
} from '../services/EventService';
import { UserService, useUserProfileQuery } from '../services/UserService';
import i18n from '../translations/i18n';
import { APP_URL } from '../utils/constants';
import { getDimensions } from '../utils/dimensions';

const EventDetailScreen: React.FC = () => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute<RouteProp<MainStackParamList, 'EventDetail'>>();
  const { showActionSheetWithOptions } = useActionSheet();
  const [isSaving, setIsSaving] = useState(false);

  const eventId = route.params.eventId;

  useEffect(() => {
    if (navigationReady && !eventId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, eventId]);

  const { data: user } = useUserProfileQuery();
  const { data: event, isFetched } = useEventDetailQuery(eventId);
  const { data: news } = useEventRelatedNewsQuery(eventId);
  const { mutateAsync: deleteEvent } = useDeleteEventMutation(eventId);
  const { showDialog, hideDialog } = useDialog();

  const onPressSave = async () => {
    if (!user) {
      showInfo(i18n.t('common.pleaseSignIn'));
      return;
    }
    if (!event) {
      return;
    }
    const hasSaved = !!event.saved?.length;
    setIsSaving(true);
    if (hasSaved) {
      await EventService.unsaveEvent(event.id);
    } else {
      await EventService.saveEvent(event.id);
    }
    setIsSaving(false);
  };

  const hasPermission = EventService.hasEditPermission(event, user);
  const onPressEdit = () => {
    let options = [i18n.t('common.cancel')];
    let destructiveButtonIndex: number[];
    if (hasPermission) {
      options = [i18n.t('common.modify'), i18n.t('common.delete'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.delete'))];
    } else {
      options = [i18n.t('common.report'), i18n.t('common.block'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.report'))];
    }
    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        containerStyle: {
          paddingBottom: insets.bottom,
        },
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case options.indexOf(i18n.t('common.modify')): {
            navigation.push('EventEdit', { eventId: event?.id });
            break;
          }
          case options.indexOf(i18n.t('common.delete')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.delete')}
                content={i18n.t('common.areYouSureYouWantToDeleteIt')}
                confirmText={i18n.t('common.delete')}
                onPressDismiss={hideDialog}
                onPressConfirm={async () => {
                  await deleteEvent();
                  showSuccess(i18n.t('common.itHasBeenDeleted'));
                  hideDialog();
                  if (navigation.canGoBack()) {
                    navigation.goBack();
                  } else {
                    navigation.replace('MainTab', { screen: 'EventFeed' });
                  }
                }}
              />,
            );
            break;
          }
          case options.indexOf(i18n.t('common.report')): {
            navigation.push('Report', {
              title: `${i18n.t('common.report')}: ${event?.title!}`,
              type: 'events',
              target: eventId,
            });
            break;
          }
          case options.indexOf(i18n.t('common.block')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.block')}
                content={i18n.t('common.blockConfirm', {
                  name: event?.created?.name!,
                })}
                confirmText={i18n.t('common.block')}
                onPressConfirm={async () => {
                  try {
                    await UserService.blockUser(event?.created?.id!);
                    hideDialog();
                    showInfo(i18n.t('common.blockSuccess'));
                  } catch (e) {
                    hideDialog();
                    showError(e);
                  }
                }}
                onPressDismiss={hideDialog}
              />,
            );
            break;
          }
          default:
        }
      },
    );
  };

  useEffect(() => {
    if (isFetched) {
      if (event) {
        navigation.setOptions({
          title: `${event.title}`,
          headerRight: ({ tintColor }: { tintColor?: string }) => (
            <DetailHeaderRight
              color={tintColor}
              title={`${event.title} - ${i18n.t('common.appName')}`}
              url={`${APP_URL}/events/${event.id}`}
              more={!!user}
              share={true}
              onPressMore={() => onPressEdit()}
            />
          ),
        });
      }
      if (!event) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'EventFeed' });
        }
      }
    }
  }, [event, user]);

  useEffect(
    () => () => {
      if (user) {
        EventRepository.updateViewCount(eventId!);
      }
    },
    [],
  );

  const { width, height } = getDimensions();
  const carouselHeight = height / 2.5;
  return event?.id ? (
    <View
      style={{
        flex: 1,
        paddingBottom: insets.bottom,
      }}
    >
      <ScrollView>
        {event.image_urls?.[0] ? (
          <EventDetailCover
            width={width}
            height={carouselHeight + insets.top}
            image={event.image_urls[0]}
          />
        ) : (
          <Image
            source={require('../../assets/images/event-placeholder.png')}
            style={{ width, height: carouselHeight }}
          />
        )}
        <EventDetailContent
          event={event}
          news={news}
          theme={theme}
          hasPermission={hasPermission}
        />
      </ScrollView>
      <EventDetailBottomBar
        event={event}
        theme={theme}
        isSaving={isSaving}
        onPressSave={onPressSave}
      />
    </View>
  ) : (
    <Loading />
  );
};

export default EventDetailScreen;
