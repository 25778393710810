import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import SettingActions from '../components/setting/SettingActions';
import { MainStackParamList } from '../navigation/MainStack';
import i18n from '../translations/i18n';
import { getBuild } from '../utils/codePush';
import { APP_BUILD, APP_VERSION } from '../utils/constants';

type SettingScreenProps = NativeStackScreenProps<MainStackParamList, 'Setting'>;

const SettingScreen: React.FC<SettingScreenProps> = () => {
  const theme = useTheme();
  const [build, setBuild] = useState(APP_BUILD);
  useEffect(() => {
    (async () => {
      setBuild(await getBuild());
    })();
  });
  return (
    <ScrollView style={{ flex: 1 }}>
      <SettingActions theme={theme} />
      <Text variant={'bodyLarge'} style={{ textAlign: 'center' }}>
        {i18n.t('common.version')}: {APP_VERSION} ({build})
      </Text>
    </ScrollView>
  );
};

export default SettingScreen;
