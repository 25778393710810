import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Image } from 'expo-image';
import React, { useState } from 'react';
import { MD3Theme, Text, useTheme } from 'react-native-paper';
import styled from 'styled-components/native';

import Loading from '../shared/Loading';

const ImageSelectorContainer = styled.ScrollView<{ theme: MD3Theme }>`
  width: 100%;
  padding-top: 5px;
`;

const SelectorWrapper = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 94px;
`;

const ImageWrapper = styled.View<{
  theme: MD3Theme;
  border?: boolean;
}>`
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-right: 8px;
  border-radius: 10px;
  ${({ border, theme }) =>
    border ? `border: solid 1px ${theme.colors.outline}` : ''};
`;

const ImageCounter = styled(Text)<{ theme: MD3Theme }>`
  position: absolute;
  bottom: 10px;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const RemoveIcon = styled.TouchableOpacity`
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
`;

const CoverLabel = styled.View<{ theme: MD3Theme }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};
`;

const ImagesSelector: React.FC<{
  images: string[];
  onAddImage: () => Promise<void>;
  onRemoveImage: (index: number) => void;
  size: number;
}> = ({ images, onAddImage, onRemoveImage, size }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <Loading indicator={false} />}
      <ImageSelectorContainer
        theme={theme}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {images.length < size && (
          <SelectorWrapper
            onPress={async () => {
              setLoading(true);
              await onAddImage();
              setLoading(false);
            }}
          >
            <ImageWrapper theme={theme} border>
              <MaterialCommunityIcons
                name={'camera-plus'}
                size={24}
                color={theme.colors.onSurface}
              />
              <ImageCounter
                theme={theme}
              >{`${images.length}/${size}`}</ImageCounter>
            </ImageWrapper>
          </SelectorWrapper>
        )}
        {images.map((image, index) => (
          <SelectorWrapper key={index}>
            <RemoveIcon onPress={() => onRemoveImage(index)}>
              <MaterialCommunityIcons
                name={'close-circle'}
                size={24}
                color={theme.colors.primaryContainer}
              />
            </RemoveIcon>
            <ImageWrapper theme={theme}>
              <Image
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 10,
                }}
                source={{ uri: image }}
                contentFit={'cover'}
              />
              {index === 0 && (
                <CoverLabel theme={theme}>
                  <Text>대표 이미지</Text>
                </CoverLabel>
              )}
            </ImageWrapper>
          </SelectorWrapper>
        ))}
      </ImageSelectorContainer>
    </>
  );
};

export default ImagesSelector;
