import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';

import EventCardList from '../components/event/EventCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';

const UserPostedEventsScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (navigationReady && !userId) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, userId]);

  return (
    <EventCardList
      postedUserId={userId}
      sort={'event_date_desc'}
      onPressItem={event =>
        navigation.push('EventDetail', { eventId: event.id })
      }
    />
  );
};

export default UserPostedEventsScreen;
