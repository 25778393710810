import React from 'react';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import DefaultToast, {
  BaseToast,
  ErrorToast,
  InfoToast,
} from 'react-native-toast-message';

import { captureException } from '../../sentry';
import i18n from '../../translations/i18n';

const TOP_OFFSET = 65;

const Toast: React.FC = () => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  return (
    <DefaultToast
      position={'top'}
      topOffset={insets.top + TOP_OFFSET}
      config={{
        success: props => (
          <BaseToast
            {...props}
            style={{
              borderLeftColor: theme.colors.onSurface,
              backgroundColor: theme.colors.surface,
            }}
            text1Props={{ numberOfLines: 2 }}
            text1Style={{ fontSize: 15, color: theme.colors.onSurface }}
            text2Props={{ numberOfLines: 3 }}
            text2Style={{ fontSize: 12, color: theme.colors.onSurface }}
          />
        ),
        info: props => (
          <InfoToast
            {...props}
            style={{
              borderLeftColor: theme.colors.primary,
              backgroundColor: theme.colors.surface,
            }}
            text1Props={{ numberOfLines: 2 }}
            text1Style={{ fontSize: 15, color: theme.colors.onSurface }}
            text2Props={{ numberOfLines: 3 }}
            text2Style={{ fontSize: 12, color: theme.colors.onSurface }}
          />
        ),
        error: props => (
          <ErrorToast
            {...props}
            style={{
              borderLeftColor: theme.colors.errorContainer,
              backgroundColor: theme.colors.surface,
            }}
            text1Props={{ numberOfLines: 2 }}
            text1Style={{ fontSize: 15, color: theme.colors.onSurface }}
            text2Props={{ numberOfLines: 3 }}
            text2Style={{ fontSize: 12, color: theme.colors.onSurface }}
          />
        ),
      }}
    />
  );
};

export const showInfo = (message: string, extra?: string) => {
  DefaultToast.show({
    position: 'top',
    type: 'info',
    text1: message,
    text2: extra,
  });
};

export const showSuccess = (message: string, extra?: string) => {
  DefaultToast.show({
    position: 'top',
    type: 'success',
    text1: message,
    text2: extra,
  });
};

export const showError = (error?: unknown, extraMessage?: string) => {
  if (!error) {
    return;
  }
  let message = i18n.t('common.defaultError');
  if (typeof error === 'string') {
    message = error;
  }
  if (error instanceof Error) {
    if (!extraMessage) {
      extraMessage = i18n.t('common.defaultErrorExtra');
    }
  }
  if (typeof error === 'object') {
    if ('code' in error) {
      switch (error.code) {
        case 'P0001': {
          if ('message' in error && typeof error.message === 'string') {
            if (error.message?.startsWith('i18n')) {
              const messages = error.message.split(',');
              message = i18n.t(messages[1], { keyword: messages[2] });
              extraMessage = undefined;
            }
          }
          break;
        }
        case '42501': {
          message = i18n.t('common.doNotHavePermission');
          extraMessage = undefined;
          break;
        }
        case 'ECONNABORTED': {
          message = i18n.t('common.networkError');
          extraMessage = i18n.t('common.networkErrorExtra');
          break;
        }
      }
    }
  }
  DefaultToast.show({
    type: 'error',
    text1: message,
    text2: extraMessage,
  });
  if (typeof error !== 'string') {
    sendError(error);
  }
};

export const sendError = (error?: unknown) => {
  console.error(error);
  captureException(error);
};

export default Toast;
