import React, { useState } from 'react';
import { useTheme } from 'react-native-paper';
import { StyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import { TextStyle } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

import PlaceService from '../../services/PlaceService';
import i18n from '../../translations/i18n';
import { LatLng } from '../map';
import DistrictList from '../place/DistrictList';
import Loading from '../shared/Loading';
import { showError } from '../shared/Toast';
import { FormAutoCompleteSelect } from './FormInputs';

export const DistrictSelector: React.FC<{
  style?: StyleProp<TextStyle>;
  value: string;
  onSelect: (district: string, location: LatLng) => object;
  children: React.ReactNode;
}> = ({ style, value: defaultValue, onSelect, children }) => {
  const theme = useTheme();
  const [value, setValue] = useState<string>(defaultValue);
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <Loading />}
      <FormAutoCompleteSelect
        displaySuggestion
        theme={theme}
        style={[{ width: '80%' }, style]}
        label={i18n.t('event.feed.district')}
        icon={'map-marker-check-outline'}
        onSelectItem={async item => {
          setLoading(true);
          const district = item.name;
          try {
            const location = await PlaceService.getLocationByAddress(item.name);
            onSelect(district, location!);
          } catch (error) {
            showError(error);
          }
          setLoading(false);
        }}
        onChange={setValue}
        value={value ?? ''}
        ListComponent={DistrictList}
      >
        {children}
      </FormAutoCompleteSelect>
    </>
  );
};

export default DistrictSelector;
