import { NavigationProp } from '@react-navigation/core/src/types';
import { useNavigation } from '@react-navigation/native';
import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import { useUser } from '@supabase/auth-helpers-react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';

import { Event, EventsParams } from '../../repositories/EventRepository';
import { useEventsQuery } from '../../services/EventService';
import { UserData } from '../../services/UserService';
import i18n from '../../translations/i18n';
import { DEFAULT_DELTA, deltaToKm } from '../../utils/locationHelpers';
import { SmallLoading } from '../shared/Loading';
import { NoResultCard } from './EmptyEventCard';
import EventCard from './EventCard';

const EventCardList: React.FC<
  EventsParams & {
    header?: React.ReactElement;
    district?: string;
    onPressItem: (event: Event) => void;
    onRefresh?: () => Promise<void>;
    emptyCard?: React.ReactElement;
  }
> = ({ header, district, onPressItem, onRefresh, emptyCard, ...params }) => {
  const user = useUser();
  const userData: UserData | undefined = user?.user_metadata;
  const {
    data: events,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = useEventsQuery(params);
  const [refreshing, setRefreshing] = useState(false);
  let navigation: NavigationProp<ReactNavigation.RootParamList> | undefined;
  try {
    navigation = useNavigation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {}
  const ref = useRef<FlatList>(null);

  if (navigation) {
    useScrollToTop(ref);
  }

  useEffect(() => {
    const shortDistrictName = _.last(
      (district ?? userData?.district)?.split(' '),
    );
    const districtAndDistance = [
      shortDistrictName,
      `${deltaToKm(params.region?.latitudeDelta ?? DEFAULT_DELTA)}km`,
    ].filter(value => value);
    let title = `${i18n.t('common.events')} (${districtAndDistance.join(
      ', ',
    )})`;
    if (params.artistId) {
      const event = events?.pages?.[0]?.events?.[0] as Event;
      title = `${event?.artists?.[0]?.name ?? ''} ${i18n.t('common.events')}`;
    }
    if (params.agencyId) {
      const event = events?.pages?.[0]?.events?.[0] as Event;
      title = i18n.t('artist.detailContent.agencyEvent', {
        name: event?.agencies?.[0]?.name ?? '',
      });
    }
    if (params.placeId) {
      const event = events?.pages?.[0]?.events?.[0] as Event;
      title = `${event?.place?.name ?? ''} ${i18n.t('common.events')}`;
    }
    if (params.savedUserId) {
      title = i18n.t('profile.actions.savedEvents');
    }
    if (params.attendedUserId) {
      title = i18n.t('profile.actions.attendedEvents');
    }
    if (params.postedUserId) {
      title = i18n.t('profile.actions.postedEvents');
    }
    navigation?.setOptions({
      title,
    });
  }, [isFetching, district]);

  const handleRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    if (onRefresh) {
      await onRefresh();
    }
    setRefreshing(false);
  };

  const loading = isLoading || isFetching;
  const empty = !!events?.pages?.[0]?.end;
  return empty ? (
    emptyCard ? (
      emptyCard
    ) : (
      <>
        {header && header}
        <NoResultCard />
      </>
    )
  ) : (
    <FlatList
      ref={ref}
      showsVerticalScrollIndicator={false}
      data={events?.pages?.map(({ events }) => events).flat()}
      renderItem={({ item }) => (
        <EventCard
          key={item?.id}
          event={item!}
          onPress={item => onPressItem(item)}
        />
      )}
      keyExtractor={(item, index) => `${item?.id}${index}`}
      onEndReached={() => !loading && hasNextPage && fetchNextPage()}
      onEndReachedThreshold={0.5}
      ListHeaderComponent={refreshing || loading ? null : header}
      ListFooterComponent={!refreshing && loading ? <SmallLoading /> : null}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
      }
    />
  );
};

export default EventCardList;
