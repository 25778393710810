import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React from 'react';
import { Platform, View } from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import SharedIconButton from '../components/shared/IconButton';
import EventFeedScreen from '../screens/EventFeedScreen';
import PlaceListScreen from '../screens/PlaceListScreen';
import ProfileScreen from '../screens/ProfileScreen';
import SearchScreen from '../screens/SearchScreen';
import StoryFeedScreen from '../screens/StoryFeedScreen';
import {
  useEventNotificationCountQuery,
  useStoryNotificationCountQuery,
} from '../services/NotificationService';
import i18n from '../translations/i18n';
import { MainStackParamList } from './MainStack';
import { resetTab } from './navigations';

export type MainTabParamList = {
  EventFeed: {
    sort?: 'event_date' | 'new' | 'distance';
  };
  StoryFeed: {
    sort?: 'new' | 'likes' | 'comments';
    eventId?: string;
    artistId?: string;
    placeId?: string;
    district?: string;
  };
  Places: {
    placeId?: string;
  };
  Search: undefined;
  Profile: undefined;
};

export const MainTabScreens = {
  screens: {
    EventFeed: 'events',
    StoryFeed: 'stories',
    Places: 'places',
    Search: 'search',
    Profile: 'profile',
  },
};

const Tab = createBottomTabNavigator<MainTabParamList>();

type HeaderButton = ({
  navigation,
}: {
  navigation: NativeStackNavigationProp<MainStackParamList>;
}) => (props: {
  tintColor?: string | undefined;
  pressColor?: string | undefined;
  pressOpacity?: number | undefined;
}) => React.ReactNode;

const DefaultBackButton: HeaderButton =
  ({ navigation }) =>
  ({ tintColor }) =>
    navigation.canGoBack() ? (
      <SharedIconButton
        tintColor={tintColor}
        icon={'chevron-left'}
        size={40}
        onPress={() => navigation.goBack()}
      />
    ) : null;

const tabs: {
  name: keyof MainTabParamList;
  badge?: boolean;
  headerShown: boolean;
  unmountOnBlur?: boolean;
  headerLeft?: HeaderButton;
  headerRight?: HeaderButton;
  component: React.ComponentType<any>;
  focusedIcon: React.ComponentProps<typeof MaterialCommunityIcons>['name'];
  unfocusedIcon: React.ComponentProps<typeof MaterialCommunityIcons>['name'];
}[] = [
  {
    name: 'EventFeed',
    badge: true,
    headerShown: true,
    // unmountOnBlur: true,
    // headerLeft: DefaultBackButton,
    headerRight:
      () =>
      ({ tintColor }) =>
        (
          <IconButton
            size={23}
            icon={'filter-check-outline'}
            iconColor={tintColor}
          />
        ),
    component: EventFeedScreen,
    focusedIcon: 'calendar',
    unfocusedIcon: 'calendar-blank-outline',
  },
  {
    name: 'StoryFeed',
    badge: true,
    headerShown: true,
    // unmountOnBlur: true,
    headerLeft: DefaultBackButton,
    component: StoryFeedScreen,
    focusedIcon: 'text-box',
    unfocusedIcon: 'text-box-outline',
  },
  {
    name: 'Places',
    headerShown: true,
    // unmountOnBlur: true,
    headerLeft: DefaultBackButton,
    component: PlaceListScreen,
    focusedIcon: 'map-marker',
    unfocusedIcon: 'map-marker-outline',
  },
  {
    name: 'Search',
    headerShown: false,
    unmountOnBlur: true,
    component: SearchScreen,
    focusedIcon: 'magnify',
    unfocusedIcon: 'magnify',
  },
  {
    name: 'Profile',
    headerShown: true,
    headerRight:
      ({ navigation }) =>
      ({ tintColor }) =>
        (
          <IconButton
            size={23}
            icon={'cog'}
            iconColor={tintColor}
            onPress={() => navigation.push('Setting')}
          />
        ),
    component: ProfileScreen,
    focusedIcon: 'account',
    unfocusedIcon: 'account-outline',
  },
];

const MainTab: React.FC = () => {
  const user = useUser();
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { data: eventNotifications } = useEventNotificationCountQuery(user);
  const { data: storyNotifications } = useStoryNotificationCountQuery(user);
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <Tab.Navigator
        screenOptions={{
          headerStyle: {
            height: 55 + insets.top,
            shadowColor: theme.colors.elevation.level4,
            borderBottomColor: theme.colors.elevation.level3,
            backgroundColor: theme.colors.background,
          },
          headerTintColor: theme.colors.onBackground,
          headerTitleStyle: {
            fontWeight: 'bold',
          },
          tabBarActiveTintColor: theme.colors.onBackground,
          tabBarInactiveTintColor: theme.colors.onSurfaceDisabled,
          tabBarStyle: {
            height: 55 + insets.bottom,
            borderTopColor: theme.colors.elevation.level3,
            backgroundColor: theme.colors.background,
          },
          tabBarShowLabel: false,
        }}
        sceneContainerStyle={{
          backgroundColor: theme.colors.background,
        }}
      >
        {tabs.map(
          ({
            name,
            component,
            badge,
            headerShown,
            unmountOnBlur,
            headerLeft,
            headerRight,
            focusedIcon,
            unfocusedIcon,
          }) => (
            <Tab.Screen
              key={name}
              name={name}
              component={component}
              options={{
                title: i18n.t(`tab.${name}`),
                tabBarBadge:
                  ((name === 'EventFeed' && eventNotifications) ||
                    (name === 'StoryFeed' && storyNotifications)) &&
                  badge
                    ? ''
                    : undefined,
                tabBarBadgeStyle: {
                  top: Platform.select({
                    ios: 14,
                    android: 13,
                    web: 11,
                  }),
                  left: 5,
                  minWidth: 10,
                  width: 10,
                  height: 10,
                  fontSize: 1,
                  borderRadius: 5,
                },
                headerShown,
                unmountOnBlur,
                headerLeft: headerLeft?.({ navigation }),
                headerRight: headerRight?.({ navigation }),
                tabBarIcon: ({ focused, color, size }) => (
                  <MaterialCommunityIcons
                    name={focused ? focusedIcon : unfocusedIcon}
                    color={color}
                    size={size + 3}
                  />
                ),
              }}
              listeners={({ navigation, route }) => ({
                tabPress: e => {
                  if (navigation.isFocused()) {
                    return;
                  }
                  if (route.name === 'StoryFeed' || route.name === 'Places') {
                    e.preventDefault();
                    navigation.getParent().dispatch(resetTab(route.name));
                  }
                },
              })}
            />
          ),
        )}
      </Tab.Navigator>
    </View>
  );
};

export default MainTab;
