import { Region } from '../components/map';
import supabase from '../supabase';
import { Database } from '../types/database';
import http from '../utils/http';
import { deltaToKm, getNearby } from '../utils/locationHelpers';
import { Artist } from './ArtistRepository';
import { Place } from './PlaceRepository';
import { Profile } from './ProfileRepository';
import UserRepository, {
  UserAttendedEvent,
  UserBlockedUser,
} from './UserRepository';

type EventResponse = Awaited<ReturnType<typeof EventRepository.getEvent>>;
type EventResponseSuccess = EventResponse['data'];
type UserSavedEvent = Database['public']['Tables']['users_saved_events']['Row'];

export interface Event
  extends Omit<
    NonNullable<EventResponseSuccess>,
    'artists' | 'agencies' | 'place' | 'saved' | 'attended' | 'created'
  > {
  artists?: Artist[];
  agencies?: Artist[];
  place?: Place;
  saved?: UserSavedEvent[];
  attended?: UserAttendedEvent[];
  created?: Profile & {
    blocked?: UserBlockedUser[];
  };
}

export enum EventStatus {
  RUNNING = 'RUNNING',
  SOLD_OUT = 'SOLD_OUT',
  CANCELED = 'CANCELED',
}

export enum EventPaymentType {
  PAID = 'PAID',
  FREE = 'FREE',
  FREE_DONATE = 'FREE_DONATE',
}

export interface EventsParams {
  artistId?: string;
  agencyId?: string;
  placeId?: string;
  savedUserId?: string;
  attendedUserId?: string;
  postedUserId?: string;
  region?: Region;
  searchQuery?: string;
  sort?: 'event_date' | 'event_date_desc' | 'new' | 'distance' | 'price';
  offset?: number;
}

export interface EventStats {
  audience: number;
  revenue: number;
}

export default class EventRepository {
  private static table = () => supabase.from('events');
  private static tableArtists = () => supabase.from('events_artists');
  private static tableAgencies = () => supabase.from('events_agencies');

  static PAGE_SIZE = 20;

  static async getEvent(eventId: string) {
    const user = await UserRepository.getCurrentUser();
    const query = this.table()
      .select(
        '*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies(*), saved:users_saved_events(*), attended:users_attended_events(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))',
      )
      .eq('display', true)
      .eq('id', eventId);
    if (user?.id) {
      query.eq('saved.user_id', user?.id);
      query.eq('attended.user_id', user?.id);
    }
    return query.maybeSingle();
  }

  static async getClosestPlaceEvents(placeId: string) {
    return this.table()
      .select(
        '*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies(*)',
      )
      .eq('place_id', placeId)
      .eq('display', true)
      .gte('event_date', new Date().toISOString())
      .order('event_date')
      .limit(3);
  }

  private static getDefaultQuery() {
    return this.table().select(
      `*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
    );
  }

  private static getArtistQuery(artistId: string) {
    return this.table()
      .select(
        `*, place:places(*), artists!events_artists!inner(*), agencies:artists!events_agencies(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
      )
      .eq('artists.id', artistId);
  }

  private static getAgencyQuery(agencyId: string) {
    return this.table()
      .select(
        `*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies!inner(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
      )
      .eq('agencies.id', agencyId);
  }

  private static getUserSavedQuery(userId: string) {
    return this.table()
      .select(
        `*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies(*), saved:users_saved_events!inner(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
      )
      .eq('saved.user_id', userId);
  }

  private static getUserAttendedQuery(userId: string) {
    return this.table()
      .select(
        `*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies(*), attended:users_attended_events!inner(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
      )
      .eq('attended.user_id', userId);
  }

  private static getUserPostedQuery(userId: string) {
    return this.table()
      .select(
        `*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
      )
      .eq('created_by', userId);
  }

  private static getRegionQuery(region: Region) {
    const nears = getNearby(region);
    return this.table()
      .select(
        `*, place:places!inner(*), artists!events_artists(*), agencies:artists!events_agencies(*), created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))`,
      )
      .gte('place.latitude', nears.lesser.latitude)
      .gte('place.longitude', nears.lesser.longitude)
      .lte('place.latitude', nears.greater.latitude)
      .lte('place.longitude', nears.greater.longitude)
      .gte('event_date', new Date().toISOString());
  }

  static async getEvents({
    artistId,
    agencyId,
    placeId,
    savedUserId,
    attendedUserId,
    postedUserId,
    region,
    searchQuery,
    sort,
    offset = 0,
  }: EventsParams) {
    let query = this.getDefaultQuery();
    if (artistId) {
      query = this.getArtistQuery(artistId);
    }
    if (agencyId) {
      query = this.getAgencyQuery(agencyId);
    }
    if (savedUserId) {
      query = this.getUserSavedQuery(savedUserId);
    }
    if (attendedUserId) {
      query = this.getUserAttendedQuery(attendedUserId);
    }
    if (postedUserId) {
      query = this.getUserPostedQuery(postedUserId);
    }
    if (region) {
      query = this.getRegionQuery(region);
    }
    if (placeId) {
      query.eq('place_id', placeId);
    }
    if (searchQuery) {
      query
        .or(`title.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%`)
        .order('event_date', { ascending: false });
    }
    switch (sort) {
      case 'new':
        query.order('created_at', { ascending: false });
        break;
      case 'event_date_desc':
        query.order('event_date', { ascending: false });
        break;
      case 'price':
        query
          .order('advance_ticket_price', { nullsFirst: true })
          .order('event_date', { ascending: false });
        break;
      default:
        query.order('event_date');
        break;
    }

    return query
      .eq('display', true)
      .order('id')
      .range(offset, offset + EventRepository.PAGE_SIZE - 1);
  }

  static async getUpcomingEvents(region: Region) {
    const query = this.getRegionQuery(region);
    return query.eq('display', true).not('artists', 'is', 'null').range(0, 100);
  }

  static async getEventsWithDistance({ region, offset = 0 }: EventsParams) {
    try {
      const query = supabase.rpc('events_distance', {
        lat: region!.latitude,
        lng: region!.longitude,
        distance_limit: deltaToKm(region!.latitudeDelta) * 1000,
        page_offset: offset,
        page_limit: offset + EventRepository.PAGE_SIZE - 1,
      });
      const { data } = await query;
      const events = (
        data as unknown as {
          event: Event;
          place: string;
          artists: string[];
        }[]
      )
        .map(({ event, place, artists }) => ({
          ...event,
          place: { name: place },
          artists: artists.filter(name => name).map(name => ({ name })),
        }))
        .filter(event => !!event.display);
      return { data: events };
    } catch (e) {
      throw e;
    }
  }

  static async upsertEvent(event: Event) {
    return this.table()
      .upsert(event)
      .select(
        '*, place:places(*), artists!events_artists(*), agencies:artists!events_agencies(*)',
      )
      .maybeSingle();
  }

  static async deleteEventArtists(eventId: string) {
    return this.tableArtists().delete().eq('event_id', eventId);
  }

  static async upsertEventArtists(
    eventArtists: { event_id: string; artist_id: string }[],
  ) {
    return this.tableArtists().upsert(eventArtists);
  }

  static async deleteEventAgencies(eventId: string) {
    return this.tableAgencies().delete().eq('event_id', eventId);
  }

  static async upsertEventAgencies(
    eventAgencies: { event_id: string; agency_id: string }[],
  ) {
    return this.tableAgencies().upsert(eventAgencies);
  }

  static async deleteEvent(eventId: string) {
    return this.table().update({ display: false }).eq('id', eventId);
  }

  static async updateViewCount(eventId: string) {
    try {
      http.post(`/api/counts/events/${eventId}/views`);
    } catch {}
  }

  static async getEventStats(eventId: string): Promise<EventStats | undefined> {
    try {
      const { data } = await http.get<EventStats>(
        `/api/events/${eventId}/stats`,
      );
      return data;
    } catch {}
  }
}
