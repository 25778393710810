import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';

import ArtistCardList from '../components/artist/ArtistCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';

const UserPostedArtistsScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (navigationReady && !userId) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, userId]);

  return (
    <ArtistCardList
      postedUserId={userId}
      onPressItem={artist =>
        navigation.push('ArtistDetail', { artistId: artist.id })
      }
    />
  );
};

export default UserPostedArtistsScreen;
