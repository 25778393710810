import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Button, useTheme } from 'react-native-paper';
import styled from 'styled-components/native';

import { MainStackParamList } from '../../navigation/MainStack';
import { Event } from '../../repositories/EventRepository';
import { useUpsertEventMutation } from '../../services/EventService';
import i18n from '../../translations/i18n';
import { ResourceType } from '../../utils/enums';
import { getId } from '../../utils/id';
import { uploadImage } from '../../utils/upload';
import { Column, Row } from '../shared/Layouts';
import Loading from '../shared/Loading';
import { showError, showSuccess } from '../shared/Toast';
import {
  EventAdvanceTicketPrice,
  EventAgencies,
  EventArtists,
  EventDate,
  EventDescription,
  EventDoorTicketPrice,
  EventFormDivider,
  EventImages,
  EventPaymentType,
  EventPlace,
  EventStatus,
  EventTicketCount,
  EventTicketUrl,
  EventTime,
  EventTitle,
  EventYoutubeUrl,
} from './EventFormFields';

const EventFormContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
`;

export type EventFormInputs = Event & {
  artist?: string;
  agency?: string;
};

export type EventFormType = UseFormReturn<EventFormInputs>;

const EventForm: React.FC<{ event: Partial<Event> }> = ({ event }) => {
  const theme = useTheme();
  const edit = !!event.id;
  const [uploading, setUploading] = useState<boolean>(false);
  const { mutateAsync: upsertEvent, isLoading } = useUpsertEventMutation();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  const form = useForm<EventFormInputs>({
    defaultValues: event,
  });

  const {
    handleSubmit,
    setFocus,
    formState: { isDirty, dirtyFields },
  } = form;

  const onSubmit = async ({ artist, agency, ...event }: EventFormInputs) => {
    if (edit && !isDirty) {
      return;
    }
    if (!event.id) {
      event.id = getId();
    }
    try {
      setUploading(true);
      if (event.image_urls?.length && dirtyFields.image_urls) {
        const uploads = event.image_urls.map(url =>
          url.startsWith('http')
            ? Promise.resolve(url)
            : uploadImage(url, ResourceType.EVENT, event.id),
        );
        event.image_urls = await Promise.all(uploads);
      }
    } catch (error) {
      showError(error);
      return error;
    } finally {
      setUploading(false);
    }

    try {
      setUploading(true);
      const result = await upsertEvent(event);
      showSuccess(
        edit
          ? i18n.t('common.form.modified')
          : i18n.t('common.form.registered'),
      );
      if (result?.id) {
        if (edit) {
          navigation.navigate('EventDetail', { eventId: result.id });
        } else {
          navigation.replace('EventDetail', { eventId: result.id });
        }
      }
    } catch (error) {
      showError(error);
      return error;
    } finally {
      setUploading(false);
    }
  };

  const onError = (errors: FieldErrors) => {
    const errorNames: (keyof FieldErrors)[] = Object.keys(errors);
    const hasError = !!errorNames.length;
    if (hasError) {
      showError(i18n.t('common.form.checkInput'));
      setFocus(errorNames[0] as keyof EventFormInputs);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          disabled={edit && !isDirty}
          labelStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={handleSubmit(onSubmit, onError)}
        >
          {edit ? i18n.t('common.save') : i18n.t('common.post')}
        </Button>
      ),
    });
  }, [isDirty]);

  const showLoading = uploading || isLoading;
  return (
    <EventFormContainer>
      {showLoading && <Loading />}
      <EventImages theme={theme} form={form} />
      <EventFormDivider theme={theme} />
      <EventTitle theme={theme} form={form} />
      <EventArtists theme={theme} form={form} />
      <EventAgencies theme={theme} form={form} />
      <EventPlace theme={theme} form={form} />
      <Row>
        <EventDate theme={theme} form={form} />
        <EventTime theme={theme} form={form} />
      </Row>
      <Row>
        <EventAdvanceTicketPrice theme={theme} form={form} />
        <EventDoorTicketPrice theme={theme} form={form} />
      </Row>
      <Row>
        <EventTicketCount theme={theme} form={form} />
        <Column style={{ flex: 6 }}>
          <EventPaymentType theme={theme} form={form} />
          <EventStatus theme={theme} form={form} />
        </Column>
      </Row>
      <EventFormDivider theme={theme} />
      <EventTicketUrl theme={theme} form={form} />
      <EventYoutubeUrl theme={theme} form={form} />
      <EventDescription theme={theme} form={form} />
    </EventFormContainer>
  );
};

export default EventForm;
