import { I18n } from 'i18n-js';

import messages from './index';

export const DEFAULT_LOCALE = 'ko';

const i18n = new I18n(messages);

i18n.locale = DEFAULT_LOCALE;
i18n.enableFallback = true;

export default i18n;
