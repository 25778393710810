import React from 'react';
import { Button, Text } from 'react-native-paper';

import i18n from '../../translations/i18n';
import { Column } from '../shared/Layouts';

export const EmptyStoryCard: React.FC<{
  eventTotal: boolean;
  onPressReset: () => void;
  onPressAdd: () => void;
}> = ({ eventTotal, onPressReset, onPressAdd }) => (
  <Column style={{ flex: 1, gap: 10, marginTop: 20 }}>
    <Text>{i18n.t('story.cardList.noStory')}</Text>
    <Button
      style={{ width: 100 }}
      compact={true}
      mode={'contained'}
      onPress={onPressAdd}
    >
      {i18n.t('story.cardList.write')}
    </Button>
    {eventTotal && (
      <Button
        style={{ width: 100 }}
        compact={true}
        mode={'contained-tonal'}
        onPress={onPressReset}
      >
        {i18n.t('story.cardList.total')}
      </Button>
    )}
  </Column>
);
