import Constants from 'expo-constants';

const EXTRA = Constants?.expoConfig?.extra;

export const WEB_MAX_WIDTH = 480;
export const APP_NAME = Constants.expoConfig?.name;
export const APP_VERSION = Constants.expoConfig?.version;
export const APP_BUILD = EXTRA?.build;
export const ENV = EXTRA?.env;
export const WEB_URL = EXTRA?.webUrl;
export const APP_URL = EXTRA?.appUrl;
export const API_URL = EXTRA?.apiUrl;
export const SUPABASE_URL = EXTRA?.supabase?.url;
export const SUPABASE_ANON_KEY = EXTRA?.supabase?.anonKey;
export const GOOGLE_MAP_WEB_API_KEY =
  Constants.expoConfig?.web?.config?.googleMaps?.apiKey;
export const FIREBASE_WEB_CONFIG = Constants.expoConfig?.web?.config?.firebase;
export const SENTRY = EXTRA?.sentry;
