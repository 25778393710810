export const REGEXP_URL =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
export const REGEXP_EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const REGEXP_PHONE =
  /(0\d{1,2})-?\d{3,4}-?\d{4}|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/;
export const REGEXP_NUMBER = /^\d+$/;

export function contactType(value: string): 'email' | 'phone' | undefined {
  if (REGEXP_EMAIL.test(value)) {
    return 'email';
  }
  if (REGEXP_PHONE.test(value)) {
    return 'phone';
  }
}

export function getContactLink(value: string): string {
  const type = contactType(value);
  switch (type) {
    case 'phone':
      return `tel:${value}`;
    case 'email':
      return `mailto:${value}`;
    default:
      return '';
  }
}

const SPOTIFY_REGEXP = /\/artist\/(.*)/;
export function extractSpotifyId(url: string): string {
  const match = url.match(SPOTIFY_REGEXP);
  if (match?.[1]) {
    return match[1];
  }
  return '';
}

const YOUTUBE_REGEXP = /youtube\.com\/channel\/([a-zA-Z0-9_-]+)/;
export function extractYoutubeId(url: string): string {
  const match = url.match(YOUTUBE_REGEXP);
  let matched = match?.[1];
  if (matched) {
    if (matched.startsWith('UC')) {
      matched = matched.replace('UC', '');
    }
    if (matched.startsWith('UU')) {
      matched = matched.replace('UU', '');
    }
    return matched;
  }
  return '';
}
