import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ImageBackground } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Pressable } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { MD3Theme } from 'react-native-paper/src/types';
import styled from 'styled-components/native';

import { Story } from '../../repositories/StoryRepository';
import { toDateTimeString } from '../../utils/datetimes';
import { getDimensions } from '../../utils/dimensions';
import { ChipContainer } from '../shared/Chip';
import Dot from '../shared/Dot';
import ImageIconAvatar from '../shared/ImageIconAvatar';
import { Row } from '../shared/Layouts';

const Card = styled.View<{ theme: MD3Theme }>`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  padding: 15px 0;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level3};
  border-bottom-width: 1px;
`;

const Content = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  flex-wrap: wrap;
  flex: 1;
  gap: 5px;
`;

const StoryCard: React.FC<{
  story: Story;
  thumbnail?: boolean;
  onPress: (story: Story) => void;
}> = ({ story, thumbnail, onPress }) => {
  const theme = useTheme();
  const { width } = getDimensions();
  const uri = story?.image_urls?.[0];
  const thumbnailUri = uri?.replace('/images/', '/images/thumbs/');
  return (
    story && (
      <Pressable onPress={() => onPress(story)}>
        <Card theme={theme}>
          <Content>
            {thumbnail && thumbnailUri ? (
              <ImageBackground
                style={{ width: '100%', height: width / 2 }}
                source={{ uri: thumbnailUri }}
                contentFit={'cover'}
              >
                <LinearGradient
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  colors={[
                    'rgba(0,0,0, 0.5)',
                    'transparent',
                    'rgba(0,0,0, 0.5)',
                  ]}
                />
              </ImageBackground>
            ) : null}
            <Text variant="bodyLarge" style={{ fontWeight: 'bold' }}>
              {story.title?.trim()}
            </Text>
            {(story.event || story.artist || story.place) && (
              <ChipContainer>
                {story.event && (
                  <Text
                    style={{
                      overflow: 'hidden',
                      maxWidth: '100%',
                      borderRadius: 3,
                      padding: 2,
                      backgroundColor: theme.colors.surface,
                    }}
                    numberOfLines={1}
                    variant="bodySmall"
                  >
                    @{story?.event?.title?.trim()}
                  </Text>
                )}
                {story.artist && (
                  <Text
                    style={{
                      overflow: 'hidden',
                      maxWidth: '100%',
                      borderRadius: 3,
                      padding: 2,
                      backgroundColor: theme.colors.surface,
                    }}
                    numberOfLines={1}
                    variant="bodySmall"
                  >
                    @{story?.artist?.name?.trim()}
                  </Text>
                )}
                {story.place && (
                  <Text
                    style={{
                      overflow: 'hidden',
                      maxWidth: '100%',
                      borderRadius: 3,
                      padding: 2,
                      backgroundColor: theme.colors.surface,
                    }}
                    numberOfLines={1}
                    variant="bodySmall"
                  >
                    @{story.place.name}
                  </Text>
                )}
              </ChipContainer>
            )}
            {story.district && (
              <>
                <Text style={{ fontWeight: 'bold' }} variant="bodyMedium">
                  {story.district}
                </Text>
              </>
            )}
            <ChipContainer>
              {story?.created?.name && (
                <>
                  <ImageIconAvatar
                    size={20}
                    icon={'account'}
                    uri={story.created.photo_url}
                  />
                  <Text variant="bodyMedium">{story?.created?.name}</Text>
                  <Dot />
                </>
              )}
              <Text
                style={{ color: theme.colors.onSurfaceVariant }}
                variant="bodyMedium"
              >
                {toDateTimeString(story.created_at)}
              </Text>
            </ChipContainer>
            {(story.like_count > 0 || story.comment_count > 0) && (
              <Row style={{ justifyContent: 'flex-end', gap: 5 }}>
                {story.like_count > 0 && (
                  <Text
                    style={{
                      fontSize: 13,
                      color: theme.colors.outline,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    <MaterialCommunityIcons
                      name={'thumb-up-outline'}
                      size={15}
                    />
                    {story.like_count}
                  </Text>
                )}
                {story.comment_count > 0 && (
                  <Text
                    style={{
                      fontSize: 13,
                      color: theme.colors.outline,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    <MaterialCommunityIcons
                      name={'comment-outline'}
                      size={15}
                    />
                    {story.comment_count}
                  </Text>
                )}
              </Row>
            )}
          </Content>
        </Card>
      </Pressable>
    )
  );
};

export default StoryCard;
