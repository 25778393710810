import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Button, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import DefaultDialog from '../components/dialogs/DefaultDialog';
import ProfileForm from '../components/profile/ProfileForm';
import { useDialog } from '../components/providers/DialogProvider';
import Loading from '../components/shared/Loading';
import { showSuccess } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import {
  useDeleteUserProfileMutation,
  useUserProfileQuery,
} from '../services/UserService';
import { signOut } from '../supabase';
import i18n from '../translations/i18n';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';

const ProfileEditScreen: React.FC = () => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const { showDialog, hideDialog } = useDialog();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (navigationReady && !userId) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, userId]);

  const {
    data: profile,
    isLoading,
    isFetching,
    isFetched,
  } = useUserProfileQuery();

  const { mutateAsync: deleteUserProfile, isLoading: isMutationLoading } =
    useDeleteUserProfileMutation(userId);

  useEffect(() => {
    if (isFetched) {
      if (!profile) {
        signOut();
      }
    }
  }, [profile]);

  const showLoading = isLoading || isFetching || isMutationLoading || !profile;
  return showLoading ? (
    <Loading />
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      <ProfileForm
        edit={true}
        profile={profile}
        onSuccess={() => {
          navigation.replace('MainTab', { screen: 'Profile' });
          showSuccess(i18n.t('profile.edit.updated'));
        }}
      />
      <Button
        mode={'outlined'}
        labelStyle={{ fontSize: 16, fontWeight: 'bold' }}
        textColor={theme.colors.errorContainer}
        style={{ width: '85%', borderColor: theme.colors.errorContainer }}
        onPress={() => {
          showDialog(
            <DefaultDialog
              title={i18n.t('profile.edit.withdrawal')}
              content={i18n.t('profile.edit.content')}
              confirmText={i18n.t('profile.edit.confirmText')}
              onPressDismiss={hideDialog}
              onPressConfirm={async () => {
                await deleteUserProfile();
                hideDialog();
                await signOut();
              }}
            />,
          );
        }}
      >
        {i18n.t('profile.edit.withdrawal')}
      </Button>
    </KeyboardAwareScrollView>
  );
};

export default ProfileEditScreen;
