import { MaterialCommunityIcons } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FAB, IconButton, MD3Theme, Text, useTheme } from 'react-native-paper';

import RecommendedArtist from '../components/artist/RecommendedArtist';
import DefaultDialog from '../components/dialogs/DefaultDialog';
import NoticeCards from '../components/notice/NoticeCards';
import { useDialog } from '../components/providers/DialogProvider';
import StoryCardList from '../components/story/StoryCardList';
import StoryFilterDialog from '../components/story/StoryFilterDialog';
import { MainStackParamList } from '../navigation/MainStack';
import { MainTabParamList } from '../navigation/MainTab';
import { resetTab } from '../navigation/navigations';
import { useArtistDetailQuery } from '../services/ArtistService';
import {
  useEventDetailQuery,
  useUpcomingEventQuery,
} from '../services/EventService';
import { useNotificationCountQuery } from '../services/NotificationService';
import { usePlaceDetailQuery } from '../services/PlaceService';
import { UserData } from '../services/UserService';
import i18n from '../translations/i18n';
import LocalStorage, { RegionAndDistrict } from '../utils/localStorage';
import { DEFAULT_REGION } from '../utils/locationHelpers';
import { refetchQueries } from '../utils/queryClient';
import { colors } from '../utils/themes';

const StoryFeedHeader: React.FC<{
  onPressClose?: () => void;
  theme: MD3Theme;
  text: string;
}> = ({ onPressClose, theme, text }) => (
  <View
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      alignSelf: 'center',
      paddingVertical: 10,
      paddingHorizontal: 20,
      backgroundColor: theme.colors.primary,
    }}
  >
    <Text
      variant={'bodyLarge'}
      style={{ fontWeight: 'bold', color: theme.colors.onPrimary }}
      numberOfLines={1}
    >
      {text}
    </Text>
    <TouchableOpacity onPress={onPressClose}>
      <MaterialCommunityIcons
        size={20}
        name={'close-circle'}
        color={theme.colors.onPrimary}
      />
    </TouchableOpacity>
  </View>
);

const StoryFeedScreen: React.FC<any> = () => {
  const theme = useTheme();
  const user = useUser();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const [regionAndDistrict, setRegionAndDistrict] = useState<RegionAndDistrict>(
    { district: '', ...DEFAULT_REGION },
  );
  const { data: notification } = useNotificationCountQuery(user);
  const route = useRoute<RouteProp<MainTabParamList, 'StoryFeed'>>();
  const params = route.params ?? {};
  const { data: event } = useEventDetailQuery(route.params?.eventId);
  const { data: artist } = useArtistDetailQuery(route.params?.artistId);
  const { data: place } = usePlaceDetailQuery(
    route.params?.placeId!,
    user?.id!,
  );
  const district = params.district;
  const emptyParam = _.isEmpty(params);
  const { district: storageDistrict, ...region } = regionAndDistrict;
  const { data: events } = useUpcomingEventQuery(
    emptyParam ? region : undefined,
  );

  const recommendedEvent = _(events)
    .shuffle()
    .find(event => !!event.artists?.some(artist => artist.spotify_url));
  const recommendedArtist = recommendedEvent?.artists?.find(
    artist => artist.spotify_url,
  );
  useEffect(() => {
    (async () => {
      const userData = user?.user_metadata as UserData;
      let regionAndDistrict: RegionAndDistrict = {
        district: userData?.district,
        latitude: userData?.latitude!,
        longitude: userData?.longitude!,
        latitudeDelta: userData?.latitudeDelta!,
        longitudeDelta: userData?.longitudeDelta!,
      };
      if (!userData) {
        regionAndDistrict = (await LocalStorage.getRegionAndDistrict())!;
      }
      setRegionAndDistrict(regionAndDistrict!);
    })();
  }, []);

  const { showDialog, hideDialog } = useDialog();

  const headerRight = ({ tintColor }: { tintColor?: string }) => (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {user && (
        <View>
          <View
            style={{
              display: notification ? 'flex' : 'none',
              zIndex: 1,
              position: 'absolute',
              top: 14,
              right: 7,
              width: 8,
              height: 8,
              borderRadius: 4,
              backgroundColor: colors.notification,
            }}
          />
          <IconButton
            style={{ marginHorizontal: 0 }}
            size={23}
            icon={'heart-outline'}
            iconColor={tintColor}
            onPress={() => navigation.push('NotificationFeed')}
          />
        </View>
      )}
      <IconButton
        key={JSON.stringify(params)}
        style={{ marginLeft: 0, marginRight: 10 }}
        size={23}
        icon={'tune-variant'}
        iconColor={tintColor}
        onPress={onPressFilter}
      />
    </View>
  );

  const onPressFilter = () => {
    showDialog(
      <DefaultDialog
        content={
          <StoryFilterDialog
            navigation={navigation}
            currentSort={params?.sort}
            hideDialog={hideDialog}
          />
        }
        onPressDismiss={hideDialog}
      />,
    );
  };

  const updateTitle = async () => {
    let title = i18n.t('story.feed.musicStory');
    if (params?.eventId) {
      title = i18n.t('story.feed.eventStory');
    }
    if (artist) {
      title = i18n.t('story.feed.story', { name: artist.name });
    }
    if (place) {
      title = i18n.t('story.feed.story', { name: place.name });
    }
    if (district) {
      const shortDistrictName = _.last(district?.split(' '));
      title = i18n.t('story.feed.story', { name: shortDistrictName });
    }
    if (title) {
      navigation.setOptions({
        title,
        headerRight,
      });
    }
  };

  useEffect(() => {
    updateTitle();
  }, [params, event, artist, place, notification]);

  const onPressReset = () =>
    navigation.getParent()?.dispatch(resetTab('StoryFeed'));

  return (
    <>
      {!!event && (
        <StoryFeedHeader
          onPressClose={onPressReset}
          theme={theme}
          text={event.title}
        />
      )}
      {!!artist && (
        <StoryFeedHeader
          theme={theme}
          text={artist.name}
          onPressClose={onPressReset}
        />
      )}
      {!!place && (
        <StoryFeedHeader
          theme={theme}
          text={place.name}
          onPressClose={onPressReset}
        />
      )}
      {!!district && (
        <StoryFeedHeader
          theme={theme}
          text={district}
          onPressClose={onPressReset}
        />
      )}
      <StoryCardList
        {...params}
        thumbnail={true}
        header={
          <>
            <NoticeCards storyNotice={true} />
            {recommendedEvent && recommendedArtist ? (
              <RecommendedArtist
                district={_.last(storageDistrict?.split(' '))!}
                eventId={recommendedEvent.id}
                artist={recommendedArtist}
              />
            ) : undefined}
          </>
        }
        onPressItem={story =>
          navigation.push('StoryDetail', { storyId: story.id })
        }
        onPressReset={onPressReset}
        onRefresh={async () => {
          await refetchQueries('notification');
        }}
      />
      {user && (
        <FAB
          icon={'plus'}
          onPress={() => navigation.push('StoryAdd', { ...params })}
          style={{
            position: 'absolute',
            margin: 16,
            right: 0,
            bottom: 0,
            borderRadius: 30,
          }}
        />
      )}
    </>
  );
};

export default StoryFeedScreen;
