import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { LatLng } from '../components/map';
import { sendError, showError } from '../components/shared/Toast';
import NewsRepository, { RelatedNews } from '../repositories/NewsRepository';
import PlaceRepository, {
  Place,
  PlacesParams,
} from '../repositories/PlaceRepository';
import UserRepository from '../repositories/UserRepository';
import i18n from '../translations/i18n';
import http from '../utils/http';
import { DEFAULT_DISTRICT } from '../utils/locationHelpers';
import { refetchAll, resetAll } from '../utils/queryClient';

export default class PlaceService {
  private static ALL_DISTRICTS_CACHE: string[] = [];

  static async getPlaces(
    params: PlacesParams,
  ): Promise<{ places: Place[]; offset?: number; end: boolean }> {
    if (params.ownerId) {
      const { data } = await PlaceRepository.getOwnedPlaces(
        params.ownerId,
        params.offset,
      );
      const places = (data
        ?.filter(({ places }) => !!places)
        .map(({ places }) => places)
        .flat() ?? []) as unknown as Place[];
      return {
        places,
        offset: params.offset,
        end: !places?.length,
      };
    }
    const { data } = await PlaceRepository.getPlaces(params);
    const places = ((data ?? []) as Place[]).filter(
      place => !place.created?.blocked?.length,
    );
    return {
      places,
      offset: params.offset,
      end: !places?.length,
    };
  }

  static async getAddressByLocation(location: LatLng): Promise<string> {
    try {
      const response = await http.post(`/api/geocode`, {
        lat: location.latitude,
        lng: location.longitude,
      });
      return response?.data?.address;
    } catch (e) {
      showError(i18n.t('place.service.unableToVerifyAddressInformation'));
      sendError(e);
      return '';
    }
  }

  static async getLocationByAddress(
    address: string,
  ): Promise<LatLng | undefined> {
    try {
      const response = await http.post(`/api/geocode`, {
        address,
      });
      const location = response?.data;
      if (location.latitude && location.longitude) {
        return location;
      }
    } catch (e) {
      showError(i18n.t('place.service.address.notValid'));
      sendError(e);
    }
  }

  static async getDistrictByLocation(location: LatLng): Promise<string> {
    try {
      const response = await http.post(`/api/geocode?short=true`, {
        lat: location.latitude,
        lng: location.longitude,
      });
      return response?.data?.address;
    } catch (e) {
      showError(
        i18n.t('place.service.location.notValid'),
        i18n.t('place.service.location.setDefault'),
      );
      sendError(e);
      return DEFAULT_DISTRICT;
    }
  }

  static async getAllDistricts(): Promise<string[]> {
    try {
      if (!PlaceService.ALL_DISTRICTS_CACHE.length) {
        const response = await http.get(`/api/districts`);
        PlaceService.ALL_DISTRICTS_CACHE = response.data;
      }
      return PlaceService.ALL_DISTRICTS_CACHE;
    } catch (error) {
      showError(error);
      return [];
    }
  }

  static async existsNameAndAddress(
    name: string,
    address: string,
  ): Promise<boolean> {
    const { data, error } = await PlaceRepository.getPlaceByNameAndAddress(
      name,
      address,
    );
    if (error) {
      showError(error);
      return false;
    }
    return !!data;
  }

  static async favoritePlace(placeId: string): Promise<void> {
    try {
      await UserRepository.insertUserFavoritePlace(placeId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }

  static async unfavoritePlace(placeId: string): Promise<void> {
    try {
      await UserRepository.deleteUserFavoritePlace(placeId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }
}

export function usePlacesQuery(params: PlacesParams) {
  return useInfiniteQuery(
    ['places', params],
    async ({ pageParam }) =>
      await PlaceService.getPlaces({ ...params, offset: pageParam }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + PlaceRepository.PAGE_SIZE,
    },
  );
}

export function useDistrictsQuery() {
  return useQuery<{ id: string; name: string }[]>(['districts'], async () =>
    (await PlaceService.getAllDistricts()).map(district => ({
      id: district,
      name: district,
    })),
  );
}

export function usePlaceDetailQuery(id: string, userId: string) {
  return useQuery<Place | null>(['placeDetail', id], async () => {
    if (!id) {
      return null;
    }
    const place = (await PlaceRepository.getPlace(id, userId)).data as Place;
    if (place?.created?.blocked?.length) {
      return null;
    }
    return place;
  });
}

export function usePlaceRelatedNewsQuery(id?: string) {
  return useQuery<RelatedNews | null>(['placeRelatedNews', id], async () => {
    if (!id) {
      return null;
    }
    const news = await NewsRepository.getPlaceRelatedNews(id);
    if (!news?.title) {
      return null;
    }
    return news;
  });
}

export function useUpsertPlaceMutation() {
  return useMutation(
    async (place: Place & { location?: LatLng }) =>
      (await PlaceRepository.upsertPlace(place)).data,
    {
      onSuccess: async place => await resetAll(),
    },
  );
}

export function useDeletePlaceMutation(placeId?: string) {
  return useMutation(
    () =>
      placeId
        ? PlaceRepository.deletePlace(placeId)
        : Promise.reject(new Error('Invalid PlaceId')),
    {
      onSuccess: async () => await resetAll(),
    },
  );
}
