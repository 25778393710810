import { MaterialCommunityIcons } from '@expo/vector-icons';
import { isAfter } from 'date-fns';
import * as Linking from 'expo-linking';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Button, MD3Theme, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { Event, EventPaymentType } from '../../repositories/EventRepository';
import i18n from '../../translations/i18n';
import { parse } from '../../utils/datetimes';
import { SmallLoading } from '../shared/Loading';

const BottomContainer = styled.View<{ theme: MD3Theme }>`
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.elevation.level4};
  background-color: ${({ theme }) => theme.colors.background};
`;

const BottomLeftSection = styled.View<{ theme: MD3Theme }>`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const BottomSeparator = styled.View<{ theme: MD3Theme }>`
  margin-horizontal: 10px;
  width: 2px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.elevation.level4};
`;

const EventDetailBottomBar: React.FC<{
  event: NonNullable<Event>;
  theme: MD3Theme;
  isSaving?: boolean;
  onPressSave: () => Promise<void>;
}> = ({ event, theme, isSaving, onPressSave }) => {
  const prices = [];
  if (event.advance_ticket_price) {
    prices.push(
      i18n.t('event.card.advance', {
        advance_ticket_price: event.advance_ticket_price.toLocaleString(),
      }),
    );
  }
  if (event.door_ticket_price) {
    prices.push(
      i18n.t('event.card.door', {
        door_ticket_price: event.door_ticket_price.toLocaleString(),
      }),
    );
  }
  switch (event.payment_type) {
    case EventPaymentType.FREE: {
      prices.push(i18n.t('event.formField.payment_type.free'));
      break;
    }
    case EventPaymentType.FREE_DONATE: {
      prices.push(i18n.t('event.formField.payment_type.freeDonation'));
      break;
    }
  }
  if (!prices.length && event.ticket_url) {
    prices.push(i18n.t('event.detailBottomBar.seeLink'));
  }
  const priceText = prices.join('\n');
  let urlText = i18n.t('event.detailBottomBar.textReference');
  if (event.ticket_url) {
    const past = isAfter(new Date(), parse(event.event_date)!);
    urlText = past
      ? i18n.t('event.detailBottomBar.information')
      : i18n.t('event.detailBottomBar.reservation');
  }
  return (
    <BottomContainer theme={theme}>
      <BottomLeftSection theme={theme}>
        <TouchableOpacity onPress={onPressSave}>
          {isSaving ? (
            <View style={{ width: 35 }}>
              <SmallLoading />
            </View>
          ) : (
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={event.saved?.length ? 'bookmark' : 'bookmark-outline'}
              size={35}
              direction={'ltr'}
            />
          )}
        </TouchableOpacity>
        <BottomSeparator theme={theme} />
        {!!priceText && (
          <Text variant={'bodyLarge'} style={{ fontWeight: 'bold' }}>
            {priceText}
          </Text>
        )}
      </BottomLeftSection>
      {!!urlText && (
        <Button
          mode={'contained'}
          disabled={!event.ticket_url}
          labelStyle={{ fontWeight: 'bold' }}
          contentStyle={{ height: 40 }}
          onPress={() => Linking.openURL(event.ticket_url!)}
        >
          {urlText}
        </Button>
      )}
    </BottomContainer>
  );
};

export default EventDetailBottomBar;
