import { useActionSheet } from '@expo/react-native-action-sheet';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ScrollView, TextInput, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { RefreshControl } from 'react-native-web-refresh-control';

import CommentCards from '../components/comment/CommentCards';
import CommentInput from '../components/comment/CommentInput';
import DefaultDialog from '../components/dialogs/DefaultDialog';
import { useDialog } from '../components/providers/DialogProvider';
import DetailHeaderRight from '../components/shared/DetailHeaderRight';
import { SmallLoading } from '../components/shared/Loading';
import { showError, showInfo, showSuccess } from '../components/shared/Toast';
import StoryDetailContent from '../components/story/StoryDetailContent';
import StoryLikeComment from '../components/story/StoryLikeComment';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import StoryRepository from '../repositories/StoryRepository';
import {
  StoryService,
  useDeleteStoryMutation,
  useStoryDetailQuery,
} from '../services/StoryService';
import { UserService, useUserProfileQuery } from '../services/UserService';
import i18n from '../translations/i18n';
import { resetAll } from '../utils/queryClient';

const StoryDetailScreen: React.FC = () => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute<RouteProp<MainStackParamList, 'StoryDetail'>>();
  const isFocused = useIsFocused();
  const { showActionSheetWithOptions } = useActionSheet();
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const commentInputRef: MutableRefObject<TextInput | null> =
    useRef<TextInput>(null);

  const storyId = route.params.storyId;

  useEffect(() => {
    if (navigationReady && !storyId) {
      navigation.replace('MainTab', { screen: 'StoryFeed' });
    }
  }, [navigationReady, storyId]);

  const { data: user } = useUserProfileQuery();

  const { data: story, isFetched, refetch } = useStoryDetailQuery(storyId);
  const { mutateAsync: deleteStory } = useDeleteStoryMutation(storyId);
  const { showDialog, hideDialog } = useDialog();

  const onPressLike = async () => {
    if (!user) {
      showInfo(i18n.t('common.pleaseSignIn'));
      return;
    }
    if (!story) {
      return;
    }
    const hasSaved = !!story.liked?.length;
    setIsSaving(true);
    if (hasSaved) {
      await StoryService.unlikeStory(story.id);
    } else {
      await StoryService.likeStory(story.id);
    }
    setIsSaving(false);
  };

  const onPressComment = async () => {
    if (!user) {
      showInfo(i18n.t('common.pleaseSignIn'));
      return;
    }
    commentInputRef?.current?.focus();
  };

  const onPressEdit = () => {
    let options = [i18n.t('common.cancel')];
    let destructiveButtonIndex: number[];
    const self = user?.id === story?.created_by || !!user?.admin;
    if (self) {
      options = [i18n.t('common.modify'), i18n.t('common.delete'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.delete'))];
    } else {
      options = [i18n.t('common.report'), i18n.t('common.block'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.report'))];
    }
    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        containerStyle: {
          paddingBottom: insets.bottom,
        },
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case options.indexOf(i18n.t('common.modify')): {
            navigation.push('StoryEdit', { storyId });
            break;
          }
          case options.indexOf(i18n.t('common.delete')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.delete')}
                content={i18n.t('common.areYouSureYouWantToDeleteIt')}
                confirmText={i18n.t('common.delete')}
                onPressDismiss={hideDialog}
                onPressConfirm={async () => {
                  await deleteStory();
                  showSuccess(i18n.t('common.itHasBeenDeleted'));
                  hideDialog();
                  if (navigation.canGoBack()) {
                    navigation.goBack();
                  } else {
                    navigation.replace('MainTab', { screen: 'StoryFeed' });
                  }
                }}
              />,
            );
            break;
          }
          case options.indexOf(i18n.t('common.block')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.block')}
                content={i18n.t('common.blockConfirm', {
                  name: story?.created?.name!,
                })}
                confirmText={i18n.t('common.block')}
                onPressConfirm={async () => {
                  try {
                    await UserService.blockUser(story?.created?.id!);
                    hideDialog();
                    showInfo(i18n.t('common.blockSuccess'));
                  } catch (e) {
                    hideDialog();
                    showError(e);
                  }
                }}
                onPressDismiss={hideDialog}
              />,
            );
            break;
          }
          case options.indexOf(i18n.t('common.report')): {
            navigation.push('Report', {
              title: `${i18n.t('common.report')}: ${story?.title!}`,
              type: 'stories',
              target: storyId,
            });
            break;
          }
          default:
        }
      },
    );
  };

  useEffect(() => {
    if (isFetched) {
      if (story) {
        navigation.setOptions({
          headerRight: ({ tintColor }: { tintColor?: string }) => (
            <DetailHeaderRight
              more={!!user}
              color={tintColor}
              onPressMore={() => onPressEdit()}
            />
          ),
        });
      }
      if (!story) {
        showInfo(i18n.t('story.detailContent.invalidStory'));
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'StoryFeed' });
        }
      }
    }
  }, [story, user]);

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);

  useEffect(
    () => () => {
      if (user) {
        StoryRepository.updateViewCount(storyId!);
      }
    },
    [],
  );

  const onRefresh = async () => {
    setLoading(true);
    await resetAll();
    await refetch();
    setLoading(false);
  };

  return !story ? (
    <SmallLoading />
  ) : (
    <View style={{ flex: 1 }}>
      <ScrollView
        overScrollMode={'never'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
      >
        <StoryDetailContent story={story} theme={theme} />
        <StoryLikeComment
          story={story}
          isSaving={isSaving}
          onPressLike={onPressLike}
          onPressComment={onPressComment}
        />
        <CommentCards storyUserId={story?.created?.id} storyId={storyId!} />
      </ScrollView>
      <CommentInput parent={story} ref={commentInputRef} />
    </View>
  );
};

export default StoryDetailScreen;
