import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useTheme } from 'react-native-paper';

const FavoriteButton: React.FC<{
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  size?: number;
  color?: string;
  onPress?: () => void | Promise<void>;
}> = ({ icon, color, size = 25, onPress }) => {
  const theme = useTheme();
  return (
    <TouchableOpacity onPress={onPress}>
      <MaterialCommunityIcons
        color={color ?? theme.colors.onBackground}
        name={icon}
        size={size}
      />
    </TouchableOpacity>
  );
};

export default FavoriteButton;
