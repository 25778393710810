import { Loader } from '@googlemaps/js-api-loader';
import { GoogleMap } from '@react-google-maps/api';
import React from 'react';
import { View } from 'react-native';
import { Camera } from 'react-native-maps';
import { MapViewProps } from 'react-native-maps/lib/MapView';

import { GOOGLE_MAP_WEB_API_KEY } from '../../../utils/constants';
import { deltaToZoom, zoomToDelta } from '../../../utils/locationHelpers';

interface MapViewState {
  google?: typeof google;
  map?: google.maps.Map;
  camera?: {
    center?: google.maps.LatLngLiteral;
    zoom?: number;
  };
}

const MAP_LOADER = new Loader({
  apiKey: GOOGLE_MAP_WEB_API_KEY,
});

class MapView extends React.Component<MapViewProps, MapViewState> {
  constructor(props: MapViewProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { initialRegion } = this.props;
    const google = await MAP_LOADER.load();
    this.setState({
      google,
      camera: {
        center: {
          lat: initialRegion?.latitude!,
          lng: initialRegion?.longitude!,
        },
        zoom: deltaToZoom(initialRegion?.latitudeDelta!),
      },
    });
  }

  componentDidUpdate(prevProps: MapViewProps, prevState: MapViewState) {
    if (prevState.map !== this.state.map) {
      this.onChange();
    }
    if (prevState.camera !== this.state.camera) {
      this.onChange();
    }
  }

  onLoad = (map: google.maps.Map) => {
    const { onMapReady } = this.props;
    this.setState({ map });
    onMapReady?.();
  };

  onChange = () => {
    const { onRegionChangeComplete } = this.props;
    if (!onRegionChangeComplete) {
      return;
    }

    if (!this.state.map) {
      return;
    }

    const center = this.state.map.getCenter();
    const zoom = this.state.map.getZoom();
    if (!center || !zoom) {
      return;
    }

    onRegionChangeComplete(
      {
        latitude: center.lat(),
        longitude: center.lng(),
        latitudeDelta: zoomToDelta(zoom),
        longitudeDelta: zoomToDelta(zoom),
      },
      {},
    );
  };

  setCamera = (camera: Partial<Camera>): void => {
    if (camera?.center) {
      this.state.map?.setCenter({
        lat: camera?.center.latitude,
        lng: camera.center.longitude,
      });
      this.onChange();
    }
  };

  render() {
    const {
      style,
      children,
      minZoomLevel,
      maxZoomLevel,
      zoomControlEnabled,
      rotateEnabled,
      onPress,
    } = this.props;
    const { google, camera } = this.state;
    const hasCamera = !!(
      camera?.center?.lat &&
      camera?.center?.lng &&
      camera?.zoom
    );
    return (
      <View style={style}>
        {google && hasCamera && (
          <GoogleMap
            center={camera.center}
            zoom={camera.zoom}
            mapContainerStyle={{ height: '100%' }}
            onLoad={this.onLoad}
            onZoomChanged={() => this.onChange()}
            onDragEnd={() => this.onChange()}
            onClick={e => onPress?.(e as any)}
            options={{
              minZoom: minZoomLevel,
              maxZoom: maxZoomLevel,
              zoomControl: zoomControlEnabled,
              rotateControl: rotateEnabled,
              fullscreenControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
            }}
          >
            {children}
          </GoogleMap>
        )}
      </View>
    );
  }
}

export default MapView;
