import AsyncStorage from '@react-native-async-storage/async-storage';
import { createClient } from '@supabase/supabase-js';
import { Platform } from 'react-native';

import { showError } from './components/shared/Toast';
import { Database } from './types/database';
import { SUPABASE_ANON_KEY, SUPABASE_URL } from './utils/constants';
import LocalStorage from './utils/localStorage';
import queryClient from './utils/queryClient';

const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY, {
  auth: {
    storage: AsyncStorage as any,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: Platform.OS === 'web',
  },
});

export const signOut = async (): Promise<void> => {
  await queryClient.clear();
  await LocalStorage.clear();
  const { error } = await supabase.auth.signOut();
  if (error) {
    showError(error);
  }
};

export default supabase;
