import { FontAwesome5 } from '@expo/vector-icons';
import { Provider } from '@supabase/gotrue-js';
import { makeRedirectUri, useAuthRequest } from 'expo-auth-session';
import { Image } from 'expo-image';
import React from 'react';
import { Platform } from 'react-native';
import { Button } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/src/components/Icon';

import supabase from '../../supabase';
import i18n from '../../translations/i18n';
import { SUPABASE_URL } from '../../utils/constants';
import { resetAll } from '../../utils/queryClient';
import { showError } from './Toast';

const isWebView = (): boolean => {
  const navigator = window.navigator;
  const userAgent = navigator.userAgent;
  const normalizedUserAgent = userAgent.toLowerCase();
  const standalone = (navigator as any).standalone;

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const isAndroid = /android/.test(normalizedUserAgent);
  const isSafari = /safari/.test(normalizedUserAgent);
  return (
    (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
    (isIos && !standalone && !isSafari)
  );
};

const signInWeb = async (provider: Provider) => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider,
    options: {
      redirectTo: window.location.origin,
    },
  });
  if (error) {
    showError(error);
  }
};

const SignInButton: React.FC<{
  icon: IconSource;
  label: string;
  onPress: () => void | Promise<void>;
}> = ({ icon, label, onPress }) => {
  return (
    <Button
      mode={'outlined'}
      textColor={'#fff'}
      style={{
        borderColor: '#fff',
        minWidth: 250,
      }}
      labelStyle={{
        fontSize: 15,
        fontWeight: 'bold',
      }}
      icon={icon}
      onPress={onPress}
    >
      {label}
    </Button>
  );
};

const ProviderSignInButton: React.FC<{
  provider: Provider;
  icon: IconSource;
  label: string;
}> = ({ provider, icon, label }) => {
  const redirectUri = makeRedirectUri({ path: 'auth/callback' });
  const [, , promptAsync] = useAuthRequest({ clientId: '', redirectUri }, {});
  const onPress = async () => {
    if (Platform.OS === 'web') {
      if (provider === 'google') {
        if (isWebView()) {
          if (confirm(i18n.t('signIn.cannotSignInGoogle'))) {
            return await signInWeb(provider);
          }
          return;
        }
      }
      return await signInWeb(provider);
    } else {
      const res = await promptAsync({
        url: `${SUPABASE_URL}/auth/v1/authorize?provider=${provider}&redirect_to=${redirectUri}`,
        showInRecents: Platform.OS === 'android',
      });
      if (res.type === 'success' || res.type === 'error') {
        if (res.error?.code !== 'state_mismatch') {
          showError(res.error);
          return;
        }
        await supabase.auth.setSession({
          access_token: res.params?.access_token,
          refresh_token: res.params?.refresh_token,
        });
        await resetAll();
      }
    }
  };

  return <SignInButton icon={icon} label={label} onPress={onPress} />;
};

export const GoogleSignInButton: React.FC = () => (
  <ProviderSignInButton
    provider="google"
    icon={({ size }) => (
      <Image
        source={require('../../../assets/logos/google.png')}
        style={{ width: size, height: size }}
      />
    )}
    label={i18n.t('signIn.google')}
  />
);

export const AppleSignInButton: React.FC = () => (
  <ProviderSignInButton
    provider="apple"
    icon={({ size }) => <FontAwesome5 name="apple" size={size} color="#fff" />}
    label={i18n.t('signIn.apple')}
  />
);

export const FacebookSignInButton: React.FC = () => (
  <ProviderSignInButton
    provider="facebook"
    icon={({ size }) => (
      <Image
        source={require('../../../assets/logos/facebook.png')}
        style={{ width: size, height: size }}
      />
    )}
    label={i18n.t('signIn.facebook')}
  />
);

export const KakaoSignInButton: React.FC = () => (
  <ProviderSignInButton
    // @ts-ignore
    provider="kakao"
    icon={({ size }) => (
      <Image
        source={require('../../../assets/logos/kakao.png')}
        style={{ width: size, height: size, borderRadius: size / 4 }}
      />
    )}
    label={i18n.t('signIn.kakao')}
  />
);

export default SignInButton;
