export default {
  common: {
    appName: 'Livewire',
    updating: 'Updating to the latest version.',
    updatingError: 'An error occurred while updating. Please try again later.',
    english: 'Eng',
    korean: 'Kor',
    genre: 'Genres',
    like: 'Likes',
    view: 'Views',
    interest: 'Interests',
    comment: 'Comments',
    reply: 'Reply',
    event: 'Event',
    events: 'Events',
    story: 'Community',
    posts: 'Posts',
    share: 'Share',
    areYouSureYouWantToDeleteIt: 'Are you sure you want to delete it?',
    itHasBeenDeleted: 'It has been deleted.',
    modify: 'Modify',
    delete: 'Delete',
    cancel: 'Cancel',
    report: 'Report',
    block: 'Block',
    blockConfirm:
      'Do you really want to block %{name}?\nEvery events and posts will be hidden.',
    blockSuccess: 'Blocked.',
    unblock: 'Unblock',
    unblockConfirm:
      'Do you really want to unblock %{name}?\nEvery events and posts will be shown.',
    unblockSuccess: 'Unblocked.',
    apply: 'Apply',
    save: 'Save',
    search: 'Search',
    searchEmpty: 'No search result for keyword.',
    all: 'All',
    more: 'See More',
    post: 'Post',
    select: 'Select',
    confirm: 'Confirm',
    searchByAddress: 'Search by address',
    addressCannotBeDetermined: 'The address cannot be located',
    linkHasBeenCopiedToTheClipboard:
      'The link has been copied to the clipboard',
    defaultError: 'Error has occurred.',
    defaultErrorExtra: 'If this continues, please restart the application.',
    unknownError: 'Unknown Error',
    networkError: 'Network Error',
    networkErrorExtra: 'Please check network status',
    doNotHavePermission: 'You do not have permission',
    pleaseSignIn: 'Please Sign In',
    signIn: 'Sign In',
    signUp: 'Sign up',
    artist: 'Artist',
    agency: 'Agency',
    place: 'Place',
    pleaseEnterSearchKeyword: 'Please enter search keyword',
    recentSearchWord: 'Recent Searches',
    notExistRecentSearchTerms: 'There are no recent search terms',
    closest: 'Closest',
    nearest: 'Nearest',
    new: 'New',
    price: 'Price',
    likes: 'Top',
    comments: 'Controversial',
    district: 'District',
    distance: 'Distance',
    version: 'Version',
    location: {
      refresh: 'Search this area',
      rules: {
        permission: 'Location permission is required.',
        permissionExtra: 'Using default location information.',
      },
    },
    basicRegion: 'Mapo-gu, Seoul',
    justNow: 'Just Now',
    foundBannedKeyword: "Keyword '%{keyword}' cannot be posted.",
    form: {
      modified: 'Update',
      registered: 'Registered',
      checkInput: 'Please check what you have entered.',
    },
    formField: {
      name: {
        rules: {
          required: 'Please enter a name.',
          minLength: 'You can enter more than one character',
          maxLength: 'You can enter up to 30 characters in the name.',
        },
        label: 'Name',
      },
      members: {
        rules: {
          maxLength:
            'You can enter up to 200 characters in the members/real name.',
        },
        label: 'Members/Real Name',
      },
      id: {
        rules: {
          pattern: 'The ID can only be lowercase letters numbers _',
          required: 'Please enter a ID.',
          minLength: 'You can enter more than 3 characters of ID.',
          maxLength: 'You can enter up to 30 characters of ID',
        },
        label: 'ID (separate from user ID)',
      },
      instagramId: {
        rules: {
          pattern:
            'Instagram Username can only be lowercase letters and numbers _',
          minLength: 'Instagram Username can be more than 3 characters long.',
          maxLength:
            'You can enter up to 30 characters for your Instagram Username.',
        },
        label: 'Instagram username',
      },
      url: {
        rules: {
          pattern: 'Please check the URL format. (Starts with https://)',
        },
      },
      title: {
        rules: {
          required: 'Please enter a title',
          minLength: 'Title can be entered in more than 3 characters',
          maxLength: 'Title can be up to 100 characters',
        },
        label: 'Title',
      },
      instagram_url: 'Instagram URL',
      youtube_url: 'YouTube URL',
      toss_payment_url: 'Toss Payment URL',
      kakao_payment_url: 'Kakao Pay URL',
      pleaseEnterAnBio: 'Please enter an bio.',
      existsName: 'Nickname in use.',
      existsPlace: 'The place is already registered. (name, address)',
      number_only: 'Please enter a number.',
      imageSizeOver: 'Images bigger than 10MB cannot be attached.',
    },
    updateTitle: 'Update',
    updateMessage: 'A new update is available. Updating.',
    recommendNotification:
      'Please turn on notification if you want get new events notification',
    relatedNews: 'Recommended News',
  },
  stack: {
    EventDetail: '',
    EventAdd: 'New Event',
    EventEdit: 'Edit a Event',
    StoryDetail: 'Community',
    StoryAdd: 'New Post',
    StoryEdit: 'Edit an Post',
    CommentDetail: 'Comments',
    ArtistList: 'Artists',
    ArtistDetail: '',
    ArtistAdd: 'New Artist',
    ArtistEdit: 'Edit a Artist',
    ArtistEventFeed: "Artist's Events",
    PlaceDetail: 'Place Detail',
    PlaceAdd: 'New place',
    PlaceEdit: 'Edit a Place',
    ProfileEdit: 'Edit Profile',
    UserSavedEvents: 'Saved Events',
    UserAttendedEvents: 'Attended Events',
    UserAttendedEventDetail: 'Attended Event',
    UserAttendedEventAdd: 'Post an Event Review',
    UserAttendedEventEdit: 'Edit an Event Review',
    UserLikedStories: 'Liked Posts',
    UserLikedArtists: 'Liked Musicians',
    UserFavoritePlaces: 'Liked Places',
    UserPostedStories: 'Your Posts',
    UserPostedComments: 'Your Comments',
    UserBlockedUsers: 'Blocked Users',
    UserPostedEvents: 'Posted Events',
    UserPostedArtists: 'Posted Artists',
    UserPostedPlaces: 'Posted Places',
    UserManagedArtists: 'My Artists',
    UserManagedPlaces: 'My Places',
    Setting: 'Setting',
    NotificationSetting: 'Notification Setting',
    OssNotice: 'Open Source License Notices',
    NoticeFeed: 'Notices',
    NoticeDetail: 'Notices',
    NotificationFeed: 'Notifications',
    Report: 'Report',
  },
  tab: {
    EventFeed: 'Events',
    StoryFeed: 'Community',
    Places: 'Places',
    Search: 'Search',
    Profile: 'Profile',
  },
  signIn: {
    IntroText: 'Discover your next favorite artist.',
    google: 'Continue with Google',
    facebook: 'Continue with Facebook',
    twitter: 'Continue with Twitter',
    apple: 'Continue with Apple',
    kakao: 'Continue with Kakao',
    anonymous: 'Continue without Account',
    signingUp: 'Signing Up.\nPlease wait.',
    signingUpExtra:
      'Processing may be delayed depending on network conditions.',
    signUpFailure: 'The signup process timed out.\nPlease try again.',
    cannotSignInGoogle:
      'Browser may not support Google sign in. Please try in other browsers if failed.',
  },
  event: {
    card: {
      advance: 'Adv: ₩%{advance_ticket_price}',
      door: 'Door: ₩%{door_ticket_price}',
      price: 'Price: See Details',
      past: 'Past Event',
      rated: 'Rated',
    },
    detailBottomBar: {
      seeLink: 'See Link',
      reservation: 'Buy a Ticket',
      information: 'Information',
      textReference: 'Price: See Details',
    },
    detailContent: {
      seeLink: 'See Link',
      date: 'Date',
      place: 'Location',
      lineUp: 'Lineup',
      presents: 'Presents',
      story: 'Community',
      eventStory: 'Event Stories',
      postEventStory: 'Post Event Stories',
      postAttendedEvent: 'I Was There',
      editAttendedEvent: 'Attended Event',
      submitAttendedEvent: 'Do you want to add review, rating, ticket picture?',
      addAttendedEventDetail: 'Yes',
      stats: 'Estimate Revenue',
      audience: 'Estimated Ticket Sales: %{audience}',
      revenue: 'Estimated Revenue: ₩%{revenue}',
      statsWarning: 'Ticket sales and revenue are estimates and may vary.',
      statsNotFound: "A venue that doesn't provide revenue information.",
      warning:
        'Livewire is an intermediary in a online-order sale and is not a party to the online-order sale and is therefore not responsible for the product information and transactions',
    },
    formField: {
      artist: {
        validate: {
          notExist: 'Please enter a line up.',
          limit: 'You can enter up to %{ARTIST_LIMIT} teams.',
        },
        label: 'Lineup',
      },
      agency: {
        label: 'Presents',
        validate: {
          limit: 'You can enter up to %{AGENCY_LIMIT} presents.',
        },
      },
      place: {
        rules: {
          required: 'Please enter the place for the event.',
          notValid: 'This is an unregistered place.',
        },
        label: 'Place',
      },
      add: 'Add',
      event_date: {
        rules: {
          required: 'Please enter the date of the event.',
        },
        label: 'Event Date',
      },
      event_time: {
        label: 'Start Time',
      },
      advance_ticket_price: 'Adv price',
      door_ticket_price: 'Door price',
      scale: 'Seats',
      seats: 'Seats',
      payment_type: {
        free: 'Free',
        freeDonation: 'Free Donation',
        soldOut: 'Sold out',
      },
      ticket_url: 'Purchase link',
      streaming_url: 'Streaming link (YouTube, Instagram)',
      description: {
        rules: {
          required: 'Please enter event details.',
          maxLength: 'You can enter up to 5000 characters.',
        },
        label: 'Detail',
        placeholder:
          'Please enter event details.\n(Link, Instagram Username can be included.)',
      },
    },
    feed: {
      artistsOrAgencies: 'Artists, Agencies',
      places: 'Places',
      updateLocation:
        'Are you sure you want to update search location to current location?',
      district: 'Search District',
    },
    emptyEventCard: {
      not_matched: 'There is no event that satisfies the conditions.',
      check_another: 'Try changing search filter.',
      change_filter: 'Change Search Filter',
    },
  },
  story: {
    cardList: {
      noStory: "There's no post yet.",
      write: 'New Post',
      total: 'Total',
    },
    detailContent: {
      invalidStory: 'Post does not exist.',
      youtube_urls: 'YouTube Video',
      instagram_url: 'Instagram Link',
    },
    formField: {
      insertLimit:
        'You cannot post a new post within a short time from previous post.',
      description: {
        rules: {
          required: 'Please enter details',
          maxLength: 'You can enter up to 1000 characters.',
        },
        placeholder: "What's on your mind?",
        label: 'Content',
      },
      event: {
        validate: {
          not_found_id: 'This is an unregistered event.',
        },
        label: '@Event',
      },
      artist: {
        validate: {
          not_found_id: 'You are an unregistered Artist.',
        },
        label: '@Artist',
      },
      place: {
        validate: {
          not_found_id: 'This is an unregistered place.',
        },
        label: '@Place',
      },
      addMore: 'Add New',
      district: '@District',
      youtube_urls: 'YouTube link',
      instagram_url: 'Link to Instagram',
    },
    feed: {
      musicStory: 'Community',
      eventStory: 'Event Stories',
      story: '%{name} Community',
    },
  },
  userAttendedEvent: {
    cardList: {
      noUserAttendedEvent: "There's no content yet.",
      write: 'New Content',
      total: 'Total',
    },
    detailContent: {
      invalidUserAttendedEvent: 'Content has been removed.',
    },
    formField: {
      description: {
        rules: {
          required: 'Please enter details',
          maxLength: 'You can enter up to 1000 characters.',
        },
        placeholder: 'How was the event?',
        label: 'Content',
      },
      private: 'Every content in this form is private.',
      image_urls: {
        label: 'Ticket, Wristband, Stamp or Stage Pictures',
        options: {
          ticket: 'Scan Ticket',
          picture: 'Picture',
        },
      },
      cameraPermissionErrorTitle: 'Permission required',
      cameraPermissionError:
        'Camera permissions must be granted to scan tickets.',
    },
  },
  comment: {
    originalPost: 'Original Post',
    cardList: {
      noComment: 'There is no Comment',
      write: 'Write',
      commentInput: 'Please enter your comment',
    },
    invalidComment: 'Comment does not exist.',
    insertLimit: 'You cannot post a new comment within a short time.',
  },
  notification: {
    story: {
      newComment: "New comment added to post '%{title}'",
      likes: "%{likeCount} likes on your post! '%{title}'",
    },
    comment: {
      newReply: "New reply added to comment '%{title}'",
    },
    removeNotice: 'Notifications will be deleted after 30days.',
    artist: {
      newEvent: "New event of %{name} added. '%{title}' (%{district})",
      newStory: "New post added to %{name} community. '%{title}'",
      newArtistStory: "New post of %{name} has been added. '%{title}'",
    },
    agency: {
      newEvent: "New event by %{name} added. '%{title}' (%{district})",
    },
    place: {
      newEvent: "New event from %{name}. '%{title}'",
      newStory: "New post added to %{name} community. '%{title}'",
    },
  },
  place: {
    cardList: {
      place: {
        rules: {
          empty: 'No matched places.',
        },
      },
    },
    detailBottomBar: {
      toss_payment_url: 'Send Money with Toss',
      kakao_payment_url: 'Send Money with Kakao Pay',
      contact: 'Contact',
    },
    detailContent: {
      address: 'Address: %{address}',
      event: '%{name} Events',
      story: '%{name} Community',
      postStory: 'Post %{name} Post',
      naver: 'Open in Naver Map',
      kakao: 'Open in Kakao Map',
      nextEvent: 'Upcoming Events',
      favorite: 'Has been added to favorite places',
      unfavorite: 'Has been removed from favorite places',
    },
    formField: {
      address: {
        label: 'Address',
        rules: { required: 'Please Enter an address.' },
      },
      contact: {
        rules: {
          validate: 'Only email or phone number can be entered.',
        },
        label: 'Contact Us (Phone Email)',
      },
      description: {
        rules: {
          required: 'Please enter an introduction.',
          maxLength: 'You can enter up to 1000 characters.',
        },
        placeholder:
          'Please enter an introduction.\n(Link Instagram Username can be included.)',
        label: 'introduction',
      },
    },
    listScreen: {
      find_event: 'Find Events (~%{deltaToKm}Km)',
      scheduled_event: 'Upcoming events until next week',
      nothing: 'None',
      showAllPlaces: 'Display All Places',
      showAllPlacesDescription:
        'Display places where events are not scheduled.',
    },
    service: {
      address: {
        notValid: 'Unable to verify address information.',
      },
      location: {
        notValid: 'Unable to determine location information.',
        setDefault: 'Set to default location information.',
      },
    },
  },
  artist: {
    cardList: {
      artist: {
        rules: {
          empty: 'No Artists or Agencies satisfy the criteria.',
        },
      },
    },
    bottomBar: {
      toss_payment_url: 'Support with Toss',
      kakao_payment_url: 'Support with Kakao Pay',
      shop_url: 'Store',
      contact: 'Contact',
    },
    detailContent: {
      check_out_profile: "Check out %{name}'s Profile - %{appName}",
      region: 'Based in',
      event: '%{name} Events',
      agencyEvent: 'Events presented by %{name}',
      story: '%{name} Community',
      postStory: 'Post %{name} Post',
      spotify: 'Spotify',
      youtube: 'Youtube',
      liked: 'Has been added to liked musicians',
      unliked: 'Has been removed from liked musicians',
    },
    formField: {
      genre: {
        rules: {
          maxItem: 'You can only choose up to five genres.',
        },
      },
      activity_area: 'Are you active?',
      debut: 'Debut Date',
      spotify_url: 'Spotify URL',
      soundcloud_url: 'Sound Cloud URL',
      shop_url: 'Shop URL (Album, MD)',
      contact: {
        rules: {
          validate: 'Only email or phone number can be entered.',
        },
        label: 'Contact (Phone, Email)',
      },
      description: {
        placeholder:
          'Please enter an description.\n(Link, Instagram Username can be included.)',
        label: 'Bio',
        rules: {
          maxLength: 'You can enter up to 1000 characters.',
          required: 'Please enter an description.',
        },
      },
    },
    recommended: {
      upcoming: 'Artist coming to near %{district}',
      eventInfo: 'See event detail',
    },
  },
  genres: {
    alternative: 'Alternative',
    blues: 'Blues',
    classical: 'Classical',
    country: 'Country',
    dance: 'Dance',
    disco: 'Disco',
    electronic: 'Electronic',
    folk: 'Folk',
    funk: 'Funk',
    hipHop: 'Hip Hop',
    house: 'House',
    jazz: 'Jazz',
    latin: 'Latin',
    metal: 'Metal',
    pop: 'Pop',
    psychedelic: 'Psychedelic',
    punk: 'Punk',
    rAndB: 'R&B',
    reggae: 'Reggae',
    rock: 'Rock',
    shoegaze: 'Shoegaze',
    ska: 'Ska',
    soul: 'Soul',
    synthPop: 'Synth-pop',
  },
  profile: {
    join: 'Sign Up',
    formField: {
      email: {
        rules: {
          not_modified: 'Email cannot be modified.',
        },
        label: 'Email',
      },
      district: {
        rules: {
          required: 'Please specify an area of interest.',
        },
        label: 'Areas of interest',
      },
      //enterYourId: 'Please enter your ID.',
      // id: 'ID',
      name: {
        rules: {
          required: 'Please enter your nickname.',
          minLength: 'Nickname can be entered from more than 3 character.',
          maxLength: 'Nickname can be up to 30 characters.',
          whitespace: 'Nickname can not contain whitespaces.',
        },
        label: 'Nickname',
      },
      birthday: 'Date of birth',
      male: 'Male',
      female: 'Female',
      others: 'Others',
      gender: 'Gender',
      bio: {
        rules: { maxLength: 'You can enter up to 100 characters in the bio.' },
        label: 'Bio',
      },
    },
    header: {
      editProfile: 'Edit Profile',
      idCopied: 'User ID has been copied to clipboard.',
    },
    edit: {
      updated: 'Your profile has been updated.',
      content: 'Are you sure you want to leave?',
      confirmText: 'Leave',
      withdrawal: 'Remove Account',
    },
    actions: {
      region: {
        label: 'Region',
        content:
          'Are you sure you want to update your region of interest to its current location?',
      },
      likedArtists: 'Liked Artists',
      likedPlaces: 'Liked Places',
      savedEvents: 'Saved Events',
      attendedEvents: 'Attended Events',
      likedStories: 'Liked Posts',
      postedStories: 'My Posts',
      postedComments: 'My Comments',
      postedEvents: 'Posted Events',
      postedArtists: 'Posted Artists',
      postedPlaces: 'Posted Places',
      managedArtists: 'My Artists',
      managedPlaces: 'My Places',
      blockedUsers: 'Blocked Users',
    },
    empty: {
      emptyUser: 'Please sign in',
      signIn: 'Sign In',
    },
    cardList: {
      profile: {
        empty: 'No User satisfy the criteria.',
      },
    },
  },
  setting: {
    language: 'Language',
    light_mode: 'Light Mode',
    dark_mode: 'Dark Mode',
    marketing_on: 'You have agreed to receive marketing information.',
    marketing_off: 'You refused to receive marketing information.',
    notification: 'Notification Settings',
    notification_require_permission: 'Need a Notification Permission',
    notification_require_permission_text:
      'Please allow Notification Permission from Settings.',
    notification_not_supported: 'Notification Not Supported',
    notification_not_supported_text:
      'This Environment does not support a notification.',
    location: 'Location Permission',
    location_require_permission: 'Need a Location Permission',
    location_require_permission_text:
      'Please allow Location Permission from Settings.',
    tracking: 'Allow Tracking',
    tracking_require_permission: 'Tracking Permission',
    tracking_require_permission_text:
      'Please change Tracking Permission from Settings.',
    settings: 'Settings',
    notice: 'Notices',
    terms_and_conditions: 'Terms and Conditions',
    privacy_policy: 'Privacy Policy',
    marketing_agree: 'Marketing Agreement',
    customer_service: 'Customer Service',
    about: 'About',
    open_source_license: 'Open Source License',
    sign_in: 'Sign In',
    sign_out: 'Sign out',
  },
  term: {
    checkboxes: {
      pre_sentence:
        "By proceeding with the registration process, vou're agreeing to ",
      post_sentence: '.',
      accepted_terms_at: {
        rules: {
          required: 'Please agree to the terms and conditions.',
        },
      },
      terms_and_conditions: 'Terms and Conditions',
      and: ' and ',
      privacy_policy: 'Privacy Policy',
      AgreeWithYou: 'I agree',
      receiveMarketingInformation: 'Receive marketing information',
      AgreeSelect: 'I agree (Select)',
    },
    dates: {
      accepted_terms_at: 'Date of acceptance of terms and conditions',
      accepted_marketing_terms_at:
        'Date of consent to receive marketing information',
    },
    dialog: {
      agree_title: 'Agree to the terms and conditions',
      agree_content:
        'Please agree to the terms and conditions for using the service.',
      terms_and_conditions: 'Terms and Conditions',
      and: 'and',
      privacy_policy: 'Privacy Policy',
      marketing_agree: 'Agree to receive marketing information',
      select: '(Select)',
      allAgree: 'All agree',
      terms_and_conditions_required:
        'Please agree to the service terms and conditions.',
      consent: 'Consent',
    },
  },
  report: {
    submit: 'Submit',
    reported: 'Your report has been submit.',
    formField: {
      checkInput: 'Please check what you have entered.',
      description: 'Report Detail',
      pleaseEnterDescription: 'Please enter a report detail.',
      youCanEnterUpTo1000Characters: 'You can enter up to 1000 characters.',
    },
  },
};
