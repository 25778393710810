import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { FAB } from 'react-native-paper';

import ArtistCardList from '../components/artist/ArtistCardList';
import { MainStackParamList } from '../navigation/MainStack';

const ArtistListScreen: React.FC = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  return (
    <>
      <ArtistCardList
        onPressItem={artist =>
          navigation.push('ArtistDetail', { artistId: artist.id })
        }
      />
      <FAB
        icon={'plus'}
        style={{
          position: 'absolute',
          margin: 16,
          right: 0,
          bottom: 0,
          borderRadius: 30,
        }}
      />
    </>
  );
};

export default ArtistListScreen;
