import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';

import EventCardList from '../components/event/EventCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';

const PlaceEventFeedScreen: React.FC = () => {
  const route = useRoute<RouteProp<MainStackParamList, 'PlaceEventFeed'>>();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const placeId = route.params.placeId;

  useEffect(() => {
    if (navigationReady && !placeId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, placeId]);

  return (
    <EventCardList
      placeId={placeId}
      sort={'event_date_desc'}
      onPressItem={event =>
        navigation.push('EventDetail', { eventId: event.id })
      }
    />
  );
};

export default PlaceEventFeedScreen;
