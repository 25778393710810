import React, { useRef, useState } from 'react';
import { GestureResponderEvent, PanResponder, View } from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';

interface StarRatingProps {
  defaultRating?: number;
  editable?: boolean;
  onRatingChanged?: (rating: number) => void;
}

const StarIcon = ({
  iconName,
  color,
  onPress,
}: {
  iconName: string;
  color: string;
  onPress?: () => void;
}) => (
  <IconButton size={30} icon={iconName} iconColor={color} onPress={onPress} />
);

const StarRating: React.FC<StarRatingProps> = ({
  defaultRating = 0,
  editable = true,
  onRatingChanged,
}) => {
  const [rating, setRating] = useState(defaultRating);
  const starRef = useRef<View>(null);
  const theme = useTheme();

  const handleRating = (rate: number) => {
    setRating(rate);
    onRatingChanged?.(rate);
  };

  const calculateRating = (touchX: number, starWidth: number) =>
    Math.min(5, Math.max(0, Math.ceil((touchX / starWidth) * 2) / 2));

  const handleDragStar = editable
    ? (event: GestureResponderEvent) => {
        event.persist();
        if (!event?.nativeEvent?.pageX) {
          return;
        }
        if (starRef.current) {
          starRef.current.measure((fx, fy, width) => {
            const touchX = event.nativeEvent.pageX - fx;
            handleRating(calculateRating(touchX, width / 5));
          });
        }
      }
    : undefined;

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderMove: handleDragStar,
      onPanResponderRelease: handleDragStar,
    }),
  ).current;

  const renderStars = () => {
    return Array.from({ length: 5 }, (_, i) => {
      const index = i + 1;
      let iconName = 'star-outline';
      if (index <= rating) {
        iconName = 'star';
      } else if (index - 1 < rating && rating < index) {
        iconName = 'star-half-full';
      }
      return (
        <StarIcon
          key={i}
          iconName={iconName}
          onPress={editable ? () => handleRating(index) : undefined}
          color={theme.colors.primary}
        />
      );
    });
  };

  return (
    <View
      ref={starRef}
      style={{
        borderColor: theme.colors.surface,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...panResponder.panHandlers}
    >
      {renderStars()}
    </View>
  );
};

export default StarRating;
