import React from 'react';

import IconButton from './IconButton';

const MoreButton: React.FC<{
  tintColor?: string;
  onPress?: () => void;
  visible?: boolean;
}> = ({ tintColor, onPress, visible = true }) => {
  return (
    <IconButton
      shadow={true}
      size={30}
      tintColor={visible ? tintColor : 'transparent'}
      icon={'dots-horizontal'}
      onPress={visible ? onPress : undefined}
    />
  );
};

export default MoreButton;
