import {
  getCurrentPositionAsync,
  getForegroundPermissionsAsync,
  LocationAccuracy,
  LocationObject,
  requestForegroundPermissionsAsync,
} from 'expo-location';
import { earthRadius, getBoundsOfDistance } from 'geolib';
import _ from 'lodash';
import { Platform } from 'react-native';

import { LatLng, Region } from '../components/map';
import { showError } from '../components/shared/Toast';
import i18n from '../translations/i18n';
import LocalStorage, { RegionAndDistrict } from './localStorage';

const DECIMAL_LIMIT = 5;
const KM_UNIT = 1000;
const EARTH_RADIUS_KM = earthRadius / KM_UNIT;
const RADIANS_PER_DEGREE = Math.PI / 180;

export function toFixed(number: number): number {
  return _.round(number, DECIMAL_LIMIT);
}

export function deltaToKm(delta: number): number {
  return Math.round(delta * EARTH_RADIUS_KM * RADIANS_PER_DEGREE) || 1;
}

export function kmToDelta(km: number): number {
  return toFixed(km / EARTH_RADIUS_KM / RADIANS_PER_DEGREE);
}

export function deltaToZoom(delta: number): number {
  return Math.round(Math.log(360 / delta) / Math.LN2);
}

export function zoomToDelta(zoom: number): number {
  return toFixed(Math.exp(Math.log(360) - zoom * Math.LN2));
}

// Default Location: Hongdae
export const DEFAULT_DISTRICT = i18n.t('common.basicRegion');
export const DEFAULT_LOCATION: LatLng = {
  latitude: 37.55747,
  longitude: 126.91667,
};

export const DEFAULT_DISTANCE_KM = 10;
export const DEFAULT_DELTA = toFixed(kmToDelta(DEFAULT_DISTANCE_KM));
export const DEFAULT_ZOOM = deltaToZoom(DEFAULT_DELTA);
export const DEFAULT_REGION: Region = {
  ...DEFAULT_LOCATION,
  latitudeDelta: DEFAULT_DELTA,
  longitudeDelta: DEFAULT_DELTA,
};

export const toXY = ({
  longitude,
  latitude,
}: LatLng): { x: number; y: number } => ({
  x: (earthRadius * longitude * Math.PI) / 180,
  y: earthRadius * Math.log(Math.tan(((90 + latitude) * Math.PI) / 360)),
});

export function getNearby(region: Region): { lesser: LatLng; greater: LatLng } {
  const location: LatLng = {
    latitude: toFixed(region.latitude),
    longitude: toFixed(region.longitude),
  };
  const distance = deltaToKm(region.latitudeDelta);
  const [lesser, greater] = getBoundsOfDistance(location, distance * KM_UNIT);
  return {
    lesser: _.mapValues(lesser, toFixed),
    greater: _.mapValues(greater, toFixed),
  };
}

export async function getLocationPermission(): Promise<boolean> {
  const { status: existingStatus } = await getForegroundPermissionsAsync();
  let enabled = existingStatus === 'granted';
  if (!enabled) {
    const { status } = await requestForegroundPermissionsAsync();
    enabled = status === 'granted';
  }
  await LocalStorage.setLocation(enabled);
  return enabled;
}

export async function getCurrentLocation(): Promise<LatLng> {
  let permission = await LocalStorage.getLocation();
  if (!permission) {
    permission = await getLocationPermission();
  }
  if (!permission) {
    showError(
      i18n.t('common.location.rules.permission'),
      i18n.t('common.location.rules.permissionExtra'),
    );
    return DEFAULT_LOCATION;
  }
  const currentLocation = await getLocation();
  if (!currentLocation) {
    showError(
      i18n.t('place.service.location.notValid'),
      i18n.t('place.service.location.setDefault'),
    );
    return DEFAULT_LOCATION;
  }
  return {
    latitude: toFixed(currentLocation.coords.latitude),
    longitude: toFixed(currentLocation.coords.longitude),
  };
}

function delay(timeInMilliseconds: number) {
  return new Promise<null>(resolve => {
    setTimeout(() => resolve(null), timeInMilliseconds);
  });
}

export async function getLocation(): Promise<LocationObject | null> {
  const ANDROID_DELAY_IN_MS = 4 * 1000;
  const IOS_DELAY_IN_MS = 15 * 1000;
  const DELAY_IN_MS =
    Platform.OS === 'ios' ? IOS_DELAY_IN_MS : ANDROID_DELAY_IN_MS;
  const MAX_TRIES = 3;
  let tries = 1;
  let location: LocationObject | null = null;
  do {
    try {
      location = await Promise.race([
        delay(DELAY_IN_MS),
        getCurrentPositionAsync({
          accuracy: LocationAccuracy.Balanced,
          distanceInterval: 0,
        }),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      tries += 1;
    }
  } while (!location && tries <= MAX_TRIES);
  return location;
}

export async function getStorageRegion(): Promise<Region> {
  let region = await LocalStorage.getRegion();
  if (!region) {
    const location = await getCurrentLocation();
    region = { ...DEFAULT_REGION, ...location };
    await LocalStorage.setRegion(region);
  }
  return region;
}

export async function setStorageRegionAndDistrict(
  regionAndDistrict: RegionAndDistrict,
): Promise<void> {
  await LocalStorage.setRegionAndDistrict(regionAndDistrict);
}
