import { User } from '@supabase/gotrue-js';

import { showError } from '../components/shared/Toast';
import supabase, { signOut } from '../supabase';
import { Database } from '../types/database';
import { Event } from './EventRepository';

type UserAttendedEventResponse =
  Database['public']['Tables']['users_attended_events']['Row'];

export interface UserAttendedEvent
  extends Omit<NonNullable<UserAttendedEventResponse>, 'event'> {
  event: Event;
}

export type UserBlockedUser =
  Database['public']['Tables']['users_blocked_users']['Row'];

export default class UserRepository {
  private static tableBlockedUsers = () => supabase.from('users_blocked_users');

  static async getCurrentUser(): Promise<User | undefined> {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      showError(error);
      await signOut();
      return;
    }
    if (!data) {
      await signOut();
      return;
    }
    return data?.session?.user;
  }

  static async insertUserSavedEvent(eventId: string) {
    const { error } = await supabase.rpc('save_event', {
      event_id: eventId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async deleteUserSavedEvent(eventId: string) {
    const { error } = await supabase.rpc('unsave_event', {
      event_id: eventId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async getUserAttendedEvent(userId: string, eventId: string) {
    return supabase
      .from('users_attended_events')
      .select('*, event:events(*)')
      .eq('user_id', userId)
      .eq('attended_event_id', eventId)
      .maybeSingle();
  }

  static async upsertUserAttendedEvent(event: Partial<UserAttendedEvent>) {
    return supabase
      .from('users_attended_events')
      .upsert(event as UserAttendedEvent)
      .select()
      .maybeSingle();
  }

  static async deleteUserAttendedEvent(userId: string, eventId: string) {
    return supabase
      .from('users_attended_events')
      .delete()
      .eq('user_id', userId)
      .eq('attended_event_id', eventId);
  }

  static async insertUserLikedStory(storyId: string) {
    const { error } = await supabase.rpc('like_story', {
      story_id: storyId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async deleteUserLikedStory(storyId: string) {
    const { error } = await supabase.rpc('unlike_story', {
      story_id: storyId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async insertUserLikedArtist(artistId: string) {
    const { error } = await supabase.rpc('like_artist', {
      artist_id: artistId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async deleteUserLikedArtist(artistId: string) {
    const { error } = await supabase.rpc('unlike_artist', {
      artist_id: artistId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async insertUserFavoritePlace(placeId: string) {
    const { error } = await supabase.rpc('favorite_place', {
      place_id: placeId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async deleteUserFavoritePlace(placeId: string) {
    const { error } = await supabase.rpc('unfavorite_place', {
      place_id: placeId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async insertUserBlockedUser(blockedUserId: string) {
    return this.tableBlockedUsers().insert({
      blocked_user_id: blockedUserId,
    });
  }

  static async deleteUserBlockedUser(userId: string, blockedUserId: string) {
    return this.tableBlockedUsers()
      .delete()
      .eq('user_id', userId)
      .eq('blocked_user_id', blockedUserId);
  }
}
