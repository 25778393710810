import http from '../utils/http';

export interface RelatedNews {
  title: string;
  link: string;
  description: string;
  pubDate: string;
}

export default class NewsRepository {
  static async getEventRelatedNews(
    eventId: string,
  ): Promise<RelatedNews | undefined> {
    try {
      const { data } = await http.get<RelatedNews>(
        `/api/news/events/${eventId}`,
      );
      return data;
    } catch {}
  }

  static async getArtistRelatedNews(
    artistId: string,
  ): Promise<RelatedNews | undefined> {
    try {
      const { data } = await http.get<RelatedNews>(
        `/api/news/artists/${artistId}`,
      );
      return data;
    } catch {}
  }

  static async getPlaceRelatedNews(
    placeId: string,
  ): Promise<RelatedNews | undefined> {
    try {
      const { data } = await http.get<RelatedNews>(
        `/api/news/places/${placeId}`,
      );
      return data;
    } catch {}
  }
}
