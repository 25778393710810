import React, { useState } from 'react';
import { Button, Dialog, Switch, Text } from 'react-native-paper';

import i18n from '../../translations/i18n';
import { Column, Row } from '../shared/Layouts';

const PlaceFilterDialog: React.FC<{
  showAllPlaces: boolean;
  setShowAllPlaces: (showAllPlaces: boolean) => void;
  hideDialog: () => void;
}> = ({ showAllPlaces, setShowAllPlaces, hideDialog }) => {
  const [allPlaces, setAllPlaces] = useState(showAllPlaces);
  const onConfirm = async () => {
    setShowAllPlaces(allPlaces);
    hideDialog();
  };
  return (
    <>
      <Dialog.Content>
        <Column style={{ gap: 20, paddingTop: 24 }}>
          <Row style={{ gap: 10 }}>
            <Column
              style={{
                gap: 5,
                width: '80%',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 15 }}>
                {i18n.t('place.listScreen.showAllPlaces')}
              </Text>
              <Text style={{ width: '100%' }} variant={'bodySmall'}>
                {i18n.t('place.listScreen.showAllPlacesDescription')}
              </Text>
            </Column>
            <Switch value={allPlaces} onValueChange={setAllPlaces} />
          </Row>
        </Column>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={hideDialog}>{i18n.t('common.cancel')}</Button>
        <Button
          labelStyle={{
            fontWeight: 'bold',
          }}
          onPress={() => onConfirm()}
        >
          {i18n.t('common.apply')}
        </Button>
      </Dialog.Actions>
    </>
  );
};

export default PlaceFilterDialog;
