import supabase from '../supabase';
import UserRepository from './UserRepository';

type NotificationResponse = Awaited<
  ReturnType<typeof NotificationRepository.getNotification>
>;
type NotificationResponseSuccess = NotificationResponse['data'];

export type Notification = NonNullable<NotificationResponseSuccess>;

export interface NotificationsParams {
  actor?: string;
  notifier?: string;
  read?: boolean;
  offset?: number;
}

export default class NotificationRepository {
  private static table = () => supabase.from('notifications');
  static PAGE_SIZE = 30;

  static async getNotification(notificationId: number) {
    return this.table().select('*').eq('id', notificationId).maybeSingle();
  }

  static async getNotifications({ notifier, offset = 0 }: NotificationsParams) {
    return this.table()
      .select('*')
      .eq('notifier_user_id', notifier)
      .order('created_at', { ascending: false })
      .range(offset, offset + NotificationRepository.PAGE_SIZE - 1);
  }

  static async getNewNotificationsCount() {
    const user = await UserRepository.getCurrentUser();
    if (!user?.id) {
      return { count: 0, error: null };
    }
    return this.table()
      .select('*', { count: 'exact', head: true })
      .eq('notifier_user_id', user?.id)
      .eq('read', false);
  }

  static async getNewEventNotificationsCount() {
    const user = await UserRepository.getCurrentUser();
    if (!user?.id) {
      return { count: 0, error: null };
    }
    return this.table()
      .select('*', { count: 'exact', head: true })
      .eq('notifier_user_id', user?.id)
      .eq('resource_type', 'events')
      .eq('read', false);
  }

  static async getNewStoryNotificationsCount() {
    const user = await UserRepository.getCurrentUser();
    if (!user?.id) {
      return { count: 0, error: null };
    }
    return this.table()
      .select('*', { count: 'exact', head: true })
      .eq('notifier_user_id', user?.id)
      .neq('resource_type', 'events')
      .eq('read', false);
  }

  static async readNotifications() {
    const user = await UserRepository.getCurrentUser();
    return this.table()
      .update({ read: true })
      .eq('notifier_user_id', user?.id)
      .eq('read', false);
  }

  static async addNotification(notification: Partial<Notification>) {
    return this.table().insert(notification as Notification);
  }
}
