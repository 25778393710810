import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useState } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Comment } from '../../repositories/CommentRepository';
import { Story } from '../../repositories/StoryRepository';
import { useInsertCommentMutation } from '../../services/CommentService';
import i18n from '../../translations/i18n';
import { getId } from '../../utils/id';
import { FormTextInput } from '../form/FormInputs';
import { Row } from '../shared/Layouts';
import Loading from '../shared/Loading';
import { showInfo } from '../shared/Toast';

const CommentInput = React.forwardRef<TextInput, { parent: Story | Comment }>(
  ({ parent }, ref) => {
    const theme = useTheme();
    const insets = useSafeAreaInsets();
    const user = useUser();
    const [paddingBottom, setPaddingBottom] = useState(insets.bottom);
    const [comment, setComment] = useState<string>('');
    const { mutate: insertComment, isLoading } = useInsertCommentMutation();
    const onSubmit = async () => {
      if (!comment.trim().length) {
        return;
      }
      let story_id = parent.id;
      let parent_comment_id;
      if ('story_id' in parent) {
        const comment = parent as Comment;
        story_id = comment.story_id;
        parent_comment_id = comment.id;
      }
      await insertComment({
        comment: {
          id: getId(),
          story_id,
          parent_comment_id,
          description: comment,
          created_by: user?.id,
        },
      });
      setComment('');
    };

    const onFocus = () => {
      if (Platform.OS === 'web') {
        setPaddingBottom(0);
      }
    };
    const onBlur = () => {
      setTimeout(() => {
        if (Platform.OS === 'web') {
          setPaddingBottom(insets.bottom);
        }
      });
    };

    return (
      <KeyboardAvoidingView
        keyboardVerticalOffset={Platform.select({
          ios: 90,
          android: -250,
        })}
        behavior={'padding'}
        style={{
          marginBottom: paddingBottom,
        }}
      >
        {isLoading && <Loading />}
        <TouchableOpacity
          onPress={() =>
            user ? undefined : showInfo(i18n.t('common.pleaseSignIn'))
          }
        >
          <Row
            pointerEvents={user ? undefined : 'none'}
            style={{
              justifyContent: 'space-between',
              borderStyle: 'solid',
              borderTopWidth: 1,
              borderTopColor: theme.colors.elevation.level3,
              paddingHorizontal: 10,
              maxHeight: 120,
            }}
          >
            <FormTextInput
              minHeight={40}
              maxHeight={90}
              maxLength={1000}
              multiline={true}
              disabled={!user}
              ref={ref}
              theme={theme}
              style={{ flex: 9 }}
              dense={true}
              outlineStyle={{
                borderRadius: 20,
                backgroundColor: theme.colors.elevation.level2,
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              outlineColor={'transparent'}
              mode={'outlined'}
              placeholder={
                user
                  ? i18n.t('comment.cardList.commentInput')
                  : i18n.t('common.pleaseSignIn')
              }
              placeholderTextColor={theme.colors.elevation.level5}
              onSubmitEditing={onSubmit}
              value={comment}
              onChangeText={setComment}
            />
            <TouchableOpacity
              disabled={!comment.trim().length}
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'flex-end',
                paddingBottom: 20,
              }}
              onPress={onSubmit}
            >
              <MaterialCommunityIcons
                color={
                  comment.trim().length
                    ? theme.colors.primary
                    : theme.colors.onSurfaceDisabled
                }
                name={'send'}
                size={25}
              />
            </TouchableOpacity>
          </Row>
        </TouchableOpacity>
      </KeyboardAvoidingView>
    );
  },
);

export default CommentInput;
