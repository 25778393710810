import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { Region } from '../components/map';
import { sendError } from '../components/shared/Toast';
import { Artist } from '../repositories/ArtistRepository';
import EventRepository, {
  Event,
  EventsParams,
} from '../repositories/EventRepository';
import NewsRepository, { RelatedNews } from '../repositories/NewsRepository';
import UserRepository, {
  UserAttendedEvent,
} from '../repositories/UserRepository';
import { isEqual } from '../utils/helpers';
import { refetchAll, resetAll } from '../utils/queryClient';
import { UserProfile, UserService } from './UserService';

export default class EventService {
  static hasEditPermission(
    event?: Event | null,
    user?: UserProfile | null,
  ): boolean {
    if (!event || !user) {
      return false;
    }
    if (event.created_by === user.id || user.admin) {
      return true;
    }
    if (UserService.isManager(user, event.artists)) {
      return true;
    }
    return UserService.isOwner(user, event.place);
  }

  static async getEvents(params: EventsParams): Promise<{
    events: Event[];
    offset?: number;
    end: boolean;
  }> {
    const { data } =
      params.sort === 'distance'
        ? await EventRepository.getEventsWithDistance(params)
        : await EventRepository.getEvents(params);
    const events = ((data ?? []) as Event[]).filter(
      event => !event?.created?.blocked?.length,
    );
    return {
      events,
      offset: params.offset,
      end: !events?.length,
    };
  }

  static async upsertEvent({
    artists,
    agencies,
    place,
    created,
    saved,
    attended,
    ...event
  }: NonNullable<Event>) {
    event.place_id = place!.id;
    const { data: upserted, error } = await EventRepository.upsertEvent(
      event as Event,
    );
    if (error) {
      return Promise.reject(error);
    }
    if (!upserted) {
      throw new Error('Upsert failure');
    }

    const isArtistsDirty = !isEqual(upserted.artists as Artist[], artists);
    if (isArtistsDirty) {
      await EventRepository.deleteEventArtists(event.id);
      const toUpsert =
        artists?.map(artist => ({
          event_id: event.id,
          artist_id: artist!.id,
        })) ?? [];
      await EventRepository.upsertEventArtists(toUpsert);
    }

    const isAgencyDirty = !isEqual(upserted.agencies as Artist[], agencies);
    if (isAgencyDirty) {
      await EventRepository.deleteEventAgencies(event.id);
      const toUpsert =
        agencies?.map(agency => ({
          event_id: event.id,
          agency_id: agency!.id,
        })) ?? [];
      await EventRepository.upsertEventAgencies(toUpsert);
    }
    return upserted;
  }

  static async saveEvent(eventId: string): Promise<void> {
    try {
      await UserRepository.insertUserSavedEvent(eventId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }

  static async unsaveEvent(eventId: string): Promise<void> {
    try {
      await UserRepository.deleteUserSavedEvent(eventId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }

  static async attendEvent(eventId: string, userId: string): Promise<void> {
    try {
      await UserRepository.upsertUserAttendedEvent({
        attended_event_id: eventId,
        user_id: userId,
      } as UserAttendedEvent);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }

  static async unAttendEvent(eventId: string, userId: string): Promise<void> {
    try {
      await UserRepository.deleteUserAttendedEvent(userId, eventId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }
}

export function useEventsQuery(params: EventsParams) {
  return useInfiniteQuery(
    ['events', params],
    async ({ pageParam }) =>
      await EventService.getEvents({ ...params, offset: pageParam }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + EventRepository.PAGE_SIZE,
    },
  );
}

export function useEventDetailQuery(id?: string) {
  return useQuery<Event | null>(['eventDetail', id], async () => {
    if (!id) {
      return null;
    }
    const event = (await EventRepository.getEvent(id)).data as Event;
    if (event?.created?.blocked?.length) {
      return null;
    }
    return event;
  });
}

export function useEventRelatedNewsQuery(id?: string) {
  return useQuery<RelatedNews | null>(['eventRelatedNews', id], async () => {
    if (!id) {
      return null;
    }
    const news = await NewsRepository.getEventRelatedNews(id);
    if (!news?.title) {
      return null;
    }
    return news;
  });
}

export function useClosestPlaceEventsQuery(placeId?: string) {
  return useQuery<Event[] | null>(['placeEvent', placeId], async () => {
    if (!placeId) {
      return null;
    }
    const events = (await EventRepository.getClosestPlaceEvents(placeId))
      .data as Event[];
    return events?.filter(event => !event.created?.blocked?.length);
  });
}

export function useUpcomingEventQuery(region?: Region) {
  return useQuery<Event[] | null>(['upcomingEvent', region], async () => {
    if (!region) {
      return null;
    }
    const { data: events } = await EventRepository.getUpcomingEvents(region);
    return events as Event[];
  });
}

export function useUpsertEventMutation() {
  return useMutation((event: Event) => EventService.upsertEvent(event), {
    onSuccess: async event => await resetAll(),
  });
}

export function useDeleteEventMutation(eventId?: string) {
  return useMutation(
    () =>
      eventId
        ? EventRepository.deleteEvent(eventId)
        : Promise.reject(new Error('Invalid EventId')),
    {
      onSuccess: async () => await resetAll(),
    },
  );
}
