import supabase from '../supabase';
import { Database } from '../types/database';
import { Profile } from './ProfileRepository';
import { UserBlockedUser } from './UserRepository';

type CommentResponse = Database['public']['Tables']['comments']['Row'];

export interface Comment extends CommentResponse {
  created?: Profile & {
    blocked?: UserBlockedUser[];
  };
}

export interface CommentsParams {
  storyId?: string;
  commentId?: string;
  postedUserId?: string;
  searchQuery?: string;
  offset?: number;
}

export default class CommentRepository {
  private static table = () => supabase.from('comments');
  static PAGE_SIZE = 20;

  private static getDefaultQuery() {
    return this.table().select(
      '*, created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))',
    );
  }

  static async getComment(commentId: string) {
    return this.table()
      .select(
        '*, created:profiles!created_by(id, name, photo_url, blocked:users_blocked_users!blocked_user_id(blocked_user_id))',
      )
      .eq('id', commentId)
      .eq('display', true)
      .maybeSingle();
  }

  static async getComments({
    storyId,
    commentId,
    postedUserId,
    offset = 0,
  }: CommentsParams) {
    const query = this.getDefaultQuery();
    if (storyId) {
      query.eq('story_id', storyId);
    }
    if (commentId) {
      query.or(`id.eq.${commentId},parent_comment_id.eq.${commentId}`);
    }
    if (postedUserId) {
      query.eq('created_by', postedUserId);
    }
    return query
      .eq('display', true)
      .order('created_at', { ascending: false })
      .order('id')
      .range(offset, offset + CommentRepository.PAGE_SIZE - 1);
  }

  static async getAllComments({ storyId, commentId }: CommentsParams) {
    const query = this.getDefaultQuery();
    if (storyId) {
      query.eq('story_id', storyId);
    }
    if (commentId) {
      query.or(`id.eq.${commentId},parent_comment_id.eq.${commentId}`);
    }
    return query.eq('display', true).order('created_at').order('id');
  }

  static async insertComment(comment: Comment) {
    const { error } = await supabase.rpc('add_comment', {
      id: comment.id,
      story_id: comment.story_id,
      parent_comment_id: comment.parent_comment_id ?? undefined,
      description: comment.description ?? undefined,
    });
    if (error) {
      return Promise.reject(error);
    }
  }

  static async deleteComment(id: string, storyId: string) {
    const { error } = await supabase.rpc('delete_comment', {
      id,
      story_id: storyId,
    });
    if (error) {
      return Promise.reject(error);
    }
  }
}
