import React, { createContext, useContext, useEffect, useState } from 'react';
import { Appearance, ColorSchemeName } from 'react-native';

import i18n, { DEFAULT_LOCALE } from '../../translations/i18n';
import { getLocale } from '../../translations/locale';
import LocalStorage from '../../utils/localStorage';

const AppearanceProviderContext = createContext<{
  colorMode: ColorSchemeName;
  setColorMode: (colorMode: ColorSchemeName) => void;
  locale: string;
  setLocale: (locale: string) => void;
}>({
  colorMode: Appearance.getColorScheme(),
  setColorMode: _ => {},
  locale: getLocale(),
  setLocale: _ => {},
});

export const useAppearanceContext = () => useContext(AppearanceProviderContext);

const AppearanceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [colorMode, setColorMode] = useState<ColorSchemeName>();
  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);

  const getStorageColorMode = async () => {
    let storageColorMode = await LocalStorage.getColorMode();
    if (!storageColorMode) {
      storageColorMode = Appearance.getColorScheme();
      await LocalStorage.setColorMode(storageColorMode);
    }
    setColorMode(storageColorMode);
  };

  const setStorageColorMode = async (colorMode: ColorSchemeName) => {
    setColorMode(colorMode);
    await LocalStorage.setColorMode(colorMode);
  };

  const getStorageLocale = async () => {
    let storageLocale = await LocalStorage.getLocale();
    if (!storageLocale) {
      storageLocale = getLocale();
      await LocalStorage.setLocale(storageLocale);
    }
    i18n.locale = storageLocale;
    setLocale(storageLocale);
  };

  const setStorageLocale = async (locale: string) => {
    i18n.locale = locale;
    setLocale(locale);
    await LocalStorage.setLocale(locale);
  };

  useEffect(() => {
    getStorageColorMode();
    getStorageLocale();
  }, []);

  return (
    <AppearanceProviderContext.Provider
      value={{
        colorMode,
        setColorMode: setStorageColorMode,
        locale,
        setLocale: setStorageLocale,
      }}
    >
      {children}
    </AppearanceProviderContext.Provider>
  );
};

export default AppearanceProvider;
