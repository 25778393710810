import * as Linking from 'expo-linking';
import React from 'react';
import { Controller } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';
import { Checkbox, HelperText, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import i18n from '../../translations/i18n';
import { WEB_URL } from '../../utils/constants';
import { ProfileFormType } from './ProfileForm';

const CheckboxContainer = styled.TouchableOpacity`
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const TermCheckboxes: React.FC<{
  form: ProfileFormType;
}> = ({ form }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = form;
  return (
    <>
      <Controller
        control={control}
        name={'accepted_terms_at'}
        rules={{
          required: i18n.t('term.checkboxes.accepted_terms_at.rules.required'),
        }}
        render={({ field: { value } }) => (
          <>
            <CheckboxContainer
              onPress={() => {
                if (value) {
                  setValue('accepted_terms_at', null);
                } else {
                  setValue('accepted_terms_at', new Date().toISOString());
                }
              }}
            >
              <Checkbox status={value ? 'checked' : 'unchecked'} />
              <Text>
                <TouchableOpacity
                  onPress={() => Linking.openURL(`${WEB_URL}/terms`)}
                >
                  <Text
                    style={{
                      fontWeight: 'bold',
                      textDecorationLine: 'underline',
                    }}
                  >
                    {i18n.t('term.checkboxes.terms_and_conditions')}
                  </Text>
                </TouchableOpacity>
                <Text> {i18n.t('term.checkboxes.and')} </Text>
                <TouchableOpacity
                  onPress={() => Linking.openURL(`${WEB_URL}/terms/privacy`)}
                >
                  <Text
                    style={{
                      fontWeight: 'bold',
                      textDecorationLine: 'underline',
                    }}
                  >
                    {i18n.t('term.checkboxes.privacy_policy')}
                  </Text>
                </TouchableOpacity>
                {i18n.t('term.checkboxes.AgreeWithYou')}
              </Text>
            </CheckboxContainer>
            {errors.accepted_terms_at?.message && (
              <HelperText style={{ width: '100%' }} type={'error'}>
                {errors.accepted_terms_at?.message as string}
              </HelperText>
            )}
          </>
        )}
      />
      <Controller
        control={control}
        name={'accepted_marketing_terms_at'}
        render={({ field: { value } }) => (
          <CheckboxContainer
            onPress={() => {
              if (value) {
                setValue('accepted_marketing_terms_at', null);
              } else {
                setValue(
                  'accepted_marketing_terms_at',
                  new Date().toISOString(),
                );
              }
            }}
          >
            <Checkbox status={value ? 'checked' : 'unchecked'} />
            <Text>
              <TouchableOpacity
                onPress={() => Linking.openURL(`${WEB_URL}/terms/marketing`)}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    textDecorationLine: 'underline',
                  }}
                >
                  {i18n.t('term.checkboxes.receiveMarketingInformation')}
                </Text>
              </TouchableOpacity>
              {i18n.t('term.checkboxes.AgreeSelect')}
            </Text>
          </CheckboxContainer>
        )}
      />
    </>
  );
};

export default TermCheckboxes;
