import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import UserAttendedEventForm from '../components/event/UserAttendedEventForm';
import Loading from '../components/shared/Loading';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { useUserAttendedEventDetailQuery } from '../services/UserAttendedEventService';
import { FORM_BOTTOM_PADDING } from '../utils/dimensions';

const UserAttendedEventEditScreen: React.FC = () => {
  const insets = useSafeAreaInsets();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route =
    useRoute<RouteProp<MainStackParamList, 'UserAttendedEventEdit'>>();
  useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  const eventId = route.params.eventId;
  const user = useUser();

  useEffect(() => {
    if (navigationReady && !user) {
      navigation.replace('SignIn');
    }
    if (navigationReady && !eventId) {
      navigation.replace('UserAttendedEvents');
    }
  }, [navigationReady, user, eventId]);

  const {
    data: userAttendedEvent,
    isLoading,
    isFetching,
    isFetched,
  } = useUserAttendedEventDetailQuery(user?.id, eventId);

  useEffect(() => {
    if (isFetched) {
      if (!userAttendedEvent || userAttendedEvent.user_id !== user?.id) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('UserAttendedEvents');
        }
      }
    }
  }, [isFetched]);

  const loading = isLoading || isFetching || !userAttendedEvent;
  return loading ? (
    <Loading />
  ) : (
    <KeyboardAwareScrollView
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{
        alignItems: 'center',
        marginVertical: 20,
        paddingBottom: insets.bottom + FORM_BOTTOM_PADDING,
      }}
    >
      {userAttendedEvent && (
        <UserAttendedEventForm
          event={userAttendedEvent.event}
          userAttendedEvent={userAttendedEvent}
        />
      )}
    </KeyboardAwareScrollView>
  );
};

export default UserAttendedEventEditScreen;
