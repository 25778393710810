import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';

import { MainStackParamList } from '../../navigation/MainStack';
import { StoriesParams, Story } from '../../repositories/StoryRepository';
import { useStoriesQuery } from '../../services/StoryService';
import i18n from '../../translations/i18n';
import { SmallLoading } from '../shared/Loading';
import { showInfo } from '../shared/Toast';
import { EmptyStoryCard } from './EmptyStoryCard';
import StoryCard from './StoryCard';

const StoryCardList: React.FC<
  StoriesParams & {
    thumbnail?: boolean;
    header?: React.ReactElement;
    footer?: React.ReactElement;
    onPressItem: (story: Story) => void;
    onPressReset: () => void;
    onRefresh?: () => Promise<void>;
  }
> = ({
  thumbnail,
  header,
  footer,
  onPressItem,
  onPressReset,
  onRefresh,
  ...params
}) => {
  const user = useUser();
  const {
    data: stories,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = useStoriesQuery(params);
  const [refreshing, setRefreshing] = useState(false);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute();

  const ref = useRef<FlatList>(null);
  useScrollToTop(ref);

  const handleRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    if (onRefresh) {
      await onRefresh();
    }
    setRefreshing(false);
  };

  const loading = isLoading || isFetching;
  const empty = !!stories?.pages?.[0]?.end;
  return empty ? (
    <>
      {header && header}
      <EmptyStoryCard
        eventTotal={!(params.likedUserId ?? params.postedUserId)}
        onPressReset={onPressReset}
        onPressAdd={() => {
          if (!user) {
            showInfo(i18n.t('common.pleaseSignIn'));
            return;
          }
          navigation.push('StoryAdd', { ...route.params });
        }}
      />
      {footer && footer}
    </>
  ) : (
    <>
      <FlatList
        showsVerticalScrollIndicator={false}
        ref={ref}
        data={stories?.pages?.map(({ stories }) => stories).flat()}
        renderItem={({ item }) => (
          <StoryCard
            key={item.id}
            story={item!}
            thumbnail={thumbnail}
            onPress={onPressItem}
          />
        )}
        keyExtractor={(item, index) => `${item?.id + index}`}
        onEndReached={() => !loading && hasNextPage && fetchNextPage()}
        onEndReachedThreshold={0.5}
        ListHeaderComponent={refreshing || loading ? null : header}
        ListFooterComponent={
          refreshing ? null : loading ? (
            <>
              {footer && footer}
              <SmallLoading />
            </>
          ) : (
            footer
          )
        }
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
      />
    </>
  );
};

export default StoryCardList;
