import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import * as Linking from 'expo-linking';
import React, { useEffect, useState } from 'react';
import { Platform, Pressable, View } from 'react-native';
import { MD3Theme, SegmentedButtons, Switch } from 'react-native-paper';

import { getTrackingPermission } from '../../analytics';
import { MainStackParamList } from '../../navigation/MainStack';
import {
  getNotificationPermission,
  subscribeCommonTopic,
  unsubscribeCommonTopic,
} from '../../notification';
import PushTokenRepository from '../../repositories/PushTokenRepository';
import { UserService } from '../../services/UserService';
import { signOut } from '../../supabase';
import i18n from '../../translations/i18n';
import { WEB_URL } from '../../utils/constants';
import { formatDateTime } from '../../utils/datetimes';
import LocalStorage from '../../utils/localStorage';
import { getLocationPermission } from '../../utils/locationHelpers';
import DefaultDialog from '../dialogs/DefaultDialog';
import { useAppearanceContext } from '../providers/AppearanceProvider';
import { useDialog } from '../providers/DialogProvider';
import { sendError, showInfo } from '../shared/Toast';
import SettingActionButton, {
  SettingActionToggleButton,
} from './SettingActionButton';

const SettingActions: React.FC<{
  theme: MD3Theme;
}> = ({ theme }) => {
  const { showDialog, hideDialog } = useDialog();
  const user = useUser();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { colorMode, setColorMode, locale, setLocale } = useAppearanceContext();
  const [notification, setNotification] = useState(false);
  const [location, setLocation] = useState(false);
  const [tracking, setTracking] = useState(false);
  const [notificationUpdating, setNotificationUpdating] = useState(false);
  const [locationUpdating, setLocationUpdating] = useState(false);

  useEffect(() => {
    (async () => {
      setNotification(!!(await LocalStorage.getNotification()));
      let location = await LocalStorage.getLocation();
      if (typeof location === 'undefined') {
        location = await getLocationPermission();
      }
      setLocation(location);
      const tracking = await getTrackingPermission();
      setLocation(location);
      setTracking(tracking);
    })();
  }, []);

  const toggleNotification = async (nextValue: boolean) => {
    setNotificationUpdating(true);
    const { enabled, pushToken } = await getNotificationPermission();
    if (!enabled) {
      if (Platform.OS === 'web') {
        showDialog(
          <DefaultDialog
            title={i18n.t('setting.notification_not_supported')}
            content={i18n.t('setting.notification_not_supported_text')}
            dismissText={i18n.t('common.confirm')}
            onPressDismiss={hideDialog}
          />,
        );
      } else {
        showDialog(
          <DefaultDialog
            title={i18n.t('setting.notification_require_permission')}
            content={i18n.t('setting.notification_require_permission_text')}
            confirmText={i18n.t('setting.settings')}
            dismissText={i18n.t('common.confirm')}
            onPressDismiss={hideDialog}
            onPressConfirm={() => Linking.openSettings()}
          />,
        );
      }
      await LocalStorage.setNotification(false);
      setNotification(false);
      setNotificationUpdating(false);
      return;
    }
    if (nextValue) {
      const now = new Date();
      await Promise.all([
        subscribeCommonTopic(),
        UserService.updateUserData({
          accepted_marketing_terms_at: now.toISOString(),
        }),
      ]);
      if (nextValue && pushToken && user) {
        try {
          await PushTokenRepository.insertPushToken({
            token: pushToken,
            user_id: user.id,
            platform: Platform.OS,
          });
        } catch (e) {
          sendError(e);
        }
      }
      showInfo(i18n.t('setting.marketing_on'), formatDateTime(now)!);
    } else {
      await Promise.all([
        unsubscribeCommonTopic(),
        UserService.updateUserData({
          accepted_marketing_terms_at: null,
        }),
      ]);
      if (pushToken) {
        try {
          await PushTokenRepository.deletePushToken(pushToken);
        } catch (e) {
          sendError(e);
        }
      }
      showInfo(i18n.t('setting.marketing_off'));
    }
    await LocalStorage.setNotification(nextValue);
    setNotification(nextValue);
    setNotificationUpdating(false);
  };

  const toggleLocation = async () => {
    setLocationUpdating(true);
    const enabled = await getLocationPermission();
    if (!enabled) {
      showDialog(
        <DefaultDialog
          title={i18n.t('setting.location_require_permission')}
          content={i18n.t('setting.location_require_permission_text')}
          confirmText={
            Platform.OS !== 'web' ? i18n.t('setting.settings') : undefined
          }
          dismissText={i18n.t('common.confirm')}
          onPressDismiss={hideDialog}
          onPressConfirm={
            Platform.OS !== 'web' ? () => Linking.openSettings() : undefined
          }
        />,
      );
      await LocalStorage.setLocation(false);
      setLocation(false);
      setLocationUpdating(false);
      return;
    }
    const nextValue = !location;
    await LocalStorage.setLocation(nextValue);
    setLocation(nextValue);
    setLocationUpdating(false);
  };
  const toggleTracking = async () => {
    showDialog(
      <DefaultDialog
        title={i18n.t('setting.tracking_require_permission')}
        content={i18n.t('setting.tracking_require_permission_text')}
        confirmText={
          Platform.OS !== 'web' ? i18n.t('setting.settings') : undefined
        }
        dismissText={i18n.t('common.confirm')}
        onPressDismiss={hideDialog}
        onPressConfirm={
          Platform.OS !== 'web' ? () => Linking.openSettings() : undefined
        }
      />,
    );
  };

  const toggleDarkMode = () => {
    const nextMode = colorMode === 'dark' ? 'light' : 'dark';
    setColorMode(nextMode);
  };
  return (
    <View>
      <SettingActionToggleButton
        theme={theme}
        icon={'bell-outline'}
        label={i18n.t('setting.notification')}
        button={
          <Switch
            disabled={notificationUpdating}
            value={notification}
            onValueChange={toggleNotification}
          />
        }
      />
      <SettingActionToggleButton
        theme={theme}
        icon={'map-marker-outline'}
        label={i18n.t('setting.location')}
        button={
          <Switch
            disabled={locationUpdating}
            value={location}
            onValueChange={toggleLocation}
          />
        }
      />
      {Platform.OS === 'ios' && (
        <SettingActionToggleButton
          theme={theme}
          icon={'security'}
          label={i18n.t('setting.tracking')}
          button={
            <Pressable onPress={toggleTracking}>
              <Switch disabled value={tracking} />
            </Pressable>
          }
        />
      )}
      <SettingActionToggleButton
        theme={theme}
        icon={'circle-half-full'}
        label={
          colorMode === 'dark'
            ? i18n.t('setting.dark_mode')
            : i18n.t('setting.light_mode')
        }
        button={
          <Switch value={colorMode === 'dark'} onValueChange={toggleDarkMode} />
        }
      />
      <SettingActionToggleButton
        theme={theme}
        icon={'translate'}
        label={i18n.t('setting.language')}
        button={
          <SegmentedButtons
            style={{
              width: '50%',
            }}
            value={locale}
            onValueChange={locale => {
              setLocale(locale);
              UserService.updateUserData({ locale });
            }}
            buttons={[
              {
                value: 'en',
                label: i18n.t('common.english'),
              },
              {
                value: 'ko',
                label: i18n.t('common.korean'),
              },
            ]}
          />
        }
      />
      <SettingActionButton
        theme={theme}
        icon={'script-text-outline'}
        label={i18n.t('setting.terms_and_conditions')}
        onPress={() => Linking.openURL(`${WEB_URL}/terms?lang=${locale}`)}
      />
      <SettingActionButton
        theme={theme}
        icon={'account-check-outline'}
        label={i18n.t('setting.privacy_policy')}
        onPress={() =>
          Linking.openURL(`${WEB_URL}/terms/privacy?lang=${locale}`)
        }
      />
      <SettingActionButton
        theme={theme}
        icon={'bell-check-outline'}
        label={i18n.t('setting.marketing_agree')}
        onPress={() =>
          Linking.openURL(`${WEB_URL}/terms/marketing?lang=${locale}`)
        }
      />
      <SettingActionButton
        theme={theme}
        icon={'comment-question-outline'}
        label={i18n.t('setting.customer_service')}
        onPress={() => Linking.openURL('https://cs.livewire.so/')}
      />
      <SettingActionButton
        theme={theme}
        icon={'bulletin-board'}
        label={i18n.t('setting.notice')}
        onPress={() => navigation.push('NoticeFeed')}
      />
      <SettingActionButton
        theme={theme}
        icon={'open-source-initiative'}
        label={i18n.t('setting.open_source_license')}
        onPress={() => navigation.push('OssNotice')}
      />
      {user ? (
        <SettingActionButton
          theme={theme}
          icon={'logout'}
          label={i18n.t('setting.sign_out')}
          onPress={async () => {
            const { pushToken } = await getNotificationPermission();
            if (pushToken) {
              PushTokenRepository.deletePushToken(pushToken);
            }
            await signOut();
            navigation.push('SignIn');
          }}
        />
      ) : (
        <SettingActionButton
          theme={theme}
          icon={'login'}
          label={i18n.t('setting.sign_in')}
          onPress={async () => {
            await signOut();
            navigation.push('SignIn');
          }}
        />
      )}
    </View>
  );
};

export default SettingActions;
