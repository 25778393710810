import _ from 'lodash';

interface Id {
  id: string;
}

export const isEqual = (arr1: Id[] | null = [], arr2: Id[] | null = []) =>
  _.isEqual(
    _(arr1).sortBy('id').map('id').value(),
    _(arr2).sortBy('id').map('id').value(),
  );

export const trim = (text: string = ''): string => text.replace(/\s/g, '');

export const includesTrimmed = (a: string = '', b: string = ''): boolean =>
  trim(a).includes(trim(b));

export const ellipsis = (text: string = '', maxLength: number): string => {
  return text.length > maxLength
    ? text.substring(0, maxLength - 3) + '...'
    : text;
};

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
