import React from 'react';
import { View } from 'react-native';
import { MD3Theme, Text } from 'react-native-paper';

import { Story } from '../../repositories/StoryRepository';
import i18n from '../../translations/i18n';
import { getDimensions } from '../../utils/dimensions';
import linkify from '../../utils/linkify';
import DetailImages from '../shared/DetailImages';
import Dot from '../shared/Dot';
import { Row } from '../shared/Layouts';
import RenderHtml from '../shared/RenderHtml';
import StoryItems from './StoryItems';
import StoryProfileCard from './StoryProfileCard';

const MARGIN = 20;

const StoryDetailContent: React.FC<{
  story: Story;
  theme: MD3Theme;
}> = ({ story, theme }) => {
  const { width } = getDimensions();
  const Html = React.memo(() => (
    <RenderHtml
      source={{ html: linkify(story.description) }}
      style={{ paddingHorizontal: 0 }}
    />
  ));
  return (
    <View style={{ margin: MARGIN, gap: 5 }}>
      <Text style={{ fontWeight: 'bold' }} variant={'titleLarge'}>
        {story.title?.trim()}
      </Text>
      <StoryProfileCard story={story} />
      <StoryItems story={story} />
      <DetailImages
        width={width - MARGIN * 2}
        images={story.image_urls ?? []}
        gap={10}
      />
      <Html />
      <Row
        style={{ justifyContent: 'flex-start', gap: 3, paddingVertical: 10 }}
      >
        {story.like_count > 0 && (
          <>
            <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
              {i18n.t('common.like')}: {story.like_count}
            </Text>
            <Dot style={{ color: theme.colors.outline }} />
          </>
        )}
        {story.comment_count > 0 && (
          <>
            <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
              {i18n.t('common.comment')}: {story.comment_count}
            </Text>
            <Dot style={{ color: theme.colors.outline }} />
          </>
        )}
        <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
          {i18n.t('common.view')}: {story.view_count + 1}
        </Text>
      </Row>
    </View>
  );
};

export default StoryDetailContent;
