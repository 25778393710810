import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { showError } from '../components/shared/Toast';
import NoticeRepository, {
  Notice,
  NoticesParams,
} from '../repositories/NoticeRepository';

export class NoticeService {
  static async getNotice(noticeId: number): Promise<Notice | undefined> {
    const { data, error } = await NoticeRepository.getNotice(noticeId);
    if (error) {
      showError(error);
      return;
    }
    return data as Notice;
  }

  static async getNotices(params: NoticesParams) {
    const { data } = await NoticeRepository.getNotices(params);
    const notices = (data ?? []) as Notice[];
    return {
      notices,
      offset: params.offset,
      end: !notices?.length,
    };
  }
}

export function useNoticesQuery(params: NoticesParams = {}) {
  return useInfiniteQuery(
    ['notices', params],
    async ({ pageParam }) =>
      await NoticeService.getNotices({ ...params, offset: pageParam }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + NoticeRepository.PAGE_SIZE,
    },
  );
}

export function useNoticeDetailQuery(id?: number) {
  return useQuery<Notice | null>(['noticeDetail', id], async () => {
    if (!id) {
      return null;
    }
    return (await NoticeService.getNotice(id)) ?? null;
  });
}
