import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Button, Text, useTheme } from 'react-native-paper';

import { FormTextInput } from '../components/form/FormInputs';
import { showError, showSuccess } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import ReportRepository, { Report } from '../repositories/ReportRepository';
import i18n from '../translations/i18n';

const ReportScreen: React.FC = () => {
  const theme = useTheme();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute<RouteProp<MainStackParamList, 'Report'>>();
  const { title, target, type } = route.params;
  const form = useForm<Report>({
    defaultValues: { target, type },
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = form;

  const onSubmit = async (report: Report) => {
    try {
      await ReportRepository.insertReport(report);
      showSuccess(i18n.t('report.reported'));
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.replace('MainTab', { screen: 'EventFeed' });
      }
    } catch (error) {
      showError(error);
    }
  };

  const onError = (errors: FieldErrors) => {
    const hasError = !!Object.keys(errors).length;
    if (hasError) {
      showError(i18n.t('report.formField.checkInput'));
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          disabled={!isDirty}
          labelStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={handleSubmit(onSubmit, onError)}
        >
          {i18n.t('report.submit')}
        </Button>
      ),
    });
  }, [isDirty]);

  return (
    <KeyboardAwareScrollView style={{ flex: 1, padding: 20 }}>
      <Text
        style={{
          paddingVertical: 10,
          fontWeight: 'bold',
        }}
        variant={'bodyLarge'}
      >
        {title}
      </Text>
      <Controller
        control={control}
        name={'description'}
        rules={{
          required: i18n.t('report.formField.pleaseEnterDescription'),
          maxLength: {
            value: 1000,
            message: i18n.t('report.formField.youCanEnterUpTo1000Characters'),
          },
          validate: value => {
            if (!value?.trim().length) {
              return i18n.t('report.formField.pleaseEnterDescription');
            }
          },
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormTextInput
            theme={theme}
            style={{ paddingBottom: 50 }}
            textInputStyle={{
              backgroundColor: theme.colors.elevation.level3,
            }}
            label={i18n.t('report.formField.description')}
            multiline
            mode={'outlined'}
            dense
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            errorMessage={errors.description?.message as string}
          />
        )}
      />
    </KeyboardAwareScrollView>
  );
};

export default ReportScreen;
