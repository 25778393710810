import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { MainStackParamList } from '../../navigation/MainStack';
import IconButton from './IconButton';

const BackButton: React.FC<{
  shadow?: boolean;
  forceDefault?: boolean;
  defaultScreen: keyof MainStackParamList;
  tintColor?: string;
  params?: any;
}> = ({ shadow, forceDefault, defaultScreen, tintColor, params = {} }) => {
  const navigation = useNavigation<any>();
  const toDefault = () => navigation.navigate(defaultScreen, params ?? {});
  return (
    <IconButton
      shadow={shadow}
      tintColor={tintColor}
      icon={'chevron-left'}
      size={40}
      onPress={() =>
        !forceDefault && navigation.canGoBack()
          ? navigation.goBack()
          : toDefault()
      }
    />
  );
};

export default BackButton;
