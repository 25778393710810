import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Linking from 'expo-linking';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { Artist } from '../../repositories/ArtistRepository';
import { Place } from '../../repositories/PlaceRepository';
import i18n from '../../translations/i18n';
import { colors } from '../../utils/themes';
import Dot from './Dot';
import { Row } from './Layouts';

const ICON_SIZE = 30;
const GAP = 3;

const SocialMediaIcons: React.FC<{ artist: Artist }> = ({ artist }) => {
  const theme = useTheme();
  return (
    <Row style={{ justifyContent: 'space-between', marginBottom: 10 }}>
      <View style={{ width: '60%', flexDirection: 'row' }}>
        {!!artist.instagram && (
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(`https://instagram.com/${artist.instagram}`)
            }
          >
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={'instagram'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
        {!!artist.youtube_url && (
          <TouchableOpacity
            onPress={() => Linking.openURL(artist.youtube_url!)}
          >
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={'youtube'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
        {!!artist.spotify_url && (
          <TouchableOpacity
            onPress={() => Linking.openURL(artist.spotify_url!)}
          >
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={'spotify'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
        {!!artist.soundcloud_url && (
          <TouchableOpacity
            onPress={() => Linking.openURL(artist.soundcloud_url!)}
          >
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={'soundcloud'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
      </View>
      <Row
        style={{
          width: '40%',
          justifyContent: 'flex-end',
          gap: 3,
        }}
      >
        {artist.like_count > 0 && (
          <>
            <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
              {i18n.t('common.like')}: {artist.like_count}
            </Text>
            <Dot style={{ color: theme.colors.outline }} />
          </>
        )}
        <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
          {i18n.t('common.view')}: {artist.view_count + 1}
        </Text>
      </Row>
    </Row>
  );
};

export const PlaceSocialMediaIcons: React.FC<{ place: Place }> = ({
  place,
}) => {
  const theme = useTheme();
  return (
    <Row style={{ justifyContent: 'space-between', marginBottom: 10 }}>
      <View style={{ width: '60%', flexDirection: 'row' }}>
        {place.instagram_url && (
          <TouchableOpacity
            onPress={() => Linking.openURL(place.instagram_url!)}
          >
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={'instagram'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
        {place.youtube_url && (
          <TouchableOpacity onPress={() => Linking.openURL(place.youtube_url!)}>
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={'youtube'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
        {place.naver_map && (
          <TouchableOpacity onPress={() => Linking.openURL(place.naver_map!)}>
            <MaterialCommunityIcons
              color={colors.naverColor}
              name={'alpha-n-box'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
        {place.kakao_map && (
          <TouchableOpacity onPress={() => Linking.openURL(place.kakao_map!)}>
            <MaterialCommunityIcons
              color={colors.kakaoBackgroundColor}
              name={'alpha-k-box'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
        {place.google_map && (
          <TouchableOpacity onPress={() => Linking.openURL(place.google_map!)}>
            <MaterialCommunityIcons
              color={theme.colors.onBackground}
              name={'google'}
              size={ICON_SIZE}
              style={{ marginRight: GAP }}
            />
          </TouchableOpacity>
        )}
      </View>
      <Row
        style={{
          width: '40%',
          justifyContent: 'flex-end',
          gap: 3,
        }}
      >
        {place.favorite_count > 0 && (
          <>
            <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
              {i18n.t('common.like')}: {place.favorite_count}
            </Text>
            <Dot style={{ color: theme.colors.outline }} />
          </>
        )}
        <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
          {i18n.t('common.view')}: {place.view_count + 1}
        </Text>
      </Row>
    </Row>
  );
};

export default SocialMediaIcons;
