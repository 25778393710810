import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60, // 1 hours
      networkMode: 'always',
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

const REFRESH_EXCLUDE_PATTERNS = ['news'];

export const resetAll: () => Promise<Awaited<void>[]> = () =>
  Promise.all(
    queryClient
      .getQueryCache()
      .getAll()
      .filter(
        query =>
          !REFRESH_EXCLUDE_PATTERNS.some(queryKey =>
            (query.queryKey[0] as string).toLowerCase().includes(queryKey),
          ),
      )
      .map(query => queryClient.resetQueries(query.queryKey)),
  );

export const resetQueries: (queryKey: string) => Promise<Awaited<void>[]> = (
  queryKey: string,
) =>
  Promise.all(
    queryClient
      .getQueryCache()
      .getAll()
      .filter(query =>
        (query.queryKey[0] as string).toLowerCase().includes(queryKey),
      )
      .map(query => queryClient.resetQueries(query.queryKey)),
  );

export const refetchAll: () => Promise<Awaited<void>[]> = () =>
  Promise.all(
    queryClient
      .getQueryCache()
      .getAll()
      .filter(
        query =>
          !REFRESH_EXCLUDE_PATTERNS.some(queryKey =>
            (query.queryKey[0] as string).toLowerCase().includes(queryKey),
          ),
      )
      .map(query => queryClient.refetchQueries(query.queryKey)),
  );

export const refetchQueries: (queryKey: string) => Promise<Awaited<void>[]> = (
  queryKey: string,
) =>
  Promise.all(
    queryClient
      .getQueryCache()
      .getAll()
      .filter(query =>
        (query.queryKey[0] as string).toLowerCase().includes(queryKey),
      )
      .map(query => queryClient.refetchQueries(query.queryKey)),
  );

export default queryClient;
