import { useInfiniteQuery } from '@tanstack/react-query';

import ProfileRepository, {
  Profile,
  ProfilesParams,
} from '../repositories/ProfileRepository';

export default class ProfileService {
  static async getProfiles(
    params: ProfilesParams,
  ): Promise<{ profiles: Profile[]; offset?: number; end: boolean }> {
    const { data } = await ProfileRepository.getProfiles(params);
    return {
      profiles: (data ?? []) as unknown as Profile[],
      offset: params.offset,
      end: !data?.length,
    };
  }
}

export function useProfilesQuery(params: ProfilesParams) {
  return useInfiniteQuery(
    ['profiles', params],
    async ({ pageParam }) =>
      await ProfileService.getProfiles({ ...params, offset: pageParam }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + ProfileRepository.PAGE_SIZE,
    },
  );
}
