export const light = {
  dark: false,
  colors: {
    primary: 'rgb(0, 149, 246)',
    onPrimary: 'rgb(255, 255, 255)',
    primaryContainer: 'rgb(0, 0, 0)',
    onPrimaryContainer: 'rgb(255, 255, 255)',
    secondary: 'rgb(65, 147, 239)',
    onSecondary: 'rgb(255, 255, 255)',
    secondaryContainer: 'rgb(231, 231, 231)',
    onSecondaryContainer: 'rgb(0, 0, 0)',
    tertiary: 'rgb(186, 26, 32)',
    onTertiary: 'rgb(255, 255, 255)',
    tertiaryContainer: 'rgb(255, 218, 214)',
    onTertiaryContainer: 'rgb(65, 0, 3)',
    error: 'rgb(186, 26, 26)',
    onError: 'rgb(255, 255, 255)',
    errorContainer: 'rgb(189, 0, 14)',
    onErrorContainer: 'rgb(255, 180, 171)',
    background: 'rgb(253, 253, 253)',
    onBackground: 'rgb(28, 28, 28)',
    surface: 'rgb(228, 228, 228)',
    onSurface: 'rgb(72, 72, 72)',
    surfaceVariant: 'rgb(246, 246, 246)',
    onSurfaceVariant: 'rgb(28, 28, 28)',
    outline: 'rgb(120, 120, 120)',
    outlineVariant: 'rgb(201, 201, 201)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(49, 49, 49)',
    inverseOnSurface: 'rgb(225, 225, 225)',
    inversePrimary: 'rgb(155, 155, 155)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(249, 249, 249)',
      level2: 'rgb(232, 232, 232)',
      level3: 'rgb(225, 225, 225)',
      level4: 'rgb(199, 199, 199)',
      level5: 'rgb(152, 152, 152)',
    },
    surfaceDisabled: 'rgba(28, 28, 28, 0.12)',
    onSurfaceDisabled: 'rgba(28, 28, 28, 0.38)',
    backdrop: 'rgba(28, 28, 28, 0.4)',
  },
};

export const dark = {
  dark: true,
  colors: {
    primary: 'rgb(65, 147, 239)',
    onPrimary: 'rgb(255, 255, 255)',
    primaryContainer: 'rgb(255, 255, 255)',
    onPrimaryContainer: 'rgb(0, 0, 0)',
    secondary: 'rgb(0, 149, 246)',
    onSecondary: 'rgb(255, 255, 255)',
    secondaryContainer: 'rgb(25, 25, 25)',
    onSecondaryContainer: 'rgb(255, 255, 255)',
    tertiary: 'rgb(255, 179, 172)',
    onTertiary: 'rgb(104, 0, 8)',
    tertiaryContainer: 'rgb(65, 147, 239)',
    onTertiaryContainer: 'rgb(255, 255, 255)',
    error: 'rgb(255, 180, 171)',
    onError: 'rgb(105, 0, 5)',
    errorContainer: 'rgb(189, 0, 14)',
    onErrorContainer: 'rgb(255, 180, 171)',
    background: 'rgb(5, 5, 5)',
    onBackground: 'rgb(225, 225, 225)',
    surface: 'rgb(24, 24, 24)',
    onSurface: 'rgb(225, 225, 225)',
    surfaceVariant: 'rgb(72, 72, 72)',
    onSurfaceVariant: 'rgb(201, 201, 201)',
    outline: 'rgb(146, 146, 146)',
    outlineVariant: 'rgb(72, 72, 72)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(227, 226, 230)',
    inverseOnSurface: 'rgb(47, 48, 51)',
    inversePrimary: 'rgb(90, 90, 90)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(10,10,10)',
      level2: 'rgb(23,23,23)',
      level3: 'rgb(30,30,30)',
      level4: 'rgb(56,56,56)',
      level5: 'rgb(103,103,103)',
    },
    surfaceDisabled: 'rgba(225, 225, 225, 0.12)',
    onSurfaceDisabled: 'rgba(225, 225, 225, 0.38)',
    backdrop: 'rgba(28, 28, 28, 0.4)',
  },
};

export const colors = {
  tossColor: '#ffffff',
  tossBackgroundColor: '#2962f6',
  naverColor: '#2db400',
  kakaoColor: '#321c1b',
  kakaoBackgroundColor: '#ead848',
  notification: '#ff3b30',
  spotifyColor: '#1db954',
  youtubeColor: '#ff0000',
};
