import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';

import EventCardList from '../components/event/EventCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';

const ArtistEventFeedScreen: React.FC = () => {
  const route =
    useRoute<
      RouteProp<MainStackParamList, 'ArtistEventFeed' | 'AgencyEventFeed'>
    >();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const artistId = 'artistId' in route.params ? route.params?.artistId : '';
  const agencyId = 'agencyId' in route.params ? route.params?.agencyId : '';

  useEffect(() => {
    if (navigationReady && !artistId && !agencyId) {
      navigation.replace('MainTab', { screen: 'EventFeed' });
    }
  }, [navigationReady, artistId, agencyId]);

  return (
    <EventCardList
      artistId={artistId}
      agencyId={agencyId}
      sort={'event_date_desc'}
      onPressItem={event =>
        navigation.push('EventDetail', { eventId: event.id })
      }
    />
  );
};

export default ArtistEventFeedScreen;
