import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { MD3Theme, Text } from 'react-native-paper';
import styled from 'styled-components/native';

const ProfileActionContainer = styled.TouchableOpacity<{ theme: MD3Theme }>`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-horizontal: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level3};
`;

const ProfileActionButton: React.FC<{
  theme: MD3Theme;
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  label: string;
  onPress?: () => void;
}> = ({ theme, label, icon, onPress }) => (
  <ProfileActionContainer theme={theme} onPress={onPress} disabled={!onPress}>
    <MaterialCommunityIcons
      name={icon}
      color={theme.colors.onBackground}
      size={23}
      style={{ marginRight: 10 }}
    />
    <Text
      style={{ flex: 1 }}
      variant={'bodyLarge'}
      numberOfLines={2}
      ellipsizeMode={'tail'}
    >
      {label}
    </Text>
  </ProfileActionContainer>
);

export default ProfileActionButton;
