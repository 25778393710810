import { showError } from '../components/shared/Toast';
import http from '../utils/http';

export default class CommonService {
  static async findBannedWords(text: string): Promise<string> {
    try {
      const response = await http.post(`/api/verifications/keywords`, { text });
      return response?.data;
    } catch (e) {
      showError(e);
      return '';
    }
  }
}
