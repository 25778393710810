import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Button, useTheme } from 'react-native-paper';
import styled from 'styled-components/native';

import { Place } from '../../repositories/PlaceRepository';
import PlaceService, {
  useUpsertPlaceMutation,
} from '../../services/PlaceService';
import i18n from '../../translations/i18n';
import { getId } from '../../utils/id';
import { LatLng } from '../map';
import Loading from '../shared/Loading';
import { showError, showSuccess } from '../shared/Toast';
import {
  PlaceDescription,
  PlaceFormAddress,
  PlaceFormContact,
  PlaceFormInstagram,
  PlaceFormKakaoPaymentUrl,
  PlaceFormName,
  PlaceFormTossPaymentUrl,
  PlaceFormYoutubeUrl,
} from './PlaceFormFields';

const PlaceFormContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
`;

export type PlaceFormInputs = Place;

export type PlaceFormType = UseFormReturn<PlaceFormInputs>;

const PlaceForm: React.FC<{ place: Partial<Place> }> = ({ place }) => {
  const theme = useTheme();
  const edit = !!place.id;
  const [loading, setLoading] = useState<boolean>(false);
  const { mutateAsync: upsertPlace, isLoading } = useUpsertPlaceMutation();
  const navigation = useNavigation<any>();
  const location: LatLng | undefined =
    place.latitude && place.longitude
      ? {
          latitude: place.latitude,
          longitude: place.longitude,
        }
      : undefined;
  const form = useForm<PlaceFormInputs>({
    defaultValues: { ...place, location },
  });

  const {
    handleSubmit,
    setFocus,
    setValue,
    watch,
    formState: { isDirty },
  } = form;

  const onSubmit = async (place: PlaceFormInputs) => {
    if (edit && !isDirty) {
      return;
    }
    if (!place.id) {
      place.id = getId();
    }

    try {
      const result = await upsertPlace(place);
      showSuccess(
        edit
          ? i18n.t('common.form.modified')
          : i18n.t('common.form.registered'),
      );
      if (result?.id) {
        if (edit) {
          navigation.navigate('PlaceDetail', { placeId: result.id });
        } else {
          navigation.replace('PlaceDetail', { placeId: result.id });
        }
      }
    } catch (error) {
      showError(error);
    }
  };

  const onError = (errors: FieldErrors) => {
    const errorNames: (keyof FieldErrors)[] = Object.keys(errors);
    const hasError = !!errorNames.length;
    if (hasError) {
      showError(i18n.t('common.form.checkInput'));
      setFocus(errorNames[0] as keyof PlaceFormInputs);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          disabled={edit && !isDirty}
          labelStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={handleSubmit(onSubmit, onError)}
        >
          {edit ? i18n.t('common.save') : i18n.t('common.post')}
        </Button>
      ),
    });
  }, [isDirty]);

  const updateAddress = async (location: LatLng) => {
    setLoading(true);
    const address = await PlaceService.getAddressByLocation(location);
    if (address) {
      setValue('address', address, { shouldDirty: true });
    }
    setLoading(false);
  };

  const formLocation = watch('location');
  useEffect(() => {
    if (formLocation?.latitude && formLocation?.longitude) {
      updateAddress(formLocation);
    }
  }, [formLocation]);
  const showLoading = loading || isLoading;
  return (
    <PlaceFormContainer>
      {showLoading && <Loading />}
      <PlaceFormName theme={theme} form={form} edit={edit} />
      <PlaceFormAddress theme={theme} form={form} edit={edit} />
      <PlaceFormInstagram theme={theme} form={form} />
      <PlaceFormYoutubeUrl theme={theme} form={form} />
      <PlaceFormContact theme={theme} form={form} />
      <PlaceFormTossPaymentUrl theme={theme} form={form} />
      <PlaceFormKakaoPaymentUrl theme={theme} form={form} />
      <PlaceDescription theme={theme} form={form} />
    </PlaceFormContainer>
  );
};

export default PlaceForm;
