import { startOfDay } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Platform, View } from 'react-native';
import { Divider, MD3Theme } from 'react-native-paper';
import styled from 'styled-components/native';

import i18n from '../../translations/i18n';
import { formatDate } from '../../utils/datetimes';
import { contactType, REGEXP_URL } from '../../utils/regexps';
import {
  FormAutoCompleteSelect,
  FormDateInput,
  FormTextInput,
} from '../form/FormInputs';
import { ArtistFormType } from './ArtistForm';
import GenreList from './GenreList';

export const ArtistFormDivider = styled(Divider)<{ theme: MD3Theme }>`
  background-color: ${({ theme }) => theme.colors.onSurface};
  transform: scaleY(0.5);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ArtistFormName: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'name'}
    rules={{
      required: i18n.t('common.formField.name.rules.required'),
      maxLength: {
        value: 30,
        message: i18n.t('common.formField.name.rules.maxLength'),
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('common.formField.name.label') + ' *'}
        icon={'account'}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        maxLength={30}
        errorMessage={errors.name?.message as string}
      />
    )}
  />
);

export const ArtistFormMembers: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'members'}
    rules={{
      maxLength: {
        value: 200,
        message: i18n.t('common.formField.members.rules.maxLength'),
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('common.formField.members.label')}
        icon={'account-group'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={200}
        errorMessage={errors.members?.message as string}
      />
    )}
  />
);

export const ArtistFormGenres: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  },
}) => {
  const genres = (watch('genres') as NonNullable<string>[]) ?? [];
  const selectedGenres = genres.map(genre => ({
    id: genre,
    name: i18n.t(`genres.${genre}`),
  }));
  return (
    <Controller
      control={control}
      name={'genre'}
      render={({ field: { value, onBlur, onChange } }) => (
        <FormAutoCompleteSelect
          displaySuggestion
          multiselect
          theme={theme}
          style={{ zIndex: 4 }}
          icon={'shape'}
          label={i18n.t('common.genre')}
          onBlur={onBlur}
          onChange={onChange}
          selectedItems={selectedGenres}
          onSelectItem={genre => {
            const merged = _.uniqBy([...selectedGenres, genre], 'id')
              .map(({ id }) => id)
              .filter(id => id);
            if (merged.length > 5) {
              setError('genre', {
                type: 'custom',
                message: i18n.t('artist.formField.genre.rules.maxItem'),
              });
              return;
            }
            setValue('genres', merged, {
              shouldDirty: true,
            });
            setValue('genre', '', {
              shouldDirty: true,
            });
          }}
          onCloseSelectedItems={(_, index) => {
            genres.splice(index!, 1);
            if (genres.length <= 5) {
              clearErrors('genre');
            }
            setValue('genres', [...genres], {
              shouldDirty: true,
            });
          }}
          value={value ?? ''}
          maxLength={30}
          errorMessage={errors.genre?.message as string}
          ListComponent={GenreList}
        />
      )}
    />
  );
};

export const ArtistFormDebutDate: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({ theme, form: { control, setValue } }) => (
  <Controller
    control={control}
    name={'debut_date'}
    render={({ field: { value } }) => (
      <View
        style={{
          width: '100%',
        }}
      >
        <FormDateInput
          theme={theme}
          label={i18n.t('artist.formField.debut')}
          icon={'calendar'}
          value={value ? formatDate(startOfDay(new Date(value)))! : ''}
          onConfirm={value =>
            setValue('debut_date', value?.toISOString() ?? '', {
              shouldDirty: true,
            })
          }
        />
      </View>
    )}
  />
);

export const ArtistFormInstagram: React.FC<{
  edit?: boolean;
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  edit,
  theme,
  form: {
    control,
    formState: { dirtyFields, errors },
  },
}) => (
  <Controller
    control={control}
    name={'instagram'}
    rules={{
      pattern: {
        value: /^[a-zA-Z0-9._]{1,30}$/i,
        message: i18n.t('common.formField.instagramId.rules.pattern'),
      },
      minLength: {
        value: 3,
        message: i18n.t('common.formField.instagramId.rules.minLength'),
      },
      maxLength: {
        value: 30,
        message: i18n.t('common.formField.instagramId.rules.maxLength'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.instagramId.label')}
        icon={'instagram'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={30}
        errorMessage={errors.instagram?.message as string}
      />
    )}
  />
);

export const ArtistFormYoutubeUrl: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'youtube_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.youtube_url')}
        icon={'youtube'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.youtube_url?.message as string}
      />
    )}
  />
);

export const ArtistFormSpotifyUrl: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'spotify_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('artist.formField.spotify_url')}
        icon={'spotify'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.spotify_url?.message as string}
      />
    )}
  />
);

export const ArtistFormSoundcloudUrl: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'soundcloud_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('artist.formField.soundcloud_url')}
        icon={'soundcloud'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.soundcloud_url?.message as string}
      />
    )}
  />
);

export const ArtistFormTossPaymentUrl: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'toss_payment_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.toss_payment_url')}
        icon={'alpha-t-circle'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.toss_payment_url?.message as string}
      />
    )}
  />
);

export const ArtistFormKakaoPaymentUrl: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'kakao_payment_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('common.formField.kakao_payment_url')}
        icon={'alpha-k-circle'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.kakao_payment_url?.message as string}
      />
    )}
  />
);

export const ArtistFormShopUrl: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'shop_url'}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('artist.formField.shop_url')}
        icon={'shopping'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.shop_url?.message as string}
      />
    )}
  />
);

export const ArtistFormContact: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'contact'}
    rules={{
      validate: async value => {
        if (!value?.length) {
          return;
        }
        const type = contactType(value ?? '');
        if (type) {
          return;
        }
        return i18n.t('artist.formField.contact.rules.validate');
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('artist.formField.contact.label')}
        icon={'email'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'email-address'}
        maxLength={100}
        errorMessage={errors.contact?.message as string}
      />
    )}
  />
);

export const ArtistDescription: React.FC<{
  theme: MD3Theme;
  form: ArtistFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name={'description'}
    rules={{
      required: i18n.t('artist.formField.description.rules.required'),
      maxLength: {
        value: 1000,
        message: i18n.t('artist.formField.description.rules.maxLength'),
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('artist.formField.description.label') + ' *'}
        icon={'card-text-outline'}
        placeholder={i18n.t('artist.formField.description.placeholder')}
        multiline
        numberOfLines={Platform.OS === 'web' ? 3 : undefined}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={1000}
        errorMessage={errors.description?.message as string}
      />
    )}
  />
);
