import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';

import CommentCardList from '../components/comment/CommentCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';

const UserPostedCommentsScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (navigationReady && !userId) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, userId]);

  return (
    <CommentCardList
      postedUserId={userId}
      onPressCard={(_, id) => navigation.push('StoryDetail', { storyId: id })}
    />
  );
};

export default UserPostedCommentsScreen;
