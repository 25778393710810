import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import React from 'react';
import { Platform, View } from 'react-native';
import { MD3Theme, Text } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { Place } from '../../repositories/PlaceRepository';
import i18n from '../../translations/i18n';
import linkify from '../../utils/linkify';
import { toXY } from '../../utils/locationHelpers';
import { colors } from '../../utils/themes';
import SettingActionButton from '../setting/SettingActionButton';
import DetailItem from '../shared/DetailItem';
import FavoriteButton from '../shared/FavoriteButton';
import { SmallLoading } from '../shared/Loading';
import RenderHtml from '../shared/RenderHtml';
import { PlaceSocialMediaIcons } from '../shared/SocialMediaIcons';

const PlaceDetailContent: React.FC<{
  place: NonNullable<Place>;
  theme: MD3Theme;
  isSaving?: boolean;
  onPressFavorite: () => Promise<void>;
}> = ({ place, theme, isSaving, onPressFavorite }) => {
  const margin = 15;
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  let naverLink = `https://app.map.naver.com/launchApp/?version=2&lng=${place.longitude}&title=${place.name}&lat=${place.latitude}&appMenu=location&rcode=09440120&app=Y&menu=location&query=${place.name}&fallbackUrl=`;
  if (Platform.OS === 'web') {
    if (!/Mobile/.test(navigator.userAgent)) {
      const xy = toXY({
        longitude: place.longitude!,
        latitude: place.latitude!,
      });
      naverLink = `https://map.naver.com/v5/search?c=${xy.x},${xy.y},20,0,0,0,dh`;
    }
  }

  return (
    <View style={{ margin }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text
          style={{ fontWeight: 'bold', marginBottom: 10 }}
          variant={'titleLarge'}
        >
          {place.name}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: 25,
            height: 25,
          }}
        >
          {isSaving ? (
            <SmallLoading />
          ) : (
            <FavoriteButton
              icon={place.favorite?.length ? 'star' : 'star-outline'}
              onPress={onPressFavorite}
            />
          )}
        </View>
      </View>
      <View style={{ marginBottom: 10 }}>
        <PlaceSocialMediaIcons place={place} />
        <DetailItem
          theme={theme}
          icon={'map-marker'}
          iconSize={30}
          label={place.address}
          numberOfLines={2}
          onPress={() =>
            navigation.push('MainTab', {
              screen: 'Places',
              params: {
                placeId: place.id,
              },
            })
          }
        />
        <SettingActionButton
          theme={theme}
          style={{
            marginHorizontal: 0,
            paddingVertical: 10,
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.elevation.level2,
          }}
          icon={'alpha-n-box'}
          iconColor={colors.naverColor}
          label={i18n.t('place.detailContent.naver')}
          onPress={() => Linking.openURL(naverLink)}
        />
        <SettingActionButton
          theme={theme}
          style={{
            marginHorizontal: 0,
            paddingVertical: 10,
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.elevation.level2,
          }}
          icon={'alpha-k-box'}
          iconColor={colors.kakaoBackgroundColor}
          label={i18n.t('place.detailContent.kakao')}
          onPress={() =>
            Linking.openURL(
              `https://m.map.kakao.com/scheme/search?q=${place.address} ${place.name}`,
            )
          }
        />
        <SettingActionButton
          theme={theme}
          style={{
            marginHorizontal: 0,
            paddingVertical: 10,
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.elevation.level2,
          }}
          icon={'calendar'}
          label={i18n.t('place.detailContent.event', { name: place.name })}
          onPress={() =>
            navigation.push('PlaceEventFeed', { placeId: place.id })
          }
        />
        <SettingActionButton
          theme={theme}
          style={{
            marginHorizontal: 0,
            paddingVertical: 10,
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.elevation.level2,
          }}
          icon={'forum-outline'}
          label={i18n.t('place.detailContent.story', { name: place.name })}
          onPress={() =>
            navigation.push('MainTab', {
              screen: 'StoryFeed',
              params: {
                placeId: place.id,
              },
            })
          }
        />
        {!!place.description && (
          <Text style={{ width: '100%' }}>
            <RenderHtml
              source={{ html: linkify(place.description) }}
              style={{ width: '100%', paddingHorizontal: 10 }}
            />
          </Text>
        )}
      </View>
    </View>
  );
};

export default PlaceDetailContent;
