import { Marker } from '@react-google-maps/api';
import React, { Component } from 'react';
import { MapMarkerProps } from 'react-native-maps/lib/MapMarker';

class MapMarker extends Component<MapMarkerProps> {
  state = {
    isOpen: false,
  };

  eventCallout = () => {
    this.setState({ isOpen: true });
  };

  hideCallout = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const {
      title,
      description,
      coordinate,
      image,
      draggable,
      zIndex,
      onPress,
      onDragEnd,
    } = this.props;
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child: any) => {
        return React.cloneElement(child, {
          onCloseClick: this.hideCallout,
        });
      },
    );
    const icon = image
      ? {
          scaledSize: new google.maps.Size(24, 24),
          url: image as string,
        }
      : undefined;
    return (
      <Marker
        zIndex={zIndex}
        draggable={draggable}
        icon={icon}
        title={description ? `${title}\n${description}` : title}
        position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
        onClick={e => onPress?.(e as any)}
        onDragEnd={e =>
          onDragEnd?.({
            nativeEvent: {
              coordinate: {
                // @ts-ignore
                latitude: e.latLng?.lat(),
                // @ts-ignore
                longitude: e.latLng?.lng(),
              },
            },
          })
        }
      >
        {this.state.isOpen && childrenWithProps}
      </Marker>
    );
  }
}

export default MapMarker;
