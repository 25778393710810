import MapMarker from './web/MapMarker';
import MapView from './web/MapView';

export type LatLng = {
  latitude: number;
  longitude: number;
};

export type Region = LatLng & {
  latitudeDelta: number;
  longitudeDelta: number;
};

export const PROVIDER_GOOGLE = 'google';
export { MapMarker };
export default MapView;
