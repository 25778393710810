import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Clipboard from 'expo-clipboard';
import React from 'react';
import { MD3Theme, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { MainStackParamList } from '../../navigation/MainStack';
import { UserProfile } from '../../services/UserService';
import i18n from '../../translations/i18n';
import ImageIconAvatar from '../shared/ImageIconAvatar';
import { showInfo } from '../shared/Toast';

const ProfileHeaderContainer = styled.View<{ theme: MD3Theme }>`
  flex-direction: row;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.elevation.level1};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level3};
`;

const ProfileInfo = styled.View`
  flex: 1;
  gap: 10px;
  margin-left: 15px;
  justify-content: center;
`;

const ProfileNames = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ProfileEdit = styled.TouchableOpacity<{ theme: MD3Theme }>`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.elevation.level4};
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

export const ProfileHeader: React.FC<{
  theme: MD3Theme;
  profile: UserProfile;
}> = props => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  return (
    <ProfileHeaderContainer theme={props.theme}>
      <ImageIconAvatar uri={props.profile.photo_url} icon={'account'} />
      <ProfileInfo>
        <ProfileNames
          onPress={async () => {
            await Clipboard.setStringAsync(props.profile.id);
            showInfo(i18n.t('profile.header.idCopied'));
          }}
        >
          <Text style={{ fontWeight: 'bold', fontSize: 18, marginRight: 5 }}>
            {props.profile?.name}
          </Text>
        </ProfileNames>
        <ProfileEdit
          theme={props.theme}
          onPress={() => navigation.push('ProfileEdit')}
        >
          <Text
            style={{
              fontWeight: 'bold',
              color: props.theme.colors.onBackground,
            }}
          >
            {i18n.t('profile.header.editProfile')}
          </Text>
        </ProfileEdit>
      </ProfileInfo>
    </ProfileHeaderContainer>
  );
};

export default ProfileHeader;
