import supabase from '../supabase';

type NoticeResponse = Awaited<ReturnType<typeof NoticeRepository.getNotice>>;
type NoticeResponseSuccess = NoticeResponse['data'];

export type Notice = NonNullable<NoticeResponseSuccess>;

export interface NoticesParams {
  offset?: number;
  eventNotice?: boolean;
  storyNotice?: boolean;
}

export default class NoticeRepository {
  private static table = () => supabase.from('notices');
  static PAGE_SIZE = 20;

  static async getNotice(noticeId: number) {
    return this.table().select('*').eq('id', noticeId).maybeSingle();
  }

  static async getNotices({
    offset = 0,
    eventNotice,
    storyNotice,
  }: NoticesParams) {
    const query = this.table().select('*');
    if (eventNotice) {
      query.eq('display_on_events', true);
    }
    if (storyNotice) {
      query.eq('display_on_stories', true);
    }
    return query
      .order('created_at', { ascending: false })
      .range(offset, offset + NoticeRepository.PAGE_SIZE - 1);
  }
}
