import React from 'react';
import { TouchableOpacity } from 'react-native';
import { MD3Theme, TextInput } from 'react-native-paper';

const TextInputButton: React.FC<{
  theme: MD3Theme;
  onPress: () => void;
  placeholder: string;
}> = ({ theme, onPress, placeholder }) => (
  <TouchableOpacity onPress={onPress}>
    <TextInput
      pointerEvents={'none'}
      mode={'outlined'}
      disabled={true}
      dense={true}
      style={{ fontSize: 14, flex: 9 }}
      outlineStyle={{
        borderRadius: 20,
        backgroundColor: theme.colors.elevation.level2,
      }}
      placeholder={placeholder}
      placeholderTextColor={theme.colors.elevation.level5}
    />
  </TouchableOpacity>
);
export default TextInputButton;
