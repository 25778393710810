import React from 'react';
import { FlatList } from 'react-native';

import { PlacesParams } from '../../repositories/PlaceRepository';
import { useDistrictsQuery } from '../../services/PlaceService';
import { includesTrimmed } from '../../utils/helpers';
import TextCard from '../form/TextCard';
import Loading from '../shared/Loading';

const DistrictList: React.FC<
  PlacesParams & {
    onPressItem: (district: { id: string; name: string }) => void;
  }
> = params => {
  let { data: districts = [], isLoading, isFetching } = useDistrictsQuery();
  if (params?.searchQuery?.length) {
    districts = districts.filter(district =>
      includesTrimmed(district.name, params?.searchQuery),
    );
  }
  const loading = isLoading || isFetching;
  return (
    <>
      {loading && <Loading />}
      <FlatList
        data={districts}
        renderItem={({ item }) => (
          <TextCard
            id={item?.id}
            name={item!.name}
            onPress={({ id, name }) => params?.onPressItem?.({ id, name })}
          />
        )}
        keyExtractor={(item, index) => `${item?.id}${index}`}
      />
    </>
  );
};

export default DistrictList;
