import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';

import StoryCardList from '../components/story/StoryCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { resetTab } from '../navigation/navigations';

const UserLikedStoriesScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (navigationReady && !userId) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, userId]);

  return (
    <StoryCardList
      likedUserId={userId}
      onPressReset={() => navigation.dispatch(resetTab('StoryFeed'))}
      onPressItem={story =>
        navigation.push('StoryDetail', { storyId: story.id })
      }
    />
  );
};

export default UserLikedStoriesScreen;
