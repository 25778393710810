import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { showError } from '../components/shared/Toast';
import CommentRepository, {
  Comment,
  CommentsParams,
} from '../repositories/CommentRepository';
import { refetchAll } from '../utils/queryClient';

export class CommentService {
  static async getComment(userId: string): Promise<Comment | undefined> {
    const { data, error } = await CommentRepository.getComment(userId);
    if (error) {
      showError(error);
      return;
    }
    return data as Comment;
  }

  static async getComments(params: CommentsParams) {
    const { data } = await CommentRepository.getComments(params);
    const comments = ((data ?? []) as Comment[])?.filter(
      comment => !comment.created?.blocked?.length,
    );
    return {
      comments,
      offset: params.offset,
      end: !comments?.length,
    };
  }

  static async getAllComments(params: CommentsParams) {
    const { data } = await CommentRepository.getAllComments(params);
    return ((data ?? []) as Comment[])?.filter(
      comment => !comment.created?.blocked?.length,
    );
  }

  static async insertComment({ created, ...comment }: Partial<Comment>) {
    try {
      await CommentRepository.insertComment(comment as Comment);
      await refetchAll();
    } catch (error) {
      if (error) {
        showError(error);
      }
    }
  }

  static async deleteComment(id: string, storyId: string): Promise<boolean> {
    try {
      await CommentRepository.deleteComment(id, storyId);
      await refetchAll();
      return true;
    } catch (error) {
      showError(error);
      return false;
    }
  }
}

export function useInsertCommentMutation() {
  return useMutation(({ comment }: { comment: Partial<Comment> }) =>
    CommentService.insertComment(comment),
  );
}

export function useCommentsQuery(params: CommentsParams) {
  return useInfiniteQuery(
    ['comments', params],
    async ({ pageParam }) =>
      await CommentService.getComments({ ...params, offset: pageParam }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + CommentRepository.PAGE_SIZE,
    },
  );
}

export function useAllCommentsQuery(params: CommentsParams) {
  return useQuery(
    ['allComments', params],
    async () => await CommentService.getAllComments(params),
  );
}

export function useDeleteCommentMutation() {
  return useMutation((comment: Comment) =>
    comment?.id
      ? CommentService.deleteComment(comment.id, comment.story_id)
      : Promise.reject(new Error('Invalid CommentId')),
  );
}

export function useCommentDetailQuery(id?: string) {
  return useQuery<Comment | null>(['commentDetail', id], async () => {
    if (!id) {
      return null;
    }
    const comment = await CommentService.getComment(id);
    if (comment?.created?.blocked?.length) {
      return null;
    }
    return comment ?? null;
  });
}
