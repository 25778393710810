import { APP_BUILD } from './constants';

export default function codePush(_?: any): (x: any) => any {
  return (app: any) => app;
}

export const SyncStatus: any = {};
export const restartApp = () => {};

export const getBuild = async (): Promise<string> => Promise.resolve(APP_BUILD);
