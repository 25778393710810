import Slider from '@react-native-community/slider';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { Button, Dialog, Text, useTheme } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import PlaceService from '../../services/PlaceService';
import { UserService } from '../../services/UserService';
import i18n from '../../translations/i18n';
import {
  kmToDelta,
  setStorageRegionAndDistrict,
} from '../../utils/locationHelpers';
import DistrictSelector from '../form/DistrictSelector';
import { LatLng, Region } from '../map';
import { Column } from '../shared/Layouts';

const EventFilterDialog: React.FC<{
  navigation: NativeStackNavigationProp<MainStackParamList>;
  sort?: string;
  region: Region;
  district: string;
  distance: number;
  setRegion: (region: Region) => void;
  setDistrict: (district: string) => void;
  setDistance: (distance: number) => void;
  hideDialog: () => void;
}> = ({
  navigation,
  sort,
  region,
  district,
  distance,
  setRegion,
  setDistrict,
  setDistance,
  hideDialog,
}) => {
  const theme = useTheme();
  const [sliderDistance, setSliderDistance] = useState(distance);

  const onConfirm = async (selectedDistrict?: string, location?: LatLng) => {
    const nextRegion: Region = location
      ? {
          ...location,
          latitudeDelta: kmToDelta(sliderDistance),
          longitudeDelta: kmToDelta(sliderDistance),
        }
      : {
          ...region!,
          latitudeDelta: kmToDelta(sliderDistance),
          longitudeDelta: kmToDelta(sliderDistance),
        };
    const nextDistrict: string =
      selectedDistrict ??
      (await PlaceService.getDistrictByLocation({
        latitude: nextRegion.latitude,
        longitude: nextRegion.longitude,
      }));
    setRegion(nextRegion);
    setDistrict(nextDistrict);
    setDistance(sliderDistance);
    const regionAndDistrict = { ...nextRegion, district: nextDistrict };
    await setStorageRegionAndDistrict(regionAndDistrict);
    await UserService.updateUserData(regionAndDistrict);
    hideDialog();
  };

  return (
    <>
      <Dialog.Content>
        <Column style={{ gap: 20, paddingTop: 24 }}>
          <TouchableOpacity
            style={{ alignSelf: 'center' }}
            onPress={() => {
              navigation.setParams({
                sort: undefined,
              });
              hideDialog();
            }}
          >
            <Text
              style={{
                color:
                  sort === undefined
                    ? theme.colors.primary
                    : theme.colors.onSurface,
                fontWeight: 'bold',
                fontSize: 20,
              }}
            >
              {i18n.t('common.closest')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.setParams({
                sort: 'distance',
              });
              hideDialog();
            }}
          >
            <Text
              style={{
                color:
                  sort === 'distance'
                    ? theme.colors.primary
                    : theme.colors.onSurface,
                fontWeight: 'bold',
                fontSize: 20,
              }}
            >
              {i18n.t('common.nearest')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.setParams({
                sort: 'price',
              });
              hideDialog();
            }}
          >
            <Text
              style={{
                color:
                  sort === 'price'
                    ? theme.colors.primary
                    : theme.colors.onSurface,
                fontWeight: 'bold',
                fontSize: 20,
              }}
            >
              {i18n.t('common.price')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.setParams({
                sort: 'new',
              });
              hideDialog();
            }}
          >
            <Text
              style={{
                color:
                  sort === 'new'
                    ? theme.colors.primary
                    : theme.colors.onSurface,
                fontWeight: 'bold',
                fontSize: 20,
              }}
            >
              {i18n.t('common.new')}
            </Text>
          </TouchableOpacity>
          <DistrictSelector value={district!} onSelect={onConfirm}>
            <Column style={{ gap: 10 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 17 }}>
                {i18n.t('common.district')}
              </Text>
              <Text style={{ alignSelf: 'center' }}>{district}</Text>
            </Column>
          </DistrictSelector>
          <Column>
            <Text style={{ fontWeight: 'bold', fontSize: 17 }}>
              {i18n.t('common.distance')} ({sliderDistance}km)
            </Text>
            <Slider
              minimumTrackTintColor={theme.colors.primary}
              maximumTrackTintColor={theme.colors.primaryContainer}
              thumbTintColor={theme.colors.primary}
              value={sliderDistance}
              step={1}
              minimumValue={1}
              maximumValue={100}
              style={{ width: '80%', height: 45 }}
              onValueChange={setSliderDistance}
            />
          </Column>
        </Column>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={hideDialog}>{i18n.t('common.cancel')}</Button>
        <Button
          labelStyle={{
            fontWeight: 'bold',
          }}
          onPress={() => onConfirm()}
        >
          {i18n.t('common.apply')}
        </Button>
      </Dialog.Actions>
    </>
  );
};

export default EventFilterDialog;
