import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Button, useTheme } from 'react-native-paper';
import styled from 'styled-components/native';

import { Artist } from '../../repositories/ArtistRepository';
import { useUpsertArtistMutation } from '../../services/ArtistService';
import i18n from '../../translations/i18n';
import { ResourceType } from '../../utils/enums';
import { getId } from '../../utils/id';
import { uploadImage } from '../../utils/upload';
import FormAvatar from '../form/FormAvatar';
import Loading from '../shared/Loading';
import { showError, showSuccess } from '../shared/Toast';
import {
  ArtistDescription,
  ArtistFormContact,
  ArtistFormDebutDate,
  ArtistFormGenres,
  ArtistFormInstagram,
  ArtistFormKakaoPaymentUrl,
  ArtistFormMembers,
  ArtistFormName,
  ArtistFormShopUrl,
  ArtistFormSoundcloudUrl,
  ArtistFormSpotifyUrl,
  ArtistFormTossPaymentUrl,
  ArtistFormYoutubeUrl,
} from './ArtistFormFields';

const ArtistFormContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
`;

export type ArtistFormInputs = Artist & {
  genre?: string;
};

export type ArtistFormType = UseFormReturn<ArtistFormInputs>;

const ArtistForm: React.FC<{ artist: Partial<Artist> }> = ({ artist }) => {
  const theme = useTheme();
  const edit = !!artist.id;
  const [uploading, setUploading] = useState<boolean>(false);
  const { mutateAsync: upsertArtist, isLoading } = useUpsertArtistMutation();
  const navigation = useNavigation<any>();

  const form = useForm<ArtistFormInputs>({
    defaultValues: artist,
  });

  const {
    handleSubmit,
    setFocus,
    formState: { isDirty, dirtyFields },
  } = form;

  const onSubmit = async ({ genre, ...artist }: ArtistFormInputs) => {
    if (edit && !isDirty) {
      return;
    }
    if (!artist.id) {
      artist.id = getId();
    }
    try {
      if (artist.photo_url?.length && dirtyFields.photo_url) {
        setUploading(true);
        artist.photo_url = await uploadImage(
          artist.photo_url,
          ResourceType.ARTIST,
          artist.id,
        );
        setUploading(false);
      }
      const result = await upsertArtist(artist);
      showSuccess(
        edit
          ? i18n.t('common.form.modified')
          : i18n.t('common.form.registered'),
      );
      if (result?.id) {
        if (edit) {
          navigation.navigate('ArtistDetail', { artistId: result.id });
        } else {
          navigation.replace('ArtistDetail', { artistId: result.id });
        }
      }
    } catch (error) {
      showError(error);
    }
  };

  const onError = (errors: FieldErrors) => {
    const errorNames: (keyof FieldErrors)[] = Object.keys(errors);
    const hasError = !!errorNames.length;
    if (hasError) {
      showError(i18n.t('common.form.checkInput'));
      setFocus(errorNames[0] as keyof ArtistFormInputs);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          disabled={edit && !isDirty}
          labelStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={handleSubmit(onSubmit, onError)}
        >
          {edit ? i18n.t('common.save') : i18n.t('common.post')}
        </Button>
      ),
    });
  }, [isDirty]);

  const showLoading = uploading || isLoading;
  return (
    <ArtistFormContainer>
      {showLoading && <Loading />}
      <FormAvatar theme={theme} form={form} />
      <ArtistFormName theme={theme} form={form} />
      <ArtistFormMembers theme={theme} form={form} />
      <ArtistFormGenres theme={theme} form={form} />
      <ArtistFormDebutDate theme={theme} form={form} />
      <ArtistFormInstagram theme={theme} form={form} />
      <ArtistFormYoutubeUrl theme={theme} form={form} />
      <ArtistFormSpotifyUrl theme={theme} form={form} />
      <ArtistFormSoundcloudUrl theme={theme} form={form} />
      <ArtistFormShopUrl theme={theme} form={form} />
      <ArtistFormContact theme={theme} form={form} />
      <ArtistFormTossPaymentUrl theme={theme} form={form} />
      <ArtistFormKakaoPaymentUrl theme={theme} form={form} />
      <ArtistDescription theme={theme} form={form} />
    </ArtistFormContainer>
  );
};

export default ArtistForm;
