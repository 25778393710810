import * as Linking from 'expo-linking';
import React from 'react';
import { Button, MD3Theme, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { Place } from '../../repositories/PlaceRepository';
import i18n from '../../translations/i18n';
import { getContactLink } from '../../utils/regexps';
import { colors } from '../../utils/themes';

const BottomContainer = styled.View<{ theme: MD3Theme }>`
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.elevation.level4};
`;

const PaymentContainer = styled.View<{ theme: MD3Theme }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const PlaceDetailBottomBar: React.FC<{
  place: NonNullable<Place>;
  theme: MD3Theme;
}> = ({ place, theme }) => {
  const contactLink = getContactLink(place.contact ?? '');
  return (
    <BottomContainer theme={theme}>
      <PaymentContainer>
        {!!place.toss_payment_url && (
          <Button
            style={{ flex: 1, marginRight: 10 }}
            buttonColor={colors.tossBackgroundColor}
            onPress={() => Linking.openURL(place.toss_payment_url!)}
          >
            <Text style={{ fontWeight: 'bold', color: colors.tossColor }}>
              {i18n.t('place.detailBottomBar.toss_payment_url')}
            </Text>
          </Button>
        )}
        {!!place.kakao_payment_url && (
          <Button
            style={{ flex: 1 }}
            buttonColor={colors.kakaoBackgroundColor}
            onPress={() => Linking.openURL(place.kakao_payment_url!)}
          >
            <Text style={{ fontWeight: 'bold', color: colors.kakaoColor }}>
              {i18n.t('place.detailBottomBar.kakao_payment_url')}
            </Text>
          </Button>
        )}
      </PaymentContainer>
      {!!contactLink && (
        <Button
          style={{ width: '100%' }}
          buttonColor={theme.colors.secondaryContainer}
          onPress={() => Linking.openURL(contactLink)}
        >
          <Text
            style={{
              fontWeight: 'bold',
              color: theme.colors.onSecondaryContainer,
            }}
          >
            {i18n.t('place.detailBottomBar.contact')}
          </Text>
        </Button>
      )}
    </BottomContainer>
  );
};

export default PlaceDetailBottomBar;
