import { useActionSheet } from '@expo/react-native-action-sheet';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { RefreshControl } from 'react-native-web-refresh-control';

import DefaultDialog from '../components/dialogs/DefaultDialog';
import UserAttendedEventDetailContent from '../components/event/UserAttendedEventDetailContent';
import { useDialog } from '../components/providers/DialogProvider';
import DetailHeaderRight from '../components/shared/DetailHeaderRight';
import { SmallLoading } from '../components/shared/Loading';
import { showInfo, showSuccess } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import {
  useDeleteUserAttendedEventMutation,
  useUserAttendedEventDetailQuery,
} from '../services/UserAttendedEventService';
import i18n from '../translations/i18n';
import { resetAll } from '../utils/queryClient';

const UserAttendedEventDetailScreen: React.FC = () => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route =
    useRoute<RouteProp<MainStackParamList, 'UserAttendedEventDetail'>>();
  const isFocused = useIsFocused();
  const { showActionSheetWithOptions } = useActionSheet();
  const [loading, setLoading] = useState(false);

  const eventId = route.params.eventId;

  useEffect(() => {
    if (navigationReady && !eventId) {
      navigation.replace('UserAttendedEvents');
    }
  }, [navigationReady, eventId]);

  const user = useUser();

  const {
    data: userAttendedEvent,
    isFetched,
    refetch,
  } = useUserAttendedEventDetailQuery(user?.id, eventId);
  const { mutateAsync: deleteUserAttendedEvent } =
    useDeleteUserAttendedEventMutation();
  const { showDialog, hideDialog } = useDialog();

  const onPressEdit = () => {
    let options = [i18n.t('common.cancel')];
    let destructiveButtonIndex: number[] = [];
    const self = user?.id === userAttendedEvent?.user_id;
    if (self) {
      options = [i18n.t('common.modify'), i18n.t('common.delete'), ...options];
      destructiveButtonIndex = [options.indexOf(i18n.t('common.delete'))];
    }
    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        containerStyle: {
          paddingBottom: insets.bottom,
        },
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case options.indexOf(i18n.t('common.modify')): {
            navigation.push('UserAttendedEventEdit', { eventId });
            break;
          }
          case options.indexOf(i18n.t('common.delete')): {
            showDialog(
              <DefaultDialog
                title={i18n.t('common.delete')}
                content={i18n.t('common.areYouSureYouWantToDeleteIt')}
                confirmText={i18n.t('common.delete')}
                onPressDismiss={hideDialog}
                onPressConfirm={async () => {
                  setLoading(true);
                  await deleteUserAttendedEvent({ userId: user?.id, eventId });
                  setLoading(false);
                  showSuccess(i18n.t('common.itHasBeenDeleted'));
                  hideDialog();
                  navigation.replace('UserAttendedEvents');
                }}
              />,
            );
            break;
          }
          default:
        }
      },
    );
  };

  useEffect(() => {
    if (isFetched) {
      if (userAttendedEvent) {
        navigation.setOptions({
          headerRight: ({ tintColor }: { tintColor?: string }) => (
            <DetailHeaderRight
              more={!!user}
              color={tintColor}
              onPressMore={() => onPressEdit()}
            />
          ),
        });
      }
      if (!userAttendedEvent && !loading) {
        showInfo(
          i18n.t('userAttendedEvent.detailContent.invalidUserAttendedEvent'),
        );
        navigation.replace('UserAttendedEvents');
      }
    }
  }, [userAttendedEvent, user]);

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);

  const onRefresh = async () => {
    setLoading(true);
    await resetAll();
    await refetch();
    setLoading(false);
  };

  return !userAttendedEvent ? (
    <SmallLoading />
  ) : (
    <View style={{ flex: 1 }}>
      <ScrollView
        overScrollMode={'never'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
      >
        <UserAttendedEventDetailContent
          theme={theme}
          userAttendedEvent={userAttendedEvent}
        />
      </ScrollView>
    </View>
  );
};

export default UserAttendedEventDetailScreen;
