import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Keyboard,
  ScrollView,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {
  Button,
  IconButton,
  Searchbar,
  Text,
  useTheme,
} from 'react-native-paper';
import { Tabs, TabScreen, TabsProvider } from 'react-native-paper-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ArtistCardList from '../components/artist/ArtistCardList';
import EventCardList from '../components/event/EventCardList';
import PlaceCardList from '../components/place/PlaceCardList';
import AllSearchList from '../components/search/AllSearchList';
import { Column, Row } from '../components/shared/Layouts';
import StoryCardList from '../components/story/StoryCardList';
import { MainStackParamList } from '../navigation/MainStack';
import { resetTab } from '../navigation/navigations';
import i18n from '../translations/i18n';
import LocalStorage from '../utils/localStorage';

const SearchFeedScreen: React.FC = () => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const [tabIndex, setTabIndex] = useState(0);
  const [queryInput, setQueryInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [recentQueries, setRecentQueries] = useState<string[]>([]);
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();

  useEffect(() => {
    (async () => {
      setRecentQueries(await LocalStorage.getRecentSearchQueries());
    })();
  }, []);

  const handleSearch = async () => {
    if (!queryInput) {
      return;
    }
    setSearchQuery(queryInput);
    const nextRecentQueries = _.uniq(
      [queryInput, ...recentQueries].slice(0, 30),
    );
    setRecentQueries(nextRecentQueries);
    await LocalStorage.setRecentSearchQueries(nextRecentQueries);
  };

  const clearRecentSearchQueries = async () => {
    setRecentQueries([]);
    await LocalStorage.setRecentSearchQueries([]);
  };

  return (
    <View style={{ flex: 1, paddingTop: insets.top }}>
      <Searchbar
        style={{
          backgroundColor: theme.colors.elevation.level2,
          margin: 10,
        }}
        inputStyle={{ minHeight: 50 }}
        placeholder={i18n.t('common.search')}
        elevation={0}
        value={queryInput}
        onChangeText={setQueryInput}
        onSubmitEditing={handleSearch}
        onIconPress={handleSearch}
        onClearIconPress={() => setSearchQuery('')}
        keyboardAppearance={'default'}
      />
      {searchQuery ? (
        <TabsProvider defaultIndex={tabIndex} onChangeIndex={setTabIndex}>
          <Tabs style={{ backgroundColor: theme.colors.background }}>
            <TabScreen label={i18n.t('common.all')}>
              <AllSearchList searchQuery={searchQuery} />
            </TabScreen>
            <TabScreen label={i18n.t('common.event')}>
              <EventCardList
                searchQuery={searchQuery}
                onPressItem={event =>
                  navigation.push('EventDetail', { eventId: event.id })
                }
              />
            </TabScreen>
            <TabScreen label={i18n.t('common.posts')}>
              <StoryCardList
                searchQuery={searchQuery}
                onPressReset={() => navigation.dispatch(resetTab('StoryFeed'))}
                onPressItem={story =>
                  navigation.push('StoryDetail', { storyId: story.id })
                }
              />
            </TabScreen>
            <TabScreen label={i18n.t('common.artist')}>
              <ArtistCardList
                searchQuery={searchQuery}
                onPressItem={artist =>
                  navigation.push('ArtistDetail', { artistId: artist.id })
                }
              />
            </TabScreen>
            <TabScreen label={i18n.t('common.place')}>
              <PlaceCardList
                searchQuery={searchQuery}
                onPressItem={place =>
                  navigation.push('PlaceDetail', { placeId: place.id })
                }
              />
            </TabScreen>
          </Tabs>
        </TabsProvider>
      ) : (
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
          <Column style={{ flex: 1 }}>
            <Row
              style={{ paddingHorizontal: 10, justifyContent: 'space-between' }}
            >
              <Text variant={'titleMedium'} style={{ fontWeight: 'bold' }}>
                {i18n.t('common.recentSearchWord')}
              </Text>
              <IconButton
                icon={'trash-can'}
                onPress={clearRecentSearchQueries}
              />
            </Row>
            <ScrollView>
              {recentQueries?.length ? (
                recentQueries.map((query, index) => (
                  <Button
                    key={index}
                    onPress={() => {
                      setQueryInput(query);
                      setSearchQuery(query);
                    }}
                  >
                    {query}
                  </Button>
                ))
              ) : (
                <Text>{i18n.t('common.notExistRecentSearchTerms')}</Text>
              )}
            </ScrollView>
          </Column>
        </TouchableWithoutFeedback>
      )}
    </View>
  );
};

export default SearchFeedScreen;
