import { useIsFocused } from '@react-navigation/native';
import _ from 'lodash';
import React, { useEffect } from 'react';

import { CommentsParams } from '../../repositories/CommentRepository';
import {
  useAllCommentsQuery,
  useDeleteCommentMutation,
} from '../../services/CommentService';
import { Column } from '../shared/Layouts';
import CommentCard from './CommentCard';

const CommentCards: React.FC<
  CommentsParams & { storyUserId?: string; detail?: boolean }
> = ({ storyUserId, detail, ...params }) => {
  const { data: allComments, refetch } = useAllCommentsQuery(params);
  const isFocused = useIsFocused();

  const { mutateAsync: deleteComment } = useDeleteCommentMutation();

  const comments = allComments?.filter(comment => !comment.parent_comment_id);
  const replies = _(allComments)
    .filter(comment => !!comment.parent_comment_id)
    .groupBy('parent_comment_id')
    .value();

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);

  return (
    <Column>
      {comments?.map(comment => (
        <React.Fragment key={comment.id}>
          <CommentCard
            postedUser={
              storyUserId ? storyUserId === comment?.created?.id : false
            }
            comment={comment}
            deleteComment={deleteComment}
            allowReply={!detail}
          />
          {replies[comment.id]?.map(reply => (
            <CommentCard
              postedUser={
                storyUserId ? storyUserId === reply?.created?.id : false
              }
              reply={true}
              key={reply.id}
              comment={reply}
              deleteComment={deleteComment}
            />
          ))}
        </React.Fragment>
      ))}
    </Column>
  );
};

export default CommentCards;
