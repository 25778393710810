import * as SentryReact from '@sentry/react';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

import { APP_NAME, APP_VERSION, SENTRY } from './utils/constants';

export const init = (enabled: boolean, build: string) =>
  Sentry.init({
    ...SENTRY,
    release: `${APP_NAME}@${Platform.OS}:${APP_VERSION}(${build})`,
    tracesSampleRate: 0,
    tracesSampler: false,
    enabled,
  });
export const addBreadcrumb = Sentry.Browser.addBreadcrumb;
export const captureException = Sentry.Browser.captureException;
export const captureEvent = Sentry.Browser.captureEvent;
export const ErrorBoundary = SentryReact.ErrorBoundary;

export function registerNavigationContainer(_: any): void {}
