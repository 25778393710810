import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { Artist } from '../../repositories/ArtistRepository';
import i18n from '../../translations/i18n';
import { colors } from '../../utils/themes';
import { ItemChip } from '../shared/DetailItem';
import ImageIconAvatar from '../shared/ImageIconAvatar';
import { Row } from '../shared/Layouts';

const RecommendedArtist: React.FC<{
  district: string;
  eventId: string;
  artist?: Artist;
}> = ({ district, eventId, artist }) => {
  const theme = useTheme();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  return (
    <View
      style={{
        padding: 20,
        gap: 10,
      }}
    >
      <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
        {i18n.t('artist.recommended.upcoming', { district })}
      </Text>
      <ItemChip
        theme={theme}
        onPress={() =>
          navigation.push('ArtistDetail', {
            artistId: artist?.id,
            section: 'spotify',
          })
        }
      >
        <ImageIconAvatar
          style={{
            marginRight: 10,
            backgroundColor: theme.colors.elevation.level4,
          }}
          uri={artist?.photo_url}
          icon={'account-music'}
          size={50}
        />
        <Row
          style={{
            flex: 1,
            justifyContent: 'flex-start',
          }}
        >
          <Text
            style={{ flex: 1, fontWeight: 'bold' }}
            variant={'bodyLarge'}
            numberOfLines={1}
          >
            {artist?.name?.trim()}
          </Text>
          <MaterialCommunityIcons
            name={'play-circle'}
            size={40}
            color={colors.spotifyColor}
          />
        </Row>
      </ItemChip>
      <Button
        mode={'contained-tonal'}
        onPress={() => navigation.push('EventDetail', { eventId })}
      >
        {i18n.t('artist.recommended.eventInfo')}
      </Button>
    </View>
  );
};

export default RecommendedArtist;
