import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ScrollView, TextInput, View } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';

import CommentCards from '../components/comment/CommentCards';
import CommentInput from '../components/comment/CommentInput';
import Loading from '../components/shared/Loading';
import { showInfo } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { useCommentDetailQuery } from '../services/CommentService';
import i18n from '../translations/i18n';

const CommentDetailScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const route = useRoute<RouteProp<MainStackParamList, 'CommentDetail'>>();
  const commentInputRef: MutableRefObject<TextInput | null> =
    useRef<TextInput>(null);

  const commentId = route.params.commentId;

  useEffect(() => {
    if (navigationReady && !commentId) {
      navigation.replace('MainTab', { screen: 'CommentFeed' });
    }
  }, [navigationReady, commentId]);

  const [refreshing, setRefreshing] = useState(false);
  const {
    data: comment,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  } = useCommentDetailQuery(commentId);

  useEffect(() => {
    if (isFetched) {
      if (!comment) {
        showInfo(i18n.t('comment.invalidComment'));
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('MainTab', { screen: 'StoryFeed' });
        }
      }
    }
  }, [comment]);

  const onRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loading = isLoading || isFetching || !comment;
  return loading ? (
    <Loading />
  ) : (
    <View style={{ flex: 1 }}>
      <ScrollView
        overScrollMode={'never'}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <CommentCards commentId={commentId!} detail={true} />
      </ScrollView>
      <CommentInput parent={comment} ref={commentInputRef} />
    </View>
  );
};

export default CommentDetailScreen;
