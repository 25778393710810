import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Button, useTheme } from 'react-native-paper';
import styled from 'styled-components/native';

import { Story } from '../../repositories/StoryRepository';
import { useUpsertStoryMutation } from '../../services/StoryService';
import i18n from '../../translations/i18n';
import { ResourceType } from '../../utils/enums';
import { getId } from '../../utils/id';
import { uploadImage } from '../../utils/upload';
import Loading from '../shared/Loading';
import { showError, showSuccess } from '../shared/Toast';
import {
  StoryArtist,
  StoryDescription,
  StoryFormDistrict,
  StoryImages,
  StoryPlace,
  StoryEvent,
  StoryTitle,
} from './StoryFormFields';

const StoryFormContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
`;

export type StoryFormInputs = Story;
export type StoryFormType = UseFormReturn<StoryFormInputs>;

const StoryForm: React.FC<{ story: Partial<Story> }> = ({ story }) => {
  const theme = useTheme();
  const edit = !!story.id;
  const [uploading, setUploading] = useState<boolean>(false);
  const { mutateAsync: upsertStory, isLoading } = useUpsertStoryMutation();
  const navigation = useNavigation<any>();

  const form = useForm<StoryFormInputs>({
    defaultValues: story,
  });

  const {
    handleSubmit,
    setFocus,
    reset,
    formState: { isDirty, dirtyFields },
  } = form;

  useEffect(() => {
    reset(story);
  }, [story]);

  const onSubmit = async ({ ...story }: StoryFormInputs) => {
    if (edit && !isDirty) {
      return;
    }
    if (!story.id) {
      story.id = getId();
    }
    try {
      if (story.image_urls?.length && dirtyFields.image_urls) {
        setUploading(true);
        const uploads = story.image_urls.map((url: string) =>
          url.startsWith('http')
            ? Promise.resolve(url)
            : uploadImage(url, ResourceType.STORY, story.id),
        );
        story.image_urls = await Promise.all(uploads);
        setUploading(false);
      }
      story.youtube_urls =
        story.youtube_urls?.filter(url => url)?.map(url => url.trim()) ?? [];
      const result = await upsertStory(story);
      showSuccess(
        edit
          ? i18n.t('common.form.modified')
          : i18n.t('common.form.registered'),
      );
      if (result?.id) {
        if (edit) {
          navigation.navigate('StoryDetail', { storyId: result.id });
        } else {
          navigation.replace('StoryDetail', { storyId: result.id });
        }
      }
    } catch (error) {
      showError(error);
    }
  };

  const onError = (errors: FieldErrors) => {
    const errorNames: (keyof FieldErrors)[] = Object.keys(errors);
    const hasError = !!errorNames.length;
    if (hasError) {
      showError(i18n.t('common.form.checkInput'));
      setFocus(errorNames[0] as keyof StoryFormInputs);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          disabled={edit && !isDirty}
          labelStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={handleSubmit(onSubmit, onError)}
        >
          {edit ? i18n.t('common.save') : i18n.t('common.post')}
        </Button>
      ),
    });
  }, [isDirty]);

  const showLoading = uploading || isLoading;
  return (
    <StoryFormContainer>
      {showLoading && <Loading />}
      <StoryTitle theme={theme} form={form} />
      <StoryEvent theme={theme} form={form} />
      <StoryArtist theme={theme} form={form} />
      <StoryPlace theme={theme} form={form} />
      <StoryFormDistrict theme={theme} form={form} />
      {/*<StoryYoutubeUrls theme={theme} form={form} />*/}
      {/*<StoryInstagramUrl theme={theme} form={form} />*/}
      <StoryDescription theme={theme} form={form} />
      <StoryImages theme={theme} form={form} />
    </StoryFormContainer>
  );
};

export default StoryForm;
