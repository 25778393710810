import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { MD3Theme } from 'react-native-paper/src/types';
import styled from 'styled-components/native';

import { Notification } from '../../repositories/NotificationRepository';
import i18n from '../../translations/i18n';
import { toDateTimeString } from '../../utils/datetimes';

const Card = styled.View<{ theme: MD3Theme }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 8px 20px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level3};
  border-bottom-width: 1px;
`;

const NotificationCard: React.FC<{
  notification: Notification;
  onPress: (notification: Notification) => void;
}> = ({ notification, onPress }) => {
  const theme = useTheme();
  let text;
  try {
    const options = JSON.parse(notification.message ?? '{}');
    text = i18n.t(options.template, options);
  } catch {}
  if (!notification || !text) {
    return null;
  }
  return (
    <TouchableOpacity onPress={() => onPress(notification)}>
      <Card
        theme={theme}
        style={{
          backgroundColor: notification.read
            ? theme.colors.elevation.level1
            : theme.colors.elevation.level2,
        }}
      >
        <Text variant="bodyMedium" numberOfLines={3}>
          {text}
        </Text>
        <Text
          style={{
            color: theme.colors.outline,
          }}
          variant="bodySmall"
        >
          {toDateTimeString(notification.created_at)}
        </Text>
      </Card>
    </TouchableOpacity>
  );
};

export default NotificationCard;
