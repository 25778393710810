import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import React, { useEffect } from 'react';

import DefaultDialog from '../components/dialogs/DefaultDialog';
import ProfileCardList from '../components/profile/ProfileCardList';
import { useDialog } from '../components/providers/DialogProvider';
import { showInfo } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { UserService } from '../services/UserService';
import i18n from '../translations/i18n';

const UserBlockedUsersScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const { showDialog, hideDialog } = useDialog();
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (navigationReady && !userId) {
      navigation.replace('SignIn');
    }
  }, [navigationReady, userId]);

  return (
    <ProfileCardList
      blockUserId={userId}
      onPressItem={profile =>
        showDialog(
          <DefaultDialog
            content={i18n.t('common.unblockConfirm', { name: profile.name })}
            confirmText={i18n.t('common.unblock')}
            onPressConfirm={async () => {
              await UserService.unblockUser(userId!, profile.id);
              hideDialog();
              showInfo(i18n.t('common.unblockSuccess'));
            }}
            onPressDismiss={hideDialog}
          />,
        )
      }
    />
  );
};

export default UserBlockedUsersScreen;
