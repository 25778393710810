import { MaterialCommunityIcons } from '@expo/vector-icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { MD3Theme } from 'react-native-paper/src/types';
import { ImageSourcePropType } from 'react-native/Libraries/Image/Image';
import styled from 'styled-components/native';

import { Event } from '../../repositories/EventRepository';
import i18n from '../../translations/i18n';
import { isPast, toDateTimeString } from '../../utils/datetimes';
import AutoHeightImage from '../shared/AutoHeightImage';
import { Chip, ChipContainer } from '../shared/Chip';
import { Row } from '../shared/Layouts';

const Card = styled.View<{ theme: MD3Theme }>`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  padding: 15px 0;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.elevation.level3};
  border-bottom-width: 1px;
`;

const ContentWrapper = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 15px;
  flex: 1;
`;

const Content = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
`;

const MAX_ARTIST_LENGTH = 10;

const EventCard: React.FC<{
  event: Event;
  onPress: (event: Event) => void;
}> = ({ event, onPress }) => {
  const theme = useTheme();
  const uri = event?.image_urls?.[0];
  const thumbnailUri = uri?.replace('/images/', '/images/thumbs/');
  const placeholder = require('../../../assets/images/event-placeholder.png');
  const source = uri ? { uri } : placeholder;
  const thumbnailSource = thumbnailUri ? { uri: thumbnailUri } : placeholder;
  const [thumbnail, setThumbnail] =
    useState<ImageSourcePropType>(thumbnailSource);

  const artistNames = _(event.artists)
    .slice(0, MAX_ARTIST_LENGTH - 1)
    .map(({ name }) => name.trim())
    .value();
  if (artistNames.length >= MAX_ARTIST_LENGTH) {
    artistNames.push('...');
  }
  const agencyNames = _(event.agencies)
    .slice(0, MAX_ARTIST_LENGTH - 1)
    .map(({ name }) => name.trim())
    .value();
  if (agencyNames.length >= MAX_ARTIST_LENGTH) {
    agencyNames.push('...');
  }

  return event ? (
    <Pressable onPress={() => onPress(event)}>
      <Card theme={theme}>
        <View>
          <AutoHeightImage
            width={150}
            style={{
              borderRadius: 5,
            }}
            contentFit={'contain'}
            source={thumbnail}
            onError={() => setThumbnail(source)}
          />
          {isPast(event.event_date!) && (
            <Text
              style={{
                textAlign: 'center',
                position: 'absolute',
                fontSize: 16,
                fontWeight: 'bold',
                bottom: 0,
                zIndex: 1,
                width: '100%',
                paddingVertical: 5,
                backgroundColor: theme.colors.primary,
                color: theme.colors.onPrimary,
              }}
            >
              {i18n.t('event.card.past')}
            </Text>
          )}
        </View>
        <ContentWrapper>
          <Content>
            <Text style={{ fontSize: 18 }}>{event.title?.trim()}</Text>
            {!!artistNames?.length && (
              <ChipContainer
                style={[agencyNames?.length ? { marginBottom: 0 } : undefined]}
              >
                {artistNames.map((name: string, index: number) => (
                  <Chip
                    style={{ backgroundColor: theme.colors.elevation.level2 }}
                    theme={theme}
                    key={index}
                  >
                    <Text
                      style={{
                        maxWidth: '100%',
                        color: theme.colors.onSurface,
                      }}
                      numberOfLines={1}
                      variant={'bodySmall'}
                    >
                      {name}
                    </Text>
                  </Chip>
                ))}
              </ChipContainer>
            )}
            {!!agencyNames?.length && (
              <ChipContainer>
                {agencyNames.map((name: string, index: number) => (
                  <Chip
                    style={{ backgroundColor: theme.colors.elevation.level3 }}
                    theme={theme}
                    key={index}
                  >
                    <Text
                      style={{
                        maxWidth: '100%',
                        color: theme.colors.onSurface,
                      }}
                      numberOfLines={1}
                      variant={'bodySmall'}
                    >
                      {name}
                    </Text>
                  </Chip>
                ))}
              </ChipContainer>
            )}
            {!!event.place && (
              <Text
                style={{ color: theme.colors.onSurfaceVariant }}
                variant={'bodyMedium'}
              >
                {event.place.name}
              </Text>
            )}
            <Text
              style={{ color: theme.colors.onSurfaceVariant }}
              variant={'bodyMedium'}
            >
              {toDateTimeString(event.event_date)}
            </Text>
            {!!event.advance_ticket_price && (
              <Text style={{ fontWeight: 'bold' }} variant={'bodyMedium'}>
                {i18n.t('event.card.advance', {
                  advance_ticket_price:
                    event.advance_ticket_price.toLocaleString(),
                })}
              </Text>
            )}
            {!!event.door_ticket_price && (
              <Text style={{ fontWeight: 'bold' }} variant={'bodyMedium'}>
                {i18n.t('event.card.door', {
                  door_ticket_price: event.door_ticket_price.toLocaleString(),
                })}
              </Text>
            )}
            {!event.advance_ticket_price && !event.door_ticket_price && (
              <Text style={{ fontWeight: 'bold' }} variant={'bodyMedium'}>
                {i18n.t('event.card.price')}
              </Text>
            )}
          </Content>
          {(event.save_count || event.view_count) > 0 && (
            <Row style={{ justifyContent: 'flex-end', gap: 5 }}>
              {event.save_count > 0 && (
                <Text
                  style={{
                    fontSize: 15,
                    color: theme.colors.outline,
                  }}
                >
                  <MaterialCommunityIcons name={'bookmark-outline'} size={15} />
                  {event.save_count}
                </Text>
              )}
              {event.view_count > 0 && (
                <Text
                  style={{
                    fontSize: 15,
                    color: theme.colors.outline,
                  }}
                >
                  <MaterialCommunityIcons name={'eye-outline'} size={14} />{' '}
                  {event.view_count}
                </Text>
              )}
            </Row>
          )}
          {event.attended?.[0]?.rating && (
            <Text
              style={{
                fontSize: 15,
                color: theme.colors.secondary,
              }}
            >
              {i18n.t('event.card.rated') + ' '}
              <MaterialCommunityIcons name={'star'} size={14} />{' '}
              {event.attended[0]?.rating}
            </Text>
          )}
        </ContentWrapper>
      </Card>
    </Pressable>
  ) : null;
};

export default EventCard;
