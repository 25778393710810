import { NavigationContext } from '@react-navigation/native';
import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import React, { useContext, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { Text } from 'react-native-paper';
import { RefreshControl } from 'react-native-web-refresh-control';

import { Profile, ProfilesParams } from '../../repositories/ProfileRepository';
import { useProfilesQuery } from '../../services/ProfileService';
import i18n from '../../translations/i18n';
import ProfileCard from '../profile/ProfileCard';
import { Column } from '../shared/Layouts';
import { SmallLoading } from '../shared/Loading';

const ProfileCardList: React.FC<
  ProfilesParams & { onPressItem?: (profile: Profile) => void }
> = params => {
  const {
    data: profiles,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
    remove,
  } = useProfilesQuery(params);
  const [refreshing, setRefreshing] = useState(false);
  const ref = useRef<FlatList>(null);

  if (useContext(NavigationContext)) {
    useScrollToTop(ref);
  }

  const onRefresh = async () => {
    setRefreshing(true);
    remove();
    await refetch();
    setRefreshing(false);
  };

  const loading = isLoading || isFetching;
  const empty = !!profiles?.pages?.[0]?.end;

  return empty ? (
    <Column style={{ flex: 1 }}>
      <Text>{i18n.t('profile.cardList.profile.empty')}</Text>
    </Column>
  ) : (
    <FlatList
      ref={ref}
      showsVerticalScrollIndicator={false}
      data={profiles?.pages?.map(({ profiles }) => profiles).flat()}
      renderItem={({ item }) => (
        <ProfileCard
          key={item?.id}
          profile={item!}
          onPress={params?.onPressItem}
        />
      )}
      keyExtractor={(item, index) => `${item?.id}${index}`}
      onEndReached={() => !loading && hasNextPage && fetchNextPage()}
      onEndReachedThreshold={0.5}
      ListFooterComponent={!refreshing && loading ? <SmallLoading /> : null}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    />
  );
};

export default ProfileCardList;
