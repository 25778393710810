import React from 'react';
import { Text } from 'react-native-paper';
import styled from 'styled-components/native';

import i18n from '../../translations/i18n';
import { formatDate } from '../../utils/datetimes';

const TermDatesContainer = styled.TouchableOpacity`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const TermDates: React.FC<{
  accepted_terms_at?: string | null;
  accepted_marketing_terms_at?: string | null;
}> = ({ accepted_terms_at, accepted_marketing_terms_at }) => {
  return (
    <TermDatesContainer>
      {accepted_terms_at && (
        <Text>
          {i18n.t('term.dates.accepted_terms_at')}:{' '}
          {formatDate(new Date(accepted_terms_at))}
        </Text>
      )}
      {accepted_marketing_terms_at && (
        <Text>
          {i18n.t('term.dates.accepted_marketing_terms_at')}:{' '}
          {formatDate(new Date(accepted_marketing_terms_at))}
        </Text>
      )}
    </TermDatesContainer>
  );
};

export default TermDates;
