import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import { Image, ImageBackground } from 'expo-image';
import * as Linking from 'expo-linking';
import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { Row } from '../components/shared/Layouts';
import Loading from '../components/shared/Loading';
import SignInButton, {
  AppleSignInButton,
  FacebookSignInButton,
  GoogleSignInButton,
  KakaoSignInButton,
} from '../components/shared/SignInButton';
import { showError } from '../components/shared/Toast';
import { MainStackParamList } from '../navigation/MainStack';
import { useNavigationReady } from '../navigation/NavigationReadyContext';
import { getNotificationPermission } from '../notification';
import PushTokenRepository from '../repositories/PushTokenRepository';
import { captureEvent } from '../sentry';
import PlaceService from '../services/PlaceService';
import {
  UserData,
  UserProfile,
  UserService,
  useUpsertUserProfileMutation,
} from '../services/UserService';
import i18n from '../translations/i18n';
import { WEB_URL } from '../utils/constants';
import { trim } from '../utils/helpers';
import { getId } from '../utils/id';
import { getCurrentLocation } from '../utils/locationHelpers';

const TermsContainer = styled(Text)`
  color: #fff;
  flex-direction: row;
  margin-top: 15px;
  width: 65%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Intro = styled.View`
  margin: 5% 10% 10%;
`;

const IntroText = styled(Text)`
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
`;

const TEN_SECONDS_MS = 10 * 1000;

const SignInScreen: React.FC = () => {
  const navigationReady = useNavigationReady();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: upsertUserProfile, isLoading } =
    useUpsertUserProfileMutation();

  const signUp = async () => {
    setLoading(true);
    const timer: { [key: string]: number } = {};
    const timeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
        showError(i18n.t('signIn.signUpFailure'));
        captureEvent({
          message: 'signup timout',
          level: 'error',
          extra: { timer: JSON.stringify(timer, null, 2) },
        });
      }
      clearTimeout(timeout);
    }, TEN_SECONDS_MS);
    let time = new Date().getTime();
    const location = await getCurrentLocation();
    let duration = new Date().getTime() - time;
    timer['location'] = duration;
    time = new Date().getTime();
    const district = await PlaceService.getDistrictByLocation(location);
    duration = new Date().getTime() - time;
    timer['district'] = duration;
    time = new Date().getTime();
    let photo_url = '';
    try {
      await fetch(user?.user_metadata?.avatar_url);
      photo_url = user?.user_metadata?.avatar_url;
    } catch {}
    const now = new Date().toISOString();
    const { enabled: notification, pushToken } =
      await getNotificationPermission();
    if (notification && pushToken) {
      PushTokenRepository.insertPushToken({
        token: pushToken,
        user_id: user?.id!,
        platform: Platform.OS,
      });
    }
    duration = new Date().getTime() - time;
    timer['notification'] = duration;
    time = new Date().getTime();
    let name =
      trim(user?.user_metadata?.name) ||
      trim(user?.email?.split('@')?.[0]) ||
      getId();
    const exists = await UserService.existsName(name);
    if (exists) {
      name = getId();
    }
    duration = new Date().getTime() - time;
    timer['existsName'] = duration;
    time = new Date().getTime();
    const profile = {
      email: user?.email,
      id: user?.id!,
      name,
      photo_url,
      district,
      ...location,
      accepted_terms_at: now,
      accepted_marketing_terms_at: notification ? now : undefined,
    };
    await upsertUserProfile(profile as UserProfile);
    duration = new Date().getTime() - time;
    timer['upsertUserProfile'] = duration;
    clearTimeout(timeout);
    setLoading(false);
  };

  const redirection = async () => {
    if (navigationReady && user) {
      const userData = user?.user_metadata as UserData;
      const isSignedUp = userData?.accepted_terms_at;
      if (!isSignedUp) {
        await signUp();
      } else {
        navigation.replace('MainTab', { screen: 'EventFeed' });
      }
    }
  };

  useEffect(() => {
    redirection();
  }, [navigationReady, user]);

  const showLoading = isLoading || loading;
  return (
    <View style={{ flex: 1 }}>
      {showLoading && (
        <Loading
          text={loading ? i18n.t('signIn.signingUp') : undefined}
          extra={loading ? i18n.t('signIn.signingUpExtra') : undefined}
        />
      )}
      <ImageBackground
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
        }}
        source={require('../../assets/images/signin.jpg')}
        contentFit={'cover'}
      >
        <Image
          style={{ width: '80%', height: 100 }}
          source={require('../../assets/images/logo-wide-tr.png')}
          contentFit={'contain'}
        />
        <Intro>
          <IntroText>{i18n.t('signIn.IntroText')}</IntroText>
        </Intro>
        <GoogleSignInButton />
        <AppleSignInButton />
        <FacebookSignInButton />
        <KakaoSignInButton />
        <SignInButton
          icon={'account-multiple'}
          label={i18n.t('signIn.anonymous')}
          onPress={() => navigation.replace('MainTab', { screen: 'EventFeed' })}
        />
        <View
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TermsContainer>
            {i18n.t('term.checkboxes.pre_sentence')}
            <Text
              numberOfLines={1}
              style={{
                color: '#fff',
                fontWeight: 'bold',
                textDecorationLine: 'underline',
              }}
              onPress={() =>
                Linking.openURL(`${WEB_URL}/terms?lang=${i18n.locale}`)
              }
            >
              {i18n.t('term.checkboxes.terms_and_conditions')}
            </Text>
            {i18n.t('term.checkboxes.and')}
            <Text
              numberOfLines={1}
              style={{
                color: '#fff',
                fontWeight: 'bold',
                textDecorationLine: 'underline',
              }}
              onPress={() =>
                Linking.openURL(`${WEB_URL}/terms/privacy?lang=${i18n.locale}`)
              }
            >
              {i18n.t('term.checkboxes.privacy_policy')}
            </Text>
            {i18n.t('term.checkboxes.post_sentence')}
          </TermsContainer>
        </View>
        <Row style={{ gap: 10 }}>
          <Text onPress={() => Linking.openURL(WEB_URL)}>
            {i18n.t('setting.about')}
          </Text>
          <Text onPress={() => Linking.openURL('https://cs.livewire.so/')}>
            {i18n.t('setting.customer_service')}
          </Text>
        </Row>
      </ImageBackground>
    </View>
  );
};

export default SignInScreen;
