export enum ResourceType {
  PROFILE,
  ARTIST,
  PLACE,
  EVENT,
  STORY,
  COMMENT,
  ATTENDED_EVENT,
}

export const getResourceTypeName = (type: ResourceType) => {
  switch (type) {
    case ResourceType.PROFILE:
      return 'profiles';
    case ResourceType.ARTIST:
      return 'artists';
    case ResourceType.PLACE:
      return 'places';
    case ResourceType.EVENT:
      return 'events';
    case ResourceType.STORY:
      return 'stories';
    case ResourceType.COMMENT:
      return 'comments';
    case ResourceType.ATTENDED_EVENT:
      return 'attended_events';
    default:
      return 'common';
  }
};
