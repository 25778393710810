import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useUser } from '@supabase/auth-helpers-react';
import { isAfter } from 'date-fns';
import * as Linking from 'expo-linking';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import { MD3Theme, Text } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { Artist } from '../../repositories/ArtistRepository';
import EventRepository, {
  Event,
  EventStats,
} from '../../repositories/EventRepository';
import { RelatedNews } from '../../repositories/NewsRepository';
import i18n from '../../translations/i18n';
import { parse, toDateTimeString } from '../../utils/datetimes';
import { getDimensions } from '../../utils/dimensions';
import linkify from '../../utils/linkify';
import DetailImages from '../shared/DetailImages';
import DetailItem, { ItemChip, ItemContainer } from '../shared/DetailItem';
import Dot from '../shared/Dot';
import { Row } from '../shared/Layouts';
import Loading from '../shared/Loading';
import RenderHtml from '../shared/RenderHtml';
import TextInputButton from '../shared/TextInputButton';
import { showError, showInfo } from '../shared/Toast';
import EventAttendedEventItem from './EventAttendedEventItem';

const EventDetailContent: React.FC<{
  event: Event;
  news?: RelatedNews | null;
  theme: MD3Theme;
  hasPermission: boolean;
}> = ({ event, news, theme, hasPermission }) => {
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<EventStats | undefined>(undefined);
  const { width } = getDimensions();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  let description = event.description;
  if (event.ref_id?.startsWith('PF')) {
    description += `\n\n공연 정보 출처: 공연예술통합전산망 (kopis.or.kr)`;
  }
  const Html = React.memo(() => (
    <RenderHtml source={{ html: linkify(description) }} />
  ));
  const past = isAfter(new Date(), parse(event.event_date)!);

  return (
    <View>
      {loading && <Loading />}
      <Text style={{ fontWeight: 'bold', padding: 20 }} variant={'titleLarge'}>
        {event.title?.trim()}
      </Text>
      <ItemContainer
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <Text style={{ color: theme.colors.onSurface }} variant={'titleMedium'}>
          <MaterialCommunityIcons size={16} name={'calendar'} />{' '}
          {toDateTimeString(event.event_date)}
        </Text>
        {!!event.place && (
          <Pressable
            style={{ paddingVertical: 5 }}
            onPress={() =>
              navigation.push('PlaceDetail', { placeId: event.place_id! })
            }
          >
            <Text
              style={{ fontWeight: 'bold', color: theme.colors.primary }}
              variant={'titleMedium'}
            >
              <MaterialCommunityIcons
                color={theme.colors.primary}
                size={16}
                name={'map-marker-outline'}
              />{' '}
              {event.place.name ?? i18n.t('event.detailContent.seeLink')}
            </Text>
          </Pressable>
        )}
        {!!event.artists?.length && (
          <>
            <Text style={{ paddingVertical: 10, fontSize: 18 }}>
              {i18n.t('event.detailContent.lineUp')}
            </Text>
            <View
              style={{
                padding: 5,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: theme.colors.onSurface,
                gap: 5,
                marginBottom: 5,
              }}
            >
              {event.artists.map((artist: Artist, index: number) => (
                <DetailItem
                  key={index}
                  theme={theme}
                  uri={artist?.photo_url}
                  icon={'account-music'}
                  iconSize={25}
                  label={artist?.name}
                  chevron={false}
                  onPress={() =>
                    navigation.push('ArtistDetail', { artistId: artist.id })
                  }
                />
              ))}
            </View>
          </>
        )}
        {!!event.agencies?.length && (
          <>
            <Text style={{ paddingVertical: 10, fontSize: 18 }}>
              {i18n.t('event.detailContent.presents')}
            </Text>
            <View
              style={{
                padding: 5,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: theme.colors.primaryContainer,
                gap: 5,
                marginBottom: 5,
              }}
            >
              {event.agencies?.map((artist: Artist, index: number) => (
                <DetailItem
                  key={index}
                  theme={theme}
                  uri={artist?.photo_url}
                  icon={'account-music'}
                  iconSize={25}
                  label={artist?.name}
                  chevron={false}
                  onPress={() =>
                    navigation.push('ArtistDetail', { artistId: artist.id })
                  }
                />
              ))}
            </View>
          </>
        )}
        {news?.title && news?.link && (
          <>
            <DetailItem
              theme={theme}
              icon={'newspaper-variant-outline'}
              iconSize={25}
              numberOfLines={2}
              label={news.title}
              chevron={false}
              onPress={() => Linking.openURL(news.link)}
            />
          </>
        )}
        <DetailItem
          theme={theme}
          icon={'text-box'}
          iconSize={22}
          label={i18n.t('event.detailContent.eventStory')}
          onPress={() =>
            navigation.push('MainTab', {
              screen: 'StoryFeed',
              params: {
                eventId: event.id,
              },
            })
          }
        />
        {past && (
          <EventAttendedEventItem theme={theme} event={event} user={user} />
        )}
        <TextInputButton
          theme={theme}
          onPress={() =>
            user
              ? navigation.push('StoryAdd', {
                  eventId: event.id,
                })
              : showInfo(i18n.t('common.pleaseSignIn'))
          }
          placeholder={i18n.t('event.detailContent.postEventStory')}
        />
        {past && hasPermission && !stats && (
          <DetailItem
            theme={theme}
            icon={'cash'}
            iconColor={theme.colors.onPrimary}
            iconBackgroundColor={theme.colors.primary}
            iconSize={22}
            label={i18n.t('event.detailContent.stats')}
            onPress={async () => {
              setLoading(true);
              const response = await EventRepository.getEventStats(event.id);
              if (response?.revenue) {
                setStats(response);
              } else {
                showError(i18n.t('event.detailContent.statsNotFound'));
              }
              setLoading(false);
            }}
          />
        )}
        {stats && (
          <ItemChip
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            theme={theme}
            disabled
          >
            <Text>
              {i18n.t('event.detailContent.audience', {
                audience: stats.audience.toLocaleString(),
              })}
            </Text>
            <Text>
              {i18n.t('event.detailContent.revenue', {
                revenue: stats.revenue.toLocaleString(),
              })}
            </Text>
            <Text>{i18n.t('event.detailContent.statsWarning')}</Text>
          </ItemChip>
        )}
      </ItemContainer>
      <DetailImages width={width} images={(event.image_urls ?? []).slice(1)} />
      <Html />
      <Text
        style={{ paddingHorizontal: 20, color: theme.colors.outline }}
        variant={'bodySmall'}
      >
        {i18n.t('event.detailContent.warning')}
      </Text>
      <Row
        style={{
          justifyContent: 'flex-start',
          gap: 3,
          paddingVertical: 10,
          paddingHorizontal: 20,
        }}
      >
        {event.save_count > 0 && (
          <>
            <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
              {i18n.t('common.interest')}: {event.save_count}
            </Text>
            <Dot style={{ color: theme.colors.outline }} />
          </>
        )}
        <Text style={{ color: theme.colors.outline }} variant={'bodySmall'}>
          {i18n.t('common.view')}: {event.view_count + 1}
        </Text>
      </Row>
    </View>
  );
};

export default EventDetailContent;
