import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { sendError, showError } from '../components/shared/Toast';
import ArtistRepository, {
  Artist,
  ArtistsParams,
} from '../repositories/ArtistRepository';
import NewsRepository, { RelatedNews } from '../repositories/NewsRepository';
import UserRepository from '../repositories/UserRepository';
import { refetchAll, resetAll } from '../utils/queryClient';
import { UserProfile, UserService } from './UserService';

export default class ArtistService {
  static hasEditPermission(
    artist?: Artist | null,
    user?: UserProfile | null,
  ): boolean {
    if (!artist || !user) {
      return false;
    }
    if (artist.created_by === user.id || user.admin) {
      return true;
    }
    return UserService.isManager(user, [artist]);
  }

  static async getArtists(
    params: ArtistsParams,
  ): Promise<{ artists: Artist[]; offset?: number; end: boolean }> {
    if (params.managerId) {
      const { data } = await ArtistRepository.getManagedArtists(
        params.managerId,
        params.offset,
      );
      const artists = (data
        ?.filter(({ artists }) => !!artists)
        .map(({ artists }) => artists)
        .flat() ?? []) as unknown as Artist[];
      return {
        artists,
        offset: params.offset,
        end: !artists?.length,
      };
    }
    const { data } = await ArtistRepository.getArtists(params);
    const artists = ((data ?? []) as unknown as Artist[]).filter(
      artist => !artist?.created?.blocked?.length,
    );
    return {
      artists,
      offset: params.offset,
      end: !artists?.length,
    };
  }

  static async existsHandle(handle: string): Promise<boolean> {
    const { data, error } = await ArtistRepository.getArtistByHandle(handle);
    if (error) {
      showError(error);
      return false;
    }
    return !!data;
  }

  static async likeArtist(artistId: string): Promise<void> {
    try {
      await UserRepository.insertUserLikedArtist(artistId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }

  static async unlikeArtist(artistId: string): Promise<void> {
    try {
      await UserRepository.deleteUserLikedArtist(artistId);
      await refetchAll();
    } catch (e) {
      sendError(e);
    }
  }

  static async upsertArtist({
    liked,
    created,
    ...artist
  }: NonNullable<Artist>) {
    const { data: upserted, error } = await ArtistRepository.upsertArtist(
      artist,
    );
    if (error) {
      return Promise.reject(error);
    }
    if (!upserted) {
      throw new Error('Upsert failure');
    }
    return upserted;
  }
}

export function useArtistsQuery(params: ArtistsParams) {
  return useInfiniteQuery(
    ['artists', params],
    async ({ pageParam }) =>
      await ArtistService.getArtists({ ...params, offset: pageParam }),
    {
      getNextPageParam: ({ offset = 0, end }) =>
        end ? null : offset + ArtistRepository.PAGE_SIZE,
    },
  );
}

export function useArtistDetailQuery(id?: string) {
  return useQuery<Artist | null>(['artistDetail', id], async () => {
    if (!id) {
      return null;
    }
    const artist = (await ArtistRepository.getArtist(id)).data as Artist;
    if (artist?.created?.blocked?.length) {
      return null;
    }
    return artist;
  });
}

export function useArtistRelatedNewsQuery(id?: string) {
  return useQuery<RelatedNews | null>(['artistRelatedNews', id], async () => {
    if (!id) {
      return null;
    }
    const news = await NewsRepository.getArtistRelatedNews(id);
    if (!news?.title) {
      return null;
    }
    return news;
  });
}

export function useUpsertArtistMutation() {
  return useMutation(
    async (artist: Artist) => await ArtistService.upsertArtist(artist),
    {
      onSuccess: async artist => await resetAll(),
    },
  );
}

export function useDeleteArtistMutation(artistId?: string) {
  return useMutation(
    () =>
      artistId
        ? ArtistRepository.deleteArtist(artistId)
        : Promise.reject(new Error('Invalid ArtistId')),
    {
      onSuccess: async () => await resetAll(),
    },
  );
}
