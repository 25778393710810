import React from 'react';

const YoutubePlayer: React.FC<{ youtubeId: string; height: number }> = ({
  youtubeId,
  height,
}) => (
  <iframe
    style={{
      width: '100%',
      height,
      border: 0,
    }}
    allowFullScreen
    allow={
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    }
    src={`https://www.youtube.com/embed?listType=playlist&list=UU${youtubeId}`}
  ></iframe>
);

export default YoutubePlayer;
