import * as ImagePicker from 'expo-image-picker';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Platform } from 'react-native';
import { Divider, MD3Theme, TextInput } from 'react-native-paper';
import styled from 'styled-components/native';

import { Artist } from '../../repositories/ArtistRepository';
import { Event } from '../../repositories/EventRepository';
import { Place } from '../../repositories/PlaceRepository';
import CommonService from '../../services/CommonService';
import i18n from '../../translations/i18n';
import { REGEXP_URL } from '../../utils/regexps';
import { pickImage } from '../../utils/upload';
import ArtistCardList from '../artist/ArtistCardList';
import EventCardList from '../event/EventCardList';
import { FormAutoCompleteSelect, FormTextInput } from '../form/FormInputs';
import ImagesSelector from '../form/ImageSelector';
import DistrictList from '../place/DistrictList';
import PlaceCardList from '../place/PlaceCardList';
import { StoryFormType } from './StoryForm';

export const StoryFormDivider = styled(Divider)<{ theme: MD3Theme }>`
  background-color: ${({ theme }) => theme.colors.onSurface};
  transform: scaleY(0.5);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StoryTitle: React.FC<{ theme: MD3Theme; form: StoryFormType }> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name="title"
    rules={{
      required: i18n.t('common.formField.title.rules.required'),
      maxLength: {
        value: 100,
        message: i18n.t('common.formField.title.rules.maxLength'),
      },
      validate: async value => {
        if (!value) {
          return;
        }
        const bannedWords = await CommonService.findBannedWords(value);
        if (!bannedWords) {
          return;
        }
        return i18n.t('common.foundBannedKeyword', { keyword: bannedWords });
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('common.formField.title.label') + ' *'}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={100}
        errorMessage={errors.title?.message as string}
      />
    )}
  />
);

export const StoryDescription: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name="description"
    rules={{
      required: i18n.t('story.formField.description.rules.required'),
      maxLength: {
        value: 1000,
        message: i18n.t('story.formField.description.rules.maxLength'),
      },
      validate: async value => {
        if (!value) {
          return;
        }
        const bannedWords = await CommonService.findBannedWords(value);
        if (!bannedWords) {
          return;
        }
        return i18n.t('common.foundBannedKeyword', { keyword: bannedWords });
      },
    }}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <FormTextInput
        ref={ref}
        theme={theme}
        label={i18n.t('story.formField.description.label') + ' *'}
        placeholder={i18n.t('story.formField.description.placeholder')}
        placeholderTextColor={'grey'}
        multiline
        numberOfLines={Platform.OS === 'web' ? 3 : undefined}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        maxLength={1000}
        errorMessage={errors.description?.message as string}
      />
    )}
  />
);

export const StoryEvent: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    getValues,
    setValue,
  },
}) => {
  return (
    <Controller
      control={control}
      name="event.title"
      rules={{
        validate: () => {
          const event = getValues('event');
          if (!event?.title) {
            return;
          }
          if (!event.id) {
            return i18n.t('story.formField.title.validate.not_found_id');
          }
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormAutoCompleteSelect
          theme={theme}
          label={i18n.t('story.formField.event.label')}
          onBlur={onBlur}
          onChange={onChange}
          onSelectItem={(event: Event) =>
            setValue('event', event, { shouldDirty: true })
          }
          value={value ?? ''}
          maxLength={30}
          errorMessage={errors.event?.message as string}
          ListComponent={EventCardList}
        />
      )}
    />
  );
};

export const StoryArtist: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    getValues,
    setValue,
  },
}) => {
  return (
    <Controller
      control={control}
      name="artist.name"
      rules={{
        validate: () => {
          const artist = getValues('artist');
          if (!artist?.name) {
            return;
          }
          if (!artist.id) {
            return i18n.t('story.formField.artist.validate.not_found_id');
          }
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormAutoCompleteSelect
          theme={theme}
          label={i18n.t('story.formField.artist.label')}
          onBlur={onBlur}
          onChange={onChange}
          onSelectItem={(artist: Artist) =>
            setValue('artist', artist, { shouldDirty: true })
          }
          value={value ?? ''}
          maxLength={30}
          errorMessage={errors.artist?.message as string}
          ListComponent={ArtistCardList}
        />
      )}
    />
  );
};

export const StoryPlace: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    getValues,
    setValue,
  },
}) => {
  return (
    <Controller
      control={control}
      name="place.name"
      rules={{
        validate: () => {
          const place = getValues('place');
          if (!place?.name) {
            return;
          }
          if (!place.id) {
            return i18n.t('story.formField.place.validate.not_found_id');
          }
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormAutoCompleteSelect
          theme={theme}
          label={i18n.t('story.formField.place.label')}
          onSelectItem={(place: Place) =>
            setValue('place', place, { shouldDirty: true })
          }
          onBlur={onBlur}
          onChange={onChange}
          value={value ?? ''}
          maxLength={30}
          errorMessage={errors.place?.message as string}
          ListComponent={PlaceCardList}
        />
      )}
    />
  );
};

export const StoryFormDistrict: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
    setValue,
  },
}) => (
  <Controller
    control={control}
    name={'district'}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormAutoCompleteSelect
        displaySuggestion
        theme={theme}
        label={i18n.t('story.formField.district')}
        onSelectItem={item =>
          setValue('district', item.name, { shouldDirty: true })
        }
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        errorMessage={errors.district?.message as string}
        ListComponent={DistrictList}
      />
    )}
  />
);

export const StoryYoutubeUrls: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({ theme, form }) => {
  const urls = form.watch('youtube_urls') ?? [''];
  const right = useCallback(
    (index: number) => {
      const last = urls.length <= 0 ? true : urls.length - 1 === index;
      if (index >= 9) {
        return null;
      }
      if (last) {
        return (
          <TextInput.Icon
            icon={'plus'}
            onPress={() => {
              if (urls.length >= 10) {
                return;
              }
              form.setValue('youtube_urls', [...urls, ''], {
                shouldDirty: true,
              });
            }}
          />
        );
      }
      return (
        <TextInput.Icon
          icon={'minus'}
          onPress={() => {
            urls.splice(index, 1);
            form.setValue('youtube_urls', [...urls], {
              shouldDirty: true,
            });
          }}
        />
      );
    },
    [urls],
  );
  if (!urls?.length) {
    return (
      <StoryYoutubeUrl theme={theme} form={form} index={0} right={right(0)} />
    );
  }
  return (
    <>
      {urls.map((_, index) => (
        <StoryYoutubeUrl
          key={index}
          theme={theme}
          form={form}
          index={index}
          right={right(index)}
        />
      ))}
    </>
  );
};

export const StoryYoutubeUrl: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
  index: number;
  right: React.ReactNode;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
  index,
  right,
}) => (
  <Controller
    control={control}
    name={`youtube_urls.${index}`}
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('story.formField.youtube_urls')}
        right={right}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.youtube_urls?.[index]?.message as string}
      />
    )}
  />
);

export const StoryInstagramUrl: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({
  theme,
  form: {
    control,
    formState: { errors },
  },
}) => (
  <Controller
    control={control}
    name="instagram_url"
    rules={{
      pattern: {
        value: REGEXP_URL,
        message: i18n.t('common.formField.url.rules.pattern'),
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <FormTextInput
        theme={theme}
        label={i18n.t('story.formField.instagram_url')}
        onBlur={onBlur}
        onChange={onChange}
        value={value ?? ''}
        keyboardType={'url'}
        maxLength={100}
        errorMessage={errors.instagram_url?.message as string}
      />
    )}
  />
);

export const StoryImages: React.FC<{
  theme: MD3Theme;
  form: StoryFormType;
}> = ({ form: { control, setValue } }) => {
  return (
    <Controller
      control={control}
      name={'image_urls'}
      render={({ field: { value } }) => (
        <ImagesSelector
          images={value ?? []}
          size={10}
          onAddImage={async () => {
            const urls = await pickImage({
              mediaTypes: ImagePicker.MediaTypeOptions.Images,
              base64: false,
              quality: 1,
              allowsMultipleSelection: true,
              selectionLimit: 10 - (value?.length ?? 0),
            });
            if (urls?.length) {
              setValue(
                'image_urls',
                [...(value ?? []), ...urls].filter(url => url).slice(0, 10),
                {
                  shouldDirty: true,
                },
              );
            }
          }}
          onRemoveImage={index => {
            if (value?.length) {
              value.splice(index, 1);
              setValue('image_urls', [...value], {
                shouldDirty: true,
              });
            }
          }}
        />
      )}
    />
  );
};
