import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { MD3Theme, Text } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/src/components/Icon';
import { VariantProp } from 'react-native-paper/lib/typescript/src/components/Typography/types';
import styled from 'styled-components/native';

import ImageIconAvatar from './ImageIconAvatar';
import { Row } from './Layouts';

export const ItemContainer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const ItemChip = styled.TouchableOpacity<{
  theme: MD3Theme;
  borderColor?: string;
  backgroundColor?: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 5px;
  border-width: ${props => (props.borderColor ? 1 : 0)}px;
  border-color: ${props => props.borderColor};
  background-color: ${props =>
    props.backgroundColor ?? props.theme.colors.elevation.level1};
`;

const DetailItem: React.FC<{
  theme: MD3Theme;
  uri?: string | null;
  icon: IconSource;
  iconSize: number;
  iconColor?: string;
  iconBackgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  label?: string;
  numberOfLines?: number;
  variant?: VariantProp<any>;
  chevron?: boolean;
  onPress?: () => void;
}> = ({
  theme,
  uri,
  icon,
  iconSize,
  iconColor,
  iconBackgroundColor,
  textColor,
  borderColor,
  backgroundColor,
  label,
  numberOfLines = 1,
  variant = 'bodyMedium',
  onPress,
  chevron = true,
}) => (
  <ItemChip
    theme={theme}
    borderColor={borderColor}
    backgroundColor={backgroundColor}
    onPress={onPress}
    disabled={!onPress}
  >
    <ImageIconAvatar
      style={{
        marginRight: 10,
        backgroundColor: iconBackgroundColor ?? theme.colors.elevation.level4,
      }}
      uri={uri}
      icon={icon}
      size={iconSize}
      color={iconColor}
    />
    <Row
      style={{
        flex: 1,
        justifyContent: chevron ? 'space-between' : 'flex-start',
      }}
    >
      <Text
        style={[{ flex: 1 }, textColor ? { color: textColor } : undefined]}
        variant={variant}
        numberOfLines={numberOfLines}
      >
        {label?.trim()}
      </Text>
      {chevron && (
        <MaterialCommunityIcons
          name={'chevron-right'}
          size={iconSize}
          color={textColor ?? theme.colors.onSurface}
        />
      )}
    </Row>
  </ItemChip>
);

export default DetailItem;
