import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { useTheme } from 'react-native-paper';

import { MainStackParamList } from '../../navigation/MainStack';
import { Story } from '../../repositories/StoryRepository';
import DetailItem, { ItemContainer } from '../shared/DetailItem';

const StoryItems: React.FC<{ story: Story }> = ({ story }) => {
  const theme = useTheme();
  const navigation =
    useNavigation<NativeStackNavigationProp<MainStackParamList>>();
  return (
    <ItemContainer>
      {!!story.event && (
        <DetailItem
          theme={theme}
          icon={'calendar'}
          iconSize={25}
          label={story.event.title}
          onPress={() =>
            navigation.push('EventDetail', {
              eventId: story.event?.id,
            })
          }
        />
      )}
      {!!story.artist && (
        <DetailItem
          theme={theme}
          uri={story.artist?.photo_url}
          icon={'account-music'}
          iconSize={25}
          label={story.artist?.name}
          onPress={() =>
            navigation.push('ArtistDetail', {
              artistId: story.artist?.id,
            })
          }
        />
      )}
      {!!story.place && (
        <DetailItem
          theme={theme}
          icon={'map-marker'}
          iconSize={25}
          label={story.place.name}
          onPress={() =>
            navigation.push('PlaceDetail', {
              placeId: story.place?.id,
            })
          }
        />
      )}
    </ItemContainer>
  );
};

export default StoryItems;
