import 'expo/build/Expo.fx';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { Platform } from 'react-native';

import App from './App';
import codePush from './src/utils/codePush';
import { WEB_MAX_WIDTH } from './src/utils/constants';

if (Platform.OS === 'web') {
  const userAgent = navigator.userAgent;
  const appUrl = /android/i.test(userAgent)
    ? // @ts-ignore
      document.querySelector('meta[property="al:android:url"]')?.content
    : // @ts-ignore
    /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
    ? // @ts-ignore
      document.querySelector('meta[property="al:ios:url"]')?.content
    : null;
  if (appUrl) {
    window.location = appUrl;
  }
  // @ts-ignore
  document.documentElement.style.WebkitOverflowScrolling = 'touch !important';
  document.documentElement.style.overflow = 'auto !important';
  document.documentElement.style.overscrollBehavior = 'none';
  // @ts-ignore
  document.body.style.WebkitOverflowScrolling = 'touch !important';
  document.body.style.overflow = 'auto !important';
  document.body.style.overscrollBehavior = 'none';
  document.body.style.position = 'fixed';
  document.body.style.top = '0';
  document.body.style.right = '0';
  document.body.style.bottom = '0';
  document.body.style.left = '0';
  document.body.style.margin = 'auto';
  document.body.style.maxWidth = `${WEB_MAX_WIDTH}px`;
  const container = (document.getElementById('root') ??
    document.getElementById('main'))!;
  const rootTag = createRoot(container);
  rootTag.render(createElement(App));
} else {
  if (__DEV__) {
    registerRootComponent(App);
  } else {
    registerRootComponent(codePush()(App));
  }
}
