import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import React from 'react';
import { Platform, Share } from 'react-native';
import { useTheme } from 'react-native-paper';

import i18n from '../../translations/i18n';
import IconButton from './IconButton';

const ShareButton: React.FC<{
  title: string;
  url: string;
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  size?: number;
  color?: string;
  shadow?: boolean;
}> = ({ title, url, icon, size = 25, color, shadow }) => {
  const theme = useTheme();
  return (
    <IconButton
      onPress={() => share({ title, url })}
      tintColor={color ?? theme.colors.onBackground}
      icon={icon ?? 'share-variant'}
      size={size}
      shadow={shadow}
    />
  );
};

export const share = async ({ title, url }: { title: string; url: string }) => {
  const message = `${title}\n${url}`;
  if (Platform.OS === 'web') {
    await Clipboard.setStringAsync(message);
    alert(`${i18n.t('common.linkHasBeenCopiedToTheClipboard')}\n\n${message}`);
    return;
  }
  await Share.share({ message });
};

export default ShareButton;
