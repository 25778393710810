import * as FileSystem from 'expo-file-system';
import { ImageResult, manipulateAsync } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import {
  ImagePickerAsset,
  ImagePickerOptions,
} from 'expo-image-picker/src/ImagePicker.types';
import { Platform } from 'react-native';

import { showError, showInfo } from '../components/shared/Toast';
import i18n from '../translations/i18n';
import { getResourceTypeName, ResourceType } from './enums';
import http from './http';

const MAX_WIDTH = 1280;
const THUMBNAIL_WIDTH = 300;
const IMAGE_SIZE_LIMIT_IN_MB = 10;

export async function pickImage(
  options?: ImagePickerOptions,
): Promise<string[] | undefined> {
  const result = await ImagePicker.launchImageLibraryAsync(options);
  if (!result.assets?.length) {
    return;
  }
  const images: string[] = [];
  for (const image of result.assets) {
    const uri = image.uri;
    if (!result.canceled && uri) {
      const isBase64 = uri.startsWith('data');
      const byte = isBase64 ? getBase64Byte(uri) : await getFileSize(image);
      const size = toMB(byte);
      if (uri && size <= IMAGE_SIZE_LIMIT_IN_MB) {
        images.push(uri);
      } else {
        showInfo(i18n.t('common.formField.imageSizeOver'));
        return;
      }
    }
  }
  return images;
}

export function toMB(bytes: number): number {
  return bytes / 1048576;
}

export async function getFileSize(image: ImagePickerAsset): Promise<number> {
  if (image.fileSize) {
    return image.fileSize;
  }
  const fileInfo = await FileSystem.getInfoAsync(image.uri);
  if (fileInfo.exists) {
    return fileInfo.size;
  }
  return 0;
}

export function getBase64Byte(base64String: string): number {
  const base64Image = base64String.split(',')[1];
  return base64Image.replace(/=/g, '').length * 0.75;
}

async function resize(url: string, width: number): Promise<ImageResult> {
  try {
    return await manipulateAsync(url, [{ resize: { width } }], {
      compress: 0.9,
    });
  } catch (e) {
    throw e;
  }
}

async function toBlob(uri: string, name: string): Promise<Blob> {
  if (Platform.OS === 'web') {
    return (await fetch(uri)).blob();
  }
  return {
    uri,
    name,
    type: 'multipart/form-data',
  } as unknown as Blob;
}

export async function uploadImage(
  url: string,
  type: ResourceType,
  key: string,
  width?: number,
): Promise<string> {
  const sizes = [width ?? MAX_WIDTH, THUMBNAIL_WIDTH];
  const resized: ImageResult[] = await Promise.all(
    sizes.map(async width => await resize(url, width)),
  );
  const formData = new FormData();
  formData.append('original', await toBlob(resized[0].uri, 'original'));
  formData.append('thumbnail', await toBlob(resized[1].uri, 'thumbnail'));
  const fileType = getResourceTypeName(type);

  try {
    const { data } = await http.post(
      `/api/resources/images/${fileType}/${key}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data.url;
  } catch (e) {
    showError(e);
    return '';
  }
}
